import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { ProductVariant } from "../../../Models/Product";
import { ColorDetails } from "../../../Models/ProductOptions";
import { productColorForVariant } from "../../../utils";

interface ProductDetailColorChooserProps {
  onColorSelected: (color: ColorDetails) => void;
  variants: ProductVariant[];
  colorTable: ColorDetails[];
  selected: ColorDetails | null;
}

const ProductDetailColorChooser = (props: ProductDetailColorChooserProps) => {
  const colors = (() => {
    const uniqueVariants = props.variants.reduce((prevResult, currVariant) => {
      let result: ProductVariant[] = [...prevResult];
      let found = prevResult.find(
        (item) => item.colorId === currVariant.colorId
      );

      if (!found) {
        result.push(currVariant);
      }
      return result;
    }, [] as ProductVariant[]);

    return uniqueVariants
      .map((variant) => {
        // lookup variant's color value
        return productColorForVariant(variant, props.colorTable);
      })
      .filter((colorValue) => colorValue);
  })();

  return (
    <Grid
      container
      spacing={3}
      //columnSpacing={4}
      columns={{ xs: 12, sm: 12, md: 12 }}
      //columns={2}
    >
      {colors.map((color, idx) => (
        <Grid xs={4} sm={4} md={4} item key={color?.color_id}>
          <IconButton
            onClick={() => props.onColorSelected(color!)}
            key={idx}
            sx={(theme) => ({
              display: "grid",
              placeItems: "center",
              height: "6.5rem",
              width: "100%",
              borderRadius: "0.5rem",
              border: `2px solid ${
                props.selected?.color_id === color!.color_id
                  ? theme.palette.primary.main
                  : "lightgray"
              }`,
            })}
          >
            <Box
              sx={{
                width: "2rem",
                height: "2rem",
                borderRadius: "1rem",
                backgroundColor: `rgba(${color!.color_value.r}, ${
                  color!.color_value.g
                }, ${color!.color_value.b}, 1.0)`,
              }}
            />
            <Typography
              textTransform={"capitalize"}
              fontWeight="medium"
              variant="body2"
            >
              {color?.color_title}
            </Typography>
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductDetailColorChooser;
