/**
 * Abstract: A view that shows a toolbar for the edit form
 */
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import React from 'react'
import DCBackButton from './DCBackButton'
import { Inventory, SellerApp } from "../../routes";
import useMediaQuery from "@mui/material/useMediaQuery";
 

interface EditFormHeaderProps {
    productId: string 
    lastUpdated: string
    onSave: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
    isSubmitting: boolean
    prevScreenURL: string
}

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
   
    children: React.ReactElement;
  }

function ElevationScroll(props: Props) {
    const { children } = props;
    
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      //target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }
  

const EditFormHeader = ({productId, lastUpdated, onSave, isSubmitting}: EditFormHeaderProps) => {

    const navigate = useNavigate();
    const matches = useMediaQuery((theme: any)=>theme.breakpoints.up('md'));
      
    const typographyVariant = matches ? "headline" : "footnote"
    const btnVariant = matches ? "large" : "medium"
   return (
  <Stack alignItems="flex-start" sx={{
   mx: "-0.5rem",
   mt: "-0.25rem"
  }} width="inherit">
    <ElevationScroll>
       
          <Toolbar  sx={{
            width: "inherit",
            px: 0, 
            justifyContent: "space-between"
          }}>
          <DCBackButton variant={btnVariant}  onClick={()=> navigate(`/${SellerApp.pathname}/${Inventory.products}`)} />
          {/* <Typography variant="title3">Product Detail</Typography> */}
          <Typography variant={typographyVariant}>Last Updated: {lastUpdated}</Typography>
           <Typography variant={typographyVariant}>ProductID: {productId}</Typography> 
           
          </Toolbar>
       </ElevationScroll>
   
  </Stack>
  )
}

export default EditFormHeader