/**
 * interprets the model and process data
 */

import { DataObj } from "../../../Models/DataObj";
import { ResultType } from "../../../Models/Result";
import cloudinaryAPIManager, {
  CloudinaryAPIError,
} from "../../../networking/CloudinaryAPIManager/CloudinaryAPIManager";
import { ResourceType, UploadedAsset } from "../../../Models/Cloudinary";
//import { Colors, Sizes } from "../../../Models/Product";

export class InventoryDataManager {

  static shared = new InventoryDataManager()

  async uploadFile(
    file: File,
    folderPath: string,
    publicId: string
  ): Promise<CloudinaryAPIError | UploadedAsset> {
    
    let { REACT_APP_CLOUDINARY_API_KEY } = process.env;

    if (!REACT_APP_CLOUDINARY_API_KEY) {
      return CloudinaryAPIError.default;
    }

    // get signature
    const signatureResult = await cloudinaryAPIManager.generateSignature({
      public_id: publicId,
      folder: folderPath,
    });
    if (signatureResult.type === ResultType.Failure) {
      return signatureResult.error;
    }
    // upload to cloudinary
    const uploadResult = await cloudinaryAPIManager.upload(
      file,
      signatureResult.value,
      folderPath,
      publicId,
      //CLOUDINARY_API_KEY
      REACT_APP_CLOUDINARY_API_KEY
    );
    if (uploadResult.type === ResultType.Failure) {
      return uploadResult.error;
    }

    // return appropriate value
    return uploadResult.value;
    // return value
  }

  async deleteProductPhoto(
    folderPath: string,
    publicId: string
  ): Promise<CloudinaryAPIError | DataObj> {
    let { REACT_APP_CLOUDINARY_API_KEY } = process.env;
    const publicIdPath = folderPath + publicId;

    if (!REACT_APP_CLOUDINARY_API_KEY) {
      return CloudinaryAPIError.default;
    }

    const signatureResult = await cloudinaryAPIManager.generateSignature({
      public_id: publicIdPath,
    });
    if (signatureResult.type === ResultType.Failure) {
      return signatureResult.error;
    }

    const destroyResult = await cloudinaryAPIManager.destroyPhotoWithSignature(
      publicIdPath,
      signatureResult.value,
      REACT_APP_CLOUDINARY_API_KEY,
      ResourceType.image
    );

    if (destroyResult.type === ResultType.Failure) {
      return destroyResult.error;
    }

    return destroyResult.value;
  }
  
  /**
   * generates all possible combinations of valid attribute options
   * @param sizeAttribute 
   * @param colorAttribute 
   * @returns Set<T[]>
   */
  generateProductVariants<T>(selectedAttributes: {sizes: string[], colors: string[]}): Set<T[]> {
    
    let colorAttribute: DataObj = {}
    let sizeAttribute: DataObj = {}

    selectedAttributes.colors.forEach(
      (color, idx) => (colorAttribute[`color${idx + 1}`] = color)
    );
    selectedAttributes.sizes.forEach((size, idx) => (sizeAttribute[`size${idx + 1}`] = size));

     let variants: Set<T[]> = new Set()
     let sizeEntries = Object.entries(sizeAttribute).filter((entry)=> entry[1] !== "")
     let colorEntries = Object.entries(colorAttribute).filter((entry)=> entry[1] !== "")
     for (let sizeEntry of sizeEntries) {
      for (let colorEntry of colorEntries) {
          variants.add([sizeEntry[1], colorEntry[1]]) 
       }
      }

      return variants

  }
}

const inventoryDataManager = InventoryDataManager.shared
export default inventoryDataManager
