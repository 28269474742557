import React, { useContext, useEffect, useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";

import SearchIcon from "@mui/icons-material/Search";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { PCTab, ShoppingApp } from "../../routes";
import {   useLocation, useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { AppDataContext } from "../../contexts/AppDataContext";
import { AccountCircle } from "@mui/icons-material";
import { isIOS } from "react-device-detect";

const PCBottomBar = () => {
  const [value, setValue] = useState(0);
  const appData = useContext(AppDataContext);
  //const history = useHistory()
  const location = useLocation();
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [isShowingBottomBar, setIsShowingBottomBar] = useState<boolean>(true);

  //const navigate = useNavigate()

  const savedItemsCount = (() => {
    return appData.cart.reduce(
      (partialResult, currItem) => partialResult + currItem.quantity,
      0
    );
  })();

  const destinationForTab = (tabIdx: number): string => {
    switch (tabIdx) {
      case 2:
        // if it's currently selected and we on a child route
        if (value === tabIdx && location.pathname !== ShoppingApp.bag) {
          return ShoppingApp.bag;
        } else {
          return ShoppingApp.getLastVisitedForRoute(PCTab.bag);
        }
      case 1:
        if (value === tabIdx && location.pathname !== ShoppingApp.favorites) {
          return ShoppingApp.favorites;
        } else {
          return ShoppingApp.getLastVisitedForRoute(PCTab.wishlist);
        }
        case 0:
        if (value === tabIdx && location.pathname !== ShoppingApp.basename) {
          return ShoppingApp.basename;
        } else {
          return ShoppingApp.getLastVisitedForRoute(PCTab.search);
        }
      default:
        return ShoppingApp.basename;
    }
  };

 
  useEffect(() => {
    if (
      pathname.includes("/orderConfirmation") ||
      pathname.includes("/legal") ||
      isIOS
    ) {
      setIsShowingBottomBar(false);
    } else {
      setIsShowingBottomBar(true);
    }
    switch (true) {
      case pathname.includes("/account"):
        setValue(3);
        break;
      case pathname.includes("/bag"):
        setValue(2);
        break;
      case pathname.includes("/favorites"):
        setValue(1);
        break;
      case pathname.includes("/"):
        setValue(0);
        break;
      default:
        setValue(0);
    }
  }, [pathname]);

 

  return (
    <Paper
      sx={{
        visibility: isShowingBottomBar ? "visible" : "hidden",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 105,
      }}
      elevation={3}
    >
      <BottomNavigation
        sx={{
          "&.MuiBottomNavigation-root": {
            backgroundColor: "primary.main",
            //color: "blue"
          },
          "& .Mui-selected": {
            color: "#442C2E !important",
          },

          "& .MuiBottomNavigationAction-root": {
            color: "gray",
          },
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {/* <Button onClick={() => navigate("/")}>Search</Button> */}
        <BottomNavigationAction
          onClick={()=>{
            navigate(destinationForTab(0))
          }}
          // component={Link}
          // to={`${destinationForTab}`}
          //to={`${ShoppingApp.getLastVisitedForRoute(PCTab.search)}`}
          label="Explore"
          icon={<SearchIcon />}
        />
        <BottomNavigationAction
           onClick={()=>{
            navigate(destinationForTab(1))
          }}
          // component={Link}
          // to={`${destinationForTab}`}
        
          label="Favorites"
          icon={<FavoriteIcon />}
        />
        <BottomNavigationAction
          // component={Link}
          // to={`${destinationForTab}`}
          onClick={()=>{
            navigate(destinationForTab(2))
          }}
          label="Bag"
          icon={
            <Badge color="secondary" badgeContent={savedItemsCount}>
              <ShoppingBagIcon />
            </Badge>
          }
        />
        <BottomNavigationAction
           onClick={()=>{
            navigate(ShoppingApp.account)
          }}
          //  component={Link}
          //  to={ShoppingApp.account}
          label="Account"
          icon={<AccountCircle />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default PCBottomBar;
