import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { SignupCredentials } from "../../Model/SignupCredentials";
import { signupValidationSchema } from "../../Model/SignupValidationSchema";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
//import useMediaQuery from "@mui/material/useMediaQuery";

import { Typography } from "@mui/material";

import signupDataManager from "../../Model/SignupDataManager";
import { APIError } from "../../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { AuthUser } from "../../../../../Models/User";
import { NotificationContext } from "../../../../../contexts/NotificationContext";
import {
  NotificationActionType,
  NotificationType,
} from "../../../../../Models/Notification";
import { UserAccountContext } from "../../../../../contexts/UserAccountContext";
import { UserAccountActionType } from "../../../../UserAccount/Models/UserAccount";
import { SUPABASE_API_KEY } from "../../../../../defaultValues";
import { useContext, useState } from "react";
import { LoginCredentials } from "../../Model/LoginState";
import DCCheckbox from "../../../../SupportingViews/ShowPassword";
import { AuthUserProfile } from "../../../../../Models/UserProfile";
import { PresentationContext } from "../../../../../contexts/PresentationContext";
/*
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";*/
import { AppDataContext } from "../../../../../contexts/AppDataContext";
import { useLocation } from "react-router-dom";

//import DCButtonLink from "../../../SupportingViews/DCButtonLink";

export interface SignupFormProps {
  loginCredentials: LoginCredentials | null;
  onForgotPasswordClick: () => void;
}

const SignupForm = (props: SignupFormProps) => {
  const { dispatch } = useContext(NotificationContext);
  const {
    dispatch: dispatchAccountAction,
    saveCredentials,
    loadUserprofile,
  } = useContext(UserAccountContext);
  const { dismiss } = useContext(PresentationContext);

  const [showPassword, setShowPassword] = useState(false);
   
  const appData = useContext(AppDataContext)
  const location = useLocation()


  //const matches = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  const handleChange = (e: any, checked: any) => {
    setShowPassword(checked);
  };

  // const handleOptInChange = (e: any, checked: any) => {
  //   setOptIn(checked);
  // };

  const today = new Date().toDateString();

  const formik = useFormik<SignupCredentials>({
    initialValues: {
      firstname: "",
      lastname: "",
      birthday: new Date(today),
      email: props.loginCredentials?.email ?? "",
      password: props.loginCredentials?.password ?? "",
      allowsMarketing: true
    }, //signupCredentials,
    enableReinitialize: true,
    validationSchema: signupValidationSchema,
    onSubmit: async (credentials) => {
      //console.dir(credentials)
      
      const result = await signupDataManager.signupUser(
        credentials,
        SUPABASE_API_KEY
      );
      const apiError = result as APIError;
      if (apiError.errorDescription) {
        dispatch({
          type: NotificationActionType.open,
          payload: {
            type: NotificationType.error,
            msg: apiError.errorDescription,
          },
        });
      } else {
        let auth = result as AuthUser;
        let profileResult = await loadUserprofile(auth);
        const apiError = profileResult as APIError;
        if (apiError.errorDescription) {
          dispatch({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.error,
              msg: apiError.errorDescription,
            },
          });
          return;
        }
        profileResult = profileResult as AuthUserProfile;
        if (profileResult.authUser) {
          auth = profileResult.authUser;
          saveCredentials(profileResult.authUser);
        } else {
          saveCredentials(auth);
        }
        dispatchAccountAction({
          type: UserAccountActionType.addAuth,
          payload: auth,
        });

        dismiss?.();
        
        appData.gotoShoppingApp(location.pathname)


        // close currently opened notification
        dispatch({
          type: NotificationActionType.close,
          payload: {
            type: NotificationType.error,
            msg: apiError.errorDescription,
          },
        });
        formik.resetForm();
      }
    },
  });


  // let datepicker = (() => {
  //   console.dir(formik.values)
  //   return matches ? (
  //     <DesktopDatePicker
  //       label="Birthday"
  //       inputFormat="MM/DD/YYYY"
  //       value={dayjs(formik.values.birthday)}
  //       onChange={(value) => {
  //         if (value?.isValid()) {
  //           formik.setFieldValue("birthday", value.toDate(), true);
  //         }
  //       }}
  //       renderInput={(params) => {
  //         console.dir(formik);
  //         return (
  //           <TextField
  //             fullWidth
  //             id="birthday"
  //             name="birthday"
  //             {...params}
  //             helperText={String(formik.errors.birthday ?? "")}
  //             error={Boolean(formik.errors.birthday)}
  //           />
  //         );
  //       }}
  //     />
  //   ) : (
  //     <MobileDatePicker
  //       label="Birthday"
  //       inputFormat="MM/DD/YYYY"
  //       value={dayjs(formik.values.birthday)}
  //       onChange={(value) => {
  //         if (value?.isValid()) {
  //           formik.setFieldValue("birthday", value.toDate(), true);
  //         }
  //       }}
  //       renderInput={(params) => {
  //         // console.dir(formik)
  //         return (
  //           <TextField
  //             fullWidth
  //             id="birthday"
  //             name="birthday"
  //             {...params}
  //             helperText={String(formik.errors.birthday ?? "")}
  //             error={Boolean(formik.errors.birthday)}
  //           />
  //         );
  //       }}
  //     />
  //   );
  // })();

  return (
    <Stack
      className="signup-form"
      component="form"
      onSubmit={formik.handleSubmit}
      spacing={3}
      width={"100%"}
      direction="column"
    >
      <TextField
        fullWidth
        id="firstname"
        name="firstname"
        label="Firstname"
        value={formik.values.firstname}
        onChange={formik.handleChange}
        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
        helperText={formik.touched.firstname && formik.errors.firstname}
      />

      <TextField
        fullWidth
        id="lastname"
        name="lastname"
        label="Lastname"
        value={formik.values.lastname}
        onChange={formik.handleChange}
        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
        helperText={formik.touched.lastname && formik.errors.lastname}
      />
      {/* <Stack alignItems={"flex-start"} direction="column">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {datepicker}
        </LocalizationProvider>
      </Stack> */}
      

      <Stack spacing={0.5}>
        <TextField
          type="email"
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
         <DCCheckbox
          id="allowsMarketing"
        sx={{
          alignItems: "flex-start",
          marginTop: "0px",
          color: (theme) => theme.palette.text.primary,
        }}
        text="Use email for transactional purposes"
        onChange={formik.handleChange}
        checked={formik.values.allowsMarketing}
      />
      </Stack>

      <TextField
        fullWidth
        id="password"
        name="password"
        label="Password"
        type={showPassword ? "type" : "password"}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />

      <DCCheckbox
     
        //name="allows-marketing"
        sx={{
          alignItems: "flex-end",
          marginTop: "8px",
          color: (theme) => theme.palette.text.primary,
        }}
        onChange={handleChange}
        checked={showPassword}
      />

      <LoadingButton
        size="large"
        loading={formik.isSubmitting}
        type="submit"
        fullWidth
        variant="contained"
      >
        Agree and continue
      </LoadingButton>
      <Typography
        sx={{
          opacity: 0.54,
        }}
        variant="caption2"
      >
        By selecting Agree and continue, I agree to Diamond Crusts’ Terms of
        Service, Payments Terms of Service and acknowledge the Privacy Policy.
      </Typography>
    </Stack>
  );
};

export default SignupForm;
