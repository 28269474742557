import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { ReactNode, useEffect } from "react";
import { AppData } from "../../Models/AppData";
import { BagItem, BagManager } from "../../Models/Bag";
import { DataObj } from "../../Models/DataObj";
import { LookupTable } from "../../Models/LookupTables";
import { ProductAttribute } from "../../Models/Product";
import { formatPrice, isDiscountRedeemable } from "../../utils";
import DeleteIcon from "@mui/icons-material/Delete";
//import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  
  
} from 'react-router-dom';
import { ShoppingApp } from "../../routes";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PriceView from "./PriceView";
import DiscountView from "./DiscountView";
 

interface BagItemViewProps {
  bagItem: BagItem;
  manager: BagManager;
  appData: AppData;
  onMount: ()=>void
  onDelete: (variantId: string) => void
}

const BagItemView = ({ bagItem, manager, appData, onMount, onDelete }: BagItemViewProps) => {
  const sizeLookupTable = appData.getLookuptableForType(LookupTable.size);
  const colorLookupTable = appData.getLookuptableForType(LookupTable.color);
  
  const quantityOptions = manager.getAttributeOptionsForItem<number>(
    bagItem.productDetailId,
    ProductAttribute.quantity
  );

  const sellingPrice = bagItem.discountInfo && isDiscountRedeemable(bagItem.discountInfo) ? bagItem.discountInfo.value : bagItem.sellingPrice

  const itemPrice = formatPrice(sellingPrice); //manager.getPriceForItem(bagItem.product.productId);
  const totalPrice = formatPrice(sellingPrice * bagItem.selectedQuantity);
   

  const quantityOptionsViews = ((): ReactNode => {
    // return quantityOptions.map((quantity) => (
    return quantityOptions.map((quantity) => (
      <MenuItem
      id="menu-item"
        onClick={(e: any)=> e?.preventDefault()}
        sx={{ textTransform: "capitalize" }}
        key={quantity}
        value={quantity}
      >
        {quantity}
      </MenuItem>
    ));
  })();

  

 

  const itemAttributeStyle: SxProps<Theme> = {
    "&.MuiInputBase-root": {
      width: "100%",
      height: "100%",
      textTransform: "capitalize",
    },
  };

  const formControlStyle: SxProps<Theme> = {
    "&.MuiFormControl-root": {
      width: "45%",
      height: "3rem",
      textTransform: "capitalize",
    },
  };

  const cardLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
    itemProps,
    ref,
  ) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
  });

  const handleChange = (event: SelectChangeEvent) => {
   
    let attributeToUpdate = event.target.name as ProductAttribute;
    let valueForUpdate: DataObj | string;
     
    // get lookuptable of attribut to update
    if (attributeToUpdate === ProductAttribute.plating) {
      valueForUpdate = colorLookupTable.find(
        (color) => color.color_id === event.target.value
      );
    } else if (attributeToUpdate === ProductAttribute.size) {
      valueForUpdate = sizeLookupTable.find(
        (color) => color.size_id === event.target.value
      );
    } else {
      valueForUpdate = event.target.value;
    }

    if (!valueForUpdate) {
      return;
    }

    if (attributeToUpdate !== ProductAttribute.quantity) {
      return;
    }
    
    
    const newQuantity =  parseInt(valueForUpdate as string)
    const isSuccessful = appData.updateQuantityForItem(
      bagItem.productDetailId,
      newQuantity
    );

    if (isSuccessful) {
      // get event.target.value corresponding value from lookup table
      manager.updateAttributeValueForItem(
        bagItem.productDetailId,
        attributeToUpdate,
       newQuantity
      );
    }
  };

 
  useEffect(()=>{
     onMount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  return (
    <>
      <ListItem sx={{
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBlock: "0.5rem"
      }}
     
      
      component={cardLink}
      to={ShoppingApp.productDetailURL(ShoppingApp.bag, bagItem.product, bagItem.productDetailId)}
      >
        
        <IconButton 
           onClick={(event: any)=> {
            event?.preventDefault();
            onDelete(bagItem.productDetailId)}
          }
            >
          <DeleteIcon />
          
        </IconButton>
        <ListItemAvatar>
            <Avatar
              alt={bagItem.product.title}
              src={bagItem.photos["photo1"].url}
              sx={{ width: 80, height: 80, mr: "1rem" }}
              variant="rounded"
            />
          </ListItemAvatar>
        <Stack width="60%" spacing={1}>
        <Stack color={"text.primary"} display={isDiscountRedeemable(bagItem.discountInfo) ? "inherit" : "none"} direction="row" spacing={1} alignItems={"center"}>
            <DiscountView variant="short" discountInfo={bagItem.discountInfo}/>
          <PriceView isFavouriteCardView discountInfo={bagItem.discountInfo} sellingPrice={bagItem.sellingPrice} />
          </Stack>
          <Stack width={"100%"} justifyContent="space-between" direction="row">
            <Stack justifyContent={"flex-start"}> 
            <Typography textTransform="capitalize" color="#442C2E" variant="headline">
              {bagItem.product.title}
            </Typography>
            <Typography  textTransform="capitalize" color="#442C2E">{bagItem.selectedPlating.title} | {bagItem.selectedSize.title}</Typography>
            </Stack>
            <Typography color="#442C2E" variant="body">{itemPrice}</Typography>
          </Stack>
          <Stack width={"100%"} justifyContent="flex-start" direction="row">
            <FormControl sx={formControlStyle}>
              <InputLabel id="quantity">Quantity</InputLabel>
              <Select
                sx={itemAttributeStyle}
                //labelId="size"
                name={ProductAttribute.quantity}
                id="quantity"
                value={bagItem.selectedQuantity.toString()}
                label="Quantity"
                onClick={(e: any)=>{
                  e?.preventDefault();
                }}
                onChange={ (event: any) => {
                 
                  event.preventDefault()
                  handleChange(event)
                 // event.preventDefault()

                }
                }
              >
                {quantityOptionsViews}
              </Select>
            </FormControl>
          </Stack>
          
          <Typography color="#442C2E">Quantity {bagItem.selectedQuantity.toString()} | Price: {totalPrice}</Typography>
        </Stack>
      </ListItem>
      <Divider  />
    </>
  );
};

export default BagItemView;
