import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import {
  Outlet,
  Link as RouterLink
  
} from "react-router-dom";
import { useRouteMatch } from "../../../hooks";



const PCLegal = () => {
  const paths = [
    { value: "/legal/privacy", to: "/legal/privacy", label: "Privacy Policy" },
    { value: "/legal/tos", to: "/legal/tos", label: "Terms of Service" },
    {value: "/legal/return", to: "/legal/return", label: "Return Policy"},
    {value: "/legal/support", to: "/legal/support", label: "Support"}
  ];
  const routeMatch = useRouteMatch(paths.map((path) => path.value));
  const currentPath = routeMatch?.pattern?.path;

  return (
    <Stack spacing={3} paddingY={8} paddingX={{xs: 2, sm: 2, md: 20}}>
     
      <Tabs   value={currentPath} sx={{position: "sticky", top: "56px", bgcolor: "white", paddingY: "1rem"}} aria-label="legal">
        {paths.map((path) => (
          <Tab
            label={path.label}
            value={path.value}
            to={path.to}
            component={RouterLink}
          />
        ))}
        {/* <Tab label="Inbox" value="/inbox/:id" to="/inbox/1" component={RouterLink} />
      <Tab label="Drafts" value="/drafts" to="/drafts" component={RouterLink} /> */}
      </Tabs>
      <Outlet />
    </Stack>
  );
};

export default PCLegal;
