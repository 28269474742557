import * as yup from "yup";
//import { PhotoWithId } from "./Inventory";

export const productDetailsValidationSchema = yup.object().shape({
  description: yup
    .string()
    .required("Description must not be empty")
    .min(20, "Description must not be below 20 characters")
    .max(500, "Description must not exceed 500 characters"),

  title: yup
    .string()
    .required("Title must not be empty")
    .min(2, "Title must not be below 20 characters")
    .max(50, "Title must not exceed 50 characters"),

  /*
  stockQuantity: yup
    .number()

    .required("Please enter stock quantity")
    .test("is above 0", "Stock quantity must not be less than $0.00", (value) =>
      value && value >= 0 ? true : false
    ),*/

  wholesaleCost: yup
    .number()
    .required("Please enter wholesale cost")
    .test("is above 0", "Wholesale cost must not be less than $0.00", (value) =>
      value && value >= 0 ? true : false
    ),
  
  /*
  sellingPrice: yup
    .number()
    .required("Please enter selling price")
    .test("is above 0", "Selling price must not be less  than $0.00", (value) =>
      value && value >= 0 ? true : false
    ),*/

  subcategoryId: yup.string().required("Subcategory must not be empty"),

  categoryId: yup.string().required("Category must not be empty"),

  colors: yup
    .array()
    .required()
    .test("not empty", "Must choose at least one color", (value) =>
      value && value.length > 0 ? true : false
    ),

  sizes: yup
    .array()
    .required()
    .test("not empty", "Must choose at least one size", (value) =>
      value && value.length > 0 ? true : false
    ),

  accessories: yup
    .array()
    .required()
    .test("not empty", "Must choose at least one accessory", (value) =>
      value && value.length > 0 ? true : false
    ),
  /*
  photos: yup
    .array()
    .required()
    .test("Photo1 required", "Please upload the first photo", (value) => {
      // if (!value) { return false}
      // console.dir(value[0])
      //console.log(value && (value[0] as {photo1: string}).photo1 !== "" ? true : false)
      //return value && value[0].photo1 ? true : false
      return value && value[0][1].url ? true : false;
    }),*/

  variants: yup
    .array()
    .of(
      yup.object().shape({
        photos: yup.array().of(
          yup
            .array()
            .required()
            .test(
              "Photo1 required",
              "Please upload a photo",
              (value) => {
               
                return value && value[1].url ? true : false;
              }
            )
        ),
        // .required()
        // .test("Photo1 required", "Please upload the first photo", (value) => {
        //   return value && value[0][1].url ? true : false;
        // }),

        // discount: yup
        // .number()
        // .test(
        //   "is 0 and above",
        //   "Discount must not be less than 0",
        //   (value) => (value && value >= 0 ? true : false)
        // ),

        stockQuantity: yup
          .number()

          .required("Please enter stock quantity")
          .test(
            "is above 0",
            "Stock quantity must not be less than 0",
            (value) => (value && value >= 0 ? true : false)
          ),



        sellingPrice: yup
          .number()
          .required("Please enter selling price")
          .test(
            "is above 0",
            "Selling price must not be less  than $0.00",
            (value) => (value && value >= 0 ? true : false)
          ),
          /*
          isAvailable: yup 
            .bool()
            .required("One variant should be made available")
            .test(
              "is variant available",
              "One variant should be made available",
              (value) => (value === true)
            )*/
           
      })
        
    )
    .required("Please complete details for a variant and make it available")
    .test(
      "1 variant required",
      "Please complete details for a variant and make it available",
      (value) => {
         
        // console.dir(value)
        // console.log(value?.find((item) => item.isAvailable) !== undefined)
        
        return value?.find((item) => item.isAvailable) !== undefined ? true : false
        //return value && value.length >= 1 ? true : false;
      }
    ),
});

export const productVariantValidationSchema = yup.object().shape({
  variants: yup.array().test("1 variant required", "Make at least one variant available", (value) =>  {
    return value && value.length > 0 && value.some((item) => item.isAvailable) ? true : false
    
   })
  /*
  stockQuantity: yup
    .number()
    .required("Please enter stock quantity")
    .test("is above 0", "Stock quantity must not be less than 1", (value) =>
      value && value >= 0 ? true : false
    ),

  sellingPrice: yup
    .number()
    .required("Please enter selling price")
    .test("is above 0", "Selling price must not be less  than $0.00", (value) =>
      value && value >= 0 ? true : false
    ),*/
});
