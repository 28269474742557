import { AuthUser } from "./User"

export interface Signature {
    signature: string
    timestamp: number
} 

export enum ResourceType {
    image = "image",
    video = "video",
    raw = "raw"
}

export interface CloudinaryError {
    error: {
        message: string
    }
}

export interface UploadedAsset {
    asset_id: string
    secure_url: string
    public_id: string
    width: number
    height: number
    eager?: {secure_url?: string}[]
}

export interface AuthUploadedAsset {
    authUser: AuthUser | null 
    asset: UploadedAsset
}