 import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import React from 'react'
import { Review } from '../../../Models/Review'
import ReviewCard from '../../SupportingViews/ReviewCard'

interface ReviewsListProps {
    reviews: Review[]
    header:  ()=>JSX.Element
    onLoadMore?: ()=>void
}

const ReviewsList: React.FC<ReviewsListProps> = (props) => {
  return (
    <Stack spacing={2} alignItems={"center"}>
        {props.header()}
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {
        props.reviews.map(review => (
           <ListItem key={review.reviewId}>
            
            <ReviewCard variant="outlined" height="unset" review={review} />
           </ListItem>
        ))
      }
     
    </List>
    {props.onLoadMore && (
      <Button onClick={props.onLoadMore}>Show More</Button>
    )}
    </Stack>
  )
}

export default ReviewsList