import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import ScrollToTop from '../../SupportingViews/ScrollToTop'

const ReturnPolicy = () => {
  return (
    <Stack alignItems={"flex-start"} textAlign="left"  sx={{   '& .MuiTypography-body1': {color: "text.secondary"} }}>
          <ScrollToTop />
         <Typography variant="subtitle1">
        DETAILS
      </Typography>
        <Typography variant="body1">
            Thank you for your interest in our online store. We appreciate your business and want to ensure that you are satisfied with your purchase. We take pride in the quality of our products and want you to be completely satisfied with your purchase.
        </Typography>
        <Typography variant="body1">
            However, please note that any product sold on our website is a final sale. We do not offer returns or exchanges for any reason, except in cases where the product is damaged or defective upon arrival.
        </Typography>
        <Typography variant="body1">
         If you receive a damaged or defective product, please contact us immediately with photos of the damage and a description of the issue. We will work with you to provide a replacement product or a refund, depending on the circumstances.
        </Typography>
        <Typography variant="body1">
            Please note that all sales are final and no returns or exchanges will be accepted for any other reason. We appreciate your understanding and cooperation in this matter.
        </Typography>
        <Typography variant="body1">
        If you have any questions or concerns about our return policy, please do not hesitate to contact us. We value your satisfaction and are committed to providing high-quality products and customer service.
        </Typography>
    </Stack>
  )
}

export default ReturnPolicy