import React, { Dispatch } from "react";
import {
    NotificationState,
    NotificationAction,
    NotificationActionType,
  } from "../Models/Notification";

import { initialNotificationState } from "../defaultValues";

export const NotificationContext = React.createContext<{
    state: NotificationState;
    dispatch: Dispatch<NotificationAction>;
  }>({
    state: initialNotificationState,
    dispatch: () => null,
  });
  
export const notificationReducer = (
    state: NotificationState,
    action: NotificationAction
  ): NotificationState => {
    switch (action.type) {
      case NotificationActionType.close:
        return {
          ...state,
          opened: false,
          type: action.payload.type,
          msg: action.payload.msg,
        };
  
      case NotificationActionType.open:
        return {
          ...state,
          opened: true,
          type: action.payload.type,
          msg: action.payload.msg,
        };
  
      default:
        return state;
    }
  };