import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext, useEffect, useState } from "react";
import { Outlet  } from "react-router-dom";
import { InventoryContext } from "../contexts/InventoryContext";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import OfflineView from "../../SupportingViews/OfflineView";
 //import DCError from "../../SupportingViews/DCError";
 
const PCInventory = () => {

  const inventory = useContext(InventoryContext);
  const { getSignedInUser   } = useContext(UserAccountContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDesc, setErrorDesc] = useState<string | null>(null);
  //const [loadError, setLoadError] = useState<APIError | null>(null);

  const [counter, setCounter] = useState(0);
  
  
  const user = getSignedInUser();

   


  useEffect(() => {
    (async () => {
     
      
     if (inventory.getProducts().length > 0) {return} 
     setIsLoading(false);

       let apiError: APIError
       const loadProductsResult = await inventory.loadProductsForInventory(user, undefined, 0);
       apiError = (loadProductsResult as APIError)
        
      
      if (apiError.errorDescription) {
        setErrorDesc(apiError.errorDescription);
        setIsLoading(false);
        return;
      }
       // whether auth was updated by refresh token
      /* 
      if (authUser) {
        saveCredentials(authUser)
      }*/
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, user?.access_token, inventory.id]);

 
  if (isLoading) {
    return (
      <Box sx={{width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    );
  }
   
  
  if (errorDesc) {
     return (
      <OfflineView
        msg={errorDesc}
        sx={{ pt: 6, width: "100%", height: "100vh" }}
        onRetry={() => {
          setCounter((prev) => prev + 1);
          // setErrorDesc(null)
        }}
      />
    );
  }

  

  return (
    <Box>
      {inventory.isCreated() ? <Outlet /> : <div>No Inventory Found</div>}
      {/* <Outlet /> */}
    </Box>
  );
};

export default PCInventory;
