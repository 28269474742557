import React, { useContext, useEffect, useState } from "react";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import { Outlet } from "react-router-dom";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import "./App.css";

import IconButton from "@mui/material/IconButton";
import { NotificationContext } from "./contexts/NotificationContext";
import {
  NotificationActionType,
  NotificationType,
} from "./Models/Notification";
import { AppDataContext } from "./contexts/AppDataContext";
import { UserAccountContext } from "./contexts/UserAccountContext";
import { Accounts, Home } from "./routes";
import CssBaseline from "@mui/material/CssBaseline";
import { APIError } from "./networking/SupabaseAPIManager/SupabaseAPIManager";
import { AuthUserProfile } from "./Models/UserProfile";
import { AuthUser } from "./Models/User";
import { RequestStatus } from "./Models/Result";
import RequestStatusView from "./components/SupportingViews/RequestStatusView";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { isIOS, isMobileOnly } from "react-device-detect";
//import {v4 as uuidv4} from "uuid"

//import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { app, storedTokenKey, vapidKey } from "./firebaseConfig";
import { dateToTimestamp } from "./utils";

function App() {
  const appData = useContext(AppDataContext);
  const { state, dispatch } = useContext(NotificationContext);

  //const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);

  const {
    loadCredentials,
    setupUserCredentials,
    saveCredentials,
    loadUserprofile,
  } = useContext(UserAccountContext);
  const { msg: errMsg, opened, type } = state;

  //const [isLoading, setIsLoading] = useState(true);
  const [setupRequestStatus, setSetupRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Loading);
  const [updateAlertOpened, setUpdateAlertOpened] = useState(false);
  const navigate = useNavigate();
  const locationHash = window.location.hash;

  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = () => {
    dispatch({
      type: NotificationActionType.close,
      payload: {
        type: NotificationType.error,
        msg: "",
      },
    });
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  /*
  window.addEventListener("sw", (e) => {
    setUpdateAlertOpened(true);
  });*/

 

  const setupApp = async () => {
    if (!window.name) {
      window.name = `prettycharm-auth-token`;
    }

    // sync localstorage across tabs of this window.
    // Ensures user is signed out across tabs.
    window.onstorage = (event: StorageEvent) => {
      //console.dir(event)
      if (event.key === window.name) {
        appData.gotoShoppingApp();
      }
    };

    setSetupRequestStatus(RequestStatus.Loading);

    try {
      appData.loadPromoProduct();
    } catch (error) {
      const apiError = error as APIError;
      setSetupRequestStatus(apiError);
      return;
    }

    const loadLookupTablesResult = await appData.loadLookupTables();

    // debugger
    const apiError = loadLookupTablesResult as APIError;
    if (apiError.errorDescription) {
      // console.log(apiError.errorDescription)
      setSetupRequestStatus(apiError);
      return;
    }

    switch (true) {
      // whether user wants to recover password
      case locationHash.includes("recovery"):
        const data = appData.getPasswordRecoveryData();
        handlePasswordRecovery(data);
        break;
      // whether user authenticated using a provider
      case locationHash.includes("provider_token"):
        const authData = appData.authProviderDataFromHash(locationHash);
        // authProviderData is valid
        if (authData) {
          await handleAuthenticationWithProvider(authData);
        } else {
          dispatch({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.error,
              msg: "session expired try again",
            },
          });
          navigate(Home.index);
        }
        break;
      default:
        // const isLoaded = await loadCredentials();
        // console.log(isLoaded)

        const isSuccessful = await loadCredentials();
        if (isSuccessful && (!isMobileOnly || isIOS)) {
          dispatch({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.warning,
              msg: "To experience Prettycharm, kindly download the app and login on your mobile device",
            },
          });
        }
    }
    setSetupRequestStatus(RequestStatus.Idle);
  };

  const handlePasswordRecovery = (recoveryCredentials: Map<string, string>) => {
    const accessToken = recoveryCredentials.get("access_token");
    const credentialsType = recoveryCredentials.get("type");

    if (credentialsType === "recovery" && accessToken) {
      appData.updateRPAT(accessToken);
      navigate(Accounts.recover);
    } else if (recoveryCredentials.get("error_code")) {
      dispatch({
        type: NotificationActionType.open,
        payload: {
          type: NotificationType.error,
          msg: "session expired try again",
        },
      });
      navigate(`/${Accounts.forgotPassword}`);
    }
  };

  const handleAuthenticationWithProvider = async (
    providerCredentials: Map<string, string>
  ) => {
    const value = await setupUserCredentials(providerCredentials);
    const apiError = value as APIError;
    const pathnameBeforeAuth = appData.getPreAuthPathname();

    if (!apiError.errorDescription) {
      const auth = value as AuthUser;
      let profileResult = await loadUserprofile(auth);
      const apiError = profileResult as APIError;
      if (apiError.errorDescription) {
        dispatch({
          type: NotificationActionType.open,
          payload: {
            type: NotificationType.error,
            msg: apiError.errorDescription,
          },
        });
        navigate(Home.index);

        return;
      }
      profileResult = profileResult as AuthUserProfile;
      if (profileResult.authUser) {
        saveCredentials(profileResult.authUser);
      } else {
        saveCredentials(auth);
      }

      if (pathnameBeforeAuth) {
        navigate(pathnameBeforeAuth!);
      } else {
        navigate(Home.index);
      }

      // appData.gotoShoppingApp(pathnameBeforeAuth);
    } else {
      dispatch({
        type: NotificationActionType.open,
        payload: {
          type: NotificationType.error,
          msg: `${apiError.errorDescription}`,
        },
      });
      navigate(Home.index);
    }
  };

  async function getAndSaveToken(): Promise<boolean> {
    try {
      const currentToken = await getToken(messaging, { vapidKey });
      const storedTokenStr = localStorage.getItem(storedTokenKey);
      if (currentToken) {
        // if current token is updated by firebase
        if (storedTokenStr !== currentToken) {
          const tokenHash = {
            token: currentToken,
            timestamp: dateToTimestamp(Date()),
          };
          // send token to server
          // save token copy to device
          localStorage.setItem(storedTokenKey, JSON.stringify(tokenHash));
        } else  {
          // parse storedToken and use
        }
        // console.log(currentToken)
        return true;
      }
      return false;
    } catch (error) {
      throw error;
    }
  }

  async function requestPermission() {
     
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.log("Unable to get permission to notify.");
     
      return;
    }

    
    //  Retrieve a registration token for use with FCM.
    try {
      await getAndSaveToken();
    } catch (error) {
      console.log("couldn't generate token");
    }
     
  }

  useEffect(()=>{
    const unsubscribeToFCM = onMessage(messaging, (payload) => {
      console.log("on-message", payload);
     setUpdateAlertOpened(true)
    });
    navigator.serviceWorker.addEventListener('message', function (event) {
      console.log('Received a message from service worker: ', event.data);
      setUpdateAlertOpened(true)
    });
    
    
    return  ()=>{
      unsubscribeToFCM()
       window.removeEventListener('notificationclick', (e)=>{})
       navigator.serviceWorker.removeEventListener('message', (e)=>{})
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (async () => {
      // Initialize Firebase
      try {
        if (!("serviceWorker" in navigator)) {
          return;
        }
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        );
        console.log("Registration successful, scope is:", registration.scope);
        const isSuccessful = await getAndSaveToken();
        console.log(isSuccessful);
        if (!isSuccessful) {
          await requestPermission();
        }
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setupApp();

   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (setupRequestStatus !== RequestStatus.Idle) {
    return (
      <Box
        id="app-request-status"
        sx={{
          display: "grid",
          placeItems: "center",
          width: "100%",
          height: "50vh",
        }}
      >
        <RequestStatusView
          status={setupRequestStatus}
          onRetry={() => {
            setupApp();
          }}
        />
      </Box>
    );
  }

  return (
    <div className="App">
      <CssBaseline />
      {/* <Signup /> */}

      <Snackbar
        open={opened}
        autoHideDuration={6000}
        message={errMsg}
        action={action}
      >
        <Alert onClose={handleClose} severity={type}>
          {errMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        sx={{
          bottom: { xs: 90, sm: 0 },
        }}
        open={updateAlertOpened}
        autoHideDuration={6000}
        message="New Version Available"
        action={
          <Button
            sx={{ color: "primary.main" }}
            onClick={() => {
            // caches.delete("workbox-precache-v2-https://www.prettycharm.app/")
            // caches.delete("pwabuilder-page")
            // caches.delete("images")
              window.location.reload()
            }}
            size="small"
          >
            Update Now
          </Button>
        }
      />
      <Outlet />
    </div>
  );
}

export default App;
