import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import { PCTab, ShoppingApp } from '../../../routes';

const PCFavoritesLayout = () => {

  const location = useLocation()

  useEffect(()=>{
    ShoppingApp.setLastVisitedForRoute(PCTab.wishlist, location.pathname+location.search)
   
  }, [location])

  return (
    <Outlet />
  )
}

export default PCFavoritesLayout