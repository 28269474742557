import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppDataContext } from "../../../../../contexts/AppDataContext";
import { NotificationContext } from "../../../../../contexts/NotificationContext";
import { UserAccountContext } from "../../../../../contexts/UserAccountContext";
import {
  NotificationActionType,
  NotificationType,
} from "../../../../../Models/Notification";
import { APIError } from "../../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { Accounts } from "../../../../../routes";
import DCCheckbox from "../../../../SupportingViews/ShowPassword";
import {   resetPasswordSchema } from "../../Model/SignupValidationSchema";

export interface ResetPasswordFormProps {}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { resetPassword } = useContext(UserAccountContext);
  const [showPassword, setShowPassword] = useState(false);
  const { getRPAT } = useContext(AppDataContext);
  
  const { dispatch: dispatchNotificationAction } =
    useContext(NotificationContext);

  const navigate = useNavigate();
  // location user is going
  const location = useLocation()

  const handleChange = (e: any, checked: any) => {
    setShowPassword(checked);
  };

  if (!getRPAT()) {
      return <Navigate to={`/${Accounts.forgotPassword}`} replace state={{from: location}}  />
  }

  return (
    <Formik
      initialValues={{
        password: "",
        newPassword: ""
      }}
      validationSchema={resetPasswordSchema}
      // validateOnChange
      // validateOnBlur
      onSubmit={async (values, actions) => {
        const accessToken = getRPAT();
        if (!accessToken) {
          return;
        }
       
        const result = await resetPassword(
          "someEmail@gmail.com",
          values.password,
          accessToken
        );
        const apiError = result as APIError;
        if (apiError.errorDescription) {
          dispatchNotificationAction({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.error,
              msg: apiError.errorDescription,
            },
          });
        } else {
          dispatchNotificationAction({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.success,
              msg: "Password reset successfully",
            },
          });
         

          navigate(`/${Accounts.resetConfirmation}`)
          
        }
      }}
    >
      {(props) => {
        return (
          <Stack
            spacing={4}
            component="form"
            onSubmit={props.handleSubmit}
            width="100%"
          >
           
           <TextField
              fullWidth
              id="password"
              name="password"
              label="New Password"
              type={showPassword ? "text" : "password"}
              value={props.values.password}
             // onChange={props.handleChange('password')}
             onChange={(e)=>{
              //props.setFieldTouched('password', true, true)
              props.handleChange(e)
             }}
              error={props.touched.password && Boolean(props.errors.password)}
              helperText={props.touched.password && props.errors.password}
            />

            <TextField
              fullWidth
              id="newPassword"
              name="newPassword"
              label="Confirm New Password"
              type={showPassword ? "text" : "password"}
              value={props.values.newPassword}
              
              //onChange={props.handleChange('newPassword')}
              onChange={(e)=>{
               // props.setFieldTouched('newPassword', true, true)
                props.handleChange(e)
               }}
              error={props.touched.newPassword && Boolean(props.errors.newPassword)}
              helperText={props.touched.newPassword && props.errors.newPassword}
            />

            <DCCheckbox
              sx={{
                alignItems: "flex-end",
                marginTop: "0.5rem !important",
                color: (theme) => theme.palette.text.primary,
              }}
              onChange={handleChange}
              checked={showPassword}
            />

            <LoadingButton
              
              loading={props.isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
             size="large"
            >
              Reset Password
            </LoadingButton>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordForm;
