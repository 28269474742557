import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { UserAccountContext } from "../../../../../contexts/UserAccountContext";
import {  SUPABASE_API_KEY } from "../../../../../defaultValues";
import { AuthUser } from "../../../../../Models/User";
import { APIError } from "../../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { LoginCredentials } from "../../Model/LoginState";
import { loginValidationSchema } from "../../Model/SignupValidationSchema";
import signupDataManager from "../../Model/SignupDataManager";
import { LoginAction, LoginActionType } from "../../Model/Login";
import { NotificationContext } from "../../../../../contexts/NotificationContext";
import {
  NotificationActionType,
  NotificationType,
} from "../../../../../Models/Notification";

import DCCheckbox from "../../../../SupportingViews/ShowPassword";
import DCButtonLink from "../../../../SupportingViews/DCButtonLink";

import { useLocation } from "react-router-dom";
 
  import glogo from "../../../../../assets/g-logo.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PresentationContext } from "../../../../../contexts/PresentationContext";
import { FormType } from "../../../../Providers/AccountsProvider";
import DCError from "../../../../SupportingViews/DCError";
import { AppDataContext } from "../../../../../contexts/AppDataContext";
import { Inventory, ShoppingApp } from "../../../../../routes";
//import { isIOS, isMobileOnly } from "react-device-detect";
 

 
export interface LoginFormProps {
  dispatchLoginAction: Dispatch<LoginAction>;
  loginCredentials: LoginCredentials | null;
  onForgotPasswordClick: () => void;
}

const LoginForm = ({
  dispatchLoginAction,
  loginCredentials,
  onForgotPasswordClick,
}: LoginFormProps) => {
  const { saveCredentials, updateCurrentform } = useContext(UserAccountContext);
  const { dispatch: dispatchNotificationAction } =
    useContext(NotificationContext);
  const appData = useContext(AppDataContext)
  const { dismiss } = useContext(PresentationContext);

  const location = useLocation();

  const [credentials, setCredentials] = useState<LoginCredentials>({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [fedAuthIsLoading, setFedAuthIsLoading] = useState(false);
  const [error, setError] = useState<APIError | null>(null)

  

  const handleChange = (e: any, checked: any) => {
    setShowPassword(checked);
  };

 // const from = appData.getPreAuthPathname() || Home.index  
 // const navigate = useNavigate();
 

  const formik = useFormik<LoginCredentials>({
    initialValues: credentials,
    enableReinitialize: true,
    validationSchema: loginValidationSchema,
    onSubmit: async (credentials) => {
      
      const value = await signupDataManager.loginUserWithCredentials(
        credentials,
        SUPABASE_API_KEY
      );

      const apiError = value as APIError;
      if (apiError.errorDescription) {
        if (!apiError.isLoginError) {
          dispatchNotificationAction({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.error,
              msg: apiError.errorDescription,
            },
          });
        } else {
          // update login credentials, show signup form
          dispatchLoginAction({
            type: LoginActionType.setCredentials,
            payload: credentials,
          });
          updateCurrentform(FormType.Signup)
         
        }
      } else {
        const auth = value as AuthUser;
        saveCredentials(auth);
        appData.setPreAuthPathname(location.pathname);
        dismiss?.();
        formik.resetForm();
        if (location.pathname === ShoppingApp.sellerOnBoarding) {
          appData.gotoSellerApp(Inventory.products)
        } else {
          appData.gotoShoppingApp(location.pathname + location.search)
        }
        
        /*
        if (isMobileOnly && !isIOS) {
         // appData.gotoShoppingApp(location.pathname + location.search)
        } else {
          dispatchNotificationAction({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.warning,
              msg: "To experience Prettycharm, kindly download the app and login on your mobile device",
            },
          });
        }*/
          
        
       // navigate(from, { replace: true });
        
      }
     
     // formik.resetForm();
    },
  });
  
   
  useEffect(() => {
    if (loginCredentials) {
      setCredentials(loginCredentials);
    }
  }, [loginCredentials]);

  const googleBtnStyle = {
   // display: "flex",
    justifyContent: "center",
    paddingInline: "2rem",
    height: "3.1rem",
    display: "none",
    mt: {
      xs: "0.5rem !important",
      md: "1.5rem !important",
    },
  };
  const glogoStyle = {
    width: "1rem",
    height: "1rem",
    marginRight: "1rem",
  };

  return (
    <Stack
      component="form"
      onSubmit={formik.handleSubmit}
      spacing={3}
      direction="column"
    >
      <DCError error={error} />
      <TextField
        type="email"
       
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />

      <TextField
        
        fullWidth
        id="password"
        name="password"
        label="Password"
        type={showPassword ? "text" : "password"}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />

      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        mt="0.5rem !important"
      >
        <DCButtonLink
          sx={{
            color: "primary.dark",
            textTransform: "capitalize",
            fontSize: {
              xs: "0.9rem",
              md: "1rem",
            },
          }}
          label="forgot Password?"
          onClick={onForgotPasswordClick}
        />
        <DCCheckbox
          sx={{
            alignItems: "flex-end",
            // marginTop: "8px",
            color: (theme) => theme.palette.text.primary,
          }}
          onChange={handleChange}
          checked={showPassword}
        />
      </Stack>

      <LoadingButton
        size="large"
        sx={{
          mt: {
            xs: "0.5rem !important",
            md: "1.5rem !important",
          },
          
        }}
        loading={formik.isSubmitting}
        type="submit"
        fullWidth
        variant="contained"
      >
        continue
      </LoadingButton>

      <Typography
        sx={{
          display: "none",
          textAlign: "center",
          position: "relative",
          mt: {
            xs: "0.5rem !important",
            md: "1.5rem !important",
          },
          "&.MuiTypography-root::before": {
            content: '""',
            display: "block",
            width: "45%",
            height: "0.5px",
            background: "#442C2E",
            left: "0px",
            top: "50%",
            position: "absolute",
          },
          "&.MuiTypography-root::after": {
            content: '""',
            display: "block",
            width: "45%",
            height: "0.5px",
            background: "#442C2E",
            right: "0px",
            top: "50%",
            position: "absolute",
          },
        }}
        variant="body2"
      >
        or
      </Typography>
      {/* <form onSubmit={()=> showConsentScreen()} 
      //method="GET" 
     // action={authURL}
      >
        <LoadingButton type="submit">Submit</LoadingButton>
      </form> */}
      <LoadingButton
        loading={fedAuthIsLoading}
        variant="outlined"
        sx={googleBtnStyle}
        onClick={ async () => {
        //  let result = await axios({
        //     method: "post",
        //     url: `/.netlify/functions/login`,
        //     timeout: 20000
        //  })
        //  console.dir(result)
        
          const { REACT_APP_SUPABASE_URL: supabaseURL } = process.env;
          
          try {
            if (supabaseURL) {
              setError(null)
              setFedAuthIsLoading(true)
             
              
              const result = await fetch(new URL(`${supabaseURL}/auth/v1/authorize?provider=google`), {
                headers: new Headers({
                  'Content-type' : 'application/json',
                  "Access-Control-Allow-Origin": "*"
                })
              })
               console.dir(result)
              if (result.status === 200) {
              console.dir(result)
               // let url = new URL(result.request.responseURL)
                let url = new URL(result.url)

                url.searchParams.append("prompt", "select_account")
                appData.setPreAuthPathname(location.pathname)
                window.location.href  = url.href
              } else {
                setError(APIError.default)
              }
              setFedAuthIsLoading(false)
            }
          } catch (error) {
            console.dir(error)
          }
           
        }
      }
      >
        <Box component="img" sx={glogoStyle} src={glogo} />
        Continue with Google
      </LoadingButton>
    </Stack>
  );
};

export default LoginForm;
