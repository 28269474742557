import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Formik  } from "formik";
import React, { useContext } from "react";
import { NotificationContext } from "../../../../../contexts/NotificationContext";
import { PresentationContext } from "../../../../../contexts/PresentationContext";
import { UserAccountContext } from "../../../../../contexts/UserAccountContext";
import {
  NotificationActionType,
  NotificationType,
} from "../../../../../Models/Notification";
import { APIError } from "../../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { forgotPasswordValidationSchema } from "../../Model/SignupValidationSchema";

export interface ForgotPasswordFormProps {}
const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const { recoverPassword  } = useContext(UserAccountContext);
  const { dispatch: dispatchNotificationAction } =
    useContext(NotificationContext);
  const { dismiss } = useContext(PresentationContext);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={forgotPasswordValidationSchema}
      // validateOnChange={true}
      onSubmit={async (values, actions) => {
        const result = await recoverPassword(values.email);
        const apiError = result as APIError;
        if (apiError.errorDescription) {
          dispatchNotificationAction({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.error,
              msg: apiError.errorDescription,
            },
          });
        } else {
          dispatchNotificationAction({
            type: NotificationActionType.open,
            payload: {
              type: NotificationType.success,
              msg: `Instructions have been sent to your ${values.email}`,
            },
          });
         
          dismiss?.()
        }
      }}
    >
      {(props) => {
        return (
          <Stack
            spacing={4}
            component="form"
            onSubmit={props.handleSubmit}
            width="100%"
          >
            {/* <Typography variant="subtitle1">
                We will send you an email with instructions on how to reset your password.
            </Typography> */}
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={props.values.email}
              onChange={props.handleChange}
              error={props.touched.email && Boolean(props.errors.email)}
              helperText={props.touched.email && props.errors.email}
            />

            <LoadingButton
              loading={props.isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
            >
              Email Me
            </LoadingButton>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordForm;
