import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import { DiscountsContext } from "../contexts/DiscountsContext";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Coupon, Product, ProductVariant } from "../../../Models/Product";
import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import { InventoryContext } from "../contexts/InventoryContext";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import ListSubheader from "@mui/material/ListSubheader";
import { AppDataContext } from "../../../contexts/AppDataContext";
import { LookupTable } from "../../../Models/LookupTables";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { getPercentOff } from "../../../utils";
import OfflineView from "../../SupportingViews/OfflineView";
import { RequestStatus } from "../../../Models/Result";
 import CircularProgress from "@mui/material/CircularProgress";
import DCError from "../../SupportingViews/DCError";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoadMoreButton from "../../SupportingViews/LoadMoreButton";
 
function PrettycharmDiscounts() {
  const { credentials: userCredentials, saveCredentials } = useContext(UserAccountContext);
  const discountsManager = useContext(DiscountsContext);
  const inventoryDataManager = useContext(InventoryContext);
  const appDataManager = useContext(AppDataContext);
  const products = inventoryDataManager.getProducts();
  const [draftProducts, setDraftProducts] = useState<Product[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus | Error>(
    RequestStatus.Idle
  );
  const [applyDiscountStatus, setApplyDiscountStatus] = useState<RequestStatus | APIError>(
    RequestStatus.Idle
  );

  const [loadMoreStatus, setLoadMoreStatus] = useState<RequestStatus | APIError>(
    RequestStatus.Idle
  );

  const [counter, setCounter] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  // List

  const handleApplyDiscount = async () => {
    if (userCredentials === null || discountsManager === null) {
      return;
    }

    setApplyDiscountStatus(RequestStatus.Loading)

    for (let draft of draftProducts) {
      try {
        const {product, authUser} = await discountsManager.updateDiscountInfo(
          draft,
          userCredentials
        );
        inventoryDataManager.performProductUpdate(product);
        if (authUser !== null) {
           saveCredentials(authUser)
        }
        handleClose()
        setApplyDiscountStatus(RequestStatus.Idle)
      } catch (error) {
        let apiError = APIError.default
        setApplyDiscountStatus(apiError)
        
      }
    }
  };

  const loadSalesDiscountCoupon = async () => {
    if (userCredentials === null) {
      return;
    }

    setRequestStatus(RequestStatus.Loading);
    try {
      const authUser = await discountsManager?.loadDiscountCoupon(
        "prettycharm-sales",
        userCredentials
      );
      if (authUser) {
        saveCredentials(authUser)
      }
    
      setRequestStatus(RequestStatus.Idle);
    } catch (error) {
      setRequestStatus(error as Error);
    }

   
  };

  const loadProducts = async () => {
    if (
      userCredentials === null ||
      inventoryDataManager.getProducts().length > 0
    ) {
      return;
    }

    setRequestStatus(RequestStatus.Loading)
    const result = await inventoryDataManager.loadProductsForInventory(
      userCredentials,
      undefined,
      products.length
    );
    const error = result as APIError;
    if (error.errorDescription) {
      // set error
      const customError = new Error(error.errorDescription)
      setRequestStatus(customError)
    } else {
      setRequestStatus(RequestStatus.Idle)
    }
   };

  const handleLoadmoreProducts = useCallback(async () => {

    if (
      userCredentials === null) {
      return;
    }


    setLoadMoreStatus(RequestStatus.Loading)

    const loadProductsResult = await inventoryDataManager.loadProductsForInventory(
      userCredentials,
      undefined,
      products.length
    );
    const loadError = loadProductsResult as APIError;
    if (loadError.errorDescription) {
       setLoadMoreStatus(loadError)
    } else {
      setLoadMoreStatus(RequestStatus.Idle)
    }

  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const variantColorTitle = (colorId: string): string | undefined => {
    const colors = appDataManager.getLookuptableForType(LookupTable.color);
    return colors.find((color) => color.color_id === colorId)?.color_title;
  };

  const variantSizeTitle = (sizeId: string): string | undefined => {
    const sizes = appDataManager.getLookuptableForType(LookupTable.size);
    return sizes.find((size) => size.size_id === sizeId)?.size_title;
  };

  const handleToggle = (variant: ProductVariant) => () => {
    if (discountsManager?.prettycharmCoupon) {
      if (!variant.discountInfo) {
        setDiscountInfoForVariant(
          variant,
          discountsManager.prettycharmCoupon.percentOff
        );
      } else {
        setDiscountInfoForVariant(variant, undefined);
      }
    }
  };

  const selectAll = () => () => {
    let percentOff = undefined;
    if (discountsManager?.prettycharmCoupon) {
      if (!allSelected) {
        percentOff = discountsManager.prettycharmCoupon.percentOff;
      }
    }

    for (let product of draftProducts) {
      for (let variant of product.productDetails) {
        if (discountsManager?.prettycharmCoupon) {
          setDiscountInfoForVariant(variant, percentOff);
        }

        //handleToggle(variant)
      }
    }
  };

  const allSelected = ((): boolean => {
    for (let product of draftProducts) {
      for (let variant of product.productDetails) {
        if (!variant.discountInfo) {
          return false;
        }
      }
    }

    return true;
  })();

  function setDiscountInfoForVariant(
    variant: ProductVariant,
    percentOff?: number
  ) {
    const productIdx = draftProducts.findIndex(
      (product) => product.productId === variant.productId
    );
    if (productIdx < 0) {
      return;
    }

    const variantIdx = draftProducts[productIdx].productDetails.findIndex(
      (tempVariant) => tempVariant.productDetailId === variant.productDetailId
    );
    if (variantIdx < 0) {
      return;
    }
    const updatedProduct = draftProducts[productIdx];
    const couponId = discountsManager?.prettycharmCoupon?.id ?? null
    const redeemBy = discountsManager?.prettycharmCoupon?.redeemBy ?? null
    if (percentOff) {
      updatedProduct.productDetails[variantIdx].discountInfo = {
        value: getPercentOff(variant.sellingPrice, percentOff),
        percentOff,
        couponId,
        redeemBy
      };
    } else {
      updatedProduct.productDetails[variantIdx].discountInfo = null;
    }

    setDraftProducts([
      ...draftProducts.slice(0, productIdx),
      updatedProduct,
      ...draftProducts.slice(productIdx + 1),
    ]);

    //  const index = draftProducts.findIndex((product) => {
    //   product.productDetails.find(variant => variant.productDetailId === variantId) !== undefined
    //  })
  }

  function listItemForVariant(variant: ProductVariant): ReactNode {
    const colorTitle = variantColorTitle(variant.colorId);
    const sizeTitle = variantSizeTitle(variant.sizeId);
    const value = variant.productDetailId;
    const labelId = `checkbox-list-secondary-label-${value}`;

    if (colorTitle === undefined || sizeTitle === undefined) return null;
    return (
      <ListItem
        key={value}
        secondaryAction={
          <Checkbox
            edge="end"
            onChange={handleToggle(variant)}
            checked={variant.discountInfo !== null}
            inputProps={{ "aria-labelledby": labelId }}
          />
        }
        disablePadding
      >
        <ListItemButton>
          <ListItemAvatar>
            <Avatar
              alt={`Variant n°${value + 1}`}
              src={variant.photos.photo1.url}
            />
          </ListItemAvatar>
          <ListItemText
            sx={{ textTransform: "capitalize" }}
            id={`${labelId}-color`}
            primary={colorTitle}
          />
          <ListItemText
            sx={{ textTransform: "uppercase" }}
            id={`${labelId}-size`}
            primary={sizeTitle}
          />
          <ListItemText
            sx={{ textTransform: "capitalize" }}
            id={`${labelId}-selling-price`}
            primary={variant.sellingPrice.toFixed(2)}
          />
          {variant.discountInfo ? (
            <>
              <ListItemText
                sx={{ textTransform: "capitalize" }}
                id={`${labelId}-discount`}
                primary={variant.discountInfo.value.toFixed(2)}
              />
            </>
          ) : null}
        </ListItemButton>
      </ListItem>
    );
  }

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadSalesDiscountCoupon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useEffect(() => {
    // copy products into a local state
    const addedProducts = inventoryDataManager
      .getProducts()
      .filter((product) => {
        return draftProducts.find(
          (draftProduct) => draftProduct.productId === product.productId
        ) === undefined
          ? true
          : false;
      });
    setDraftProducts([...draftProducts, ...addedProducts]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryDataManager.getProducts().length]);

  console.dir(draftProducts);
  
  const dialogContent = (() => {
    return (
      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        {draftProducts.map((product) => {
          //  const labelId = `checkbox-list-secondary-label-${value}`;
          return (
            <li key={`section-${product.productId}`}>
              <ul>
                <ListSubheader sx={{ textTransform: "capitalize" }}>
                  {product.title}
                </ListSubheader>
                {product.productDetails.map((variant) => {
                  const listItem = listItemForVariant(variant);
                  if (listItem) {
                    return listItem;
                  }
                  return <div></div>;
                })}
              </ul>
            </li>
          );
        })}
         <LoadMoreButton
            sx={{
              display: inventoryDataManager.hasMoreProducts() ? "block" : "none",
              width: { xs: "87.5%", md: "29vw", lg: "25vw", xl: "18vw" },
              margin: "0 auto"
            }}
            loading={loadMoreStatus === RequestStatus.Loading}
            onClick={handleLoadmoreProducts}
          />
      </List>
    );
  })();

  let errorDesc = (() => {
    return (requestStatus as Error).message || null;
  })();

  let isLoading = (() => {
    return requestStatus === RequestStatus.Loading;
  })();

  let isApplyingDiscount = (() => {
    return applyDiscountStatus === RequestStatus.Loading;
  })();

  let applyDiscountError = (() => {
    let error = applyDiscountStatus as APIError
    return error.errorDescription ? error : null
  })();

  let loadMoreError = (() => {
    let error = loadMoreStatus as APIError
    return error.errorDescription ? error : null
  })();

  if (isLoading) {
    return (
      <Box sx={{width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (errorDesc) {
    return (
      <OfflineView
        msg={errorDesc}
        sx={{ pt: 6, width: "100%", height: "100vh" }}
        onRetry={() => {
          setCounter((prev) => prev + 1);
          // setErrorDesc(null)
        }}
      />
    );
  }

  return discountsManager?.prettycharmCoupon ? (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll={scroll}
        fullScreen
        sx={{alignItems: "center"}}
      >
        <DialogTitle>Discount Editor</DialogTitle>
        <Stack direction={"row"} paddingLeft={"1.6rem"} alignItems={"center"}>
        <Typography variant="subtitle1">Select All</Typography>
        <Checkbox
          edge="end"
          onChange={selectAll()}
          checked={allSelected}
          inputProps={{ "aria-labelledby": "selectAll" }}
        />
        </Stack>
        
        <DialogContent dividers={scroll === "paper"}>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          
          <LoadingButton loading={isApplyingDiscount} disabled={isApplyingDiscount} onClick={handleApplyDiscount}>Apply</LoadingButton>
          <Button disabled={isApplyingDiscount} onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {
        discountsManager.prettycharmCoupon.valid ? (
          <Box sx={{ minWidth: 275 }}>
          <Card sx={{ alignItems: "flex-start" }} variant="outlined">
            {cardContent(discountsManager.prettycharmCoupon)}
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                onClick={handleClickOpen("paper")}
                variant="contained"
                size="small"
              >
                Add Discount
              </Button>
            </CardActions>
          </Card>
        </Box>
        ) : null
      }
     
      <DCError error={applyDiscountError} onErrorDisplayed={()=>{}} />
      <DCError error={loadMoreError} onErrorDisplayed={()=>{}} />

    </>
  ) : null;
}

function cardContent(coupon: Coupon): ReactNode {

  let redeemByContent = coupon.redeemBy !== null ? (
    <Typography sx={{ mb: 1.5 }} color="text.secondary">
     Expires after {new Date(coupon.redeemBy * 1000).toDateString()}
  </Typography>
  ): null

  return (
    <React.Fragment>
      <CardContent sx={{ textAlign: "left" }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {coupon.name}
        </Typography>
        <Typography variant="h5" component="div">
          {coupon.percentOff} Off
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          0 products discounted
        </Typography> */}
        {
          redeemByContent
        }
       
      </CardContent>
    </React.Fragment>
  );
}

export default PrettycharmDiscounts;
