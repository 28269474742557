import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import React from 'react'

const ResetStatus = () => {
  return (
    <Alert sx={{width: "87.5%", margin: "0 auto"}} severity="success">
         <AlertTitle>Success</AlertTitle>
        Your password was reset successfully
    </Alert>
  )
}

export default ResetStatus