import React, { useContext, useEffect, useState } from 'react'
import { PresentationContext } from '../../../../contexts/PresentationContext'
import { UserAccountContext } from '../../../../contexts/UserAccountContext'
import { Stripe, loadStripe } from "@stripe/stripe-js";

import { PCAPIError  } from "../../../../Models/PCAPIError";
import { BagManager } from '../../../../Models/Bag';

import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
  } from '@stripe/react-stripe-js';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
//import Box from '@mui/material/Box';
import { RequestStatus } from '../../../../Models/Result';
import OfflineView from '../../../SupportingViews/OfflineView';
import CircularProgress from '@mui/material/CircularProgress';

interface CheckoutSessionViewProps {
    manager: BagManager
}

const CheckoutSessionView = (props: CheckoutSessionViewProps) => {

  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [stripeObj, setStripeObj] = useState<Stripe | null>(null)

  const presentation = useContext(PresentationContext)
  const userAccountManager = useContext(UserAccountContext)
  const user = userAccountManager.getSignedInUser();
  const accessToken = user?.access_token;
  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  const {manager} = props

  const handleCheckout = async () => {
    

    if (accessToken && stripePublicKey && manager) {
        manager.updateCheckoutStatus(RequestStatus.Loading) 

        const stripe = await loadStripe(stripePublicKey);
    
      // open checkoutSessionView
      
      
      const checkoutResult = await manager.checkout(
        accessToken,
        user.refresh_token
      );
      if (!checkoutResult) {
        manager.updateCheckoutStatus(PCAPIError.default)
        return;
      }

      
      const { authUser: refreshedAuthuser, clientSecret } = checkoutResult;
      if (refreshedAuthuser) {
        userAccountManager.saveCredentials(refreshedAuthuser);
      }

      /*
      const result = await stripe?.redirectToCheckout({ sessionId: sessionId });
      if (result?.error.message) {
        error.update(RequestError.Unexpected, result.error.message);
        manager.updateCheckoutStatus(error);
      }*/
     
      setClientSecret(clientSecret)
      setStripeObj(stripe)
      manager.updateCheckoutStatus(RequestStatus.Idle) 
      
    }  
  };

   const buttonAppBar =  (() => (
    //   <Box sx={{ flexGrow: 1 }}>
         <AppBar  position="static">
          <Toolbar>
                    <IconButton
                    onClick={()=> {
                        if (presentation) {
                            presentation.dismiss?.()
                        }
                    }}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                  >
                    <Close />
                  </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Checkout
            </Typography>
           
          </Toolbar>
        </AppBar>
    //    </Box>
    )
  )()

  const productRequestStatusView = (()=>{
    const error = manager.checkoutStatus as PCAPIError
     if (manager.checkoutStatus === RequestStatus.Loading) {
      return (
        <CircularProgress  />
      )
    }
    if (error?.errorDescription) {
      return (
       <OfflineView
          onRetry={handleCheckout}
          msg={error?.errorDescription}
          sx={{pt: "4rem", marginTop: 'auto'}}

       />
      ) 
    }
  })()
  

  useEffect(()=>{

    if (!presentation.isPresented) {return}
    handleCheckout()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentation.isPresented])

  return (
    <Stack >
     {buttonAppBar}
     {
        manager.checkoutStatus !== RequestStatus.Idle && (
            <Stack justifyContent={'center'} alignItems='center' width='100%' height='calc(100vh - 72px)'>
                { productRequestStatusView}
            </Stack>
           
        )
     }
     {(manager.checkoutStatus === RequestStatus.Idle) && (clientSecret && stripeObj) && (
         <EmbeddedCheckoutProvider
         stripe={stripeObj}
         options={{clientSecret}}
       >
         <EmbeddedCheckout />
       </EmbeddedCheckoutProvider>
     )}
    </Stack>
  )
}

export default CheckoutSessionView