 import { Photo,  ProductVariantFormData } from "../../../Models/Product";

 

 export interface ProductVariantData {
  sellingPrice: number 
  stockQuantity: number 
  isAvailable: boolean
  id: string
  colorId: string 
  sizeId: string
  productId: string
  
}


export interface ProductFormData {
  description: string;
  stockQuantity: number | undefined | string;
  sellingPrice: number | undefined | string;

  wholesaleCost: number | undefined | string;
  categoryId: string;
  subcategoryId: string;
  title: string;
  colors: string[];
  sizes: string[];
  accessories: string[];
  photos: [string, Photo][]//DataObj[]; //string[];
  variants: ProductVariantFormData[] //ProductVariantData[]
 
}

export const defaultProductFormData: ProductFormData = {
  title: "",
  description: "",
  categoryId: "",
  subcategoryId: "", //"",
  photos: [],
  stockQuantity: 0,
  wholesaleCost: 0,
  sellingPrice: 0, //product.sellingPrice || undefined,
  colors: [],
  sizes: [],
  accessories: [],
  variants: [],
}
