import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDVhTUE4qT6pO4XxDUrFXDkB7ptUGM8C2Q",
  authDomain: "prettycharm.firebaseapp.com",
  projectId: "prettycharm",
  storageBucket: "prettycharm.appspot.com",
  messagingSenderId: "358799545135",
  appId: "1:358799545135:web:3731367be8d0a7f3c487da",
  measurementId: "G-M5GMKK23GS",
};

export const vapidKey =
  "BIjE8_-hh3LHaJ3x4OTYmDK3Gb_2kJKSP9PJAWINN92DmrIBsQh_p-XWu-ppDsrKrgofjs7fKTDF9R8_VzHEUpo";

export const storedTokenKey = "device-token";

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
