import React, { useEffect, useId, useState } from "react";

import IconButton from "@mui/material/IconButton";
import { PhotoCamera } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import DCDeleteButton from "../../../../SupportingViews/DCDeleteButton";
import useMediaQuery from "@mui/material/useMediaQuery";

const CustomImageInput = ({
  id,
  field,
  form: {
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    values,
    setFieldError,
    isSubmitting,
  },
  ...props
}: any) => {
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>("");
  const theme = useTheme();
 // const matches = useMediaQuery((theme: any)=>theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.between("md", "lg"));
  const photoId = useId()

  const handleImageChange = (e: any) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        if (!reader.result) return;
        setUploadedFile(file);
        setImagePreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadPhotoIconBtn = (
    <IconButton
      color="primary"
      aria-label="upload picture"
      component="span"
      size="large"
      sx={{
        position: "absolute",
        alignSelf: "center",
        justifySelf: "center",
      }}
    >
      <PhotoCamera
        sx={{
          width: "80px",
          height: "80px",
        }}
      />
    </IconButton>
  );

  useEffect(() => {
    if (uploadedFile) {
      setFieldValue(field.name, uploadedFile);
    } 
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  useEffect(() => {
    if (values[field.name]) {
      handleSubmit();
    } else {
      setFieldValue(field.name, undefined);
      setFieldError(field.name, undefined);
      setImagePreviewUrl("");

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

 
  return (
    <Stack
      sx={{
        height: "inherit",
        position: "relative",
      }}
    >
      {imagePreviewUrl ? (
        <>
          <Box
            component="img"
            sx={{
              border: `2px solid ${theme.palette.primary.main}`,
              borderRadius: "12px",
              height: "inherit",
              objectFit: "cover",
            }}
            alt="product"
            src={imagePreviewUrl}
          />
          {!errors[field.name] && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "inherit",
                background: "rgba(254, 234, 230, 0.24)", //"rgba(0, 0, 0, 0.24)",
                display: "grid", //errors[field.name] ? "none" : "grid",
                placeItems: "center",
                borderRadius: "12px",
              }}
            >
              <CircularProgress color="primary" sx={{}} size={55} />
            </Box>
          )}
        </>
      ) : (
        <Box
          component="label"
          sx={{
            cursor: "pointer",
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "8%",
            borderStyle: "dashed",
            display: "grid",
            placeItems: "center",
            position: "relative",
            height: "inherit",
          }}
         // htmlFor={`${field.name}-${field.name.slice(-1)}`}
         htmlFor={photoId}
        >
          <Box
           // id={`${field.name}-${field.name.slice(-1)}`}
            id={photoId}
            name={field.name}
            title="select file"
            component="input"
            {...field}
            {...props}
            sx={{
              display: "block",
              opacity: 0,
              width: "100%",
            }}
            type="file"
            onChange={handleImageChange}
          />
          {uploadPhotoIconBtn}
        </Box>
      )}

      {
         errors[field.name] && (
          <Typography variant="caption" color="error">
            {errors[field.name]}
          </Typography>
        )
      }
      <>
        {errors[field.name] && (
          <DCDeleteButton
           
            size={isMobile ? "small" : (isTablet ? "medium" : "large" )}
            onClick={() => {
              setFieldValue(field.name, undefined);
              setFieldError(field.name, undefined);
               setImagePreviewUrl("");
            }}
            sx={(theme) => ({
              position: "absolute",
              top: "-12px",
              right: "-12px",
              background: theme.palette.primary.main,
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
              },
            })}
          />
          // deleteBtn
        )}
      </>
    </Stack>
  );
};

export default CustomImageInput;
