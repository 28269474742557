import Button from "@mui/material/Button";
import React, { useContext, useEffect,   useState } from "react";
import { UserAccountContext } from "../../contexts/UserAccountContext";
import Stack from "@mui/material/Stack";
import ProfilePhoto from "../SupportingViews/ProfilePhoto";
import Typography from "@mui/material/Typography";
import { AppDataContext } from "../../contexts/AppDataContext";
import { Inventory } from "../../routes";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import { FulfillmentStatus } from "../../Models/InventoryOrder";
import { APIError } from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import RequestStatusView from "../SupportingViews/RequestStatusView";
import { RequestStatus } from "../../Models/Result";
import Box from "@mui/material/Box";

const UserProfile = () => {
  const userAccount = useContext(UserAccountContext);
  const appData = useContext(AppDataContext);
  const profile = userAccount.getUserprofile();
  const isMediumAndAbove = useMediaQuery((theme: any) =>
    theme.breakpoints.up("md")
  );
  // const [fulfillmentError, setFulfillmentError] =
  //   useState<APIError | null>(null);
  const [fulfillmentStatus, setFulFullmentStatus] = useState<RequestStatus | APIError>(RequestStatus.Idle)
  const [loadCounter, setLoadCounter] = useState(0)
  const [hasMounted, setHasMounted] = useState(false)

  //let hasMounted = useRef(false)

  const checkForPendingOrders = async (): Promise<void> => {

    setFulFullmentStatus(RequestStatus.Loading)
  
    const result = await userAccount.loadFulfillmentStatus(
      FulfillmentStatus.unfulfilled
    );

    if (!result) {
      setFulFullmentStatus(RequestStatus.Idle)
      return;
    }

    const error = result as APIError;
    if (error.errorDescription) {
      setFulFullmentStatus(error)
    }
  };

  useEffect(() => {
    if (profile?.isPartner) {
      checkForPendingOrders()
    }
   
    setHasMounted(true)
    //hasMounted.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount.credentials?.access_token, loadCounter]);



  

  

  return (
    <Stack>
      
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "white",
          mb: "2rem",
        }}
        position="static"
      >
        <Toolbar
          sx={{
            alignItems: "flex-start",
            minHeight: "96px",
            pt: "1rem",
            //pb: "1.75rem",
          }}
        >
          <Typography
            variant="largeTitle"
            noWrap
            component="div"
            sx={{
              color: "primary.dark",
              //flexGrow: 1,
              alignSelf: "flex-end",
            }}
          >
            Account
          </Typography>
        </Toolbar>

        <Divider />
      </AppBar>
      {
        fulfillmentStatus !== RequestStatus.Idle ?  (
          <Box justifyContent="center" paddingY={3}>
            <RequestStatusView status={fulfillmentStatus} onRetry={()=>setLoadCounter(loadCounter + 1)} />
          </Box>
        ) : hasMounted ? (
          <Stack
          width={isMediumAndAbove ? "75%" : "100%"}
          margin={"0 auto"}
          sx={{ flex: 1 }}
          spacing={3}
          id="account-body"
          padding={2}
        >
          <Stack
            width="100%"
            spacing={2}
            direction="row"
            justifyItems={"flex-start"}
            alignItems={"center"}
          >
            <ProfilePhoto
              sx={{
                bgcolor: "primary.dark",
              }}
            />
            <Typography sx={{ textTransform: "capitalize" }}>
              {profile?.firstname}
            </Typography>
          </Stack>
          {profile?.isPartner && (
            <>
              {userAccount.fulfillmentData.count > 0 && (
                <Alert sx={{ textAlign: "left" }} severity="info">
                  <AlertTitle>Info</AlertTitle>
                  You have pending orders
                </Alert>
              )}
              <Button
                sx={{ height: "3rem" }}
                variant="contained"
                fullWidth
                key={"sell"}
                onClick={() => appData.gotoSellerApp(Inventory.products)}
              >
                Switch to selling
              </Button>
            </>
          )}
  
          <Button
            sx={{ height: "3rem" }}
            variant="contained"
            onClick={userAccount.signoutUser}
          >
            Logout
          </Button>
        </Stack>
        ) : null
      }
    </Stack>
  );
};

export default UserProfile;
