import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
 
import { Outlet, useLocation } from 'react-router-dom';
import { PCTab, ShoppingApp } from '../../../routes';

const PCSearch = () => {

  const location = useLocation()
   
  useEffect(()=>{
    ShoppingApp.setLastVisitedForRoute(PCTab.search, location.pathname+location.search)
    
  }, [location])
  
   
  return (
    <Stack
       
    >
        <Outlet />
     </Stack>
  )
}

export default PCSearch