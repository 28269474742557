import { useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import { BagManagerContext } from '../../../contexts/BagManagerContext'
 import BagViewLarge from './destop/BagViewLarge'
import BagView from './mobile/BagView'
import { Helmet } from 'react-helmet'

const PCBag = () => {

  const  manager = useContext(BagManagerContext)
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"))
  
  if (!manager) {
    return null
  }
  
  return (
    <div>
       <Helmet>
       <title>Bag</title>
        <meta name="description" content="Bag Prettycharm" />
        </Helmet>
     {isMobile ? <BagView /> : <BagViewLarge />}
    </div>
  )
}

export default PCBag