import { Login } from "../components/PCAccount/Signup/Model/Login";
import { NotificationState, NotificationType } from "../Models/Notification";

export const baseURL = "https://api.cloudinary.com";
export const appStoreURL = "https://apps.apple.com/us/app/prettycharm/id6461267525";
export const playStoreURL = "https://play.google.com/store/apps/details?id=app.gdprettycharm.www.twa"
export const parentCompanyName = "Grace Divine Inc"
export const appURL = process.env.NODE_ENV === "development" ? "http://localhost:8888" : "https://www.prettycharm.app"
export const SUPABASE_API_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRycXBvYml6aXlveHNnZmp2ZWxhIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTA0Nzc3NDksImV4cCI6MTk2NjA1Mzc0OX0.RXTeNvjhEbSkQ_S01QfwLHyMcbnjLa9raHvly__vcdY";

export const initialNotificationState: NotificationState = {
  opened: false,
  type: NotificationType.error,
  msg: "",
};

export const initialLoginState: Login = {
  loginCredentials: null,
  showingSignupForm: false,
};


