import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

const OnBoardingHome = () => {
  return (
    <Stack 
      sx={{
        display: "grid",
        placeItems: "center",
        width: "100%",
        height: "calc(100vh - 64px)",
        bgcolor: "secondary.main"
      }}
    >
     <Stack justifyContent="center">
        <Typography variant="largeTitle">Welcome Prettycharm Seller</Typography>
        <Typography variant="title1">Log to start selling</Typography>

     </Stack>
    </Stack>
  )
}

export default OnBoardingHome