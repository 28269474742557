/**
 * Abstract: Shows child views if user is authenticated
 */

import  {  useContext, useEffect  } from 'react'
// import { Navigate, useLocation  } from 'react-router-dom'
import { UserAccountContext } from '../../contexts/UserAccountContext'
import { 
         // Accounts, 
        Home } from '../../routes'

interface RequireAuthProps {
    children: JSX.Element
}

const RequireAuth = ({children}:  RequireAuthProps): JSX.Element => {

    const {getSignedInUser}  = useContext(UserAccountContext)
    const user = getSignedInUser()
 
    const handleNavigation = ()=> {
        window.location.href = (Home.index)
    }
   
    useEffect(()=>{
     
        if (!user) {
            handleNavigation()
             
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.access_token])
   
  
    
  return children
}

export default RequireAuth