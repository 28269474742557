import React, {   useEffect, useState } from 'react'
import { appStoreURL, playStoreURL } from '../../defaultValues';
import { isIOS, isMacOs } from 'react-device-detect';
import Box from '@mui/material/Box';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebaseConfig';

type PCDownloadButtonProps = {
    width?: CSSProperties,
    height?: string
    shouldShortenLabel: boolean,
}

const PCDownloadButton = (props: PCDownloadButtonProps = {width: { md: "25vw" }, height: "3rem", shouldShortenLabel: false}) => {

    const [downloadInfo, setDownloadInfo] = useState({label: "play store", url: playStoreURL});


    useEffect(()=>{
      if (isIOS || isMacOs) {
        setDownloadInfo({label: "app store", url: appStoreURL});
       } 
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 
  return (
     <Box 
     
    onClick={()=> {
      logEvent(analytics, 'select_content', {content_type: isIOS || isMacOs ? "ios_app" : "android_app"})
      window.location.href = downloadInfo.url
    } }
    sx={{
       // width: { md: "25vw" },
        width: props.width,
        height: props.height,
        alignSelf: "center",
       // height: "3rem",
       paddingInline: "1rem",
        color: "secondary.main",
        textTransform: "uppercase",
        borderRadius: "2rem",
        fontWeight: "bold",
        cursor: "pointer",
        "&": {
          borderColor: "text.primary",
          backgroundColor: "text.primary"
        },
      }}
       component="button">
        
        {props.shouldShortenLabel ? "download" : `download from the ${downloadInfo.label}`}
    </Box>
 
  )
}

export default PCDownloadButton