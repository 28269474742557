import Stack from "@mui/material/Stack";
import {
  Formik,
  Field,
  FormikProps,
  FieldArrayRenderProps,
  FormikHelpers,
} from "formik";
import * as yup from "yup";

import { Dispatch, useState } from "react";
import { AuthUploadedAsset } from "../../../../../Models/Cloudinary";
import CustomImageInput from "./CustomImageInput";
import { DataObj } from "../../../../../Models/DataObj";
import { NotificationAction } from "../../../../../Models/Notification";
import { APIError } from "../../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { SxProps, Theme } from "@mui/material/styles";

import DCError from "../../../../SupportingViews/DCError";

interface ProductPhotoUploaderProps {
  sx: SxProps<Theme>;
  arrayHelpers: FieldArrayRenderProps;
  onUpload: (
    file: File,
    productId: string,
    publicId: string,
    storagePath: string,
    accessToken: string,
    refreshToken: string,
    variantId?: string
  ) => Promise<APIError | AuthUploadedAsset>;
  productId: string;
  name: string;
  storagePath: string;
  id: string;
  dispatch: Dispatch<NotificationAction>;
  variantId?: string;
  onSubmit: (<T>(
    values: T,
    formikHelpers: FormikHelpers<T>
  ) => void | Promise<any>) &
    (<T>(values: T, actions: FormikHelpers<T>) => Promise<any>);
}

const ProductPhotoUploader = ({
  sx,
  arrayHelpers,
  onUpload,
  dispatch,
  productId,
  name,
  storagePath,
  id,
  variantId,
  onSubmit,
}: ProductPhotoUploaderProps) => {
  const [error, setError] = useState<APIError | null>(null);

  // create initial value
  //debugger
  const initialValues: DataObj = {};
 const fieldIndex = parseInt(name.slice(-1));
  //const fieldIndex = parseInt(name.split(".")[1]);

  const fieldName =`photo${fieldIndex + 1}`;

  // const fieldName = name;
  initialValues[fieldName] = undefined;

  // create schema

  // compute file name
  const fileValidationSchema = yup.lazy((value): any => {
    const FILE_SIZE = 1024 * 1024 * 1024;
    // const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif"];
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
    ];

    let validationObj: DataObj = {};
    validationObj[fieldName] = yup
      .mixed()
      .nullable()
      .required("A file is required")
      .test(
        "fileSize",
        "File too large",
        (value) => value && value.size <= FILE_SIZE
      )
      .test("fileFormat", "Unsupported Format", (value) => {
        return value && SUPPORTED_FORMATS.includes(value.type);
      });

    return yup.object().shape(validationObj);
  });

 
  return (
    <>
      <DCError onErrorDisplayed={() => setError(null)} error={error} />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={fileValidationSchema}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => {
          return (
            <Stack sx={sx} id={fieldName} component="div">
              <Field
                name={fieldName}
                component={CustomImageInput}
                title="Select a file"
                id={fieldName}
              />
            </Stack>
          );
        }}
      </Formik>
    </>
  );
};

export default ProductPhotoUploader;
