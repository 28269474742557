import React, { ReactNode, useRef, useState } from "react";
import {
  AuthInventoryOrder,
  FulfillmentStatus,
  InventoryOrder,
} from "../../../Models/InventoryOrder";
import { RequestStatus, ResultType } from "../../../Models/Result";
import { AuthUser } from "../../../Models/User";
import supabaseAPIManager, {
  APIError,
} from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { OrdersContext } from "../contexts/OrdersContext";
import { OrderManager } from "../Models/OrderManager";
import { OrderDetail } from "../../../Models/Order";
import { UserProfile } from "../../../Models/UserProfile";

interface OrderManagerProviderProps {
  children: ReactNode;
}

const OrderManagerProvider = ({ children }: OrderManagerProviderProps) => {
  const [inventoryOrders, setInventoryOrders] = useState<InventoryOrder[]>([]);
  const [, setInventoryId] = useState<string | null>(null);
  const [customerProfile, setCustomerProfile] =
    useState<UserProfile | null>(null);
  let hasMoreInventoryOrders = useRef<boolean>(false);
  const [ordersRequestStatus, setOrdersRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);
  const [selectedOrder, setSelectedOrder] =
    useState<InventoryOrder | null>(null);
  const [fulfillmentRequestStatus, setFulfillmentRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);

  const apiKey = process.env.REACT_APP_SUPABASE_API_KEY;

  const getOrderDetailForId = (orderDetailId: string): OrderDetail | null => {
    // find an order where any of its orderdetails which id matches orderDetailId
    let orderDetail: OrderDetail | null = null;
    for (let order of inventoryOrders) {
      orderDetail =
        order.orderDetails?.find(
          (orderDetail) => orderDetail.orderDetailId === orderDetailId
        ) ?? null;
    }

    return orderDetail;
  };

  const getInventoryOrder = (
    inventoryOrderId: string
  ): InventoryOrder | null => {
    return (
      inventoryOrders.find(
        (order) => order.inventoryOrderId === inventoryOrderId
      ) ?? null
    );
  };

  const loadInventoryForPartner = async (
    userId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<AuthUser | null> => {
    if (!apiKey) {
      setOrdersRequestStatus(APIError.default);
      return null;
    }

    const queryItems = new Map([["user_id", userId]]);
    const result = await supabaseAPIManager.getInventoryRow(
      queryItems,
      accessToken,
      refreshToken,
      apiKey
    );

    if (result.type === ResultType.Failure) {
      setOrdersRequestStatus(result.error);
      return null;
    }

    const { inventoryList } = result.value;
     if (inventoryList.length === 0) {
      setOrdersRequestStatus(APIError.default);
      return null;
    }
    setInventoryId(inventoryList[0].inventoryid);

    return result.value.authUser;
  };

  const loadOrdersForInventory = async (
    auth: AuthUser | null,
    inventoryOrderId?: string,
    rangeStart?: number
  ): Promise<AuthUser | null> => {
    if (!apiKey || !auth) {
      setOrdersRequestStatus(APIError.default);
      return null;
    }

    const filters = new Map<string, string>([["order", "order_date.desc"]]);
    if (inventoryOrderId) {
      filters.set("inventory_order_id", `eq.${inventoryOrderId}`);
    }

    setOrdersRequestStatus(RequestStatus.Loading);
    const result = await supabaseAPIManager.getInventoryOrders(
      filters,
      apiKey,
      auth.access_token,
      auth.refresh_token,
      rangeStart
    );

    if (result.type === ResultType.Failure) {
      setOrdersRequestStatus(result.error);
      return null;
    }

 
    setOrdersRequestStatus(RequestStatus.Idle);
    hasMoreInventoryOrders.current =
      result.value.inventoryOrdersList.length ===
      supabaseAPIManager.inventoryOrdersPerPage;
    setInventoryOrders([
      ...inventoryOrders,
      ...result.value.inventoryOrdersList,
    ]);

    return result.value.authUser;
  };

  const loadCustomerProfile = async (
    auth: AuthUser,
    stripeCustomerId: string
  ): Promise<AuthUser | null> => {
    if (!apiKey) {
      return null;
    }

    const { access_token, refresh_token } = auth;
    const queryItems = new Map<string, string>([
      ["stripe_customer_id", stripeCustomerId],
    ]);

    const result = await supabaseAPIManager.getUserProfile(
      queryItems,
      access_token,
      refresh_token,
      apiKey
    );

    if (result.type === ResultType.Failure) {
      return null;
    }

    setCustomerProfile(result.value.profile);

    return result.value.authUser;
  };

  const loadInventoryOrder = async (
    auth: AuthUser | null,
    inventoryOrderId: string
  ): Promise<AuthUser | null> => {
    if (!apiKey || !auth) {
      setOrdersRequestStatus(APIError.default);
      return null;
    }

    const filters = new Map<string, string>([
      ["inventory_order_id", `eq.${inventoryOrderId}`],
    ]);
    //   if (inventoryOrderId) {
    //    filters.set("inventory_order_id", `eq.${inventoryOrderId}`)
    // }

    setOrdersRequestStatus(RequestStatus.Loading);
    const result = await supabaseAPIManager.getInventoryOrders(
      filters,
      apiKey,
      auth.access_token,
      auth.refresh_token
    );

    if (result.type === ResultType.Failure) {
      setOrdersRequestStatus(result.error);
      return null;
    }

    setOrdersRequestStatus(RequestStatus.Idle);

    if (result.value.inventoryOrdersList.length > 0) {
      setSelectedOrder(result.value.inventoryOrdersList[0]);
      
    } else {
      setSelectedOrder(null);
    }

    return result.value.authUser;
  };

  const updateFulfillmentStatus = async (
    fulfillmentStatus: FulfillmentStatus,
    inventoryOrderId: string,
    auth: AuthUser
  ): Promise<AuthInventoryOrder | null> => {
    if (!apiKey) {
      return null;
    }
    const keyedValues = new Map<string, any>([
      ["fulfillmentStatus", fulfillmentStatus],
    ]);
    const queryItems = new Map<string, any>([
      ["inventory_order_id", inventoryOrderId],
    ]);

    setFulfillmentRequestStatus(RequestStatus.Loading);
    const result = await supabaseAPIManager.updateInventoryOrder(
      queryItems,
      keyedValues,
      apiKey,
      auth
    );

    if (result.type === ResultType.Failure) {
      setFulfillmentRequestStatus(result.error);
      return null;
    }

    const { inventoryOrder: updatedOrder, authUser } = result.value;
    setSelectedOrder((prev) =>
      prev === null
        ? null
        : {
            ...prev,
            fulfillmentStatus: updatedOrder.fulfillmentStatus,
          }
    );
    
    
    const index = inventoryOrders.findIndex((item) => item.inventoryOrderId === updatedOrder.inventoryOrderId)
    if (index > -1) { 
      setInventoryOrders([...inventoryOrders.slice(0, index), updatedOrder, ...inventoryOrders.slice(index+1)])
    }
    
    setFulfillmentRequestStatus(RequestStatus.Idle);

    //console.dir(result);
    return {authUser, inventoryOrder: updatedOrder};
  };

  const manager: OrderManager = {
    fulfillmentRequestStatus,
    inventoryOrders,
    hasMoreInventoryOrders: hasMoreInventoryOrders.current,
    ordersRequestStatus,
    customerProfile,
    selectedOrder,
    loadInventoryForPartner,
    loadCustomerProfile,
    loadInventoryOrder,
    loadOrdersForInventory,
    getOrderDetailForId,
    getInventoryOrder,
    updateFulfillmentStatus,
  };

  return (
    <OrdersContext.Provider value={manager}>{children}</OrdersContext.Provider>
  );
};

export default OrderManagerProvider;
