import { createContext  } from "react";
import {
  UserAccount,
  UserAccountAction,
  UserAccountActionType,
} from "../components/UserAccount/Models/UserAccount";
import { Accounts, defaultAccounts } from "../Models/Accounts";
import { AuthUser } from "../Models/User";

// export const UserAccountContext = createContext<{
//   state: UserAccount | null;
//   dispatch: Dispatch<UserAccountAction>;
// }>({ state: null, dispatch: () => null });

export const  UserAccountContext = createContext<Accounts>(defaultAccounts);

export const userAccountReducer = (
  state: UserAccount | null,
  action: UserAccountAction
): UserAccount | null => {
  switch (action.type) {
    case UserAccountActionType.addAuth:
        
      return {
         auth: action.payload as AuthUser,
         personalInfo: {},
         profile: {}
        //auth: action.p
      };
    default:
      return state;
  }
};
