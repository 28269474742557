import AppBar from '@mui/material/AppBar'
import React, { ReactNode } from 'react'

interface DCBottomAppBarProps {
    render: ()=>ReactNode
}

const DCBottomAppBar = (props: DCBottomAppBarProps) => {
  return (
    <AppBar color="secondary" position="fixed"   sx={{ top: 'auto', bottom: 0 }}>
        {props.render()}
    </AppBar>
  )
}

export default DCBottomAppBar