 
import { AuthUploadedAsset, UploadedAsset } from "../../../Models/Cloudinary";
import { CloudinaryAPIError } from "../../../networking/CloudinaryAPIManager/CloudinaryAPIManager";
import { AuthDataObj } from "../../../Models/DataObj";
import { AuthUser } from "../../../Models/User";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { ProductFormData } from "./ProductFormData";
import {
  AuthDeleteProductStatus,
  AuthProduct,
  AuthProductVariantList,
  Product,
} from "../../../Models/Product";
import { RequestStatus } from "../../../Models/Result";
import {   InventoryOrder } from "../../../Models/InventoryOrder";

export interface Inventory {
  readonly id: string | null;
  isCreated: () => boolean;
  // generateProductVariants: <T>(sizeAttribute: Sizes, colorAttribute: Colors) => Set<T[]>,
  generateProductVariants: (
    productId: string,
    auth: AuthUser,
    selectedAttributeOptions: Map<string, any>,
    draft: ProductFormData
  ) => Promise<AuthProductVariantList | null>;
  createInventoryForPartner: (
    userId: string,
    accessToken: string,
    refreshToken: string
  ) => Promise<APIError | AuthInventoryList>;
  deletePhotoForProduct: (
    productId: string,
    publicId: string,
    accessToken: string,
    refreshToken: string
  ) => Promise<APIError | AuthDataObj>;
  deletePhotoForVariant: (
    variantId: string,
    publicId: string,
    accessToken: string,
    refreshToken: string
  ) => Promise<APIError | AuthDataObj>;
  deleteProduct: (
    productId: string,
    partner: AuthUser
  ) => Promise<APIError | AuthDeleteProductStatus>;
  loadInventoryOrdersForInventory: (
    auth: AuthUser | null,
    inventoryOrderId?: string,
    rangeStart?: number
  ) => Promise<AuthUser | null>;

  getProductDetailForId: (
    productId: string,
    auth: AuthUser
  ) => Promise<AuthProduct | null>;
  isProductDetailsComplete: (product: Product) => boolean;
  performProductUpdate: (product: Product) => boolean
  uploadPhoto: (
    file: File,
    storagePath: string,
    productId: string,
    publicId: string
  ) => Promise<CloudinaryAPIError | UploadedAsset>;
  addProduct: (
    title: string,
    accessToken: string,
    refreshToken: string
  ) => Promise<APIError | AuthProduct>;
  updateProduct: (
    productId: string,
    formData: ProductFormData,
    accessToken: string,
    refreshToken: string
  ) => Promise<AuthProduct | APIError>;
  getProductWithId: (id: string) => Product | undefined;
  /**
   * loads product for an inventory
   */
  loadProductsForInventory: (
    auth: AuthUser | null,
    productId?: string,
    rangeStart?: number
  ) => Promise<APIError | boolean>;
  getProducts: () => Product[];
  formatValueOf: (value: number) => string;
  updateDetailsForProduct: (
    productId: string,
    newDetails: Map<string, any>,
    accessToken: string,
    refreshToken: string
  ) => Promise<APIError | AuthProduct>;

  updateProductPhoto: (
    file: File,
    productId: string,
    publicId: string,
    storagePath: string,
    accessToken: string,
    refreshToken: string,
    variantId?: string
  ) => Promise<APIError | AuthUploadedAsset>;
  updateSelectedProduct: (
    newDetails: Map<string, any>,
    accessToken: string,
    refreshToken: string
  ) => Promise<AuthUser | null>;
  hasMoreProducts: () => boolean;
  readonly selectedProduct: Product | null;
  readonly inventoryRequestStatus: RequestStatus | APIError;
  readonly hasMoreInventoryOrders: boolean;
  readonly inventoryOrders: InventoryOrder[];
}

export const defaultInventory: Inventory = {
  id: null,

  addProduct: (
    title: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthProduct> => {
    return new Promise((resolve, reject) => {
      reject(APIError.default);
    });
  },
  deletePhotoForProduct: async (
    productId: string,
    publicId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthDataObj> => {
    return new Promise((resolve, reject) => {
      reject(APIError.default);
    });
  },
  deletePhotoForVariant: async (
    variantId: string,
    publicId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthDataObj> => {
    return new Promise((resolve, reject) => {
      reject(APIError.default);
    });
  },

  deleteProduct: (
    productId: string,
    partner: AuthUser
  ): Promise<APIError | AuthDeleteProductStatus> => {
    return new Promise((resolve, reject) => {
      reject(APIError.default);
    });
  },

  generateProductVariants: (
    productId: string,
    auth: AuthUser,
    selectedAttributeOptions: Map<string, any>,
    draft: ProductFormData
  ): Promise<AuthProductVariantList | null> => {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  },

  isCreated: (): boolean => {
    return false;
  },
  createInventoryForPartner: (
    userId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthInventoryList> => {
    return new Promise((resolve, reject) => {
      reject(APIError.default);
    });
  },

  isProductDetailsComplete: (product: Product): boolean => {
    return true;
  },

   
  loadInventoryOrdersForInventory: (
    auth: AuthUser | null,
    inventoryOrderId?: string,
    rangeStart?: number
  ): Promise<AuthUser | null> => {
    return new Promise((resolve, reject) => {
      reject(null);
    });
  },

   

  
  
  getProductDetailForId: (
    productId: string,
    auth: AuthUser
  ): Promise<AuthProduct | null> => {
    return new Promise((resolve, reject) => {
      reject(null);
    });
  },

  performProductUpdate: (product: Product): boolean => {return false},

  uploadPhoto: (
    file: File,
    storagePath: string,
    productId: string,
    publicId: string
  ): Promise<CloudinaryAPIError | UploadedAsset> => {
    return new Promise((resolve, reject) => {
      resolve(CloudinaryAPIError.default);
    });
  },

  updateProduct: (
    productId: string,
    formData: ProductFormData,
    accessToken: string,
    refreshToken: string
  ): Promise<AuthProduct | APIError> => {
    return new Promise((resolve, reject) => {
      reject(APIError.default);
    });
  },

  updateSelectedProduct: async (
    newDetails: Map<string, any>,
    accessToken: string,
    refreshToken: string
  ): Promise<AuthUser | null> => {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  },

  getProductWithId: (id: string): Product | undefined => {
    return undefined;
  },

  loadProductsForInventory: (
    auth: AuthUser | null,
    productId?: string,
    rangeStart?: number
  ): Promise<APIError> => {
    return new Promise((resolve, reject) => {
      resolve(APIError.default);
    });
  },
  getProducts: (): Product[] => {
    return [];
  },
  formatValueOf: (value: number): string => {
    return "";
  },

  updateDetailsForProduct: (
    productId: string,
    newDetails: Map<string, any>,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthProduct> => {
    return new Promise((resolve, reject) => {
      reject(APIError.default);
    });
  },

  updateProductPhoto: (
    file: File,
    productId: string,
    publicId: string,
    storagePath: string,
    accessToken: string,
    variantId?: string
  ): Promise<APIError | AuthUploadedAsset> => {
    return new Promise((resolve, reject) => {
      resolve(APIError.default);
    });
  },

  hasMoreProducts: (): boolean => {
    return false;
  },
  selectedProduct: null,
  inventoryRequestStatus: APIError.default,
  hasMoreInventoryOrders: false,
  inventoryOrders: [],
};

export interface InventoryRow {
  inventoryid: string;
  userid: string;
}

export interface AuthInventoryList {
  inventoryList: InventoryRow[];
  authUser: AuthUser | null;
}
