/**
 * Abstract: A view that displays an onscreen error or logs out user in the case of an invalid token
 */
import React, { useContext, useEffect, useState } from 'react'
import { APIError } from '../../networking/SupabaseAPIManager/SupabaseAPIManager'
  import { UserAccountContext } from '../../contexts/UserAccountContext';
import { AppDataContext } from '../../contexts/AppDataContext';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface DCErrorProps {
    error: APIError | null
    onErrorDisplayed?: ()=>void
}


/**
 * 
 * @param error
 * @param onErrorDisplayed an action that is performed after on screen error is shown
 * @returns 
 */
const DCError = ({error, onErrorDisplayed}: DCErrorProps) => {

    const { clearCredentials } = useContext(UserAccountContext)
    const appData  = useContext(AppDataContext)
    const [isPresented, setIsPresented] = useState(false)
 
    const isMediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.up("md")
  );



    const anchorOrigin = ((): SnackbarOrigin =>{
      return isMediumScreen ?  {
        vertical: 'top',
        horizontal: 'right',
      } : {
        vertical: 'bottom',
        horizontal: 'center',
      }
    
  })()

  const handleClose = ()=> {
    setIsPresented(false)
    //onErrorDisplayed?.()
  }

  //  const isPresented = (() =>{
  //       if (!error) {return false}
  //       const {isInvalidTokenError, isServerError} = error
  //       if (!isInvalidTokenError && !isServerError) {
  //         return true
  //       }
  //  })()
  
    useEffect(()=>{
        if (!error) {return}
        
        
        const {errorDescription, isInvalidTokenError, isServerError} = error
        if (errorDescription) {
         if (isInvalidTokenError || isServerError) {
           clearCredentials()
           appData.gotoShoppingApp()
         } else {
           setIsPresented(true)
         }
         
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])
  
   return (
    <Snackbar anchorOrigin={anchorOrigin} open={isPresented} autoHideDuration={6000} onClose={handleClose}>
    <Alert
      onClose={handleClose}
      severity="error"
     // sx={{ width: "100%" }}
    >
      {error?.errorDescription}
    </Alert>
  </Snackbar>
   )
}

export default DCError