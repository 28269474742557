import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Field, FieldArray, FieldArrayRenderProps } from "formik";
 
import React from "react";
import { excludedAccessories } from "../../utils";
 
interface OptionsListProps {
  title: string;
  options: {id: string, title: string}[]  
  variant: any
 
}

const OptionsList = ({ title, options, variant }: OptionsListProps) => {
  return (
    <Stack width="100%" spacing={1} alignItems="flex-start">
      <Typography variant={variant}>{title}</Typography>
      <FieldArray
        name={title.toLowerCase()}
        render={(arrayHelpers) => (
          <CustomChoiceChipsView
            name={title.toLowerCase()}
            data={options}
            arrayHelpers={arrayHelpers}
          />
        )}
      />
    </Stack>
  );
};

interface CustomChoiceChipsViewProps {
  name: string
  arrayHelpers: FieldArrayRenderProps;
  data:   {id: string, title: string}[]  
}
const CustomChoiceChipsView = ({
  name,
  data,
  arrayHelpers,
}: CustomChoiceChipsViewProps) => {
  
  const theme = useTheme();
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  
   return (
    <>
      <Stack direction="row" spacing={1}>
        {data.map((item, index) => (
          <Field key={item.id} id={`${name}.${index}`} name={`${name}.${index}`}>
            {({ field, form, meta }: any) => {
                //console.dir(field)
              let selectedIdx =
                arrayHelpers.form.values[arrayHelpers.name].indexOf(item.id);
              return (
                <Chip
                  id={item.id}
                  {...field}
                  label={item.title}
                  size={matches ? "medium" : "large"}
                  sx={{
                    "& .MuiChip-label": {
                      color: theme.palette.primary.dark,
                      fontWeight: 500,
                    },
                    "&.MuiChip-root": {
                      background:
                        selectedIdx > -1
                          ? theme.palette.primary.main
                          : "rgba(68, 44, 46, 0.12)",
                      textTransform:  name === "sizes" ? "uppercase" : "capitalize",
                    },
                  }}
                  onClick={() => {
                    if (selectedIdx > -1) {
                      arrayHelpers.remove(selectedIdx);
                    } else if (!excludedAccessories.includes(item.id)) {
                      if (name === "accessories") {
                          arrayHelpers.form.setFieldValue(name, [])
                      }
                      arrayHelpers.insert(index, item.id);
                    }
                  }}
                />
              );
            }}
          </Field>
        ))}
      </Stack>
      {(arrayHelpers.form.errors[arrayHelpers.name] && arrayHelpers.form.touched[arrayHelpers.name]) && (
        <Typography   className="Mui-error" color="error" marginX={"14px"} variant="caption1">
          {arrayHelpers.form.errors[arrayHelpers.name] as string}
        </Typography>
      )}
    </>
  );
};

export default OptionsList;
