import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { useEffect, useRef, useState  } from 'react';
  
export interface ChipData {
  id: string;
  label: string;
}

export enum ChipsListVariant {
    single = "single",
    multiple = "multiple"
}

interface ChipsListProps {
    data: ChipData[], 
    onChange: (selected: Set<ChipData>) => void
    variant: ChipsListVariant
    value?: Set<ChipData>
    textTransform: "uppercase" | "capitalize"
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsList(props: ChipsListProps) {
   
  //const [selection, setSelection] = React.useState<Set<ChipData>>(new Set<ChipData>())
  const [selectionCounter, setSelectionCounter] = useState(0)
  const [selection, setSelection] = useState<Set<ChipData>>(new Set<ChipData>())
  //const [tempSelection, setTempSelection] = useState<Set<ChipData>>(new Set<ChipData>())
 
  const temp = useRef<Set<ChipData>>(new Set<ChipData>())
  const theme = useTheme();
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  const {onChange } = props

 
  const handleClick =  (data: ChipData)=>{
    
    
   // let temp = new Set<ChipData>(Array.from(tempSelection))
    
    
    // single and selectiong a different item
    if (props.variant === ChipsListVariant.single && !temp.current.has(data)) {
        temp.current.clear()
        //onChange(new Set())
    }
    //debugger
    if (temp.current.has(data)) {
       
        temp.current.delete(data)
      
    } else {
        temp.current.add(data)
       
    }
    
    setSelectionCounter(selectionCounter + 1)
  } 

  useEffect(()=>{
    if (selectionCounter === 0) {return}
    onChange(temp.current)

    //onChange(tempSelection)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectionCounter])


  useEffect(() => {
    const incoming = new Set(props.value ? Array.from(props.value) : [])
    setSelection(incoming)
    temp.current = incoming 
    
  }, [props.value])

  
  return (
    <Stack
      direction="row"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {props.data.map((data) => {
         

        return (
          <ListItem key={data.id}>
            <Chip
              
              label={data.label}
              id={data.id}   
                  size={matches ? "medium" : "medium"}
                  sx={{
                    "& .MuiChip-label": {
                      color: theme.palette.primary.dark,
                      fontWeight: 500,
                    },
                    "&.MuiChip-root": {
                      padding: "0.25rem",
                      background:
                        selection.has(data)
                          ? theme.palette.primary.main
                          : "rgba(68, 44, 46, 0.12)",
                        textTransform: props.textTransform,
                    },
                  }}
                  onClick={()=> handleClick(data)}
             
            />
          </ListItem>
        );
      })}
    </Stack>
  );
}