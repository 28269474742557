import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const Contact = () => {
  return (
    <Stack>
        <Typography>Do you have any questions the needs answers such as account, 
            e.t.c Kindly send us an email. Please note: When you request your account be deleted, your profile and all data associated with it will be deleted</Typography>
         <Box component='a' sx={{color: 'primary.main', textDecorationColor: 'primary.main'}} href='mailto:support@prettycharm.app'>support@prettycharm.app</Box>
    </Stack>
  )
}

export default Contact