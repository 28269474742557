/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useCallback, useContext, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ProductsToolbar from "./view/mobile/ProductsToolbar";
import { InventoryContext } from "../contexts/InventoryContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  NotificationActionType,
  NotificationType,
} from "../../../Models/Notification";
import LoadMoreButton from "../../SupportingViews/LoadMoreButton";
import DCAlertDialog from "./view/mobile/DCAlertDialog";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { formdataForVariants, getPhotos, getValueOf, getValuesOf } from "../../../utils";
import { productDetailsValidationSchema, productVariantValidationSchema } from "../Models/editProductValidationSchema";
import ProductRow from "./view/mobile/ProductRow";
import { SxProps, Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import DCError from "../../SupportingViews/DCError";
import ScrollToTop from "../../SupportingViews/ScrollToTop";
import { ProductFormData } from "../Models/ProductFormData";
import {
  AuthDeleteProductStatus,
  AuthProduct,
  Product,
} from "../../../Models/Product";
import { tableCellStyle } from "../../../utils/styles";

const Products = () => {
  const inventory = useContext(InventoryContext);
  const { getSignedInUser, saveCredentials } = useContext(UserAccountContext);

  const products = inventory.getProducts();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [productDeleted, setProductDeleted] =
    useState<{ title: string; productId: string } | null>(null);
  const [productToPublish, setProductToPublish] =
    useState<{ title: string; productId: string } | null>(null);
  const [error, setError] = useState<APIError | null>(null);
  const { dispatch } = useContext(NotificationContext);
  const isMobileScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  const user = getSignedInUser();

  const handleLoadmoreProducts = useCallback(async () => {
    setIsLoading(true);
    const loadProductsResult = await inventory.loadProductsForInventory(
      user,
      undefined,
      products.length
    );
    const errorDesc = (loadProductsResult as APIError).errorDescription;
    if (errorDesc) {
      dispatch({
        type: NotificationActionType.open,
        payload: {
          type: NotificationType.error,
          msg: errorDesc,
        },
      });
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const handleDeleteProduct = useCallback(
    async (productId: string, title: string) => {
      if (!user) {
        return;
      }

      setIsDeleting(true);
      const result = await inventory.deleteProduct(productId, user);
      const error = result as APIError;

      setIsDeleting(false);

      if (error.errorDescription) {
        setError(error);
        setProductDeleted(null);
        return;
      }

      const { authUser } = result as AuthDeleteProductStatus;
      if (authUser) {
        saveCredentials(authUser);
      }
      setError(null);
      setProductDeleted(null);

      dispatch({
        type: NotificationActionType.open,
        payload: {
          type: NotificationType.success,
          msg: `${title} was deleted successfully`,
        },
      });

      // console.dir(e)
    },
    [inventory, dispatch, user, error]
  );

  const handlePublishProduct = useCallback(
    async (productId: string, title: string, isPublished: boolean) => {
      if (!user) {
        return;
      }

      setProductToPublish((prev) => ({
        ...prev,
        title,
        productId,
      }));
      const newDetails = new Map<string, boolean>([
        ["isPublished", isPublished],
      ]);
      
      
      const result = await inventory.updateDetailsForProduct(
        productId,
        newDetails,
        user.access_token,
        user.refresh_token
        // user?.access_token || ""
      );

      //const result = await inventory.updateProduct()

      const error = result as APIError;
      if (error.errorDescription) {
        setError(error);
        setProductToPublish(null);
        return;
      }

      const { authUser } = result as AuthProduct;
      if (authUser) {
        saveCredentials(authUser);
      }

      setError(null);
      setProductToPublish(null);

      dispatch({
        type: NotificationActionType.open,
        payload: {
          type: NotificationType.success,
          msg: `${title} was ${
            isPublished ? "published" : "unpublished"
          } successfully`,
        },
      });
    },
    [inventory, dispatch, user, error]
  );

  /**
   * whether all details of the product have been specified
   * @param product
   * @returns true or false
   */
  const isProductDetailsComplete = (product: Product): boolean => {
    const productFormValues: ProductFormData = {
      stockQuantity: getValueOf(product.stockQuantity),
      wholesaleCost: getValueOf(product.wholesaleCost),
      sellingPrice: getValueOf(product.sellingPrice),
      description: product.description,
      categoryId: product.categoryId,
      title: product.title,
      subcategoryId: product.subcategoryId || "",
      colors: getValuesOf(product.colors),
      sizes: getValuesOf(product.sizes),
      accessories: getValuesOf(product.accessories),
      photos: getPhotos(product),
      variants: formdataForVariants(product.productDetails ?? [])
    };

    const isVariantAvailable = productVariantValidationSchema.isValidSync(productFormValues, {
      strict: true
    })

 
    const isProductDataValid = productDetailsValidationSchema.isValidSync(productFormValues, {
      strict: true,
    });

    
    return isVariantAvailable && isProductDataValid
  };

  const toolbarStyle: SxProps<Theme> = {
    top: { xs: "3.5rem", md: "4rem" },
    position: "sticky",
    height: isMobileScreen ? "3.5rem" : "4rem",
    background: "#feeae6",
    borderBottom: "0.5px solid #442C2E",
    marginBottom: "1rem",
    padding: "1rem",
    zIndex: 5,
    marginInline: "-1.6rem",
  };

  const tableHead = ((): ReactNode => {
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={tableCellStyle} align="left">
            Title
          </TableCell>
          <TableCell align="left">Quantity</TableCell>
          <TableCell 
            //width={400} 
            align="left">
            Description&nbsp;
          </TableCell>
          <TableCell align="left">Category&nbsp;</TableCell>
          <TableCell align="left">SubCategory&nbsp;</TableCell>
          <TableCell align="left">Wholesale Cost&nbsp;</TableCell>
          <TableCell align="left">Selling Price&nbsp;</TableCell>
          <TableCell align="left">Available Colors&nbsp;</TableCell>
          <TableCell align="left">Available Sizes&nbsp;</TableCell>
          <TableCell align="left">Available Accessories&nbsp;</TableCell>
          <TableCell align="left">&nbsp;</TableCell>
        </TableRow>
      </TableHead>
    );
  })();

  const productRows = (()=> {
   return products.map((product) => {
      const { productId, isPublished, title } = product;
      return (
        <ProductRow
          key={productId}
          product={product}
          formatValueOf={inventory.formatValueOf}
          buttons={[
            <Button
              startIcon={<DeleteForeverIcon />}
              onClick={(e) => {
                e.stopPropagation();
                setProductDeleted((prev) => ({
                  ...prev,
                  title,
                  productId,
                }));
              }}
            >
              Delete Forever
            </Button>,
            <LoadingButton
              startIcon={<VisibilityIcon />}
              disabled={!isProductDetailsComplete(product)}
              loading={productId === productToPublish?.productId}
              onClick={(e) => {
                e.stopPropagation();
                handlePublishProduct(
                  productId,
                  title,
                  !isPublished
                );
              }}
            >
              {isPublished ? "UnPublish" : "Publish"}
            </LoadingButton>,
          ]}
        />
      );
    })
  })()

  // useEffect(()=>{

  // }, [inventory])

 

  return (
    <>
      <ScrollToTop />
      <DCError onErrorDisplayed={() => setError(null)} error={error} />
      <ProductsToolbar
        btnSize={isMobileScreen ? "small" : "large"}
        sx={toolbarStyle}
      />
      {inventory.isCreated() ? (
        <Typography variant="headline">Welcome Partner</Typography>
      ) : (
        <div>No Inventory</div>
      )}

      {products.length === 0 ? (
        <Stack
          sx={{
            display: "grid",
            placeItems: "center",
            height: { xs: "calc(100vh - 112px)", sm: "calc(100vh - 164px)" },
          }}
        >
          <Typography fontWeight={500} variant="title2">
            Your inventory is empty
          </Typography>
        </Stack>
      ) : (
        <Stack>
             <TableContainer
              component={Paper}
            >
              <Table
                //sx={{ minWidth: "100%", borderCollapse: "inherit", tableLayout: "auto" }}
                sx={{ width: "max-content", borderCollapse: "inherit", tableLayout: "auto" }}
                size="medium"
                aria-label="products table"
              >
                {tableHead}
                <TableBody
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  {productRows}
                </TableBody>
              </Table>
            </TableContainer>
           <LoadMoreButton
            sx={{
              display: inventory.hasMoreProducts() ? "initial" : "none",
            }}
            loading={isLoading}
            onClick={handleLoadmoreProducts}
          />
          <DCAlertDialog
            item={productDeleted}
            title={productDeleted ? `Remove ${productDeleted.title}` : ""}
            message={
              "Are you sure you want to remove this product from your inventory?"
            }
            onClose={() => setProductDeleted(null)}
            onConfirm={() => {
              if (productDeleted) {
                handleDeleteProduct(
                  productDeleted.productId,
                  productDeleted.title
                );
              }
            }}
            isLoading={isDeleting}
          />
        </Stack>
      )}
    </>
  );
};

export default Products;
