import { Bag } from "../../Models/Bag";
import { PCAPIError, RequestError } from "../../Models/PCAPIError";
import { Result, ResultType } from "../../Models/Result";
import supabaseAPIManager from "../SupabaseAPIManager/SupabaseAPIManager";
import { AuthSessionId, AuthUser } from "../../Models/User";
import axios, { AxiosError } from "axios";
import { appURL } from "../../defaultValues";
import { AuthCoupon } from "../../Models/Product";

enum StripeEndpoint {
  Checkout = "create_stripe_checkout",
  OrderInfo = "stripe_order_info",
  CreateDiscount = "stripe-create-discount",
  GetCoupons = "retrieve-stripe-coupons"
}

class Endpoint {
  private functionsBaseURL =
    "https://drqpobiziyoxsgfjvela.functions.supabase.co";
  private _value: StripeEndpoint;

  /**
   *
   */
  constructor(value: StripeEndpoint = StripeEndpoint.Checkout) {
    this._value = value;
  }

  get url(): URL | null {
    switch (this._value) {
      case StripeEndpoint.Checkout:
      case StripeEndpoint.OrderInfo:
        return new URL(`${this.functionsBaseURL}/${this._value}`);
      case StripeEndpoint.GetCoupons:
        return new URL(`${this.functionsBaseURL}/${this._value}`);
       // return new URL(`https://drqpobiziyoxsgfjvela.co/functions/v1/${this._value}`);
      default:
        return null;
    }
  }

  set value(newEndpoint: StripeEndpoint) {
    this._value = newEndpoint;
  }
}

class StripeAPIManager {
  static shared = new StripeAPIManager();
  private apiError = new PCAPIError();
  private endpoint = new Endpoint();
  private refreshedAuthUser: AuthUser | null = null;
  
  
  async createStripeCheckoutSessionId(
    accessToken: string,
    refreshToken: string,
    bag: Bag,
    dbAPIkey: string
  ): Promise<Result<AuthSessionId, PCAPIError>> {
    let result: Result<AuthSessionId, PCAPIError> = {
      type: ResultType.Failure,
      error: PCAPIError.default,
    };

    const endpoint = new Endpoint(StripeEndpoint.Checkout);

    if (!endpoint.url) {
      return result;
    }

    try {
      // payment function should be in user account model
      const axiosInstance = supabaseAPIManager.useInvalidTokenInterceptor(
        refreshToken,
        dbAPIkey,
        false
      );
      const { data } = await axiosInstance.post(
        endpoint.url.href,
        {
          bag,
          successURL: `${appURL}/bag/orderConfirmation?session_id={CHECKOUT_SESSION_ID}&platform=web`,
          //cancelURL: `${appURL}/bag`,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      result = {
        type: ResultType.Success,
        value: {
          sessionId: data.session.id,
          authUser: supabaseAPIManager.refreshedAuth,
          clientSecret: data.session.client_secret
        },
      };
    } catch (error: any) {
      const requestError = this.processError(error);
      this.apiError.update(requestError);
      
      const axiosError = error as AxiosError
      const errorMsg = (axiosError.response?.data as any).error ?? undefined
     // debugger
      if (requestError === RequestError.Badrequest && errorMsg) {
        this.apiError.setMessage(errorMsg)
    }
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getOrderInfoForSessionId(
    sessionId: string,
    dbAPIkey: string
  ): Promise<Result<any, PCAPIError>> {
    let result: Result<any, PCAPIError> = {
      type: ResultType.Failure,
      error: PCAPIError.default,
    };

    this.endpoint.value = StripeEndpoint.OrderInfo;

    try {
      const { data } = await axios.post(
        this.endpoint.url?.href!,
        {
          sessionId,
        },
        {
          headers: {
            Authorization: `Bearer ${dbAPIkey}`,
          },
        }
      );

      result = {
        type: ResultType.Success,
        value: data.session,
      };
    } catch (error: any) {
      const requestError = this.processError(error);
      this.apiError.update(requestError);
      
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getCoupons(withId: string, accessToken: string, refreshToken: string, apikey: string): Promise<Result<AuthCoupon, PCAPIError>> {
    let result: Result<AuthCoupon, PCAPIError> = {
      type: ResultType.Failure,
      error: PCAPIError.default,
    };

    this.endpoint.value = StripeEndpoint.GetCoupons;

    if (this.endpoint.url === null) {return result}

    const axiosInstance = supabaseAPIManager.useInvalidTokenInterceptor(
      refreshToken,
      apikey
    );

     try {
      const { data } = await axiosInstance.post(
        this.endpoint.url.href,
        {
          id: withId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            apikey
          },
        }
      );
  
      result = {
        type: ResultType.Success,
        value: {
          authUser: supabaseAPIManager.refreshedAuth,
          coupon: data
        },
      };
    } catch (error) {
      const requestError = this.processError(error);
      this.apiError.update(requestError);
      
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }
    
    return result
  }

  private processError(error: unknown): RequestError {
    if (axios.isAxiosError(error) && error.response?.status) {
      const status = error.response?.status;
      if (error.code === "ERR_NETWORK") {
        return RequestError.AddressUnreachable;
      }

      switch (true) {
        case status === 400:
          return RequestError.Badrequest;
        case status === 401:
          return RequestError.Unauthorized;
        case status === 404:
          return RequestError.Notfound;
        case status >= 500 && status < 600:
          return RequestError.Server;
        default:
          return RequestError.Unexpected;
      }
    } else {
      return RequestError.Unexpected;
    }
  }
}

const stripeAPIManager = StripeAPIManager.shared;
export default stripeAPIManager;
