import * as yup from "yup";

export const signupValidationSchema = yup.object().shape({
  firstname: yup
    .string()
    .required("Firstname must not be empty")
    .min(2, "Firstname must not be below 2 characters")
    .max(50, "Firstname must not exceed 50 characters"),

  lastname: yup
    .string()
    .min(2, "Lastname must not be below 2 characters")
    .max(50, "Lastname must not exceed 50 characters"),

  email: yup
    .string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
    // .matches(
    //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //   "Please enter a valid email"
    // ),

  birthday: yup
    .date()
    .test(
      "is-18-above",
      (d) =>
        "You must be 18 years or older to use Diamond Crust. Other people won't see your birthday",
      (value) => {
        //console.log(value)

        if (!value) {
          return false;
        }
        console.log(new Date().getFullYear() - value.getFullYear())
        return true //new Date().getFullYear() - value.getFullYear() >= 18;
      }
    )
    .required(),
  //birthday: yup.string().required("You must be 18 years or older to use Diamond Crust. Other people won't see your birthday"),
  // day: yup
  // .string()
  // .required("Please enter a day"),

  // month: yup
  // .string()
  // .required("Please enter a month"),

  // year: yup
  // .string()
  // .required("Please enter a year"),

  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number. Can contain special case Characters"
    ),

    allowsMarketing: yup 
    .boolean()

  // country: yup.string()
  // .required("Please select a country"),

  // terms: Yup.boolean().test('has agreed',
  //  "This field is required",
  //  (value) => value === true
  // )
});

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
     

  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number. Can contain special case Characters"
    ),
});

export const resetPasswordSchema = yup.object().shape({

  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number. Can contain special case Characters"
    ),

    newPassword: yup
    .string()
    .required("Please Enter your new password")
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export const forgotPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
  
});