import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext, useEffect } from "react";
import { RequestStatus } from "../../Models/Result";
import { APIError } from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import OfflineView from "./OfflineView";
import { UserAccountContext } from "../../contexts/UserAccountContext";
import { AppDataContext } from "../../contexts/AppDataContext";

interface RequestStatusViewProps {
  status: RequestStatus | APIError;
  onRetry?: () => void;
}

const RequestStatusView = ({ status, onRetry }: RequestStatusViewProps) => {
  const error = status as APIError;
  const {clearCredentials} = useContext(UserAccountContext)
  const {gotoShoppingApp} = useContext(AppDataContext)
  const { isInvalidTokenError, isServerError} = error

  useEffect(()=>{
   
    //if (errorDescription) {
     if (isInvalidTokenError || isServerError) {
       clearCredentials()
       gotoShoppingApp()
     }  
   // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  
  // show any error but not invalid token or server error
  if (error?.errorDescription && !(isInvalidTokenError || isServerError)) {
    return (
      <OfflineView
        onRetry={onRetry}
        msg={error?.errorDescription}
        sx={{ pt: "1rem" }}
      />
    );
  }

  if (status === RequestStatus.Loading) {
    return <CircularProgress  />;
  }

  return null
};

export default RequestStatusView;
