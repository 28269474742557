import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {DiscountInfo} from "../../Models/Product"
import { isDiscountRedeemable } from "../../utils";

interface DiscountViewProps {
  discountInfo: DiscountInfo | null;
  isDetailView?: boolean
  variant?: "short" | "long"
}

const DiscountView = (props: DiscountViewProps) => {

    if (props.discountInfo === null || !isDiscountRedeemable(props.discountInfo)) {return null}

    let label = props.variant === "long" || props.variant === undefined ? (
        <Typography component={"span"} color={"red"} variant={props.isDetailView ? "title3" : "caption"}>
        Limited Time Deal
      </Typography>
    ) : null

  

  const percentOffText = (props.variant === "long" || props.variant === undefined) ? `${props.discountInfo.percentOff.toFixed()}% off` : `-${props.discountInfo.percentOff.toFixed()}%` 
    
  return (
    <Stack spacing={1} alignItems="center" direction={"row"}>
      <Typography
        color="white"
        fontWeight={500}
        borderRadius={1}
        bgcolor={"red"}
        paddingX={1}
        paddingY={0.5}
        variant={props.isDetailView ? "title3" : "caption"}
      >

        {percentOffText}
      </Typography>
      {label}
    </Stack>
  )  
};

export default DiscountView;
