import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIError } from "../../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { InventoryContext } from "../../../contexts/InventoryContext";

import ProductTitleDialog from "./ProductTitleDialog";
import { Inventory, SellerApp } from "../../../../../routes";

import Typography from "@mui/material/Typography";
import { UserAccountContext } from "../../../../../contexts/UserAccountContext";
import DCError from "../../../../SupportingViews/DCError";
import { AuthProduct } from "../../../../../Models/Product";

interface ProductsToolbarProps {
  sx: any;
  btnSize: any;
}

const ProductsToolbar = ({ sx, btnSize }: ProductsToolbarProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addProductError, setAddProductError] = useState<APIError | null>(null);
  const { addProduct, getProducts } = useContext(InventoryContext);
  const { getSignedInUser, saveCredentials } = useContext(UserAccountContext);
  const user = getSignedInUser();

  const navigate = useNavigate();

  const toggleDialog = useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [isDialogOpen]);

  const handleTitleConfirmation = async (title: string) => {
    setIsLoading(!isLoading);

    if (!user) {
      return;
    }
    // add product
    const result = await addProduct(
      title,
      user.access_token,
      user.refresh_token
    );

    setIsLoading(false);
    const error = result as APIError;
    if (error.errorDescription) {
      setAddProductError(error);
      return;
    }

    toggleDialog();
    const { product, authUser } = result as AuthProduct;
    if (authUser) {
      saveCredentials(authUser);
    }
    // navigate to editProduct screen
    const { productId } = product;
    if (productId) {
      navigate(`/${SellerApp.pathname}/${Inventory.products}/${productId}`);
    }
  };

  return (
    <>
      <Stack sx={sx} direction="row" justifyContent={"space-between"}>
        <Typography variant="headline">
          Showing {getProducts().length} products
        </Typography>
        <Button variant="text" size={btnSize} onClick={toggleDialog}>
          Add Product
        </Button>
        <ProductTitleDialog
          isOpen={isDialogOpen}
          onClose={toggleDialog}
          onConfirm={handleTitleConfirmation}
          isLoading={isLoading}
        />
      </Stack>
      <DCError
        onErrorDisplayed={() => setAddProductError(null)}
        error={addProductError}
      />
    </>
  );
};

export default ProductsToolbar;
