 
import * as React from 'react';
import Link from '@mui/material/Link';
import { SxProps, Theme } from '@mui/material/styles';
//import {Link as RouterLink} from 'react-router-dom'

interface DCButtonLinkProps {
  onClick: ()=>void
  label: string
  sx: SxProps<Theme>
}

const DCButtonLink = (props: DCButtonLinkProps) => {
  return (
    <Link
    underline="hover"
    sx={props.sx}
    //component="button"
    variant="body2"
    onClick={(e: any) => {
       e.preventDefault()
       props.onClick()
    }}
  >
   {props.label}
  </Link>
 
  )
}

export default DCButtonLink