import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Accounts } from "../../../Models/Accounts";
import { FormType } from "../../Providers/AccountsProvider";
import ReviewEditor from "./ReviewEditor";
import CloseIcon from "@mui/icons-material/Close";
import ReviewCard from "../../SupportingViews/ReviewCard";
import { AuthReview, ReviewFormData } from "../../../Models/Review";
import { Swiper, SwiperSlide } from "swiper/react";
import StarIcon from "@mui/icons-material/Star";
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";

// Import Swiper styles
import "swiper/css";
import { RequestStatus } from "../../../Models/Result";
import { SxProps, Theme } from "@mui/material/styles";
import { FormikHelpers } from "formik";
import { ProductDetail } from "../../../Models/ProductDetail";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ReviewsList from "./ReviewsList";
import Box from "@mui/material/Box";
 //import { ChevronLeftRounded } from "@mui/icons-material";

interface ReviewsSectionProps {
  sx?: SxProps<Theme>;
  isProductPurchased: boolean
  userAccountManager: Accounts;
  searchManager: ProductDetail;
  productId: string;
  variantId?: string;
  avgRating: string;
  totalReviews: number
  onUpdateReviews?: () => void;
}

const ReviewsSection = ({
  userAccountManager,
  searchManager,
  productId,
  variantId,
  avgRating,
  onUpdateReviews,
  totalReviews,
  isProductPurchased
}: ReviewsSectionProps) => {
  const [isEditorPresented, setIsEditorPresented] =
    React.useState<boolean>(false);
  const [isReviewsListPresented, setIsReviewsListPresented] =
    React.useState<boolean>(false);
  const user = userAccountManager.getSignedInUser();
  const [showReviewBtn, setShowReviewBtn] = useState(false);

  const review = (() =>
    userAccountManager.userReviews.find(
      (review) => review.productId === productId
    ))();

  const handleShowReviewEditor = () => {
    if (!user) {
      userAccountManager.updateCurrentform(FormType.Login);
    } else {
      setIsEditorPresented(true);
    }
  };

  const handleSubmitReview = async (
    reviewFormData: ReviewFormData,
    formikHelpers: FormikHelpers<ReviewFormData>
  ) => {
    if (!user) {
      return;
    }

    let authReview: AuthReview | null = null;
    if (!review) {
      authReview = await searchManager.postReview(
        user,
        productId,
        reviewFormData,
        variantId
      );
    } else {
      authReview = await searchManager.updateReview(
        user,
        review.reviewId,
        reviewFormData
      );
    }

    if (!authReview) {
      return;
    }

    if (authReview.authUser) {
      userAccountManager.saveCredentials(authReview.authUser);
    }

    onUpdateReviews?.();
    setIsEditorPresented(false);

    //await searchManager.reloadProductReviews(productId)
  };

  const handleDeleteReview = async () => {
    if (!user || !review?.reviewId) {
      return;
    }

    const authReview = await searchManager.deleteReview(user, review.reviewId);

    if (!authReview) {
      return;
    }

    if (authReview.authUser) {
      userAccountManager.saveCredentials(authReview.authUser);
    }

    setIsEditorPresented(false);
    onUpdateReviews?.();
  };

  const handleLoadMoreReviews = async () => {
    searchManager.setIsUpdatingReviews(true)
    await searchManager.loadReviewsForProduct(productId)
    searchManager.setIsUpdatingReviews(false)

  }

   

  useEffect(() => {
    setShowReviewBtn(isProductPurchased);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductPurchased]);

  const noReviewsView = (() => {
    return (
      <Stack width="100" padding={2} bgcolor="secondary.main">
        <Typography variant="headline" alignItems="center">
          This product has no reviews
        </Typography>
        <Typography variant="subhead">
          If you've purchased this product, you can post a review to share your
          experience with the community.
        </Typography>
      </Stack>
    );
  })();
  
  const reviewsView = (() => {
    return (
      <Stack>
        <Stack
          justifyContent={"space-between"}
          width="35%"
          sx={{
            height: "min-height",
            alignItems: "center",
          }}
          direction="row"
        >
          <Stack
            spacing={0.5}
            sx={{
              height: "min-height",
              alignItems: "center",
            }}
            direction="row"
          >
            <StarIcon fontSize="large" />
            <Typography
              variant="title3"
              fontWeight={"medium"}
              // color="text.secondary"
              textAlign={"left"}
            >
              {avgRating}
            </Typography>
          </Stack>
          <Box sx={{width: "0.25rem", height: "0.25rem", borderRadius: "50%", bgcolor: "primary.dark"}} /> 
          <Typography
           // textTransform="uppercase"
            sx={{ width: "33%", flexShrink: 0 }}
            fontWeight="medium"
            variant="title3"
            
          >
           {totalReviews}&nbsp;{totalReviews > 1 ? "reviews" : "review"} 
          </Typography>
        </Stack>

        {/* <Stack width="100%"> */}

        <Swiper
          style={{ width: "100%", paddingBlock: "1rem" }}
          spaceBetween={16}
          slidesPerView={1.2}
          loop
        >
          {searchManager.productReviews.map((review) => (
            <SwiperSlide key={review.reviewId}>
              <ReviewCard review={review} onShowMore={()=>setIsReviewsListPresented(true)} />
            </SwiperSlide>
          ))}
        </Swiper>

       
      </Stack>
    );
  })();

  return (
    <Stack spacing={2} py={2}>
      {searchManager.productReviews.length > 0 ? reviewsView : noReviewsView}

      <Stack
        spacing={1}
        justifyContent="space-between"
        direction="row"
        sx={{ height: "2.5rem" }}
      >
        <Button
          sx={{ display: showReviewBtn ? "block" : "none",  borderColor: "primary.dark" }}
          variant="outlined"
          fullWidth
          onClick={handleShowReviewEditor}
        >
          {review ? "Edit Review" : "Post Review"}
        </Button>
        {
          searchManager.productReviews.length > 0 && (
            <Button
          variant="outlined"
          fullWidth
          onClick={() => setIsReviewsListPresented(true)}
        >
          Show all reviews
        </Button>
          )
        }
        
      </Stack>
      <Dialog
        fullScreen={true}
        // onClose={() => setIsEditorPresented(false) }
        open={isEditorPresented}
        sx={{
          ".css-qs0bdh-MuiStack-root": {
            width: "100%",
          },
          ".css-1d9cypr-MuiStack-root": {
            width: "100%",
          },
          "& .MuiPaper-root": {
            margin: {
              md: "2rem",
            },
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                console.log("closing");
                setIsEditorPresented(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {review ? "Edit" : "Write"} Review
            </Typography>
            {/* <Button>Submit</Button> */}
          </Toolbar>
        </AppBar>

        <ReviewEditor
          onSubmit={handleSubmitReview}
          onDelete={() => handleDeleteReview()}
          review={review || null}
          variantId={variantId}
          productId={productId}
          // requestStatus={searchManager.deleteReviewStatus as RequestStatus}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={searchManager.editReviewStatus === RequestStatus.Loading}
          //onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <Dialog
        fullScreen={true}
        onClose={() => setIsReviewsListPresented(false)}
        open={isReviewsListPresented}
      >
        <ReviewsList
          reviews={searchManager.productReviews}
          onLoadMore={searchManager.canLoadMoreReviews ? handleLoadMoreReviews : undefined}
          header={() => (
            <AppBar elevation={0} sx={{ position: "sticky", pr: "unset" }}>
              <Toolbar sx={{ padding: "1rem", minHeight: "8rem", alignItems: "flex-start" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  size="small"
                  sx={{
                    border: "1px solid",
                    borderRadius: "20px"
                  }}
                  onClick={() => {
                    console.log("closing");
                    setIsReviewsListPresented(false)
                  }}
                  aria-label="close"
                >
                  <ChevronLeftRounded />
                </IconButton>

                <Stack
                  sx={{
                    height: "min-height",
                    alignItems: "center",
                    alignSelf: "end",
                    marginLeft: "-2rem"
                  }}
                  direction="row"
                >
                  <Stack
                    spacing={1}
                    sx={{
                      height: "min-height",
                      alignItems: "center",
                    }}
                    direction="row"
                  >
                    <StarIcon fontSize="medium" />
                    <Typography
                      variant="headline"
                      // color="text.secondary"
                      textAlign={"left"}
                    >
                      {avgRating}
                    </Typography>
                  </Stack>

                  <Typography
                    textTransform="capitalize"
                    sx={{ width: "33%", flexShrink: 0 }}
                  >
                    &nbsp;reviews
                  </Typography>
                </Stack>
              </Toolbar>
            </AppBar>
          )}
        />
        
      </Dialog>
    </Stack>
  );
};

export default ReviewsSection;
