import React, { ReactNode, useReducer, useRef, useState } from "react";
import {
  UserAccountContext,
  userAccountReducer,
} from "../../contexts/UserAccountContext";
import { Accounts } from "../../Models/Accounts";
import { RequestStatus, ResultType } from "../../Models/Result";
import { AuthUser } from "../../Models/User";
import supabaseAPIManager, {
  APIError,
} from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import Cookies from "js-cookie";
import { AuthUserProfile, UserProfile } from "../../Models/UserProfile";
import { deepCopy } from "../../utils";
import { FavoriteItem } from "../../Models/FavoriteItem";
import { Product } from "../../Models/Product";
import { Review } from "../../Models/Review";
import {
  FulfillmentData,
  FulfillmentStatus,
} from "../../Models/InventoryOrder";

interface AccountsProviderProps {
  children: ReactNode;
}

export enum FormType {
  Signup = "signup",
  Recovery = "recovery",
  ForgotPassword = "password",
  Login = "login",
}

const AccountsProvider = ({ children }: AccountsProviderProps) => {
  const [accountState, accountDispatch] = useReducer(userAccountReducer, null);
  const apiKey = process.env.REACT_APP_SUPABASE_API_KEY;
  const [credentials, setCredentials] = useState<AuthUser | null>(null);
  const [userprofile, setUserprofile] = useState<UserProfile | null>(null);
  const [currentFormTypeDisplayed, setCurrentFormTypeDisplayed] =
    useState<FormType | null>(null);
  const [noTimesUpdated, setNoTimesUpdated] = useState(0);
  const [accountModalIsPresented, setAccountModalIsPresented] = useState(false);
  const [accountRequestStatus, setAccountRequestStatus] =
    useState<"idle" | "loading" | APIError>("idle");
  const [favoritesRequestStatus, setFavoritesRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);
  const [reviewsRequestStatus, setReviewsRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);
  const [userReviews, setUserReviews] = useState<Review[]>([]);
  const [favorites, setFavorites] = useState<FavoriteItem[]>([]);
  const [favoritedProducts, setFavoritedProducts] = useState<Product[]>([]);
  const [removedFavorites, setRemovedFavorites] = useState<string[]>([]);
  const [fulfillmentData, setFulfillmentData] = useState<FulfillmentData>({
    fulfillmentStatus: FulfillmentStatus.unfulfilled,
    count: 0,
  });
  const [favoritedRequestStatus, setFavoritedRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);

  const sessionId = window.name;
  //console.log(window.Cookies.attributes.)
  let canLoadMoreFavoritesData = useRef<boolean>(true);
  let inventoryIdRef = useRef<string | null>(null);

  const addToFavorites = (newItem: FavoriteItem) => {
    setFavorites([...favorites, newItem]);
  };

  const clearFavorites = () => {
    canLoadMoreFavoritesData.current = true;

    setFavorites([]);
  };

  const clearUserReviews = () => {
    setUserReviews([]);
  };

  const recoverPassword = async (
    email: string
  ): Promise<boolean | APIError> => {
    //debugger
    if (!apiKey) {
      return APIError.default;
    }

    const result = await supabaseAPIManager.sendForgotPasswordLink(
      email,
      apiKey
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    return true;
  };

  const resetPassword = async (
    email: string,
    newPassword: string,
    accessToken: string
  ): Promise<boolean | APIError> => {
    if (!apiKey) {
      return APIError.default;
    }

    const result = await supabaseAPIManager.updateUser(
      email,
      newPassword,
      apiKey,
      accessToken
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    return true;
  };

  const saveCredentials = (authUser: AuthUser): void => {
    //Cookies.set(sessionId, JSON.stringify(authUser));
    try {
      
      localStorage.setItem(sessionId, JSON.stringify(authUser))
      setCredentials((prev) => {
        //console.dir(authUser)
        if (!prev) {
          return authUser;
        } else {
          return {
            ...prev,
            access_token: authUser.access_token,
            refresh_token: authUser.refresh_token,
            expires_in: authUser.expires_in,
            user: {
              ...prev.user,
              email: authUser.user.email,
              id: authUser.user.id,
              /*
              user_metadata: {
                ...authUser.user.user_metadata,
              },*/
            },
          };
        }
      });
      setNoTimesUpdated(noTimesUpdated + 1);
    } catch(error) {
        console.error(error)
    }

    
  };

  const favoriteProduct = async (
    productId: string,
    variantId: string,
    userId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | null> => {
    if (!apiKey) {
      return APIError.default;
    }

    if (favoritedRequestStatus === RequestStatus.Loading) {return APIError.default}

    setFavoritedRequestStatus(RequestStatus.Loading)
    const favoritedResult = await supabaseAPIManager.addProductToFavorites(
      productId,
      variantId,
      apiKey,
      userId,
      accessToken,
      refreshToken
    );
    if (favoritedResult.type === ResultType.Failure) {
      console.dir(favoritedResult.error);
      setFavoritedRequestStatus(favoritedResult.error)
      return favoritedResult.error;
    }

    const { authUser, favoriteItem } = favoritedResult.value;
    if (authUser) {
      saveCredentials(authUser);
    }

    setFavorites([...favorites, favoriteItem]);
    setFavoritedRequestStatus(RequestStatus.Idle)
    return null;
  };

  // const getCredentials = (): Map<string, string | undefined> => {
  //   let cred = new Map<string, string | undefined>();
  //   cred.set("accessToken", Cookies.get("accessToken"));
  //   cred.set("refreshToken", Cookies.get("refreshToken"));
  //   cred.set("email", Cookies.get("email"));
  //   return cred;
  // };

  const loadCredentials = async (): Promise<boolean> => {
    let result = false;
     
   
    const value = localStorage.getItem(sessionId);
    if (!value) {
      return false;
    }
     
    const session = JSON.parse(value) as AuthUser;
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
      user: { id, email },
    } = session;

    //const queryItems = new Map<string, string>([["id", id]]);

    const profileResult = await loadUserprofile(session);
    const error = profileResult as APIError;
    if (error.errorDescription) {
      return false;
    }

    const { authUser: auth, profile } = profileResult as AuthUserProfile;
    setUserprofile(profile);

    if (auth) {
      saveCredentials(auth);
    } else {
      setCredentials((prev) => ({
        ...prev,
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
        user: {
          ...prev?.user,
          email: email,
          id: id,
        },
      }));
    }

    //setUserprofile(profile);

    // await loadFavoritedData(id, accessToken, refreshToken, undefined, undefined, 0)

    result = true;
    return result;
  };

  const getSignedInUser = (): AuthUser | null => {
    //return Object.assign({}, credentials)
    return credentials;
  };

  const signoutUser = async (): Promise<APIError | AuthUser | boolean> => {
    if (!apiKey || !credentials) {
      return APIError.default;
    }
    const result = await supabaseAPIManager.logoutUser(
      credentials.access_token,
      credentials.refresh_token,
      apiKey
    );
    if (result.type === ResultType.Failure) {
      return result.error;
    }

    clearCredentials();

    return true;
  };

  // rename to clear account
  const clearCredentials = () => {
    
    
    localStorage.removeItem(sessionId)
    setCredentials(null);
  };

  /**
   * Sets up user credentials given auth data from an auth provider such as google
   * @param authData
   * @returns A promise that resolves to an authenticated user credentials object or an api error
   */
  const setupUserCredentials = async (
    authData: Map<string, string>
  ): Promise<AuthUser | APIError> => {
    const accessToken = authData.get("access_token");
    const refreshToken = authData.get("refresh_token");
    const expiresIn = authData.get("expires_in");
    //const providerToken = authData.get("provider_token")
    //const tokenType = authData.get("token_type")

    if (!apiKey || !accessToken || !refreshToken || !expiresIn) {
      return APIError.default;
    }
    // fetch user data
    const result = await supabaseAPIManager.getUser(accessToken, apiKey);
    if (result.type === ResultType.Failure) {
      return result.error;
    }

    const { value: user } = result;
    // merge auth and user data
    const authUser: AuthUser = {
      access_token: accessToken,
      expires_in: parseInt(expiresIn),
      refresh_token: refreshToken,
      user: {
        email: user.email,
        id: user.id,
        /*
        user_metadata: {
          avatar_url: user.user_metadata.avatar_url,
          picture: user.user_metadata.picture,
          name: user.user_metadata.name,
          full_name: user.user_metadata.full_name,
        },*/
      },
    };
    // save credentials to storage
    //saveCredentials(authUser)
    return authUser;
  };

  const loadFavoritedData = async (
    userId: string,
    accessToken: string,
    refreshToken: string,
    productId?: string,
    variantId?: string,
    startIdx?: number
  ): Promise<AuthUser | null> => {
    // if (favoritesRequestStatus === RequestStatus.Loading) {
    //   return null
    // }

    if (!apiKey) {
      setFavoritesRequestStatus(APIError.default);
      return null;
    }

    setFavoritesRequestStatus(RequestStatus.Loading);

    //let queryItems = new Map<string, string>([["user_id", credentials.user.id]])
    let queryItems = new Map<string, string>([["user_id", userId]]);
    let rangeStart: number | undefined = favorites.length;
    if (productId) {
      queryItems.set("product_id", productId);
      rangeStart = undefined;
    }

    if (variantId) {
      queryItems.set("product_detail_id", variantId);
    }

    const favoritesResult = await supabaseAPIManager.getFavorites(
      apiKey,
      accessToken,
      refreshToken,
      queryItems,
      rangeStart
    );

    if (favoritesResult.type === ResultType.Failure) {
      setFavoritesRequestStatus(favoritesResult.error);
      return null;
    }

    const { authUser: refreshedAuthuser, favoriteItems } =
      favoritesResult.value;

     
    for (let item of favoriteItems) {
      let product =  await getProduct(item.productId)
      const idx = favoriteItems.findIndex((item) => item.productId === product?.productId)
      if (idx > -1) {
        
        favoriteItems[idx].product = product
      }
    }
    setFavorites([...favorites, ...favoriteItems]);

    if (refreshedAuthuser) {
      saveCredentials(refreshedAuthuser);
    }

    //console.log(favoriteItems.length === supabaseAPIManager.favoritesPerPage)
    canLoadMoreFavoritesData.current =
      favoriteItems.length === supabaseAPIManager.favoritesPerPage;
    setFavoritesRequestStatus(RequestStatus.Idle);

    
    // console.dir(favoriteItems)

    return null;
  };

  const loadFulfillmentStatus = async (
    status: FulfillmentStatus
  ): Promise<APIError | null> => {
    if (credentials === null || apiKey === undefined) {
      return APIError.default;
    }
    const queryItems = new Map([["user_id", credentials.user.id]]);
    const inventoryResult = await supabaseAPIManager.getInventoryRow(
      queryItems,
      credentials.access_token,
      credentials.refresh_token,
      apiKey
    );

    if (inventoryResult.type === ResultType.Failure) {
      return inventoryResult.error;
    }

    const { inventoryList, authUser: updatedAuthUser } = inventoryResult.value;
    if (inventoryList.length === 0) {
      return APIError.default;
    }

    let auth = updatedAuthUser || credentials;
    const id = inventoryList[0].inventoryid;
    const result = await supabaseAPIManager.getFulfillmentStatus(
      status,
      id,
      apiKey,
      auth.access_token,
      auth.refresh_token
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    setFulfillmentData({
      fulfillmentStatus: result.value.data.fulfillmentStatus,
      count: result.value.data.count,
    });

    if (updatedAuthUser) {
      setCredentials(updatedAuthUser);
    } else if (result.value.authUser) {
      setCredentials(result.value.authUser);
    }

    return null;
  };

  const loadProduct = async (productId: string): Promise<void> => {
    if (!apiKey) {
      // setFavoritesRequestStatus(APIError.default);
      return;
    }

    // setFavoritesRequestStatus(RequestStatus.Loading);

    const filters = new Map<string, string>([
      ["select", "*,product_details(*)"],
      ["product_details.is_available", "eq.true"],
      ["is_published", `eq.true`],
    ]);
    if (productId) {
      filters.set("product_id", `eq.${productId}`);
    }

    const result = await supabaseAPIManager.getProductDetailForId(
      filters,
      apiKey
    );

    if (result.type === ResultType.Failure) {
      //setFavoritesRequestStatus(result.error);
      // setProductsRequestStatus(result.error);
      return;
    }

    setFavoritedProducts((prevFavoritedProducts) => [
      ...prevFavoritedProducts,
      ...result.value.productsList,
    ]);
    // setProducts([...products, ...result.value.productsList]);
  };

  const getProduct = async (productId: string): Promise<Product | undefined> => {
    if (!apiKey) {
      // setFavoritesRequestStatus(APIError.default);
      return undefined;
    }

    // setFavoritesRequestStatus(RequestStatus.Loading);

    const filters = new Map<string, string>([
      ["select", "*,product_details(*)"],
      ["product_details.is_available", "eq.true"],
      ["is_published", `eq.true`],
    ]);
    if (productId) {
      filters.set("product_id", `eq.${productId}`);
    }

    const result = await supabaseAPIManager.getProductDetailForId(
      filters,
      apiKey
    );

    if (result.type === ResultType.Failure) {
      //setFavoritesRequestStatus(result.error);
      // setProductsRequestStatus(result.error);
      return undefined;
    }
     
    const [product] = result.value.productsList
    return product
    // if (result.value.productsList.length > 0) {
    //   return 
    // }
    // setProducts([...products, ...result.value.productsList]);
  };

  const loadPendingOrders = async ():Promise<void> => {

   // const result = await supabaseAPIManager.getFulfillmentStatus
  }

  const loadUserprofile = async (
    auth: AuthUser
  ): Promise<AuthUserProfile | APIError> => {
    if (!apiKey) {
      return APIError.default;
    }

    const { access_token, refresh_token, user } = auth;
    const queryItems = new Map<string, string>([["id", user.id]]);

    const result = await supabaseAPIManager.getUserProfile(
      queryItems,
      access_token,
      refresh_token,
      apiKey
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    // Cookies.set("profile", JSON.stringify(result.value.profile));
    setUserprofile(result.value.profile);

    return result.value;
  };

  const getUserprofile = (): UserProfile | null => {
    return credentials ? deepCopy(userprofile) : null;
  };

  const updateCurrentform = (formType: FormType | null) => {
    setCurrentFormTypeDisplayed(formType);
  };

  const hasSelectedFormWithType = (formType: FormType): boolean => {
    return currentFormTypeDisplayed === formType;
  };

  const hasPurchasedProduct = async (
    productId: string,
    variantId?: string
  ): Promise<boolean | APIError> => {
    if (!apiKey || !userprofile || !credentials) {
      return false;
    }
    let queryItems = new Map<string, string>([
      ["product_id", `eq.${productId}`],
      ["stripe_customer_id", `eq.${userprofile.stripeCustomerId}`],
      ["select", "*"],
    ]);

    const result = await supabaseAPIManager.getOrderDetailsForProduct(
      apiKey,
      credentials.access_token,
      queryItems
    );
    if (result.type === ResultType.Failure) {
      return result.error;
    }

    return result.value.length > 0;
  };

  const loadUserReviews = async (
    productId: string,
    variantId?: string
  ): Promise<void> => {
    if (
      !apiKey ||
      !credentials ||
      reviewsRequestStatus === RequestStatus.Loading
    ) {
      return;
    }

    

    setReviewsRequestStatus(RequestStatus.Loading);
    let queryItems = new Map<string, string>([
      ["product_id", `eq.${productId}`],
      ["user_id", `eq.${credentials.user.id}`],
    ]);

    const result = await supabaseAPIManager.getProductReviews(
      apiKey,
      queryItems
    );
    if (result.type === ResultType.Failure) {
      setReviewsRequestStatus(result.error);
      return;
    }

    setUserReviews(result.value);
    setReviewsRequestStatus(RequestStatus.Idle);
  };

  const removeFavoritedProduct = (favoriteId: string) => {
    setFavorites((prev)=> [...prev.filter(favorite => favorite.favoriteId !== favoriteId)])
  }

  const toggleAccountModal = () => {
    return setAccountModalIsPresented(!accountModalIsPresented);
  };

  const setInventoryId = (newId: string) => {
    inventoryIdRef.current = newId;
  };

  const setFulfillmentCount = (
    fulfillmentStatus: FulfillmentStatus,
    newCount: number
  ) => {
    setFulfillmentData({
      fulfillmentStatus,
      count: newCount,
    });
  };

  const shouldLoadMoreFavorites = (shouldLoad: boolean) => {
    canLoadMoreFavoritesData.current = shouldLoad
    console.log(canLoadMoreFavoritesData.current)
  }

  const unFavoriteItem = async (
    favoriteId: string,
    user: AuthUser
  ): Promise<boolean> => {
    if (!apiKey) {
      return false;
    }

    if (favoritedRequestStatus === RequestStatus.Loading) {return false}

    setFavoritedRequestStatus(RequestStatus.Loading)

    const favoritedItem = favorites.find(
      (item) => item.favoriteId === favoriteId
    );

    if (!favoritedItem) {
      return false;
    }

    let queryItems = new Map<string, string>([
      ["favorite_id", `eq.${favoritedItem.favoriteId}`],
    ]);
    const favoritedResult = await supabaseAPIManager.removeProductFromFavorites(
      apiKey,
      user.access_token,
      user.refresh_token,
      queryItems
    );
    if (favoritedResult.type === ResultType.Failure) {
      //console.dir(favoritedResult.error);
      setFavoritedRequestStatus(favoritedResult.error)
      return false;
      //return favoritedResult.error
    }

    console.dir(favoritedResult.value)

    if (favoritedResult.value.authUser) {
      saveCredentials(favoritedResult.value.authUser);
    }

    setRemovedFavorites([
      ...removedFavorites,
      favoritedResult.value.favoriteItem.favoriteId,
    ]);

    setFavorites((prev)=> [...prev.filter(favorite => favorite.favoriteId !== favoritedResult.value.favoriteItem.favoriteId)])
    /*
    setFavorites([
      ...favorites.filter(
        (favorite) =>
          favorite.favoriteId !== favoritedResult.value.favoriteItem.favoriteId
      ),
    ]);*/

    setFavoritedRequestStatus(RequestStatus.Idle)

    return true;
  };

  const updateSellerStatusForUserId = async (newStatus: boolean) => {
    if (!apiKey || !userprofile || !credentials?.user.id) {
      setAccountRequestStatus(APIError.default);
      return;
    }

    setAccountRequestStatus("loading");
    const newData = new Map<string, any>([["is_partner", newStatus]]);
    const result = await supabaseAPIManager.updateUserProfileData(
      credentials?.user.id,
      apiKey,
      newData
    );

    if (result.type === ResultType.Success) {
      const temp: UserProfile = {
        ...userprofile,
        isPartner: newStatus,
      };

      Cookies.set("profile", JSON.stringify(temp));
      setUserprofile(temp);
      setAccountRequestStatus("idle");
    } else {
      setAccountRequestStatus(result.error);
    }
  };

  const accounts: Accounts = {
    favorites,
    favoritedProducts,
    favoritesRequestStatus,
    favoritedRequestStatus,
    reviewsRequestStatus: reviewsRequestStatus,
    canLoadMoreFavoritesData: canLoadMoreFavoritesData.current || false,
    fulfillmentData,
    addToFavorites,
    hasPurchasedProduct,
    inventoryIdRef,
    // inventoryId: inventoryIdRef.current,
    loadFulfillmentStatus,
    loadUserReviews,
    loadFavoritedData,
    loadUserprofile,
    recoverPassword,
    credentials,
    clearFavorites,
    clearUserReviews,
    accountModalIsPresented,
    userReviews,
    state: accountState,
    dispatch: accountDispatch,
    resetPassword,
    setInventoryId,
    saveCredentials,
    favoriteProduct,
    // getCredentials,
    loadCredentials,
    loadPendingOrders,
    loadProduct,
    getSignedInUser,
    signoutUser,
    shouldLoadMoreFavorites,
    clearCredentials,
    setupUserCredentials,
    getUserprofile,
    updateCurrentform,
    hasSelectedFormWithType,
    removeFavoritedProduct,
    setFulfillmentCount,
    toggleAccountModal,
    updateSellerStatusForUserId,
    unFavoriteItem,
    accountRequestStatus,
    removedFavorites,
  };

  return (
    <UserAccountContext.Provider value={accounts}>
      {children}
    </UserAccountContext.Provider>
  );
};

export default AccountsProvider;
