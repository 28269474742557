import LoadingButton from '@mui/lab/LoadingButton'
import { SxProps, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'

interface LoadMoreButtonProps {
    sx?: SxProps<Theme> | undefined
    loading: boolean
    onClick: ()=>void
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {

  const matches = useMediaQuery((theme: any)=>theme.breakpoints.up('xl'));
  const defaultStyles: SxProps<Theme> = {
    width: {xs: "80%", md: "25%", xl: "30%"},
    marginX: {xs: "auto"},
    marginY: {xs: "1rem"}
   // marginBlock: {xs: "16px"},
   // marginInline: {xs: "auto"},
   // display: inventory.hasMoreProducts() ? "initial" : "none",
  }

 
  return (
    <LoadingButton
    variant="contained"
    size={matches ? "large" : "medium"}
    sx={props.sx ? Object.assign({}, defaultStyles, props.sx) : defaultStyles }
    loading={props.loading}
    onClick={props.onClick}
  >
    See More
  </LoadingButton>
  )
}

export default LoadMoreButton