import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import {
  Link as RouterLink,
  // LinkProps as RouterLinkProps,
  //  MemoryRouter,
} from "react-router-dom";
import { OrderDetail } from "../../../Models/Order";
import { ColorDetails, SizeDetails } from "../../../Models/ProductOptions";
import { Inventory, SellerApp } from "../../../routes";
import { formatPrice } from "../../../utils";

interface InventoryOrderDetailProps {
  orderDetail: OrderDetail;
  productTitle: string;
  colorTable: ColorDetails[];
  sizeTable: SizeDetails[];
}

const InventoryOrderDetailItem = ({
  orderDetail,
  productTitle,
  colorTable,
  sizeTable,
}: InventoryOrderDetailProps) => {
  const colorTitle =
    colorTable.find((plating) => plating.color_id === orderDetail.colorId)
      ?.color_title ?? "N/A";
  const sizeTitle =
    sizeTable.find((size) => size.size_id === orderDetail.sizeId)?.size_title ??
    "N/A";

  const thumbnailView = (() => {
    return (
      <Stack spacing={2} direction="row">
        <Box
          component="img"
          src={orderDetail.photoUrl}
          sx={{ objectFit: "cover" }}
          width="6rem"
          height="6rem"
        />
        <Stack alignItems={"flex-start"} minWidth="200px">
          <Typography
            variant="subtitle2"
            color="primary.dark"
            component={RouterLink}
            to={`/${SellerApp.pathname}/${Inventory.products}/${orderDetail.productId}`}
          >
            {productTitle}
          </Typography>
          <Typography
            textTransform={"uppercase"}
            variant="subtitle1"
          >{`${sizeTitle}/${colorTitle}`}</Typography>
        </Stack>
      </Stack>
    );
  })();

  const amountDetailsView = (() => {
    return (
      <Stack width="25%" spacing={1}>
        {/* <Stack justifyContent="space-between" direction="row">
          <Typography variant="subhead">
            {formatPrice(orderDetail.price)}
          </Typography>
          <Typography variant="subhead">X</Typography>
          <Typography variant="subhead">{orderDetail.quantity}</Typography>
          <Typography variant="subhead">
            {formatPrice(orderDetail.total)}
          </Typography>
        </Stack> */}
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="subhead">Unit Price</Typography>
          <Typography variant="subhead">
            {formatPrice(orderDetail.price)}
          </Typography>
        </Stack>
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="subhead">Quantity Ordered</Typography>
          <Typography variant="subhead">
            {orderDetail.quantity}
          </Typography>
        </Stack>
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="subhead">Subtotal</Typography>
          <Typography variant="subhead">{formatPrice(orderDetail.total)}</Typography>
        </Stack>
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="subhead">Discount</Typography>
          <Typography variant="subhead">
            {formatPrice(orderDetail.discount)}
          </Typography>
        </Stack>
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="subhead">Total</Typography>
          <Typography variant="subhead">{formatPrice(orderDetail.total - orderDetail.discount)}</Typography>
        </Stack>
      </Stack>
      
    );
  })();

  return (
    <Stack direction="row" width="100%" justifyContent="space-between">
      {thumbnailView}
      {amountDetailsView}
    </Stack>
  );
};

export default InventoryOrderDetailItem;
