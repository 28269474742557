import AccountCircle from "@mui/icons-material/AccountCircle";
//import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext } from "react";
import { UserAccountContext } from "../../contexts/UserAccountContext";
import { SxProps, Theme } from "@mui/material/styles";

interface ProfilePhotoProps {
  sx?: SxProps<Theme>
}

const ProfilePhoto = ({sx}: ProfilePhotoProps) => {
  const isMediumAndAbove = useMediaQuery((theme: any) =>
    theme.breakpoints.up("md")
  );
  const accountManager = useContext(UserAccountContext);
  const userprofile = accountManager.getUserprofile();

  
  const avatarURL = userprofile?.avatarUrl;
  const firstname = userprofile?.firstname?.toUpperCase();
  const buttonLabel = (() => {
    return firstname ? (
      <Avatar
        sizes={isMediumAndAbove ? "medium" : "small"}
        sx={sx || {
          ml: "auto",
          bgcolor: "primary.dark",
        }}
        alt={firstname}
        src={avatarURL}
      >{firstname[0].toUpperCase()}</Avatar>
    ) : (
      <AccountCircle
      sx={{
       
        color: "primary.dark",
      }}
        
        fontSize={isMediumAndAbove ? "large" : "large"}
      />
    );
  })();

  return (
    <>
      {buttonLabel}
    </>
  );
};

export default ProfilePhoto;
