import MenuItem from '@mui/material/MenuItem'
import React, { ReactNode } from 'react'
import PCMenuButton from './PCMenuButton'
import Menu, { MenuProps } from "@mui/material/Menu";
import { UserProfile } from '../../Models/UserProfile';
import { AuthUser } from '../../Models/User';

type PCAppBarMenuButtonProps = MenuProps & {
    userProfile: UserProfile | null
    user: AuthUser | null
    loginAction: ()=>void
    logoutAction: ()=>void
    signupAction: ()=>void
    successAction: ()=>void
    handleClose: ()=>void
    handleClick: (event: React.MouseEvent<HTMLButtonElement>)=>void
    id?: string
    shouldAllowSignup: boolean
}

const PCAppBarMenuButton = (props: PCAppBarMenuButtonProps) => {

    const menuItems = (() => {
        const userProfile = props.userProfile //getUserprofile();
        let temp: ReactNode[] = [];
    
        if (props.user) {
          temp.push(
            <MenuItem key={"logout"} onClick={props.logoutAction}>
              Log out
            </MenuItem>
          );
          if (userProfile?.isPartner) {
            temp.push(
              <MenuItem
                key={"sell"}
                onClick={props.successAction}
               // onClick={() => appData.gotoSellerApp(Inventory.products)}
              >
                Switch to selling
              </MenuItem>
            );
          }
        } else {
          temp.push(
            <MenuItem
              key={"log in"}
              onClick={props.loginAction}
              /*
              onClick={() => {
                updateCurrentform(FormType.Login);
                appData.setPreAuthPathname(location.pathname);
                setIsDialogPresented(!isDialogPresented);
              }}*/
            >
              Log in
            </MenuItem>,
            <MenuItem
              key={"sign up"}
              onClick={props.signupAction}
              /*
              onClick={() => {
                updateCurrentform(FormType.Login);
                appData.setPreAuthPathname(location.pathname);
                setIsDialogPresented(!isDialogPresented);
              }}*/
            >
              Sign up
            </MenuItem>
          );
        }
    
        return temp;
      })();
    
  return (
    <div>
        <PCMenuButton
                    onClick={props.handleClick}
                    id={props.id}
                    userprofile={props.userProfile}
                  />
                  <Menu
                   sx={props.sx}
                   id="account-menu"
                   anchorEl={props.anchorEl}
                   open={props.open}
                   onClose={props.handleClose}
                   MenuListProps={{
                     "aria-labelledby": "basic-button",
                   }}
                 
                  >
                    {menuItems}
                  </Menu>
    </div>
  )
}

export default PCAppBarMenuButton