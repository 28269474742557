 import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserAccountContext } from "../../contexts/UserAccountContext";
import { APIError } from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { ShoppingApp } from "../../routes";
import { FormType } from "../Providers/AccountsProvider";
import DCError from "./DCError";
import LoggedOutView from "./LoggedOutView";

interface RequireAuthViewProps {
  children: JSX.Element;
}

const RequireAuthView = ({ children }: RequireAuthViewProps): JSX.Element => {
  const userAccount = useContext(UserAccountContext);
  const user = userAccount.getSignedInUser();
  const { pathname } = useLocation();
  const [error, setError] = useState<APIError | null>(null);

  const navigationBarDetails = (() => {
    switch (true) {
      case pathname.includes(ShoppingApp.bag):
        return {
          title: "Bag",
          subtitle: "Log in to manage your bag",
        };

      case pathname.includes(ShoppingApp.favorites):
        return {
          title: "Wishlist",
          subtitle: "Log in to manage your wishlist",
        };

        case pathname.includes(ShoppingApp.account):
          return {
            title: "Account",
            subtitle: "Log in to manage your account",
          };

      default:
    }
  })();

  const appBar = (() => (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "white",
      }}
      position="static"
    >
      <Toolbar
        sx={{
          alignItems: "flex-start",
          minHeight: "96px",
          pt: "1rem",
        }}
      >
        <Typography
          variant="largeTitle"
          noWrap
          component="div"
          sx={{
            color: "primary.dark",
            //flexGrow: 1,
            alignSelf: "flex-end",
          }}
        >
          {navigationBarDetails?.title}
        </Typography>
      </Toolbar>
      <Divider />
    </AppBar>
  ))();

  const handleLogout = async () => {
    userAccount.updateCurrentform(FormType.Login);
  };

  if (user) {
    return children;
  }

  return (
    <Stack
      //spacing={2}
      sx={{
        width: "100%",
        mb: "4rem",
        // height: "calc(100vh + 200px)"
      }}
    >
      {appBar}

      <LoggedOutView
        text={navigationBarDetails?.subtitle ?? ""}
        onLogin={() => handleLogout()}
        onSignup={()=>userAccount.updateCurrentform(FormType.Signup) }
      />

      <DCError error={error} onErrorDisplayed={() => setError(null)} />
    </Stack>
  );
};

export default RequireAuthView;
