/**
 * Abstract: A view that shows offline network status
 * whenever online data needed by a view is not available
 */
 import WifiOffIcon from '@mui/icons-material/WifiOff';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
 
/**
 * @param msg message displaed to the user
 * @param onRetry calback excecuted when user clicks on button to fetch data
 * @returns
 */

interface OfflineViewProps {
  msg: string;
  onRetry?: () => void;
  sx: SxProps<Theme> | undefined
}
const OfflineView = ({ msg, onRetry, sx }: OfflineViewProps) => {
  //  const msgSize = useBreakpointValue({base: "h6", sm: "h6",
  //     md: "h6", lg: "h5", xl: "h5"})

  return (
    <Stack alignItems="center" spacing={2} mb={4} sx={sx}>
      <WifiOffIcon fontSize="large" />
      <Typography width="87.5%" textAlign="center" variant="title2">
        {msg}
      </Typography>
      {onRetry ? (
        <Button
          variant="contained"
          sx={{
            aspectRatio: "6 / 1",
          }}
          onClick={onRetry}
        >
          Try Again
        </Button>
      ) : null}
    </Stack>
  );
};

export default OfflineView;
