/**
 * Abstract: Shows child views if user is not authenticated
 */

import React, { useContext } from 'react'
import { Navigate  } from 'react-router-dom'
import { UserAccountContext } from '../../contexts/UserAccountContext'
import { Accounts } from '../../routes'
//import { Inventory, SellerApp } from '../../routes'


interface RequireAuthProps {
    children: JSX.Element
}

const RequireNoAuth = ({children}:  RequireAuthProps): JSX.Element => {

    const {getSignedInUser}  = useContext(UserAccountContext)
    //const location = useLocation()
    //const from = (location.state as any)?.from?.pathname || `/${SellerApp.pathname}/${Inventory.products}` //Home.index
    const to = `/${Accounts.user}`
     if (getSignedInUser()) {
        //return (<Navigate to={from} replace />)
        return (<Navigate to={to} />)
    }
    
  return children
}

export default RequireNoAuth