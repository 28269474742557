import LoadingButton from "@mui/lab/LoadingButton";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import List from "@mui/material/List";

import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
//import useScrollTrigger from "@mui/material/useScrollTrigger";
import React, {  useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
 
 import { UserAccountContext } from "../../../../contexts/UserAccountContext";
 import { PCAPIError  } from "../../../../Models/PCAPIError";
import { RequestStatus } from "../../../../Models/Result";
import { Home } from "../../../../routes";
import { FormType } from "../../../Providers/AccountsProvider";
import BagItemView from "../../../SupportingViews/BagItemView";
import PCErrorView from "../../../SupportingViews/PCErrorView";
import ProfilePhoto from "../../../SupportingViews/ProfilePhoto";
import { AppDataContext } from "../../../../contexts/AppDataContext";
import { LookupTable } from "../../../../Models/LookupTables";
import { BagManagerContext } from "../../../../contexts/BagManagerContext";
import Divider from "@mui/material/Divider";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import OfflineView from "../../../SupportingViews/OfflineView";
import CircularProgress from "@mui/material/CircularProgress";
import { APIError } from "../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import Dialog from "@mui/material/Dialog";
import CheckoutSessionView from "./CheckoutSessionView";
import { PresentationContext } from "../../../../contexts/PresentationContext";

interface BagViewProps {
  // manager: BagManager;
}

const BagView = (props: BagViewProps) => {

  const [counter, setCounter] = useState(0)
  const [isCheckoutModalOpened, setIsCheckoutModalOpened] = useState(false)
  const userAccountManager = useContext(UserAccountContext);
  const manager = useContext(BagManagerContext);
  const bagItems = manager?.getBagItems(); //[...manager.getBagItems(), ...manager.getBagItems()];
  const user = userAccountManager.getSignedInUser();
  const accessToken = user?.access_token;
  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const appData = useContext(AppDataContext);
  const colorTable = appData.getLookuptableForType(LookupTable.color)
  const sizeTable = appData.getLookuptableForType(LookupTable.size)

  const targetRef = useRef(null)
  const observerRef = useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollRef = useBottomScrollListener(()=>{
    console.log("At bottom")
   // debugger
   loadMoreProductIfNeeded()
   
  }, { triggerOnNoScroll: false });

  const location = useLocation();
  const from = (location.state as any)?.from?.pathname || Home.index;

  const loadMoreProductIfNeeded = ()=>{
     if (manager && bagItems && manager.moreItemsAvailable) {
      manager.loadBagItems("", manager.productsFetchedSoFar, appData.cart, colorTable, sizeTable)
   }
  }

  const handleCheckout = async () => {
    
    if (accessToken && stripePublicKey && manager) {
     // const stripe = await loadStripe(stripePublicKey);
      console.log(stripePublicKey)
      // open checkoutSessionView
      setIsCheckoutModalOpened(true)
      /*
      const checkoutResult = await manager.checkout(
        accessToken,
        user.refresh_token
      );
      if (!checkoutResult) {
        return;
      }

      const { sessionId, authUser: refreshedAuthuser } = checkoutResult;
      if (refreshedAuthuser) {
        userAccountManager.saveCredentials(refreshedAuthuser);
      }

      const result = await stripe?.redirectToCheckout({ sessionId: sessionId });
      if (result?.error.message) {
        error.update(RequestError.Unexpected, result.error.message);
        manager.updateCheckoutStatus(error);
      }*/
      
    } else {
      userAccountManager.updateCurrentform(FormType.Login);
    }
  };

  const checkoutStatusError = ((): PCAPIError | null => {
    const apiError = manager?.checkoutStatus as PCAPIError;
    return apiError.errorDescription ? apiError : null;
    //return apiError.errorDescription ? apiError : null;
  })();

  const navItems = (() => {
    
    if (bagItems && manager && bagItems.length > 0 ) {
      return (
        <Stack
        direction={"row"}
        width="inherit"
        paddingX={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        {/* <Typography variant={"headline"}>{bagItems.reduce((partialResult, item) => partialResult + item.selectedQuantity, 0)} Items</Typography> */}
 
        <Button
          onClick={() => {
            appData.clearCart();
            manager.clearBag();
          }}
        >
          Clear
        </Button>
      </Stack>
      )
    }
  })()




  
  const productRequestStatusView = (()=>{
    const error = manager?.productsRequestStatus as APIError
    if (error?.errorDescription) {
      return (
       <OfflineView
          onRetry={loadMoreProductIfNeeded}
          msg={error?.errorDescription}
          sx={{pt: "4rem"}}

       />
      ) 
    }
    if (manager?.productsRequestStatus === RequestStatus.Loading) {
      return (
        <CircularProgress />
      )
    }
  })()
  

 

  useEffect(() => {
    const newlyAddedItems = appData.cart.filter((item) =>
      bagItems?.every((bagItem) => bagItem.productDetailId !== item.variantId)
    );
   
    manager?.loadBagItems(
      "",
      counter,
      newlyAddedItems,
      colorTable,
      sizeTable
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);



  useEffect(() => {
    manager?.updateCheckoutStatus(RequestStatus.Idle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, location.state]);

 
  if (!manager || !bagItems) {
    return null;
  }

 

  return (
    <Stack
      alignItems={"center"}
      direction={"column"}
      sx={{
        width: "100%",
        mb: "4rem",
        // height: "calc(100vh + 200px)"
      }}
    >
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "white",
        }}
        position="static"
      >
        <Toolbar
          sx={{
            alignItems: "flex-start",
            minHeight: "96px",
            pt: "1rem",
            //pb: "1.75rem",
          }}
        >
          <Typography
            variant="largeTitle"
            noWrap
            component="div"
            sx={{
              color: "primary.dark",
              //flexGrow: 1,
              alignSelf: "flex-end",
            }}
          >
            Cart
          </Typography>
          <Box
            sx={{
              ml: "auto",
            }}
          >
            <ProfilePhoto />
          </Box>
        </Toolbar>
         {navItems}
       <Divider />
      </AppBar>
      {/* <ElevationScroll>
        <Toolbar
          sx={{
            width: "inherit",
            px: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"headline"}>Cart</Typography>
          <ProfilePhoto />
        </Toolbar>
      </ElevationScroll>
      <Stack direction={"row"} width="inherit" paddingX={2} justifyContent="space-between">
        <Typography variant={"headline"}>{bagItems.length} Items</Typography>
        <Button>Clear</Button>
      </Stack> */}
      {
        manager?.productsRequestStatus === RequestStatus.Idle && bagItems.length === 0 ? (
        <Stack sx={{
          width: "100%",
          height: "100vh",
          display: "grid",
          placeItems: "center"
        }}>
           <Typography sx={{mt: "-17rem"}} variant={"title2"}>Your Bag is empty</Typography>
        </Stack>
      ) : (
        <>
          <List dense 
           onClick={(e: any)=>{
            e?.stopPropagation()
           }}
          ref={observerRef} onScroll={() => setCounter(counter + 1)} sx={{ width: "100%", bgcolor: "background.paper" }}>
            {bagItems.map((bagItem) => (
              <div id={bagItem.productDetailId}  key={bagItem.productDetailId} ref={targetRef}>
              <BagItemView
                key={bagItem.productDetailId}
                bagItem={bagItem}
                manager={manager}
                appData={appData}
                onDelete={(variantId: string)=> {
                  const leftOver = appData.removeBagItem(bagItem.productDetailId)
                  if (leftOver) {
                    manager.updateBagItems(leftOver)
                  }
                }}
                onMount={ ()=>{
                  
                }}
              />
              </div>
            ))}
          </List>
          {
            productRequestStatusView
          }
          {
            manager?.productsRequestStatus === RequestStatus.Idle && (
              <LoadingButton
              onClick={handleCheckout}
              loading={manager.checkoutStatus === RequestStatus.Loading}
              sx={{
                mt: "2rem",
                height: "3.1rem",
                width: "90%",
                fontWeight: "500",
              }}
              variant="contained"
            >
              {accessToken ? "Proceed" : "Login"} To Checkout
            </LoadingButton>
            )
          }
         
          <PCErrorView error={checkoutStatusError} />
          {   
             <Dialog
             fullScreen={true}
             //onClose={closeDialog}
             open={isCheckoutModalOpened}
             sx={{
               ".css-qs0bdh-MuiStack-root": {
                 width: "100%",
               },
               ".css-1d9cypr-MuiStack-root": {
                 width: "100%",
               },
               "& .MuiPaper-root": {
                 margin: {
                   md: "2rem",
                 },
               },
             }}
           >
             <PresentationContext.Provider
               value={{
                 dismiss: () => {
                  manager.updateCheckoutStatus(RequestStatus.Idle)
                  setIsCheckoutModalOpened(false)
                 },
                 isPresented: isCheckoutModalOpened,
               }}
             >
               <CheckoutSessionView manager={manager} />
             </PresentationContext.Provider>
           </Dialog>
          }
        </>
      )}
    </Stack>
  );
};

export default BagView;
