import React, { ReactNode, useCallback, useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
//import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useNavigate } from "react-router-dom";
import { DiscountsRoute, Inventory, Orders, SellerApp } from "../../routes";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UserAccountContext } from "../../contexts/UserAccountContext";
import { menuStyle } from "../../utils/styles";
import { APIError } from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { AppDataContext } from "../../contexts/AppDataContext";
//import DCError from "../SupportingViews/DCError";
import PCMenuButton from "../SupportingViews/PCMenuButton";
import { FulfillmentStatus } from "../../Models/InventoryOrder";
import Chip from "@mui/material/Chip";
import { AuthInventoryList } from "./Models/Inventory";
import { InventoryContext } from "./contexts/InventoryContext";
import OfflineView from "../SupportingViews/OfflineView";
import CircularProgress from "@mui/material/CircularProgress";
import { isProdEnv } from "../../utils";
import { Sell } from "@mui/icons-material";

const PCSellerApp = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const userAccount = useContext(UserAccountContext);

  const user = userAccount.getSignedInUser();
  const userprofile = userAccount.getUserprofile();

  const [error, setError] = useState<APIError | null>(null);
  const [, setLoading] = useState<boolean>(false);

  const appData = useContext(AppDataContext);

  const inventory = useContext(InventoryContext);

  const [errorDesc, setErrorDesc] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(true);




  //const { getSignedInUser, saveCredentials, clearCredentials, setInventoryId  } = useContext(UserAccountContext);


  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: object, reason: string) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "auth-popover" : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    if (index === 0) {
      navigate(`/${SellerApp.pathname}/${Inventory.products}`);
    }
    if (index === 1) {
      navigate(`/${SellerApp.pathname}/${Orders.all}`);
    }
    if (index === 2) {
      navigate(`/${SellerApp.pathname}/${DiscountsRoute.prettycharm}`);
    }
  };

  const handleLogout = useCallback(async () => {
    setLoading(true);
    const result = await userAccount.signoutUser();

    const apiError = result as APIError;
    if (apiError.errorDescription) {
      setError(apiError);
      setLoading(false);
      return;
      // show error
    }

    setAnchorEl(null);
    appData.gotoShoppingApp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const drawerWidth = 240;

  let toolbar = ((): ReactNode => {
    return (
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          Partner Dashboard
        </Typography>
        {
          <PCMenuButton
            onClick={handleClick}
            id={id}
            userprofile={userprofile}
          />
        }

        <Menu
          sx={menuStyle}
          keepMounted
          // hideBackdrop
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-menu",
          }}
        >
          {!userAccount.getSignedInUser() && (
            <MenuItem onClick={() => {}}>Log in</MenuItem>
          )}
          <MenuItem onClick={handleLogout}>Log out</MenuItem>
          <MenuItem key={"sell"} onClick={() => appData.gotoShoppingApp()}>
            Switch to shopping
          </MenuItem>
        </Menu>
      </Toolbar>
    );
  })();
  
  
  const listItemViews = (() => {
    let someData = isProdEnv ? [
      { icon: <InventoryIcon />, title: "inventory" },
      { icon: <LocalShippingIcon />, title: "orders" },
      { icon: <Sell />, title: "discounts" },
    ] : [
      { icon: <InventoryIcon />, title: "inventory" },
      { icon: <LocalShippingIcon />, title: "orders" },
      { icon: <Sell />, title: "discounts" },
    ]
    return someData.map((iconItem, index) => (
      <ListItem key={iconItem.title} disablePadding>
        <ListItemButton
          selected={selectedIndex === index}
          onClick={(event) => handleListItemClick(event, index)}
          sx={{
            "&.MuiListItemButton-root.Mui-selected": {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.common.white, //theme.palette.primary.contrastText
            },
          }}
        >
          <ListItemIcon
            sx={{
              color:
                selectedIndex === index ? "white" : theme.palette.primary.dark,
            }}
          >
            {iconItem.icon}
          </ListItemIcon>

          <ListItemText
            sx={{
              textTransform: "capitalize",
            }}
            primaryTypographyProps={{
              fontSize: 14,
              fontWeight: "medium",
              letterSpacing: 0,
            }}
            primary={iconItem.title}
          />
          {iconItem.title === "orders" &&
            userAccount.fulfillmentData.count > 0 && (
              <Chip
                sx={{ fontWeight: 500 }}
                label={
                  userAccount.fulfillmentData.count < 100
                    ? `${userAccount.fulfillmentData.count} new`
                    : "99+ new"
                }
                color={"error"}
              />
            )}
        </ListItemButton>
      </ListItem>
    ));
  })();

  const drawer = (
    <div>
      <Toolbar
        sx={{
          paddingTop: 16,
        }}
      />
      {/* <Divider /> */}
      <List>{listItemViews}</List>
    </div>
  );


  const setupInventory = async() => {
    if (!user) {
      return;
    }
    setIsLoading(true);
    
    const value = await inventory.createInventoryForPartner(
      user.user.id,
      user.access_token,
      user.refresh_token
    );

    let apiError = value as APIError
     if (apiError.errorDescription) {
      
      if (apiError.isInvalidTokenError) {
       userAccount.clearCredentials()
       appData.gotoShoppingApp()
      } else {
        setErrorDesc(apiError.errorDescription);
      }
      setIsLoading(false);
      return;
    }
     const {authUser, inventoryList} =  value as AuthInventoryList
   
      if (inventoryList.length === 0) {return} 
      userAccount.setInventoryId(inventoryList[0].inventoryid)

      if (authUser) {
        userAccount.saveCredentials(authUser)
       }

       setIsLoading(false);
  }

  useEffect(()=>{
      setupInventory()

        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user?.access_token])

  useEffect(() => {
    //console.dir(matchPath(location.pathname, "inventory"))

    if (location.pathname.includes(Inventory.index)) {
      setSelectedIndex(0);
    } else if (location.pathname.includes(Orders.index)) {
      setSelectedIndex(1);
    } else if (location.pathname.includes(DiscountsRoute.index)) {
      setSelectedIndex(2);
    }
    if (user) {
      userAccount.loadFulfillmentStatus(FulfillmentStatus.unfulfilled);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user?.access_token]);

   

  if (errorDesc) {
    return (
      <OfflineView
      onRetry={()=>{}}
      msg={errorDesc}
      sx={{pt: "4rem"}}

   />
    )
  }

  if (isLoading) {
    return (
      <Box sx={{width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        {toolbar}
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: theme.palette.primary.main,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              background: theme.palette.primary.main,
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          paddingTop: 9,
          // width: "100vw"
          width: {
            xs: "100%",
            sm: "100%",
            md: `calc(100% - ${drawerWidth}px)`,
          },
        }}
      >
        {/* <DCError  error={error} onErrorDisplayed={() => setError(null)} /> */}
        <Outlet />
        
      </Box>
    </Box>
  );
};

export default PCSellerApp;
