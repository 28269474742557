import Grid from "@mui/material/Grid";

import React, { ReactNode } from "react";
import { SizeDetails } from "../../Models/ProductOptions";

interface AttributeOptionsGridProps {
  data: SizeDetails[];
  render: (item: SizeDetails) => ReactNode;
}

const AttributeOptionsGrid = (props: AttributeOptionsGridProps) => {
  return (
    <Grid
      container
      spacing={2}
      columns={{ xs: 12, sm: 12, md: 12 }}
      //columns={2}
    >
      {props.data.map((item, idx) => (
        <Grid xs={4} sm={4} md={4} item key={item?.size_id}>
          {props.render(item)}
        </Grid>
      ))}
    </Grid>
  );
};
/*
interface AttributeOptionsGridProps<T> {
    data: T[]
    render: (item: T) => ReactNode
}

const AttributeOptionsGrid = (props: AttributeOptionsGridProps) => {
    return (
        <Grid
          container
          spacing={2}
          columns={{xs: 4, sm: 8, md: 12}}
          //columns={2}
        >
          {colors.map((color, idx) => (
            <Grid xs={2} sm={4} md={4} item key={color?.color_id}>
            <IconButton
              onClick={() => props.onColorSelected(color!)}
              key={idx}
              sx={(theme) => ({
                display: "grid",
                placeItems: "center",
                height: "4rem",
                width: "100%",
                borderRadius: "0.5rem",
                border: `2px solid ${ props.selected?.color_id === color!.color_id ? theme.palette.primary.main : "lightgray"}`
                  
              })}
            >
              <Box
                sx={{
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "1rem",
                  backgroundColor: `rgba(${color!.color_value.r}, ${
                    color!.color_value.g
                  }, ${color!.color_value.b}, 1.0)`,
                }}
              />
              <Typography textTransform={"capitalize"} variant="body2">{color?.color_title}</Typography>
            </IconButton>
            </Grid>
          ))}
        </Grid>
      );
}*/

export default AttributeOptionsGrid;
