/**
 * Model for the signup route/component
 */

import { LoginCredentials } from "./LoginState";

export interface Login {
  loginCredentials: LoginCredentials | null;
  showingSignupForm: boolean;
}

export enum LoginActionType {
  setCredentials,
  toggleShowingSignupForm,
}

export interface LoginAction {
  type: LoginActionType;
  payload: LoginCredentials | boolean;
}
