import React, { Dispatch  } from "react";
import { FormType } from "../components/Providers/AccountsProvider";
import {
  UserAccount,
  UserAccountAction,
} from "../components/UserAccount/Models/UserAccount";
import { APIError } from "../networking/SupabaseAPIManager/SupabaseAPIManager";
import { FavoriteItem } from "./FavoriteItem";
import { Product } from "./Product";
import { RequestStatus } from "./Result";
import { Review } from "./Review";
import { AuthUser } from "./User";
import { AuthUserProfile, UserProfile } from "./UserProfile";
import { FulfillmentData, FulfillmentStatus } from "./InventoryOrder";

export interface Accounts {
  state: UserAccount | null;
  readonly accountModalIsPresented: boolean;
  readonly favorites: FavoriteItem[];
  readonly favoritedProducts: Product[];
  readonly favoritesRequestStatus: RequestStatus | APIError;
  readonly favoritedRequestStatus: RequestStatus | APIError;
  readonly canLoadMoreFavoritesData: boolean;
  readonly removedFavorites: string[];
  readonly reviewsRequestStatus: RequestStatus | APIError;
  readonly userReviews: Review[];
  readonly fulfillmentData: FulfillmentData
  readonly inventoryIdRef:  React.MutableRefObject<string | null>
 // readonly inventoryId: string | null
  addToFavorites: (newItem: FavoriteItem) => void;
  clearCredentials: () => void;
  clearUserReviews: () => void
  dispatch: Dispatch<UserAccountAction>;
  clearFavorites: () => void;

  /**
   * checks whether user has purchased product
   * @param productId id of product
   * @param variantId variantId of product
   * @returns true if user has purchased product, false otherwise
   */
  hasPurchasedProduct: (
    productId: string,
    variantId?: string
  ) => Promise<boolean | APIError>;

  loadFavoritedData: (
    userId: string,
    accessToken: string,
    refreshToken: string,
    productId?: string,
    variantId?: string,
    startIdx?: number
  ) => Promise<AuthUser | null>;

  loadPendingOrders: () => Promise<void>;

  /**
   * loads user review for a product
   * @param productId
   * @param variantId
   *
   */
  loadUserReviews: (productId: string, variantId?: string) => Promise<void>;

  loadFulfillmentStatus: (status: FulfillmentStatus) => Promise<APIError  | null>


  /**
   * recover forgotten password for an email
   * @param email email which password is recovered
   * @returns true if successful, false otherwise
   */
  recoverPassword: (email: string) => Promise<boolean | APIError>;

  /**
   * reset user password
   * @param email user email
   * @param newPassword new password
   * @param accessToken accessToken for the password reset
   * @returns a promise that resolve to true if sucessful, and false otherwise
   */
  resetPassword: (
    email: string,
    newPassword: string,
    accessToken: string
  ) => Promise<boolean | APIError>;

  removeFavoritedProduct: (favoriteId: string) => void

  saveCredentials: (authUser: AuthUser) => void;
  //getCredentials: () => Map<string, string | undefined>;

  /**
   * wishlists a  product
   * @param productId productId
   * @param variantId variantId
   * @param userId userId
   * @param accessToken access token of user
   * @param refreshToken refresh token
   * @returns promise that resolves to null if successful error otherwise
   */
  favoriteProduct: (
    productId: string,
    variantId: string,
    userId: string,
    accessToken: string,
    refreshToken: string
  ) => Promise<null | APIError>;

  /**
   *
   * @returns a promise that resolves to true if operation is successful and false otherwise
   */
  loadCredentials: () => Promise<boolean>;
  /**
   * loads a product
   * @param productId 
   * @returns void promise
   */
  loadProduct: (productId: string) => Promise<void>;
  /**
   * retrieves authentication credentials
   * @returns authentication credentials
   */
  getSignedInUser: () => AuthUser | null;

  readonly credentials: AuthUser | null;
  signoutUser: () => Promise<APIError | AuthUser | boolean>;

   /**
   * Sets up user credentials given auth data from an auth provider such as google
   * @param authData
   * @returns A promise that resolves to an authenticated user credentials object or an api error
   */
  setupUserCredentials: (
    authData: Map<string, string>
  ) => Promise<AuthUser | APIError>;

  /**
   * loads a user profile for authentication credentials
   * @param auth authentication data
   * @returns promise that resolves to an object containing the user profile and an updated authentication data if access token was refreshed
   */
  loadUserprofile: (auth: AuthUser) => Promise<AuthUserProfile | APIError>;

  getUserprofile: () => UserProfile | null;

  shouldLoadMoreFavorites: (shouldLoad: boolean) => void,

  /**
   * changes the authentication form displayed to the user to a new formType
   * @param formType type of authentication form to display to user
   * @returns 
   */
  updateCurrentform: (formType: FormType | null) => void;

 /**
  * indicated whether a type of authentication form is currently displayed      
  * @param formType type of authentication form to test for
  * @returns true if formType is currently selected
  */
  hasSelectedFormWithType: (formType: FormType) => boolean;

  /**
   * toggles the display of the account view
   * @returns void
   */
  toggleAccountModal: () => void;

  /**
   * updates the account's status to a seller status
   * @param newStatus indicates whether account should be updated a seller or not
   * @returns void
   */
  updateSellerStatusForUserId: (newStatus: boolean) => void;

   /**
   * keeps a references to inventoryId if user is a partner
   * @param newId  
   * @returns void
   */
   setInventoryId: (newId: string) => void;

  /**
   * unwishlist a product   
   * @param favoriteId favorite id of the product to be unwishlisted
   * @param user 
   * @returns 
   */
  unFavoriteItem: (favoriteId: string, user: AuthUser) => Promise<boolean>;
  accountRequestStatus: "idle" | "loading" | APIError;
  setFulfillmentCount: (fulfillmentStatus: FulfillmentStatus, newCount: number) => void

  //  updateAuthCredentials: (authUser: AuthUser) => void
}

 

export const defaultAccounts: Accounts = {
  clearCredentials: () => {},
  state: null,
  dispatch: () => null,
  canLoadMoreFavoritesData: false,
  favorites: [],
  favoritedProducts: [],
  removedFavorites: [],
  favoritesRequestStatus: RequestStatus.Idle,
  favoritedRequestStatus: RequestStatus.Idle,
  reviewsRequestStatus: RequestStatus.Idle,
  userReviews: [],
  fulfillmentData: {fulfillmentStatus: FulfillmentStatus.unfulfilled, count: 0},
  inventoryIdRef: {current: null},
  //inventoryId:  null,
  addToFavorites: (newItem: FavoriteItem) => null,
  clearFavorites: () => null,
  clearUserReviews: () => null,
  hasPurchasedProduct: (
    productId: string,
    variantId?: string
  ): Promise<boolean | APIError> => {
    return new Promise((resolve, reject) => resolve(false));
  },
  loadFulfillmentStatus:  (status: FulfillmentStatus): Promise<APIError | null> => {
    return new Promise((resolve, reject) => {
      resolve(APIError.default);
    });
  },

  loadPendingOrders: (): Promise<void> => {
    return new Promise((resolve, reject) => resolve());
  },

  loadUserReviews: (productId: string, variantId?: string): Promise<void> => {
    return new Promise((resolve, reject) => resolve());
  },
  loadFavoritedData: (
    userId: string,
    accessToken: string,
    refreshToken: string,
    productId?: string,
    variantId?: string,
    startIdx?: number
  ): Promise<AuthUser | null> => {
    return new Promise((resolve, reject) => reject(null));
  },
  loadProduct: (productId: string): Promise<void> => {
    return new Promise((resolve, reject) => resolve());
  },
  accountModalIsPresented: false,
  recoverPassword: (email: string): Promise<boolean | APIError> => {
    return new Promise((resolve, reject) => resolve(true));
  },
  removeFavoritedProduct: (favoriteId: string) => {return},

  resetPassword: async (
    email: string,
    newPassword: string,
    accessToken: string
  ): Promise<boolean | APIError> => {
    return new Promise((resolve, reject) => resolve(true));
  },
  saveCredentials: (authUser: AuthUser): void => {},
  setInventoryId: (newId: string): void => {},
  favoriteProduct: (
    productId: string,
    userId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<null | APIError> => {
    return new Promise((resolve, reject) => reject(APIError.default));
  },
  // getCredentials: (): Map<string, string | undefined> => {
  //   return new Map<string, string | undefined>();
  // },
  loadCredentials: (): Promise<boolean> => {
    return new Promise((resolve, reject) => resolve(true));
  },
  getSignedInUser: (): AuthUser | null => {
    return null;
  },
  credentials: null,
  signoutUser: (): Promise<APIError | AuthUser | boolean> => {
    return new Promise((resolve, reject) => reject(APIError.default));
  },
  setupUserCredentials: (
    authData: Map<string, string>
  ): Promise<AuthUser | APIError> => {
    return new Promise((resolve, reject) => reject(APIError.default));
  },
  shouldLoadMoreFavorites: (shouldLoad: boolean) => {},
  loadUserprofile: (auth: AuthUser): Promise<AuthUserProfile | APIError> => {
    return new Promise((resolve, reject) => reject(APIError.default));
  },
  getUserprofile: (): UserProfile | null => {
    return null;
  },
  updateCurrentform: (formType: FormType | null) => {},
  hasSelectedFormWithType: (formType: FormType): boolean => {
    return false;
  },
  toggleAccountModal: () => {},
  updateSellerStatusForUserId: (newStatus: boolean) => {
    return;
  },
  setFulfillmentCount: (fulfillmentStatus: FulfillmentStatus, newCount: number) => {},
  unFavoriteItem: (favoriteId: string, user: AuthUser): Promise<boolean> => {
    return new Promise((resolve, reject) => resolve(false));
  },

  accountRequestStatus: "idle",
};
