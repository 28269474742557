import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Rating from "@mui/material/Rating";

import { getIn } from "formik";
//import { FormikProps } from 'formik'
import React from "react";
import { DCMenuItem } from "../../Models/ProductOptions";
import NumberFormatCustom from "./NumberFormatCustom";

// interface InputFieldsProps {
//     formikProps: FormikProps<ProductData>
// }

export const DCTextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: any) => {
  return (
    <TextField
      fullWidth
      multiline={field.name === "description"}
      minRows={field.name === "description" ? 4 : undefined}
      //{...props}
      id={props.id}
      name={field.name}
      label={props.label}
      value={field.value}
      onChange={field.onChange}
      error={touched[field.name] && Boolean(errors[field.name])}
      helperText={touched[field.name] && errors[field.name]}
    />
  );
};

export const DCRating = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: any) => {
  return (
    <Rating
    id={field.id}
    name={field.name}
    value={field.value}
    
    onChange={(event, newValue) => {
      // console.log(newValue)
      const value = newValue || undefined;
      props.setFieldValue("rating", value);
      // setValue(newValue);
    }}
  />
  );
};

export const DCNumericField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: any) => {
  const handlePriceChange = (
    value: string,
    setFieldValue: any,
    name: string
  ) => {
    let parsedValue: any;
    // console.log(parseInt(parsedValue))
    if (name.includes("stockQuantity")) {
      parsedValue = parseInt(value);
    } else {
      parsedValue = parseFloat(value);
    }
    if (!isNaN(parsedValue)) {
      setFieldValue(name, parsedValue);
    } else {
      setFieldValue(name, "");
    }
  };
 
   

  return (
    <TextField
      fullWidth
      id={props.id}
      name={field.name}
      label={props.label}
      value={field.value}
      disabled={props.id.includes("discount")}
      
      // onChange={field.onChange}
      onChange={(e) => {
        handlePriceChange(e.target.value, setFieldValue, e.target.name);
      }}
      error={getIn(touched, field.name) && Boolean(getIn(errors, field.name))}
      helperText={getIn(touched, field.name) && getIn(errors, field.name)}
      // error={touched[field.name] && Boolean(errors[field.name])}
      // helperText={touched[field.name] && errors[field.name]}
      InputProps={{
        startAdornment:
          !props.id.includes("stockQuantity") && !props.id.includes("discount") ? (
            <InputAdornment color="primary" position="start">
              $
            </InputAdornment>
          ) : props.id.includes("discount") ? (
            <InputAdornment color="primary" position="end">
            $
          </InputAdornment>
          ) : undefined,
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};

export const DCSelectField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: any) => {
  const selectStyle = {
    textTransform: "capitalize",
    textAlign: "left",
    width: "100%",
  };

  const categoriesView = (() => {
    return props.menuItems.map((item: DCMenuItem) => (
      <MenuItem
        sx={selectStyle}
        key={item.id}
        value={item.id}
      >
        {item.title}
      </MenuItem>
    ));
  })();

  return (
    <FormControl
      sx={{
        alignItems: "flex-start",
      }}
      variant="outlined"
      fullWidth
    >
      <InputLabel variant="outlined" htmlFor={props.id}>
        {props.label}
      </InputLabel>
      <Select
        disabled={props.id === "subcategoryId" && props.menuItems.length === 0}
        sx={selectStyle}
        value={field.value}
        title={props.label}
        labelId={props.id}
        id={props.id}
        name={field.name}
        label={props.label}
        onChange={(e) => {
          field.onChange(e);
          props.onChange && props.onChange(e);
        }}
        error={touched[field.name] && Boolean(errors[field.name])}
      >
        {categoriesView}
      </Select>
      {touched[field.name] && Boolean(errors[field.name]) ? (
        <Typography marginX={"14px"} color="error" variant="caption1">
          {errors[field.name]}
        </Typography>
      ) : null}
    </FormControl>
  );
};
