import LoadingButton from "@mui/lab/LoadingButton";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppDataContext } from "../../../contexts/AppDataContext";
import { LookupTable } from "../../../Models/LookupTables";
import { ColorDetails, SizeDetails } from "../../../Models/ProductOptions";
import { formatPrice } from "../../../utils";
import { OrdersContext } from "../contexts/OrdersContext";
import InventoryOrderDetailItem from "./InventoryOrderDetailItem";
import CustomerOrderDetail from "./CustomerOrderDetail";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import { FulfillmentStatus } from "../../../Models/InventoryOrder";
import { RequestStatus } from "../../../Models/Result";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import DCError from "../../SupportingViews/DCError";
import useMediaQuery from "@mui/material/useMediaQuery";

const InventoryOrderDetail = () => {
  const ordersManager = useContext(OrdersContext);
  const accountManager = useContext(UserAccountContext);
  const appData = useContext(AppDataContext);
  const { inventoryOrderId } = useParams<{ inventoryOrderId: string }>();

  const isMediumAndAbove = useMediaQuery((theme: any) =>
           theme.breakpoints.up("md")
      );

  const user = accountManager.getSignedInUser();
  const platingTable = appData.getLookuptableForType(
    LookupTable.color
  ) as ColorDetails[];

  const sizesTable = appData.getLookuptableForType(
    LookupTable.size
  ) as SizeDetails[];

  const inventoryOrder = (() => {
    return ordersManager?.selectedOrder ?? null;
    // if (!inventoryOrderId) {
    //   return null;
    // }
    // return ordersManager?.getInventoryOrder(inventoryOrderId);
  })();

  const fulfillmentError = (() => {
    const error = ordersManager?.fulfillmentRequestStatus as APIError;
    return error.errorDescription ? error : null;
  })();

  const header = (() => {
    if (!inventoryOrder) {
      return null;
    }
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{ width: "inherit", height: "6rem" }}
      >
        <Typography variant="headline">Order Details</Typography>
        <Chip
          sx={{ fontWeight: 500 }}
          label={inventoryOrder.fulfillmentStatus}
          color={inventoryOrder.fulfillmentStatus === FulfillmentStatus.fulfilled ? "success" : (inventoryOrder.fulfillmentStatus === FulfillmentStatus.fulfilling ? "warning" : "error")}
        />
      </Stack>
    );
  })();

  const orderSummary = (() => {
    return !inventoryOrder ? null : (
      <Stack width="inherit" direction="row" justifyContent={"flex-end"}>
        <Stack width={isMediumAndAbove ? "50%" : "inherit"} spacing={1} alignItems="flex-start">
          <Typography variant="subhead">Shipping -- {inventoryOrder.shippingCost.displayName}</Typography>
          <Stack justifyContent={"space-between"} width="100%" direction="row">
            <Typography variant="subhead">Subtotal</Typography>
            <Typography variant="subhead">{formatPrice(inventoryOrder.shippingCost.amountSubtotal)}</Typography>
          </Stack>
          <Stack justifyContent={"space-between"} width="100%" direction="row">
            <Typography variant="subhead">Tax</Typography>
            <Typography variant="subhead">{formatPrice(inventoryOrder.shippingCost.amountTax)}</Typography>
          </Stack>
          <Stack justifyContent={"space-between"} width="100%" direction="row">
            <Typography variant="subhead">Total</Typography>
            <Typography variant="subhead">
              {formatPrice(inventoryOrder.shippingCost.amountTotal)}
            </Typography>
          </Stack>
           
          <Stack justifyContent={isMediumAndAbove ? "space-between" : "flex-start"} paddingTop={"1rem"} width="100%" direction="row">
            <Typography textAlign="left" variant="headline">Grand Total Paid By Customer</Typography>
            <Typography variant="headline">
              {inventoryOrder.amountTotal
                ? formatPrice(inventoryOrder.paid ?? 0)
                : "N/A"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  })();

  const fulfillmentStatusLabel = (()=> {
      if (inventoryOrder === null) {
        return "N/A"
      }
      if (inventoryOrder.fulfillmentStatus === FulfillmentStatus.unfulfilled) {
        return "Start Fulfilling"
      } else {
        return inventoryOrder.fulfillmentStatus
      }
  })()

  const handleStartFulfilling = async () => {
    
    if (inventoryOrderId === undefined || ordersManager === null || user === null) {return}

    const result = await ordersManager.updateFulfillmentStatus(FulfillmentStatus.fulfilling, inventoryOrderId, user)

    if (result === null) {
      return
    }
    const {authUser: auth} = result
    if (auth !== null) {
      accountManager.saveCredentials(auth)
    }

    accountManager.setFulfillmentCount(FulfillmentStatus.unfulfilled, accountManager.fulfillmentData.count - 1)
  }

  useEffect(() => {
    (async () => {
      if (!inventoryOrderId || !user) {
        return null;
      }
      const authUser = await ordersManager?.loadInventoryOrder(
        user,
        inventoryOrderId
      );
      if (authUser) {
        accountManager.saveCredentials(authUser);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  if (!inventoryOrder) {
    return null;
  }

  return (
    <Stack direction={isMediumAndAbove ? "row" : "column"} justifyContent="space-between" width="100%">
      <DCError error={fulfillmentError} />
      <Card sx={{ width: isMediumAndAbove ? "70%" : "inherit", px: "1rem" }}>
        {header}
        <CardContent
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          {inventoryOrder.orderDetails?.map((orderDetail) => (
            <div key={orderDetail.orderDetailId}>
              <InventoryOrderDetailItem
                key={orderDetail.orderDetailId}
                orderDetail={orderDetail}
                productTitle={orderDetail.productTitle ?? "N/A"}
                colorTable={platingTable}
                sizeTable={sizesTable}
              />
              <Divider sx={{ my: "1rem" }} />
            </div>
          ))}
          {orderSummary}
          <Divider />
          
        </CardContent>
        
        <CardActions
          sx={{ width: "100%", justifyContent: "flex-end", height: "5rem", display: isMediumAndAbove ? "inherit" : "none" }}
        >
          <LoadingButton
             onClick={handleStartFulfilling }
             loading={ordersManager?.fulfillmentRequestStatus === RequestStatus.Loading}
             disabled={ordersManager?.fulfillmentRequestStatus === RequestStatus.Loading || inventoryOrder.fulfillmentStatus !== FulfillmentStatus.unfulfilled}
            sx={{
              height: "2.1rem",
              // width: "90%",
              fontWeight: "500",
            }}
            variant="contained"
          >
            {fulfillmentStatusLabel}
          </LoadingButton>
        </CardActions>
      </Card>
      <Card
        sx={{
          width: isMediumAndAbove ? "25%" : "inherit",
        }}
      >
        <CustomerOrderDetail
          auth={user}
          ordersManager={ordersManager}
          inventoryOrder={inventoryOrder}
        />
         <CardActions
          sx={{ width: "100%",   display: isMediumAndAbove ? "none" : "inherit" }}
        >
          <LoadingButton
             fullWidth
             onClick={handleStartFulfilling }
             loading={ordersManager?.fulfillmentRequestStatus === RequestStatus.Loading}
             disabled={ordersManager?.fulfillmentRequestStatus === RequestStatus.Loading || inventoryOrder.fulfillmentStatus !== FulfillmentStatus.unfulfilled}
            sx={{
              height: "2.1rem",
              // width: "90%",
              fontWeight: "500",
            }}
            variant="contained"
          >
            {fulfillmentStatusLabel}
          </LoadingButton>
        </CardActions>
      </Card>
     
    </Stack>
  );
};

export default InventoryOrderDetail;
