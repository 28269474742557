import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";

import { titleValidationSchema } from "../../../../../utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { confirmBtnStyle } from "../../../../../utils/styles";

interface ProductTitleDialogProps {
  onClose: () => void;
  onConfirm: (title: string) => void;
  isOpen: boolean;
  isLoading: boolean;
}

/**
 * Shows an alert dialog that prompts user for a title
 * @param props props include title, description, cancelAction and acceptActionn
 * @returns AlertDialog
 */
export default function ProductTitleDialog(props: ProductTitleDialogProps) {
  const { isOpen, onClose, onConfirm, isLoading } = props;

  const formik = useFormik<{ title: string }>({
    initialValues: {
      title: "",
    },
    validationSchema: titleValidationSchema,
    onSubmit: (values) => {
      onConfirm(values.title);
    },
  });

  return (
    // <div>
    <Dialog
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          formik.handleSubmit();
        }
      }}
      open={isOpen}
      
      onClose={() => {
        onClose();
        formik.resetForm();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Product Title"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          What's the name of the new product?
        </DialogContentText>
        <TextField
          name="title"
          autoFocus
          margin="dense"
          id="name"
          label="Product Title"
          type="text"
          fullWidth
          variant="outlined"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{
            display: isLoading ? "none" : "inline-flex",
            '&:hover': {
              backgroundColor: 'white'
            },
          }}
          onClick={() => {
            onClose();
            formik.resetForm();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          //variant="contained"
          sx={confirmBtnStyle}
          loading={isLoading}
          onClick={() => formik.handleSubmit()}
          autoFocus
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
    // </div>
  );

  
}
