import Box from "@mui/material/Box";
//import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {  logEvent } from "firebase/analytics";

 import InstagramIcon from "@mui/icons-material/Instagram";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import FacebookIcon from "@mui/icons-material/Facebook";

import React, {  useEffect, useState } from "react";
//import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { SxProps, Theme } from "@mui/material/styles";

import { InView } from "react-intersection-observer";
import { isIOS, isMacOs } from "react-device-detect";

import AffordableLogo from "./AffordableLogo";

import VarietyLogo from "./VarietyLogo";
//import GenzWoman from "./GenzWoman";
// import appIcon from "../../../../public/logo192.png"
import { Helmet } from "react-helmet";
import PCDownloadButton from "../../SupportingViews/PCDownloadButton";
//import HeaderHeroReact from "../../SupportingViews/header-hero/HeaderHeroReact";
import "../../SupportingViews/header-hero/HeaderHero.css";
import { analytics } from "../../../firebaseConfig";
import IconButton from "@mui/material/IconButton";
 

const PCAbout = () => {
  const [uspImg, setUSPImg] = useState(0);
  


  const uspImgs = {
    curated: 1,
    options: 2,
    price: 3,
  };

  const sectionPadding = 2;

  const dividerStyle: SxProps<Theme> = {
    display: { md: "none" },
    position: "absolute",
    bottom: "0px",
    left: {
      xs: `${sectionPadding}rem`,
      sm: `${sectionPadding}rem`,
      md: `${7}vw`,
    },
    height: "2px",
    width: {
      xs: `calc(100% - ${sectionPadding * 2}rem)`,
      sm: `calc(100% - ${sectionPadding * 2}rem)`,
      md: `calc(100% - ${7 * 2}vw)`,
    },
    borderColor: "primary.dark",
  };

  const uspStyle: any = { xs: 4, md: "7vw 0 0 7vw" };

  const containerStyle: any = {
    xs: "column",

    md: "row",
  };

  
  const screenshotInfo = (() => {

    const publicURL = process.env.PUBLIC_URL;
    let info: { url: string; altDesc: string } = {
      url: `${publicURL}/android_screenshots/android_shots@3x_framed.png`,
      altDesc: "android_app",
    };

    if (isMacOs || isIOS) {
      info.url = `${publicURL}/iphone_screenshots/explore@3x.png`;
      info.altDesc = "ios_app";
    }  

    return info;
  })();

  useEffect(() => {
    setUSPImg(uspImgs.curated);
   //const analytics = getAnalytics()
  
   logEvent(analytics, 'page_view', {page_title: "about"})
  
  }, [uspImgs.curated]);

  return (
    <Stack paddingTop={6} height={"100vh"}>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About Prettycharm" />
      </Helmet>
      <Stack
        direction={containerStyle}
        padding={{ xs: 4, md: "4vw" }}
        paddingRight={{ xs: 4, md: "7vw" }}
        spacing={4}
        id="mission-section"
        textAlign={"left"}
        alignItems={"flex-start"}
      >
        
        <Box
          sx={{
           // width: { xs: "12rem", md: "40vw" },
           // height: { xs: "12rem", md: "30%", xl: "45%" },
            width: { xs: "8rem", md: "29vw", lg: "25vw", xl: "18vw" },
            height: { xs: "16rem", md: "18%", lg: "37%", xl: "80%" },
            margin: { xs: "0 auto", md: "0 0 0 7vw" },
            position: { xs: "unset", md: "sticky" },
            top: { xs: "unset", md: "16px" },
          }}
          component={"div"}
        >
          <img
            style={{ width: "inherit", height: "100%" }}
            alt={screenshotInfo.altDesc}
             src={screenshotInfo.url}
          />
          {/* <GenzWoman /> */}

          {/* <HeaderHeroReact iconSrc={`${process.env.PUBLIC_URL}/logo192.png`} appTitle={"Prettycharm"}>
              <div slot="headline">
              <Typography component={"div"} marginTop={3} variant={"subtitle1"}>
                Unlock Effortless Adventures
              </Typography>
            </div>
            <div slot="image-headline">
              <Box
                marginY={breakpointObj.isMobile ? 2 : 8}
                component="img"
                width={breakpointObj.isMobile ? "10rem" : "15rem"}
                src={feature1Img}
              />
            </div>
          </HeaderHeroReact> */}
        </Box>
        <Stack
          sx={{ width: { md: "35vw" }, marginLeft: { md: "auto !important" } }}
        >
          <Typography
            fontWeight="500"
            letterSpacing={"2px"}
            fontSize={{ md: "4rem" }}
            variant="largeTitle"
          >
            Making Elegance Affordable
          </Typography>
          <Typography
            fontSize={{ md: "2.5rem" }}
            sx={{ color: "text.secondary" }}
            variant="title2"
          >
            Prettycharm is an online store for women's jewelry. It's home
            to all categories of plated jewelry. In a time of increasing
            automation, it's our mission to provide women on a budget with
            access to high-quality brazilian gold plated and non-tarnish jewelry
            that helps them achieve an elegant look without breaking the bank.
            Our jewelry collections are designed and curated by world-class
            professionals, making it easier than ever for our customers to shop
            for the perfect piece. By offering affordable luxury, we aim to
            empower our customers to express themselves with confidence and
            style.
          </Typography>
        </Stack>
      </Stack>

      <Stack
        padding={{ md: "7vw" }}
        id="usp-section"
        bgcolor={"secondary.main"}
        direction={containerStyle}
      >
        <Stack width={{ md: "42vw" }} id="usp-container">
          <Stack
            padding={uspStyle}
            spacing={2}
            // bgcolor="secondary.main"
            sx={{ textAlign: "left", position: "relative" }}
            id="how-it-works-section"
          >
            <Typography
              fontSize={{ md: "3rem" }}
              fontWeight={"medium"}
              variant="title3"
            >
              How Prettycharm Works
            </Typography>
            <Typography
              fontSize={{ md: "1.6rem" }}
              component="div"
              sx={{ color: "text.secondary", marginBottom: "1rem" }}
              variant="body"
            >
              Our platform curates the best-designed jewelry and makes it easier
              for buyers to find what they love.
            </Typography>
            <Box
              sx={{
                width: { xs: "12rem", md: "20vw" },
                height: { xs: "12rem", md: "25%" },
                display: { xs: "initial", md: "none" },
              }}
              component={"img"}
              src={`${process.env.PUBLIC_URL}/curation-no-bg.png`}
            />
            {/* <Divider sx={dividerStyle} /> */}
          </Stack>
          <InView
            as="div"
            threshold={1}
            onChange={(inView, entry) => setUSPImg(uspImgs.curated)}
          >
            <Stack
              spacing={2}
              padding={uspStyle}
              sx={{ textAlign: "left", position: "relative" }}
              // bgcolor="secondary.main"
              id="buy-extraordinary-section"
            >
              <Typography
                fontSize={{ md: "3rem" }}
                fontWeight={"medium"}
                variant="title3"
              >
                Curated for You
              </Typography>
              <Typography
                fontSize={{ md: "1.6rem" }}
                sx={{ color: "text.secondary" }}
                variant="body"
              >
                Our jewelry collections are designed and curated by world-class
                professionals, which ensures that our customers get access to
                the latest trends and styles in the jewelry industry
              </Typography>

              <Divider sx={dividerStyle} />
            </Stack>
          </InView>

          <InView
            // rootMargin="-200px 0px"
            as="div"
            threshold={1}
            onChange={(inView, entry) => setUSPImg(uspImgs.options)}
          >
            <Stack
              spacing={2}
              padding={uspStyle}
              // bgcolor="secondary.main"
              sx={{ textAlign: "left", position: "relative" }}
              id="wide-variety-options"
            >
              <Box
                sx={{
                  width: { xs: "18rem", md: "20vw" },
                  height: { xs: "18rem", md: "25%" },
                  display: { xs: "initial", md: "none" },
                }}
                // component={"img"}
                // src={uspImgs.options}
              >
                <VarietyLogo />
              </Box>
              <Typography
                fontSize={{ md: "3rem" }}
                fontWeight={"medium"}
                variant="title3"
              >
                Wide Variety of Options
              </Typography>
              <Typography
                fontSize={{ md: "1.6rem" }}
                sx={{ color: "text.secondary" }}
                variant="body"
              >
                We offer a wide variety of plated jewelry options that cater to
                every taste and style. Our customers can choose from a range of
                options, from necklaces to rings, to find the perfect jewelry
                piece that suits their personality
              </Typography>

              <Divider sx={dividerStyle} />
            </Stack>
          </InView>

          <InView
            as="div"
            threshold={1}
            onChange={(inView, entry) => {
              // debugger

              if (entry.boundingClientRect.top < 256) {
                setUSPImg(uspImgs.price);
              }
            }}
          >
            {({ inView, ref, entry }) => {
              return (
                <Stack
                  ref={ref}
                  spacing={2}
                  padding={uspStyle}
                  sx={{ textAlign: "left", position: "relative" }}
                  // bgcolor="secondary.main"
                  paddingY={4}
                  id="affordable-pricing"
                >
                  <Box
                    sx={{
                      width: { xs: "18rem", md: "20vw" },
                      height: { xs: "18rem", md: "25%" },
                      display: { xs: "initial", md: "none" },
                    }}
                    component={"div"}
                  >
                    <AffordableLogo />
                  </Box>
                  <Typography
                    fontSize={{ md: "3rem" }}
                    fontWeight={"medium"}
                    variant="title3"
                  >
                    Affordable Pricing
                  </Typography>
                  <Typography
                    fontSize={{ md: "1.6rem" }}
                    sx={{ color: "text.secondary" }}
                    variant="body"
                  >
                    We understand that our target market is GenZ women on a
                    budget, and we offer affordable pricing without compromising
                    on quality. Our customers can enjoy an elegant look without
                    breaking the bank.
                  </Typography>

                  <Divider sx={dividerStyle} />
                </Stack>
              );
            }}
          </InView>

          <InView
            as="div"
            threshold={1}
            onChange={(inView, entry) => setUSPImg(0)}
          >
            <Stack
              padding={uspStyle}
              spacing={2}
              sx={{ textAlign: "left", position: "relative" }}
              // bgcolor="secondary.main"
              id="shopping-experience"
            >
              <Typography
                fontSize={{ md: "3rem" }}
                fontWeight={"medium"}
                variant="title3"
              >
                User-friendly Shopping Experience
              </Typography>
              <Typography
                fontSize={{ md: "1.6rem" }}
                sx={{ color: "text.secondary" }}
                variant="body"
              >
                We offer a seamless experiance with our easy-to-use search
                tools, making it easy for our customers to shop plated jewelry
                from the comfort of their own homes
              </Typography>
              <Divider sx={dividerStyle} />
            </Stack>
          </InView>
          <Stack
            padding={uspStyle}
            spacing={2}
            sx={{ textAlign: "left" }}
            // bgcolor="secondary.main"
            id="shop-securely-section"
          >
            <Typography
              fontSize={{ md: "3rem" }}
              fontWeight={"medium"}
              variant="title3"
            >
              Shop Securely
            </Typography>
            <Typography
              fontSize={{ md: "1.6rem" }}
              sx={{ color: "text.secondary" }}
              variant="body"
            >
              We use Stripe to process all payments on the Prettycharm platform.
              Keeping buyers' transactions safe, fun and secure is our priority
              and we're always here to help.
            </Typography>
          </Stack>
        </Stack>
        <Box
          id="usp-img-desktop"
          sx={{
            width: { xs: "12rem", md: "26vw" },
            height: { xs: "12rem", md: "17%" },
            margin: { xs: "0 auto", md: "14vw 7vw 0 auto" },
            display: { xs: "none", md: "initial" },
            position: { md: "sticky" },
            //top: { md: "14vw" },
            top: { md: "64px" },

            //margin: "0 auto"
          }}
          //component={"img"}
          component={"div"}
          //src={uspImg}
        >
          {uspImg === 1 ? (
            <Box
              sx={{ objectFit: "contain", height: "100%", width: "100%" }}
              component="img"
              src={`${process.env.PUBLIC_URL}/curation-no-bg.png`}
            />
          ) : uspImg === 2 ? (
            <VarietyLogo />
          ) : uspImg === 3 ? (
            <AffordableLogo />
          ) : null}
        </Box>
      </Stack>
      <Stack
        padding={{ xs: 4, md: "7vw" }}
        spacing="1rem"
        id="download-section"
        // paddingY="3rem"
        //paddingX="1rem"
        bgcolor={"primary.main"}
      >
        <Box
          sx={{
            width: { xs: "6rem", sm: "6rem", md: "10vw" },
            height: { xs: "6rem", sm: "6rem", md: "10vw" },
            margin: "0 auto",
          }}
          component={"img"}
          src={`${process.env.PUBLIC_URL}/logo512.png`}
        />
        <Typography
          fontSize={{ md: "3rem" }}
          variant="title2"
          fontWeight="medium"
        >
          I'm ready to shop. Where do I begin?
        </Typography>
        <PCDownloadButton
          shouldShortenLabel={false}
          width={{ md: "25vw" }}
          height={"3rem"}
        />
      </Stack>
      <Stack
        padding={{ xs: 4, md: "7vw" }}
        spacing="1rem"
        //padding="2rem"
        id="contact-section"
        bgcolor="secondary.main"
      >
        <Typography fontSize={{ md: "1.6rem" }} variant="headline">
          WE LOVE HEARING FROM YOU! Call us at 443-824-4914 or{" "}
          <Link
            sx={{ color: "primary.dark", textDecoration: "underline" }}
            component={"a"}
            href="mailto:support@prettycharm.app"
          >
            email us
          </Link>{" "}
          anytime with a question, or just to chat!
        </Typography>
        <Stack
          justifyContent="center"
          width={{ md: "30%" }}
          alignSelf="center"
          direction="row"
        >
          {/* <IconButton size="large">
            <TwitterIcon sx={{ color: "text.primary" }} fontSize="large" />
          </IconButton> */}

          <IconButton
            size="large"

             onClick={()=>window.open('https://www.instagram.com/prettycharmapp/', '_blank')}
          >
            <InstagramIcon sx={{ color: "text.primary" }} fontSize="large" />
          </IconButton>

          {/* <IconButton size="large">
            <FacebookIcon sx={{ color: "text.primary" }} fontSize="large" />
          </IconButton> */}
        </Stack>
      </Stack>

      <Stack
        id="footer-section"
        spacing="1rem"
        paddingX="1rem"
        paddingTop="2rem"
        paddingBottom="1rem"
        bgcolor={"text.primary"}
      >
        <Typography variant="subhead" fontWeight="500" color={"white"}>
          Copyright <span>&#169;</span> 2023 Divine Grace Inc. All rights
          reserved.
        </Typography>
        <Stack
          justifyContent="space-between"
          // width={{ md: "20%" }}
          width={"16rem"}
          alignSelf="center"
          direction="row"
        >
          <Link
            sx={{ color: "white" }}
            component={RouterLink}
            to="/legal/tos"
            variant="footnote"
          >
            Terms of Service
          </Link>
          <Link
            sx={{ color: "white" }}
            component={RouterLink}
            to="/legal/privacy"
            variant="footnote"
          >
            Privacy Policy
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PCAbout;
