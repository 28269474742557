 import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import React, { useContext  } from "react";
import { PresentationContext } from "../../../contexts/PresentationContext";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import LoggedinView from "./mobile/LoggedinView";
import LoggedoutView from "./mobile/LoggedoutView";
import AppBar from "@mui/material/AppBar";

const Authentication = () => {
  const accountManager = useContext(UserAccountContext);
  const { dismiss } = useContext(PresentationContext);
  const user = accountManager.getSignedInUser();

  return (
    <Stack>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "white",
        }}
        position="sticky"
      >
        <Toolbar
          sx={{
            alignItems: "flex-start",
            minHeight: "128px",
            pt: "1rem",
            pb: "2rem"
          }}
        >
          <Typography
            variant="largeTitle"
            noWrap
            component="div"
            sx={{
              color: "primary.dark",
              flexGrow: 1,
              alignSelf: "flex-end",
            }}
          >
            Account
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => dismiss?.()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <>{user ? <LoggedinView manager={accountManager} /> : <LoggedoutView  manager={accountManager} />}</>
    </Stack>
  );
};

export default Authentication;
