import React from "react";
import { ChevronLeftOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { SxProps, Theme } from "@mui/material/styles";

interface DCBackButtonProps {
  onClick: () => void;
  variant: string;
  sx?: SxProps<Theme> | undefined;
}

const DCBackButton = (props: DCBackButtonProps) => {

  
  return (
    <IconButton
      sx={(theme) => {
        let style: SxProps<Theme> = {
          paddingLeft: 0,
          color: theme.palette.text.primary,
        }

        //const {sx} = props

        // for(let field in sx) {
        //   style[field] = sx[field]
        // }
        
        // if (sx !== undefined) {
        //   style =  {
        //     ...style,
        //     ...sx
        //   } 
        //  }

        return  style
      }}
      onClick={props.onClick}
      aria-label="back"
      size={props.variant as any}
    >
      <ChevronLeftOutlined
        sx={{
          border: "1px solid",
          borderRadius: "50%",
          bgcolor: "white",
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
};

export default DCBackButton;
