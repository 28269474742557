import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { SxProps, Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react'
import DCDeleteButton from '../../../../SupportingViews/DCDeleteButton';

interface UploadedPhotoProps {
    src: string
    sx: SxProps<Theme>
    onDelete: () => void
    showProgress: boolean
}

const UploadedPhoto = ({src, sx, onDelete, showProgress}: UploadedPhotoProps) => {

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
    const isMediumLarge = useMediaQuery((theme: any) =>
      theme.breakpoints.between("md", "xl")
    );
  return (
    <Stack
    sx={{
      // aspectRatio: "1 / 1",
     // height: "100%",
      position: "relative",
    }}
  >
    <Box
      component="img"
      sx={sx}
      alt="product"
      src={src}
    />
   
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "rgba(255, 0, 0, 0.24)",  
        display: showProgress ? "grid" : "none" , 
        placeItems: "center",
        placeContent: "center",
        borderRadius: "12px",
      }}
    >
      <CircularProgress sx={{
        color: "error.main"
      }} size={55} />
    </Box>
    <DCDeleteButton
      size={
        isMobile
          ? "small"
          : isMediumLarge
          ? "medium"
          : "large"
      }
      onClick={onDelete}
      sx={{
        display: showProgress ? 'none' : "inline-flex",
        position: "absolute",
        top: "-12px",
        right: "-12px",
        backgroundColor: "primary.main",
        color: "text.primary",
        "&:hover": {
          backgroundColor: "secondary.main",
        },
      }

    
    }
    />
  </Stack>   
  )
}

export default UploadedPhoto