import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppDataContext } from "../../../../contexts/AppDataContext";
import { Accounts } from "../../../../Models/Accounts";
import { APIError } from "../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import DCError from "../../../SupportingViews/DCError";

interface LoggedinViewProps {
  manager: Accounts;
}

const LoggedinView = ({ manager }: LoggedinViewProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<APIError | null>(null);
  const appDataManager = useContext(AppDataContext)
  const location = useLocation()

  const handleLogout = async () => {
    setLoading(true);
    const signoutResult = await manager.signoutUser();

    const apiError = signoutResult as APIError;
    setLoading(false);
    if (apiError.errorDescription) {
      setError(apiError);
      return;
      // show error
    }
    
    appDataManager.clearSavedAppData()
    // close account view 
    manager.updateCurrentform(null)
    appDataManager.gotoShoppingApp(location.pathname)
   };

  return (
    <Stack alignItems={"center"}>
      <Typography
        variant="headline"
        noWrap
        component="div"
        sx={{
          color: "primary.dark",
         
        }}
      >
        You are logged in {manager.getUserprofile()?.firstname}
      </Typography>
      <LoadingButton
        size="large"
        onClick={handleLogout}
        sx={{
            
            width: "90%",
            fontWeight: "500",
            mt: {
                xs: "0.5rem !important",
                md: "1.5rem !important",
              },
          }}
        
        loading={loading}
        fullWidth
        variant="contained"
      >
        log out
      </LoadingButton>
      <DCError error={error} onErrorDisplayed={() => setError(null)} />
    </Stack>
  );
};

export default LoggedinView;
