import { AuthUser } from "../../../Models/User";

interface PersonalInfo {

}

interface Profile {

}

export interface UserAccount {
    auth: AuthUser
    personalInfo: PersonalInfo
    profile: Profile
}

export enum UserAccountActionType {
    addAuth = "AddAuth", // add authentication details
    updateAccount = "UpdateAccount",
    updateEmail = "UpdateEmail"
}

export interface UserAccountAction {
    type: UserAccountActionType
    payload: AuthUser | string
}