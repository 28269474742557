import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { PCTab, ShoppingApp } from '../../../routes'

const BagParent = () => {

  const location = useLocation()
   
  useEffect(()=>{
    ShoppingApp.setLastVisitedForRoute(PCTab.bag, location.pathname+location.search)
    
  }, [location])
  
  return (
   <Outlet />
  )
}

export default BagParent