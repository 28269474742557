import {
  PricesRangeDetails,
  AccessoryDetails,
  CategoryDetails,
  ColorDetails,
  SizeDetails,
  SubcategoryDetails,
} from "./ProductOptions";

export interface LookupTables {
  category: CategoryDetails[];
  color: ColorDetails[];
  accessory: AccessoryDetails[];
  size: SizeDetails[];
  priceRanges: PricesRangeDetails[]
  subcategory: Map<string, SubcategoryDetails[]>;
}

export const defaultLookupTables: LookupTables = {
  category: [],
  color: [],
  accessory: [],
  size: [],
  priceRanges: [],
  subcategory: new Map([]),
};

export enum LookupTable {
  category = "category",
  color = "color",
  size = "size",
  accessory = "accessory",
  subcategory = "subcategory",
  price = "price"
}

export interface LookupTableModel {
  lookup_table_id: number;
  title: string;
  categories_details: CategoryDetails[];
  accessories_details: AccessoryDetails[];
  colors_details: ColorDetails[];
  sizes_details: SizeDetails[];
  subcategories_details: SubcategoryDetails[];
  price_ranges_details: PricesRangeDetails[];
}

export interface PromoProduct {
  is_active: boolean
  title: string
  photo_url: {
    small: string
    large: string
  }
  headline: string 
  banner_heading: string
  message: string 
  expiration_date: number 
  expiration_text: string 
}
