import React, { ReactNode, useRef, useState } from "react";
import { ProductDetailContext } from "../../contexts/ProductDetailContext";
import { AuthProductList, Product, ProductVariant } from "../../Models/Product";
import { ProductDetail } from "../../Models/ProductDetail";
import { RequestStatus,   ResultSuccess, ResultType } from "../../Models/Result";
import { AuthReview, Review, ReviewData, ReviewFormData } from "../../Models/Review";
import { AuthUser } from "../../Models/User";
import supabaseAPIManager, {
  APIError,
} from "../../networking/SupabaseAPIManager/SupabaseAPIManager";

interface ProductDetailProviderProps {
  children: ReactNode;
}

const ProductDetailProvider = ({ children }: ProductDetailProviderProps) => {
  const [productReviews, setProductReviews] = useState<Review[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [reviewRequestStatus, setReviewRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);
   
  const [favoriteStatus, setFavoriteStatus] = useState<
  RequestStatus | APIError
>(RequestStatus.Idle);
  

  const [editReviewStatus, setEditReviewStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);
  const [isUpdatingReviews, setIsUpdatingReviews] = useState(false);
  const [selectedProductStatus, setSelectedProductStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);

   

  let canLoadMoreReviews = useRef<boolean>(true);
  const apiKey = process.env.REACT_APP_SUPABASE_API_KEY;

  const clearProductReviews = () => setProductReviews([])
  const clearSelectedProduct = () => {
    setProductReviews([])
    setSelectedProduct(null)
  }

  const deleteReview = async (user: AuthUser, reviewId: string): Promise<AuthReview | null> => {
    
    if (!apiKey || editReviewStatus === RequestStatus.Loading) {
      return null;
    }

    setEditReviewStatus(RequestStatus.Loading)

    const queryParams = new Map<string, string>([["review_id", `eq.${reviewId}`]])

    const result = await supabaseAPIManager.deleteReview(apiKey, user.access_token, user.refresh_token, queryParams)
    if (result.type === ResultType.Failure) {
      setEditReviewStatus(result.error)
      return null
    }
    
     
    setEditReviewStatus(RequestStatus.Idle)

    return result.value

  }

  const favoriteVariant = async (productId: string, variantId: string, user: AuthUser): Promise<AuthUser | null> => {
    
    if (!apiKey || selectedProduct == null) {return null}

    const favoritedResult = await supabaseAPIManager.addProductToFavorites(productId, variantId, apiKey, user.user.id, user.access_token, user.refresh_token)
    if (favoritedResult.type === ResultType.Failure) {
       setFavoriteStatus(favoritedResult.error)
      return null
    }

    const {favoriteItem, authUser} = favoritedResult.value

    const idx =  selectedProduct.productDetails.findIndex(variant => variant.productDetailId === favoriteItem.productDetailId)
    
    const updatedVariant: ProductVariant = {
      ...selectedProduct.productDetails[idx],
      favoriteId: favoriteItem.favoriteId
    }

    
    if (idx > -1) {
        setSelectedProduct({
          ...selectedProduct,
          productDetails: [...selectedProduct.productDetails.slice(0, idx), updatedVariant, ...selectedProduct.productDetails.slice(idx+1)]
        })
    }

    return authUser
  }

  const loadReviewsForProduct = async (
    productId:  string,
    isReloading?: boolean,
    variantId?: string,
    userId?: string,
   
  ): Promise<void> => {
    if (!apiKey || reviewRequestStatus === RequestStatus.Loading) {
      return;
    }
    
   
    setReviewRequestStatus(RequestStatus.Loading);

    const queryParams = new Map<string, string>([
      ["product_id", `eq.${productId}`],
    ]);
    if (userId) {
      queryParams.set("user_id", `eq.${userId}`);
    }

    if (variantId) {
      queryParams.set("product_detail_id", `eq.${variantId}`);
    }
     
    const startIdx = isReloading !== undefined && isReloading ? 0 : productReviews.length
    const result = await supabaseAPIManager.getProductReviews(
      apiKey,
      queryParams,
      startIdx
      //isReloading ? 0 : productReviews.length
    );
    if (result.type === ResultType.Failure) {
      setReviewRequestStatus(result.error);
      return;
    }

   
    //debugger
    if (startIdx === 0) {
        setProductReviews(result.value)
    } else {
      setProductReviews((prev) => [...prev, ...result.value]);
    }
    canLoadMoreReviews.current =
      result.value.length === supabaseAPIManager.reviewsPerPg;
    setReviewRequestStatus(RequestStatus.Idle);
  };

  /*
  const loadProductDetailData =  async (productId: string, user: AuthUser | null): Promise<AuthUser | null> => {

    if (!apiKey || productDetailDataStatus === RequestStatus.Loading) {
      return null;
    }

    let error = APIError.default
    await loadSelectedProduct(productId, user)
    error = selectedProductStatus  as APIError
    if (error.errorDescription) {
        setProductDetailDataStatus(error)
        return null
    }

    await loadReviewsForProduct(productId)
    error = reviewRequestStatus  as APIError
    if (error.errorDescription) {
        setProductDetailDataStatus(error)
        return null
    }

    return null
  }*/

  const loadSelectedProduct = async (productId: string, user: AuthUser | null): Promise<AuthUser | null> => {
   
    if (!apiKey || selectedProductStatus === RequestStatus.Loading) {
      return null;
    }

    setSelectedProductStatus(RequestStatus.Loading);


    const filters = new Map<string, string>([
      ["select", "*,product_details(*)"],
      ["product_details.is_available", "eq.true"],
      ["product_id", `eq.${productId}`]
    ]);
     
    
    const result = await supabaseAPIManager.getProductDetailForId(
      filters,
      apiKey
    )

    if (result.type === ResultType.Failure) {
      setSelectedProductStatus(result.error);
      return null;
    }

    let refreshedAuthuser: AuthUser | null = null

    if (user) {
      refreshedAuthuser = await updateWishlistedStatusOfVariants(result, user, apiKey)

      /*
      let queryItems = new Map<string, string>([["user_id", `${user.user.id}`]])
      let accessToken = user.access_token
      let refreshToken = user.refresh_token
       
     for (let product of result.value.productsList) {
       queryItems.set("product_id", `${product.productId}`)
       const favoritesResult = await supabaseAPIManager.getFavorites(apiKey, accessToken, refreshToken, queryItems, undefined)
       if (favoritesResult.type === ResultType.Success) {

           // find each favorited variant amongst the product's variant
           for (let favorited of favoritesResult.value.favoriteItems) {
             const foundIdx = product.productDetails.findIndex((variant)=> variant.productDetailId === favorited.productDetailId)
             if (foundIdx > -1) {
                 product.productDetails[foundIdx].favoriteId = favorited.favoriteId
                
             }
           }

           if (favoritesResult.value.authUser) {
             accessToken = favoritesResult.value.authUser.access_token
             refreshToken = favoritesResult.value.authUser.refresh_token
             refreshedAuthuser = favoritesResult.value.authUser
           }
       } 
     }*/


   }
    

    if (result.value.productsList.length > 0) {
      setSelectedProduct(result.value.productsList[0]);
      //console.dir(result.value.productsList[0])
    } else {
      //setSelectedProduct(null);
    }

    setSelectedProductStatus(RequestStatus.Idle);

    return refreshedAuthuser;
  }



  const updateWishlistedStatusOfVariants = async (result: ResultSuccess<AuthProductList>, user: AuthUser, apiKey: string): Promise<AuthUser | null> => {

    let queryItems = new Map<string, string>([["user_id", `${user.user.id}`]])
    let accessToken = user.access_token
    let refreshToken = user.refresh_token
    let refreshedAuthuser: AuthUser | null = null

   for (let product of result.value.productsList) {
     queryItems.set("product_id", `${product.productId}`)
     const favoritesResult = await supabaseAPIManager.getFavorites(apiKey, accessToken, refreshToken, queryItems, undefined)
     if (favoritesResult.type === ResultType.Success) {

         // find each favorited variant amongst the product's variant
         for (let favorited of favoritesResult.value.favoriteItems) {
           const foundIdx = product.productDetails.findIndex((variant)=> variant.productDetailId === favorited.productDetailId)
           if (foundIdx > -1) {
               product.productDetails[foundIdx].favoriteId = favorited.favoriteId
              
           }
         }

         if (favoritesResult.value.authUser) {
           accessToken = favoritesResult.value.authUser.access_token
           refreshToken = favoritesResult.value.authUser.refresh_token
           refreshedAuthuser = favoritesResult.value.authUser
         }
     } 
   }

   return refreshedAuthuser
  }

  const postReview = async (user: AuthUser, productId: string, reviewFormData: ReviewFormData, variantId?: string): Promise<AuthReview | null> => {
    
    if (!apiKey || editReviewStatus === RequestStatus.Loading) { return null}
    const rating = reviewFormData.rating

    if (!rating) return null
    setEditReviewStatus(RequestStatus.Loading)

    const reviewData: ReviewData = {
       title: reviewFormData.title,
       description: reviewFormData.description,
       rating,
       userId: user.user.id,
       productDetailId: variantId,
       productId
    }

    const result = await supabaseAPIManager.addProductReview(apiKey, user.access_token, user.refresh_token, reviewData)
    if (result.type === ResultType.Failure) {
      setEditReviewStatus(result.error)
      return null
    }
    
    
    setEditReviewStatus(RequestStatus.Idle)

    return result.value
  
  }

  const updateReview = async (user: AuthUser, reviewId: string, reviewFormData: ReviewFormData): Promise<AuthReview | null> => {
    if (!apiKey || editReviewStatus === RequestStatus.Loading) { return null}
    const rating = reviewFormData.rating
    if (!rating) return null
    setEditReviewStatus(RequestStatus.Loading)

    const queryParams = new Map<string, string>([["review_id", `eq.${reviewId}`]])

    const reviewData: ReviewData = {
       title: reviewFormData.title,
       description: reviewFormData.description,
       rating,
    }

    const result = await supabaseAPIManager.updateProductReview(apiKey, user.access_token, user.refresh_token, queryParams, reviewData)
    if (result.type === ResultType.Failure) {
      setEditReviewStatus(result.error)
      return null
    }
    
   // console.dir(result.value.review)
   setEditReviewStatus(RequestStatus.Idle)

    return result.value
  
  }

  const updateProductDetailsForId = async (): Promise<void> => {

    if (!apiKey || selectedProduct == null) {
      return;
    }

    const filters = new Map<string, string>([
      ["select", "*,product_details(*)"],
      ["product_details.is_available", "eq.true"],
      ["product_id", `eq.${selectedProduct.productId}`]
    ]);
     
    
    const result = await supabaseAPIManager.getProductDetailForId(
      filters,
      apiKey
    )

    if (result.type === ResultType.Failure) {
      return;
    }
    

    if (result.value.productsList.length > 0) {
       
      setSelectedProduct({
        ...selectedProduct,
        avgRating: result.value.productsList[0].avgRating,
        reviewCount: result.value.productsList[0].reviewCount || selectedProduct.reviewCount
      })
    }  

     
    return;
  }

  const unFavoriteVariant = async (productId: string, favoriteId: string, user: AuthUser): Promise<AuthUser | null> => {
    
    if (!apiKey || selectedProduct == null) {return null}

    let queryItems = new Map<string, string>([["favorite_id", `eq.${favoriteId}`]])
    const favoritedResult = await supabaseAPIManager.removeProductFromFavorites(apiKey, user.access_token, user.refresh_token, queryItems)
    if (favoritedResult.type === ResultType.Failure) {
      setFavoriteStatus(favoritedResult.error)
      return null
    }

    

    const {favoriteItem, authUser} = favoritedResult.value

    const idx =  selectedProduct.productDetails.findIndex(variant => variant.productDetailId === favoriteItem.productDetailId)
    
    const updatedVariant: ProductVariant = {
      ...selectedProduct.productDetails[idx],
      favoriteId: null
    }
    if (idx > -1) {
        setSelectedProduct({
          ...selectedProduct,
          productDetails: [...selectedProduct.productDetails.slice(0, idx), updatedVariant, ...selectedProduct.productDetails.slice(idx+1)]
        })
    }

    return authUser
  }


  const productDetail: ProductDetail = {
    //deleteReviewStatus,
    canLoadMoreReviews: canLoadMoreReviews.current,
    editReviewStatus,
    favoriteStatus,
    isUpdatingReviews,
    setIsUpdatingReviews,
    reviewRequestStatus, 
    selectedProductStatus,
    productReviews,
    selectedProduct,
    clearProductReviews,
    clearSelectedProduct,
    favoriteVariant,
    loadReviewsForProduct,
    deleteReview,
    loadSelectedProduct,
    unFavoriteVariant,
    updateReview,
    updateProductDetailsForId,
    postReview
    
  };

  return (
    <ProductDetailContext.Provider value={productDetail}>
      {children}
    </ProductDetailContext.Provider>
  );
};

export default ProductDetailProvider;
