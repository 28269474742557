/**
 * Provides model to all screen in the inventory route
 */

import { ReactNode, useCallback, useRef, useState } from "react";

import { InventoryContext } from "../contexts/InventoryContext";
import { ProductDialogContext } from "../../../contexts/ProductDialogContext";
import { RequestStatus, Result, ResultType } from "../../../Models/Result";
import supabaseAPIManager, {
  APIError,
} from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";

import cloudinaryAPIManager, {
  CloudinaryAPIError,
} from "../../../networking/CloudinaryAPIManager/CloudinaryAPIManager";
import {
  AuthUploadedAsset,
  ResourceType,
  UploadedAsset,
} from "../../../Models/Cloudinary";
//import { ProductFormData  } from "../DCSellerApp/DCInventory/model/ProductDetail";
import { AuthDataObj, DataObj } from "../../../Models/DataObj";

import {
  createPhotoObj,
  deepCopy,
  formdataForVariants,
  
  getPhotos,
  getValueOf,
  getValuesOf,
} from "../../../utils";
import { AuthUser } from "../../../Models/User";
import { ProductFormData } from "../Models/ProductFormData";
import {
  AuthDeleteProductStatus,
  AuthProduct,
  AuthProductList,
  AuthProductVariantList,
  
  Photo,
  Photos,
  Product,
  ProductDialog,
  ProductVariant,
} from "../../../Models/Product";
import { AuthInventoryList, Inventory } from "../Models/Inventory";
import inventoryDataManager from "../Models/InventoryDataManager";
import process from "process";
import { InventoryOrder } from "../../../Models/InventoryOrder";
import {
  productDetailsValidationSchema,
  productVariantValidationSchema,
} from "../Models/editProductValidationSchema";
 
interface InventoryDataProviderProps {
  children: ReactNode;
}

const InventoryDataProvider = (props: InventoryDataProviderProps) => {
  const { children } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [inventoryOrders, setInventoryOrders] = useState<InventoryOrder[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [inventoryRequestStatus, setInventoryRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);

  const [inventoryId, setInventoryId] = useState<string | null>(null);
  let productsAvailable = useRef<boolean>(true);
  let hasMoreInventoryOrders = useRef<boolean>(false);
  //const [inventoryId, setInventoryId] = useState<string | null>(null);
  const apiKey = process.env.REACT_APP_SUPABASE_API_KEY;
  let { REACT_APP_CLOUDINARY_API_KEY } = process.env;

  const isCreated = (): boolean => (inventoryId ? true : false);

  const getProductWithId = (id: string): Product | undefined => {
    let found = products.find((product) => product.productId === id);
    return found ? deepCopy(found) : found;
  };

  /**
   * whether all details of the product have been specified
   * @param product
   * @returns true or false
   */
  const isProductDetailsComplete = (product: Product): boolean => {
    const productFormValues: ProductFormData = {
      stockQuantity: getValueOf(product.stockQuantity),
      wholesaleCost: getValueOf(product.wholesaleCost),
      sellingPrice: getValueOf(product.sellingPrice),
      description: product.description,
      categoryId: product.categoryId,
      title: product.title,
      subcategoryId: product.subcategoryId || "",
      colors: getValuesOf(product.colors),
      sizes: getValuesOf(product.sizes),
      accessories: getValuesOf(product.accessories),
      photos: getPhotos(product),
      variants: formdataForVariants(product.productDetails ?? []),
    };

    const isVariantAvailable = productVariantValidationSchema.isValidSync(
      productFormValues,
      {
        strict: true,
      }
    );

    const isProductDataValid = productDetailsValidationSchema.isValidSync(
      productFormValues,
      {
        strict: true,
      }
    );

    //debugger
    return isVariantAvailable && isProductDataValid;
  };

  const getProductDetailForId = async (
    productId: string,
    auth: AuthUser
  ): Promise<AuthProduct | null> => {
    let result: Result<AuthProductList, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };
    if (!apiKey || !inventoryId) {
      return null;
    }

    const filters = new Map<string, string>([
      ["inventory_id", `eq.${inventoryId}`],
    ]);
    if (productId) {
      filters.set("product_id", `eq.${productId}`);
    }

    result = await supabaseAPIManager.getProductDetailForId(filters, apiKey);

    // handle error if array is empty
    if (result.type === ResultType.Failure) {
      return null;
    }

    setSelectedProduct(result.value.productsList[0]);

    return {
      authUser: result.value.authUser,
      product: result.value.productsList[0],
    };
  };

  /**
   * returns a products in the inventory
   */
  const getProducts = (): Product[] => {
    return deepCopy(products);
  };
  /*
  const getProductColors = async (): Promise<APIError | ProductColor[]> => {
    if (!apiKey) {
      return APIError.default;
    }
    const result = await supabaseAPIManager.getColorRows(apiKey);

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    return result.value;
  };*/

  const formatValueOf = (value: number): string => {
    return value >= 0 ? value.toString() : "N/A";
  };

  const updateDetailsForProduct = async (
    productId: string,
    newDetails: Map<string, any>,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthProduct> => {
    if (!apiKey) {
      return APIError.default;
    }

    const queryItems = new Map([["product_id", productId]]);
    const updateResult = await supabaseAPIManager.updateProduct(
      queryItems,
      newDetails,
      apiKey,
      accessToken,
      refreshToken
    );
    if (updateResult.type === ResultType.Failure) {
      return updateResult.error;
    }

    // save product variants since updat

    const idx = products.findIndex(
      (item) => item.productId === updateResult.value.product.productId
    );

    const updatedProduct: Product = {
      ...updateResult.value.product,
      productDetails: products[idx].productDetails,
    };

    if (idx < 0) {
      return APIError.default;
    }

    setProducts([
      ...products.slice(0, idx),
      updatedProduct,
      //updateResult.value.product,
      ...products.slice(idx + 1),
    ]);

    return updateResult.value;
  };

  const updateSelectedProduct = async (
    newDetails: Map<string, any>,
    accessToken: string,
    refreshToken: string
  ): Promise<AuthUser | null> => {
    if (!apiKey || !selectedProduct) {
      return null;
    }

    const queryItems = new Map([["product_id", selectedProduct.productId]]);
    const updateResult = await supabaseAPIManager.updateProduct(
      queryItems,
      newDetails,
      apiKey,
      accessToken,
      refreshToken
    );
    if (updateResult.type === ResultType.Failure) {
      return null;
    }

    // save product variants since updat
    //debugger;
    const updatedProduct = {
      ...updateResult.value.product,
      productDetails: selectedProduct.productDetails,
    };
    setSelectedProduct(updatedProduct);
    // setSelectedProduct({
    //   ...selectedProduct,

    // })
    const foundIdx = products.findIndex(
      (product) => product.productId === updateResult.value.product.productId
    );
    if (foundIdx > -1) {
      setProducts([
        ...products.slice(0, foundIdx),
        updatedProduct,
        ...products.slice(foundIdx + 1),
      ]);
    }

    return updateResult.value.authUser;
  };

  const folderPath = (productId: string): string | null => {
    if (!inventoryId) {
      return null;
    }

    return `inventories/${inventoryId}/${productId}`;
  };

  // intents
  const toggleNewProductDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const addProduct = async (
    title: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthProduct> => {
    //const addProduct = async (title: string): Promise<Result<Product , APIError>> => {

    if (!apiKey || !inventoryId) {
      return APIError.default;
    }
    // create product in db
    const result = await supabaseAPIManager.addProduct(
      title,
      inventoryId,
      apiKey,
      accessToken,
      refreshToken
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    const { product: addedProduct } = result.value as AuthProduct;

    setProducts([addedProduct, ...products]);

    return result.value;
  };

  /**
   * Deletes a product photo from cloudinary and its url from the database
   * @param productId
   * @param publicId
   * @param accessToken
   * @param refreshToken
   * @returns Promise that resolves to an error or AuthDataObj
   */
  const deletePhotoForProduct = async (
    productId: string,
    publicId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthDataObj> => {
    if (!apiKey || !inventoryId || !REACT_APP_CLOUDINARY_API_KEY) {
      return APIError.default;
    }

    // update product with new photo details
    const product = getProductWithId(productId);
    if (!product) {
      return APIError.default;
    }

    const updatedPhotosData = createPhotoObj(product.photos, publicId, null);
    const someMap = new Map<string, Photos>([["photos", updatedPhotosData]]);

    const result = await updateDetailsForProduct(
      productId,
      someMap,
      accessToken,
      refreshToken
    );
    const apiError = result as APIError;
    if (apiError.errorDescription) {
      return apiError;
    }

    // delete product photos from cloudinary
    const photoDataToDelete = product.photos[publicId as keyof Photos];
    //const pathToFolder = `${folderPath(productId)}/`;
    /*
    const destroyResult = await cloudinaryAPIManager.destroyPhotos(
      photoDataToDelete.publicId
        ? [photoDataToDelete.publicId]
        : [`${pathToFolder}${publicId}`],
      REACT_APP_CLOUDINARY_API_KEY,
      ResourceType.image
    );*/

    if (!photoDataToDelete.publicId) {
      return apiError;
    }

    const destroyResult = await cloudinaryAPIManager.destroyPhotos(
      [photoDataToDelete.publicId],
      REACT_APP_CLOUDINARY_API_KEY,
      ResourceType.image
    );

    const { authUser: user } = result as AuthProduct;

    if (destroyResult.type === ResultType.Success) {
      return {
        data: destroyResult.value,
        authUser: user,
      };
    }

    return {
      authUser: user,
    }; //APIError.default;
  };

  /**
   * Deletes a product photo from cloudinary and its url from the database
   * @param productId
   * @param publicId
   * @param accessToken
   * @param refreshToken
   * @returns Promise that resolves to an error or AuthDataObj
   */
  const deletePhotoForVariant = async (
    variantId: string,
    publicId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthDataObj> => {
    if (
      !apiKey ||
      !inventoryId ||
      !REACT_APP_CLOUDINARY_API_KEY ||
      !selectedProduct
    ) {
      return APIError.default;
    }

    // update product with new photo details
    const variant = selectedProduct?.productDetails?.find(
      (item) => item.productDetailId === variantId
    ); //getProductWithId(productId);
    if (!variant) {
      return APIError.default;
    }

    const updatedPhotosData = createPhotoObj(
      deepCopy(variant.photos),
      publicId,
      null
    );
    const someMap = new Map<
      string,
      {
        photo1: Photo;
      }
    >([["photos", updatedPhotosData]]);

    const queryItems = new Map<string, string>([
      ["product_detail_id", variantId],
      ["product_id", selectedProduct!.productId],
    ]);

    const result = await supabaseAPIManager.updateProductDetails(
      queryItems,
      someMap,
      apiKey,
      accessToken,
      refreshToken
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    // Invalidate product's is_published status
    const productQueryParams = new Map<string, any>([
      ["product_id", selectedProduct.productId],
    ]);
    const valuesForUpdate = new Map<string, any>([["is_published", false]]);

    const productUpdateResult = await supabaseAPIManager.updateProduct(
      productQueryParams,
      valuesForUpdate,
      apiKey,
      accessToken,
      refreshToken
    );

    if (productUpdateResult.type === ResultType.Failure) {
      return productUpdateResult.error;
    }

    // delete product photos from cloudinary
    const photoDataToDelete =
      variant.photos[
        publicId as keyof {
          photo1: Photo;
        }
      ];

    if (!photoDataToDelete.publicId) {
      return APIError.default;
    }

    const destroyResult = await cloudinaryAPIManager.destroyPhotos(
      [photoDataToDelete.publicId],
      REACT_APP_CLOUDINARY_API_KEY,
      ResourceType.image
    );

    const { authUser: user, variant: updatedVariant } = result.value;

    setSelectedProduct((prev) => {
      return prev
        ? {
            ...prev,
            isPublished: productUpdateResult.value.product.isPublished,
            productDetails:
              prev.productDetails?.map((detail) =>
                detail.productDetailId === updatedVariant.productDetailId
                  ? updatedVariant
                  : detail
              ) ?? [],
          }
        : null;
    });

    if (destroyResult.type === ResultType.Success) {
      return {
        data: destroyResult.value,
        authUser: user,
      };
    }

    return {
      authUser: user,
    }; //APIError.default;
  };

  /**
   * Deletes product data from data base and its corresponding photos from cloudinary
   * @param productId
   * @param partner
   * @returns An object that contains status of the delete operation and an authentication object if accessToken was updated.
   *  If accessToken was not update the authentication object is null
   */
  const deleteProduct = async (
    productId: string,
    partner: AuthUser
  ): Promise<APIError | AuthDeleteProductStatus> => {
    if (!apiKey || !inventoryId || !REACT_APP_CLOUDINARY_API_KEY) {
      return APIError.default;
    }

    // update product with new photo details
    const product = getProductWithId(productId);
    const productIdx = products.findIndex(
      (item) => item.productId === product?.productId
    );

    if (!product || productIdx < 0) {
      return APIError.default;
    }

    const queryItems = new Map<string, string>([["product_id", productId]]);

    // remove previously saved variants
    const removeResult = await supabaseAPIManager.deleteProductDetailsForId(
      productId,
      apiKey,
      partner.access_token,
      partner.refresh_token
    );

    if (removeResult.type === ResultType.Failure) {
      return removeResult.error;
    }

    const deleteResult = await supabaseAPIManager.deleteProduct(
      queryItems,
      apiKey,
      partner.access_token,
      partner.refresh_token
    );

    // debugger;
    if (deleteResult.type === ResultType.Failure) {
      return deleteResult.error;
    }

    let photoPublicIds: string[] = [];
    const pathToFolder = `${folderPath(productId)}/`;
    Object.entries(product.photos).forEach(([key, value]) => {
      if (value) {
        photoPublicIds.push(`${pathToFolder}${key}`);
      }
    });

    // delete product photos from cloudinary
    let destroyResult: Result<DataObj, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    if (photoPublicIds.length === 0) {
      destroyResult = { type: ResultType.Success, value: { result: "ok" } };
    } else {
      let tempResult = await cloudinaryAPIManager.destroyPhotos(
        photoPublicIds,
        REACT_APP_CLOUDINARY_API_KEY,
        ResourceType.image
      );
      if (tempResult.type === ResultType.Success) {
        destroyResult = tempResult; //{type: ResultType.Success, value: {result: "ok"}}
      }
    }

    /// update model

    setProducts((prev) => [
      ...prev.slice(0, productIdx),
      ...prev.slice(productIdx + 1),
    ]);

    if (destroyResult.type === ResultType.Success) {
      return deleteResult.value;
    }

    return deleteResult.value;
    //return APIError.default;
  };

  // const updateDetailsForProduct = async (  productId: string,
  //   formData: ProductFormData,
  //   accessToken: string,
  //   refreshToken: string
  // ): Promise<AuthProduct | APIError> => {

  // }

  /**
   * Updates a product with data collected from a form
   * @param productId productId
   * @param formData data collected from a from
   * @returns  An object that contains the updated product and an authentication object if accessToken was updated.
   *  If accessToken was not update the authentication object is null
   */
  const updateProduct = async (
    productId: string,
    formData: ProductFormData,
    accessToken: string,
    refreshToken: string
  ): Promise<AuthProduct | APIError> => {
    if (!apiKey || !inventoryId) {
      return APIError.default;
    }

    let colors: DataObj = {};
    let sizes: DataObj = {};
    let accessories: DataObj = {};
    let newDetails = new Map<string, any>();

    // populate corresponding temporary object
    formData.colors.forEach(
      (color, idx) => (colors[`color${idx + 1}`] = color)
    );
    formData.sizes.forEach((size, idx) => (sizes[`size${idx + 1}`] = size));
    formData.accessories.forEach(
      (accessory, idx) => (accessories[`accessory${idx + 1}`] = accessory)
    );

    Object.entries(formData).forEach(([key, value]) => {
      if (
        !["photos", "colors", "sizes", "accessories", "variants"].includes(key)
      ) {
        newDetails.set(key, value);
      } else if (key === "accessories") {
        newDetails.set(key, accessories);
      } else if (key === "colors") {
        newDetails.set(key, colors);
      } else if (key === "sizes") {
        newDetails.set(key, sizes);
      }
    });

    newDetails.set("inventoryId", inventoryId);

    let queryParams = new Map([["product_id", productId]]);
    let variants: ProductVariant[] = [];
    for (let variant of formData.variants) {
      queryParams.set("product_detail_id", variant.id);
      let newProductDetails = new Map<string, any>();

      Object.entries(variant).forEach(([key, value]) => {
        if (
          key === "isAvailable" ||
          key === "sellingPrice" ||
          key === "stockQuantity"
        ) {
          newProductDetails.set(key, value);
        }

        
      });


      const detailsResult = await supabaseAPIManager.updateProductDetails(
        queryParams,
        newProductDetails,
        apiKey,
        accessToken,
        refreshToken
      );

      if (detailsResult.type === ResultType.Failure) {
        return detailsResult.error;
      }

      variants.push(detailsResult.value.variant);
      console.dir(detailsResult);
    }

    // remove id queryparam so that it's not affect request to the product table
    queryParams.delete("product_detail_id");

    const result = await supabaseAPIManager.updateProduct(
      queryParams,
      newDetails,
      apiKey,
      accessToken,
      refreshToken
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    const idx = products.findIndex(
      (item) => item.productId === result.value.product.productId!
    );

    if (idx < 0) {
      return APIError.default;
    }

    const updatedProductWithDetails: Product = {
      ...result.value.product,
      productDetails: variants,
    };

    const authProduct: AuthProduct = {
      authUser: result.value.authUser,
      product: updatedProductWithDetails,
    };

    setSelectedProduct({
      ...updatedProductWithDetails,
    });

    setProducts(
      products.map((product) => {
        if (product.productId === result.value.product.productId) {
          //return result.value.product;
          return updatedProductWithDetails;
        } else {
          return product;
        }
      })
    );

    return authProduct; //result.value;
  };

  const uploadPhoto = async (
    file: File,
    storagePath: string,
    productId: string,
    publicId: string
  ): Promise<CloudinaryAPIError | UploadedAsset> => {
    if (!REACT_APP_CLOUDINARY_API_KEY || !inventoryId) {
      return CloudinaryAPIError.default;
    }

    const folderPath = storagePath; //`inventories/${inventoryId}/${productId}/`;
    const transformation = "q_30,f_webp";
    // get signature
    const signatureResult = await cloudinaryAPIManager.generateSignature({
      public_id: publicId,
      folder: folderPath,
      eager: transformation,
      //eager: "c_scale,w_400,e_make_transparent"
    });
    if (signatureResult.type === ResultType.Failure) {
      return signatureResult.error;
    }
    // upload to cloudinary
    const uploadResult = await cloudinaryAPIManager.upload(
      file,
      signatureResult.value,
      folderPath,
      publicId,
      //CLOUDINARY_API_KEY
      REACT_APP_CLOUDINARY_API_KEY,
      transformation
    );
    if (uploadResult.type === ResultType.Failure) {
      return uploadResult.error;
    }

    console.dir(uploadResult);

    // return appropriate value
    return uploadResult.value;
    // return value
  };

  const loadProductsForInventory = async (
    auth: AuthUser | null,
    productId?: string,
    rangeStart?: number
  ): Promise<APIError | boolean> => {
    
    if (!apiKey || !inventoryId || !auth) {
      return APIError.default;
    }

    
    const filters = new Map<string, string>([
      ["inventory_id", `eq.${inventoryId}`],
    ]);
    if (productId) {
      filters.set("product_id", `eq.${productId}`);
    }

    const result = await supabaseAPIManager.getProductDetailForId(
      filters,
      apiKey,
      rangeStart
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    productsAvailable.current =
      result.value.productsList.length === supabaseAPIManager.productsPerPage;
    setProducts([...products, ...result.value.productsList]);

    return true;
  };

  const loadInventoryOrdersForInventory = async (
    auth: AuthUser | null,
    inventoryOrderId?: string,
    rangeStart?: number
  ): Promise<AuthUser | null> => {
    if (!apiKey || !inventoryId || !auth) {
      setInventoryRequestStatus(APIError.default);
      return null;
    }

    const filters = new Map<string, string>([]);
    if (inventoryOrderId) {
      filters.set("inventory_order_id", `eq.${inventoryOrderId}`);
    }

    setInventoryRequestStatus(RequestStatus.Loading);
    const result = await supabaseAPIManager.getInventoryOrders(
      filters,
      apiKey,
      auth.access_token,
      auth.refresh_token,
      rangeStart
    );

    if (result.type === ResultType.Failure) {
      setInventoryRequestStatus(result.error);
      return null;
    }

    setInventoryRequestStatus(RequestStatus.Idle);

    hasMoreInventoryOrders.current =
      result.value.inventoryOrdersList.length ===
      supabaseAPIManager.inventoryOrdersPerPage;
    setInventoryOrders([
      ...inventoryOrders,
      ...result.value.inventoryOrdersList,
    ]);

    return result.value.authUser;
  };

  const getInventoryForPartner = async (
    userId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<APIError | AuthInventoryList> => {
    if (!apiKey) {
      return APIError.default;
    }
    const queryItems = new Map([["user_id", userId]]);
    const result = await supabaseAPIManager.getInventoryRow(
      queryItems,
      accessToken,
      refreshToken,
      apiKey
    );

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    const { inventoryList } = result.value;
    if (inventoryList.length === 0) {
      return APIError.default;
    }
    setInventoryId(inventoryList[0].inventoryid);

    return result.value;
  };

  const performProductUpdate = (product: Product): boolean => {
    const updatedProducts = products.map(tempProduct => tempProduct.productId === product.productId ? product : tempProduct)
    setProducts(updatedProducts)
    return true
  }

  const updateProductPhoto = async (
    file: File,
    productId: string,
    publicId: string,
    storagePath: string,
    accessToken: string,
    refreshToken: string,
    variantId?: string
  ): Promise<APIError | AuthUploadedAsset> => {
    // uploadPhoto
    //debugger;

    const uploadResult = await uploadPhoto(
      file,
      storagePath,
      productId,
      publicId
    );
    const error = uploadResult as CloudinaryAPIError;
    if (error.errorDescription) {
      return APIError.default;
    }

    if (!apiKey) {
      return APIError.default;
    }

    const product = getProductWithId(productId);
    const asset = uploadResult as UploadedAsset;
    if (!product) {
      return APIError.default;
    }

    /* invalidate product's published status
    const productQueryParams = new Map<string, any>([["product_id", productId]])
    const valuesForUpdate = new Map<string, any>([["is_published", false]])
    
    const productUpdateResult = await supabaseAPIManager.updateProduct(
      productQueryParams,
      valuesForUpdate,
      apiKey,
      accessToken,
      refreshToken
      )

    if (productUpdateResult.type === ResultType.Failure) {
      return productUpdateResult.error
    }*/

    // debugger
    if (variantId && selectedProduct) {
      const variant = selectedProduct.productDetails?.find(
        (item) => item.productDetailId === variantId
      );
      if (!variant) {
        return APIError.default;
      }
      let queryParams = new Map([
        ["product_id", productId],
        ["product_detail_id", variant.productDetailId],
      ]);

      // debugger

      try {
        const tempPhotosObj = createPhotoObj(variant.photos, publicId, asset);
        const details = new Map<string, Photos>([["photos", tempPhotosObj]]);
        let updateResult = await supabaseAPIManager.updateProductDetails(
          queryParams,
          details,
          apiKey,
          accessToken,
          refreshToken
        );

        if (updateResult.type === ResultType.Failure) {
          return updateResult.error;
        }

        const { variant: updatedVariant, authUser } = updateResult.value;

        setSelectedProduct((prev) => {
          return prev
            ? {
                ...prev,

                productDetails:
                  prev.productDetails?.map((detail) =>
                    detail.productDetailId === updatedVariant.productDetailId
                      ? updatedVariant
                      : detail
                  ) ?? [],
              }
            : null;
        });

        return {
          authUser,
          asset,
        };
      } catch (error) {
        let someError = error as Error;
        console.error(someError.message);
        let apiError = APIError.default;

        // apiError.update("unexpected", (error as Error).msg)
        return apiError;
      }
    } else {
      // create a new photo obj from old one
      // sets photo value for key in photos object
      const tempPhotosObj = createPhotoObj(product.photos, publicId, asset);
      const details = new Map<string, Photos>([["photos", tempPhotosObj]]);
      // update product details

      let updateResult = await updateDetailsForProduct(
        productId,
        details,
        accessToken,
        refreshToken
      );

      const apiError = updateResult as APIError;
      if (apiError.errorDescription) {
        return apiError;
      }

      return {
        authUser: (updateResult as AuthProduct).authUser,
        asset,
      };
    }
  };

  // whether total products has reached/exceeded maximum products per page
  const hasMoreProducts = () => {
    return productsAvailable.current; //products.length > supabaseAPIManager.productsPerPage
  };

  const generateProductVariants = async (
    productId: string,
    auth: AuthUser,
    selectedAttributeOptions: Map<string, any>,
    draft: ProductFormData
  ): Promise<AuthProductVariantList> => {
    let authVariantList: AuthProductVariantList = {
      authUser: null,
      variantsList: [],
    };
    if (!apiKey) {
      //console.log("Error");
      setInventoryRequestStatus(APIError.default);
      return authVariantList;
    }
    // debugger

    setInventoryRequestStatus(RequestStatus.Loading);

    const optionsCombinations = inventoryDataManager.generateProductVariants({
      sizes: selectedAttributeOptions.get("sizes"),
      colors: selectedAttributeOptions.get("colors"),
    });
    const variants: Map<string, any>[] = Array.from(optionsCombinations).map(
      (combinedOptions) =>
        new Map(
          Object.entries({
            sizeId: combinedOptions[0],
            colorId: combinedOptions[1],
            inventory_id: inventoryId,
            product_id: productId,
          })
        )
    );

    // remove previously saved variants
    const removeResult = await supabaseAPIManager.deleteProductDetailsForId(
      productId,
      apiKey,
      auth.access_token,
      auth.refresh_token
    );

    if (removeResult.type === ResultType.Failure) {
      setInventoryRequestStatus(removeResult.error);
      //console.log(removeResult.error.errorDescription);
      return authVariantList;
    }

    // invalidate product's published status
    const productQueryParams = new Map<string, any>([
      ["product_id", productId],
    ]);
    const valuesForUpdate = new Map<string, any>([["is_published", false]]);

    const productUpdateResult = await supabaseAPIManager.updateProduct(
      productQueryParams,
      valuesForUpdate,
      apiKey,
      auth.access_token,
      auth.refresh_token
    );

    if (productUpdateResult.type === ResultType.Failure) {
      setInventoryRequestStatus(productUpdateResult.error);
      return authVariantList;
      //return productUpdateResult.error
    }

    const result = await supabaseAPIManager.addVariantsForProductId(
      productId,
      apiKey,
      auth.access_token,
      auth.refresh_token,
      variants
    );

    if (result.type === ResultType.Failure) {
      // console.log(result.error.errorDescription);
      setInventoryRequestStatus(result.error);
      return authVariantList;
    }

    let colors: DataObj = {};
    let sizes: DataObj = {};

    // populate corresponding temporary object
    selectedAttributeOptions
      .get("colors")
      .forEach(
        (color: any, idx: number) => (colors[`color${idx + 1}`] = color)
      );
    selectedAttributeOptions
      .get("sizes")
      .forEach((size: any, idx: any) => (sizes[`size${idx + 1}`] = size));

    let queryParams = new Map([["product_id", productId]]);
    let newDetails = new Map<string, any>([
      ["colors", colors],
      ["sizes", sizes],
    ]);

    const updateProductResult = await supabaseAPIManager.updateProduct(
      queryParams,
      newDetails,
      apiKey,
      auth.access_token,
      auth.refresh_token
    );

    if (updateProductResult.type === ResultType.Failure) {
      return authVariantList; //updateProductResult.error;
    }

    setInventoryRequestStatus(RequestStatus.Idle);

    setSelectedProduct((prev) => {
      //console.dir(new Map<string, Photo>(result.value.variantsList[0].photos))
      return prev
        ? {
            ...prev,
            isPublished: productUpdateResult.value.product.isPublished,
            productDetails: result.value.variantsList,
            colors: updateProductResult.value.product.colors,
            sizes: updateProductResult.value.product.sizes,
          }
        : null;
    });
    return result.value;
  };

  let inventory: Inventory = {
    id: inventoryId,
    addProduct,
    deletePhotoForProduct,
    deleteProduct,
    deletePhotoForVariant,
    generateProductVariants: generateProductVariants,
    isCreated,
    createInventoryForPartner: getInventoryForPartner,
    getProductDetailForId: getProductDetailForId,
    isProductDetailsComplete,
    uploadPhoto,
    updateProduct,
    updateSelectedProduct,
    getProductWithId,
    loadProductsForInventory,

    getProducts,
    formatValueOf,
    performProductUpdate,
    updateDetailsForProduct,
    updateProductPhoto,
    hasMoreProducts,
    selectedProduct,
    inventoryRequestStatus,
    loadInventoryOrdersForInventory,
    hasMoreInventoryOrders: hasMoreInventoryOrders.current,
    inventoryOrders,
  };

  const dialogModel: ProductDialog = {
    toggleDialog: toggleNewProductDialog,
    open,
  };

  return (
    <InventoryContext.Provider value={inventory}>
      <ProductDialogContext.Provider value={dialogModel}>
        {children}
      </ProductDialogContext.Provider>
    </InventoryContext.Provider>
  );
};

export default InventoryDataProvider;
