import React, { ReactNode, useRef, useState } from "react";
import { SearchManagerContext } from "../../contexts/SearchManagerContext";
import { Product, ProductVariant } from "../../Models/Product";
import { RequestStatus, ResultType } from "../../Models/Result";
import { SearchFilter, SearchManager } from "../../Models/SearchManager";
import { AuthUser } from "../../Models/User";
import supabaseAPIManager, {
  APIError,
} from "../../networking/SupabaseAPIManager/SupabaseAPIManager";

interface SearchManagerProviderProps {
  children: ReactNode;
}

const SearchManagerProvider = ({ children }: SearchManagerProviderProps) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [filters, setFilters] = useState<Map<SearchFilter, any>>(
    new Map<SearchFilter, any>([])
  );

  const [isSearching, setIsSearching] = useState(false)


  const [productsRequestStatus, setProductsRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);

  let productsAvailable = useRef<boolean>(true);
  let unfilteredProducts = useRef<Product[]>([]);

  //let canLoadMoreReviews = useRef<boolean>(true);

  const apiKey = process.env.REACT_APP_SUPABASE_API_KEY;

  const shouldInitializeSearch = (shouldSearch: boolean) => {
    if (!isSearching) {
      unfilteredProducts.current = products
      setIsSearching(shouldSearch)
    }
   
  }

  const getProductForId = (productId: string): Product | null => {
    const found = products.find((product) => product.productId === productId);
    return found || null;
  };

   const cancelSearch = () => {
   
    setIsSearching(false)
    setProducts(unfilteredProducts.current);
    unfilteredProducts.current = []
    updateFilters(null)
    productsAvailable.current = true;
  };

  const clearProducts = () => {
    setProducts([]);
    productsAvailable.current = true;
  };



  /**
   * favorites a variant
   * @param productId id of product that has variants
   * @param variantId id favorited variant
   * @param auth authentication data
   * @returns a promise to an updated auth data if the token was refreshed
   */
  const favoriteVariant = async (
    productId: string,
    variantId: string,
    auth: AuthUser
  ): Promise<AuthUser | null> => {
    if (!apiKey) {
      return null;
    }

    const favoritedResult = await supabaseAPIManager.addProductToFavorites(
      productId,
      variantId,
      apiKey,
      auth.user.id,
      auth.access_token,
      auth.refresh_token
    );
    if (favoritedResult.type === ResultType.Failure) {
      return null;
    }

    //debugger
    const productIdx = products.findIndex(
      (product) => product.productId === productId
    );
    const variantIdx = products[productIdx]?.productDetails.findIndex(
      (variant) => variant.productDetailId === variantId
    );
    if (variantIdx > -1) {
      const temp = products.map((product) => {
        if (product.productId !== productId) {
          return product;
        } else {
          return {
            ...product,
            productDetails: product.productDetails.map((variant) => {
              if (variant.productDetailId !== variantId) {
                return variant;
              } else {
                return {
                  ...variant,
                  favoriteId: favoritedResult.value.favoriteItem.favoriteId,
                };
              }
            }),
          };
        }
      });
       setProducts(temp);
    }
 
    return favoritedResult.value.authUser;
  };

  
  /**
   * syncronizes product details of a product with that of a source
   * @param sourceProduct 
   * @returns 
   */
  const syncProduct = (sourceProduct: Product): void => {
    const foundIdx = products.findIndex(
      (product) => product.productId === sourceProduct.productId
    );
    if (foundIdx === -1) {
      return;
    }
    let targetProduct: Product = {
      ...products[foundIdx],
      productDetails: [...sourceProduct.productDetails]
    }
     

     
    setProducts([
      ...products.slice(0, foundIdx),
      targetProduct,
      ...products.slice(foundIdx + 1),
    ]);
  };
  

  /**
   * Search for products according to filters specified
   *  
   * @param userFilters filters applied to the search
   * @param startIdx Idx the results should start from
   * @param auth authentication data
   * @returns promise to an object that contains updated auth data if token was refreshed
   */
  const searchProductsforQuery = async (
    userFilters: Map<SearchFilter, any>,
    startIdx: number,
    auth: AuthUser | null
  ): Promise<AuthUser | null> => {
    if (!apiKey) {
      setProductsRequestStatus(APIError.default);
      return null;
    }

    if (productsRequestStatus === RequestStatus.Loading) {
      return null
    }


    setProductsRequestStatus(RequestStatus.Loading);

    const filters = new Map<string, string>([
      ["select", "*,product_details(*)"],
      ["product_details.is_available", "eq.true"],
      ["is_published", `eq.true`],
    ]);
    // debugger

    let valuesList = "";
    for (let [key, value] of userFilters.entries()) {
      if (key === SearchFilter.subcategory && value.length > 0) {
        const valuesList = "(" + value.join(",") + ")";
        filters.set("subcategory_id", `in.${valuesList}`);
      } else if (key === SearchFilter.plating && value.length > 0) {
        valuesList = "(" + value.join(",") + ")";
        filters.set(
          "or",
          `(colors->>color1.in.${valuesList},colors->>color2.in.${valuesList},colors->>color3.in.${valuesList},colors->>color4.in.${valuesList},colors->>color5.in.${valuesList})`
        );
      } else if (key === SearchFilter.size && value.length > 0) {
        valuesList = "(" + value.join(",") + ")";
        filters.set(
          "or",
          `(sizes->>size1.in.${valuesList},sizes->>size2.in.${valuesList},sizes->>size3.in.${valuesList},sizes->>size4.in.${valuesList},sizes->>size5.in.${valuesList},sizes->>size6.in.${valuesList})`
        );
      } else if (key === SearchFilter.price && value.length > 0 ) {
        //
        valuesList =`(selling_price.gte.${value[0]})`
        if (value.length === 2) {
          valuesList =`(selling_price.gte.${value[0]},selling_price.lte.${value[1]})`
        }
        filters.set(
          "select",
          `*,product_details!inner(*)`
        );
        
        filters.set(
          "product_details.and",
          
          `${valuesList}`
        );
      } else if (key === SearchFilter.textQuery && value !== "") {
        filters.set("fts", `wfts.${value}`)
      }
    }

  
    const result = await supabaseAPIManager.getProductDetailForId(
      filters,
      apiKey,
      startIdx
    );

    if (result.type === ResultType.Failure) {
      setProductsRequestStatus(result.error);
      return null;
    }

    let refreshedAuthuser: AuthUser | null = null;

    // debugger
    if (auth) {
      let queryItems = new Map<string, string>([
        ["user_id", `${auth.user.id}`],
      ]);
      let accessToken = auth.access_token;
      let refreshToken = auth.refresh_token;
    
      for (let product of result.value.productsList) {
        queryItems.set("product_id", `${product.productId}`);
        const favoritesResult = await supabaseAPIManager.getFavorites(
          apiKey,
          accessToken,
          refreshToken,
          queryItems,
          undefined
        );
        if (favoritesResult.type === ResultType.Success) {
          // find each favorited variant amongst the product's variant
          for (let favorited of favoritesResult.value.favoriteItems) {
            const foundIdx = product.productDetails.findIndex(
              (variant) => variant.productDetailId === favorited.productDetailId
            );
            if (foundIdx > -1) {
              product.productDetails[foundIdx].favoriteId =
                favorited.favoriteId;
              // console.dir(product.productDetails[foundIdx])
            }
          }

          if (favoritesResult.value.authUser) {
            accessToken = favoritesResult.value.authUser.access_token;
            refreshToken = favoritesResult.value.authUser.refresh_token;
            refreshedAuthuser = favoritesResult.value.authUser;
          }
        }
      }
    }
    setProductsRequestStatus(RequestStatus.Idle);

    //console.dir(result.value.productsList)
    productsAvailable.current =
      result.value.productsList.length === supabaseAPIManager.productsPerPage;
    setProducts([...products, ...result.value.productsList]);

    return refreshedAuthuser;
  };

  
  const updateFilters = (newFilters: Map<SearchFilter, any> | null) => {
    if (newFilters) {
      setFilters(newFilters)
    } else {
      // reset
      setFilters(new Map<SearchFilter, any>([]))
    }
  }
   
   /**
   * unfavorites a variant
   * @param productId id of product that has variants
   * @param favoriteId id of favorited variant
   * @param auth authentication data
   * @returns a promise to updated auth data if the token was refreshed
   */
  const unFavoriteVariant = async (
    productId: string,
    favoriteId: string,
    user: AuthUser
  ): Promise<AuthUser | null> => {
    if (!apiKey) {
      return null;
    }

    let queryItems = new Map<string, string>([
      ["favorite_id", `eq.${favoriteId}`],
    ]);
    const favoritedResult = await supabaseAPIManager.removeProductFromFavorites(
      apiKey,
      user.access_token,
      user.refresh_token,
      queryItems
    );
    if (favoritedResult.type === ResultType.Failure) {
      return null;
    }

    const productIdx = products.findIndex(
      (product) => product.productId === productId
    );
    if (productIdx > -1) {
      const variantIdx = products[productIdx].productDetails.findIndex(
        (variant) =>
          variant.favoriteId === favoritedResult.value.favoriteItem.favoriteId
      );
      if (variantIdx > -1) {
        const tempVariant: ProductVariant = {
          ...products[productIdx].productDetails[variantIdx],
          favoriteId: null,
        };
        const temp: Product = {
          ...products[productIdx],
          productDetails: [
            ...products[productIdx].productDetails.slice(0, variantIdx),
            tempVariant,
            ...products[productIdx].productDetails.slice(variantIdx + 1),
          ],
        };

        setProducts([
          ...products.slice(0, productIdx),
          temp,
          ...products.slice(productIdx + 1),
        ]);
      }
    }

    return favoritedResult.value.authUser;
  };

  
  const searchManager: SearchManager = {
    cancelSearch,
    isSearching,
    filters,
    products,
    productsAvailable: productsAvailable.current,
    clearProducts,
    getProductForId,
    productsRequestStatus,
    favoriteVariant,
    searchProductsforQuery,
    shouldInitializeSearch,
    syncProduct,
    unFavoriteVariant,
    updateFilters
   };
  return (
    <SearchManagerContext.Provider value={searchManager}>
      {children}
    </SearchManagerContext.Provider>
  );
};

export default SearchManagerProvider;
