import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, {  useState } from 'react'
/*
import { useLocation } from 'react-router-dom';
import { AppDataContext } from '../../../../contexts/AppDataContext';*/
import { Accounts } from '../../../../Models/Accounts';
import { APIError } from '../../../../networking/SupabaseAPIManager/SupabaseAPIManager';
import { FormType } from '../../../Providers/AccountsProvider';
import DCError from '../../../SupportingViews/DCError';

interface LoggedoutViewProps {
  manager: Accounts;
}

const LoggedoutView = ({manager}: LoggedoutViewProps) => {
  
  const [error, setError] = useState<APIError | null>(null);
  

  const handleLogout = async () => {
     manager.updateCurrentform(FormType.Login)
  };

  return (
    <Stack alignItems={"center"}>
      <Typography
        variant="headline"
        noWrap
        component="div"
        sx={{
          color: "primary.dark",
         
        }}
      >
        You are logged out
      </Typography>
      <LoadingButton
        onClick={handleLogout}
        size="large"
        sx={{
           
            width: "90%",
            fontWeight: "500",
            mt: {
                xs: "0.5rem !important",
                md: "1.5rem !important",
              },
          }}
        
        loading={false}
        fullWidth
        variant="contained"
      >
        log in
      </LoadingButton>
      <DCError error={error} onErrorDisplayed={() => setError(null)} />
    </Stack>
  );
}

export default LoggedoutView