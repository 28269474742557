import React, { ReactNode, useRef, useState } from "react";
import { BagManagerContext } from "../../contexts/BagManagerContext";
import { Bag, BagItem, BagManager } from "../../Models/Bag";
import {  ProductAttribute } from "../../Models/Product";
import { deepCopy, toBagItem } from "../../utils";
import { RequestStatus, ResultType } from "../../Models/Result";
import stripeAPIManager from "../../networking/StripeAPIManager/StripeAPIManager";
import { PCAPIError  } from "../../Models/PCAPIError";
import { AuthSessionId, AuthUser } from "../../Models/User";
import { ColorDetails, SizeDetails } from "../../Models/ProductOptions";
import supabaseAPIManager, {
  APIError,
} from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { SavedItem } from "../../Models/AppData";

interface BagProviderProps {
  children: ReactNode;
}

const emptyBag: Bag = { items: [] };

const BagManagerProvider = ({ children }: BagProviderProps) => {
  const apikey = process.env.REACT_APP_SUPABASE_API_KEY;
  const [bag, setBag] = useState<Bag>(emptyBag);
  const [checkoutStatus, setCheckoutStatus] = useState<
    RequestStatus | PCAPIError
  >(RequestStatus.Idle);
  const [productsRequestStatus, setProductsRequestStatus] = useState<
    RequestStatus | APIError
  >(RequestStatus.Idle);
  const [session, setSession] = useState<any | null>(null);
  const moreItemsAvailable = useRef<boolean>(false);

  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  const clearBag = () => {
    setBag({
      items: [],
    });
  };

  const getBagItems = (): BagItem[] => {
    return deepCopy(bag.items);
  };

  var productsFetchedSoFar = (() => {
    // reduct bag items to unique products
    let temp: string[] = [];
    const productIds = bag.items.reduce(
      (partialResult, currentItem): string[] => {
        if (!partialResult.find((item) => item === currentItem.productId)) {
          temp.push(currentItem.productId);
        }
        return temp;
      },
      temp
    );

    return productIds.length;
  })();

  function getAttributeOptionsForItem<T>(
    variantId: string,
    attribute: ProductAttribute
  ): T[] {
    let temp: T[] = [];

    const foundItem = bag.items.find(
      (item) => item.productDetailId === variantId
    );
    if (!foundItem) {
      return [];
    }

    switch (attribute) {
      case ProductAttribute.quantity:
        let start = 0;
        //temp.push(start)
        do {
          start = start + 1;
          temp.push(start as unknown as T);
        } while (temp.length < foundItem.quantity);
        break;
      case ProductAttribute.size:
        break;
      case ProductAttribute.plating:
        break;
      default:
        break;
    }

    return temp;
  }

  function updateAttributeValueForItem<T>(
    variantId: string,
    attribute: ProductAttribute,
    value: T
  ): boolean {
    const foundItemIdx = bag.items.findIndex(
      (item) => item.productDetailId === variantId
    );

    let updateSuccessful = false;
    if (foundItemIdx < 0) {
      return updateSuccessful;
    }

    let productToUpdate = deepCopy(bag.items[foundItemIdx]);

    switch (attribute) {
      case ProductAttribute.quantity:
        productToUpdate.selectedQuantity = value as unknown as number;
        break;
      case ProductAttribute.size:
        const sizeObj = value as unknown as SizeDetails;
        productToUpdate.selectedSize.id = sizeObj.size_id;
        productToUpdate.selectedSize.title = sizeObj.size_title;

        break;
      case ProductAttribute.plating:
        const colorObj = value as unknown as ColorDetails;
        productToUpdate.selectedPlating.id = colorObj.color_id;
        productToUpdate.selectedPlating.title = colorObj.color_title;
        break;
      default:
        break;
    }

    updateSuccessful = true;

    setBag({
      items: [
        ...bag.items.slice(0, foundItemIdx),
        productToUpdate,
        ...bag.items.slice(foundItemIdx + 1),
      ],
    });

    return updateSuccessful;
  }

  const getPriceForItem = (productId: string): string => {
    const foundItem = bag.items.find(
      (item) => item.product.productId === productId
    );
    if (!foundItem) {
      return "";
    }

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(foundItem.product.sellingPrice);
  };

  const checkout = async (
    accessToken: string,
    refreshToken: string
  ): Promise<AuthSessionId | null> => {
    if (!apikey || !stripePublicKey) {
      setCheckoutStatus(PCAPIError.default);
      return null;
    }

    if (checkoutStatus === RequestStatus.Loading) {
      return null;
    }

    setCheckoutStatus(RequestStatus.Loading);

   
    const apiResult = await stripeAPIManager.createStripeCheckoutSessionId(
      accessToken,
      refreshToken,
      bag,
      apikey
    );

    switch (apiResult.type) {
      case ResultType.Failure:
        //debugger
        //apiResult.error.update(RequestError.Unexpected)
        setCheckoutStatus(apiResult.error);
        return null;
      case ResultType.Success:
        return apiResult.value;
    }
  };

 

  const loadBagItems = async (
    categoryId: string,
    startIdx: number,
    savedItemData: SavedItem[],
    colorTable: ColorDetails[],
    sizeTable: SizeDetails[]
  ): Promise<AuthUser | null> => {
    if (!apikey) {
      // setProductsRequestStatus(RequestStatus.Loading);
      return null;
    }

    if (
      savedItemData.length <= 0 ||
      productsRequestStatus === RequestStatus.Loading
    ) {
      return null;
    }

    setProductsRequestStatus(RequestStatus.Loading);

    const productIdsList = savedItemData.map(
      (savedItem) => savedItem.productId
    );

    const variantIdsList = savedItemData.map(
      (savedItem) => savedItem.variantId
    );

    const filters = new Map<string, string>();

    filters.set("select", "*,product_details(*)");
    filters.set("product_details.is_available", "eq.true");
    filters.set(
      "product_details.product_detail_id",
      `in.(${variantIdsList.join(",")})`
    );
    filters.set("product_id", `in.(${productIdsList.join(",")})`);
    // debugger;
    const result = await supabaseAPIManager.getProductDetailForId(
      filters,
      apikey,
      startIdx
    );

    if (result.type === ResultType.Failure) {
      setProductsRequestStatus(result.error);
      // setProductsRequestStatus(result.error);
      return null;
    }

    let bagItems: BagItem[] = [];

    // convert all variants into bagItems
    for (let variant of savedItemData) {
      // find product of variant
      const foundProduct = result.value.productsList.find(
        (product) => product.productId === variant.productId
      );
      if (!foundProduct) {
        continue;
        // return null
      }
      // convert variant to bagItem
      const bagItem = toBagItem(variant, foundProduct, colorTable, sizeTable);
      if (!bagItem) {
        continue;
        //return null
      }

      bagItems.push(bagItem);
    }

    //let temp: string[] = [];

    // compute productIds of products the bagItems belong to
    const productIds = bagItems.reduce(
      (partialResult, currentItem): string[] => {
        if (!partialResult.find((item) => item === currentItem.productId)) {
          partialResult.push(currentItem.productId);
        }
        return partialResult;
      },
      [] as string[]
    );
    // const productIds = bagItems.reduce(
    //   (partialResult, currentItem): string[] => {
    //     if (!partialResult.find((item) => item === currentItem.productId)) {
    //       temp.push(currentItem.productId);
    //     }
    //     return temp;
    //   },
    //   temp
    // );

    moreItemsAvailable.current =
      productIds.length === supabaseAPIManager.productsPerPage;

    setProductsRequestStatus(RequestStatus.Idle);

    setBag({
      ...bag,
      items: [...bag.items, ...bagItems],
    });

    return null;
  };

  const loadOrderInfo = async (sessionId: string) => {
    if (!apikey || !stripePublicKey) {
      setCheckoutStatus(PCAPIError.default);
      return;
    }

    setCheckoutStatus(RequestStatus.Loading);

    const orderinfoResult = await stripeAPIManager.getOrderInfoForSessionId(
      sessionId,
      apikey
    );

    switch (orderinfoResult.type) {
      case ResultType.Failure:
        setCheckoutStatus(orderinfoResult.error);
        break;
      case ResultType.Success:
        
        setSession(orderinfoResult.value);
        setCheckoutStatus(RequestStatus.Idle);
        break;
    }
  };

  const updateCheckoutStatus = (newStatus: RequestStatus | PCAPIError) => {
    setCheckoutStatus(newStatus);
  };

  const updateBagItems = (savedItems: SavedItem[]) => {
    let temp: BagItem[] = [];
    for (let savedItem of savedItems) {
      const found = bag.items.find(
        (item) => item.productDetailId === savedItem.variantId
      );
      if (found) {
        temp.push(found);
      }
    }

    setBag({
      ...bag,
      items: temp,
    });
  };

  const manager: BagManager = {
    clearBag,
    getBagItems,
    getAttributeOptionsForItem,
    updateAttributeValueForItem,
    getPriceForItem,
    
    checkout,
    productsRequestStatus,
    loadBagItems,
    loadOrderInfo: loadOrderInfo,
    moreItemsAvailable: moreItemsAvailable.current,
    checkoutStatus,
    updateBagItems,

    session,
    productsFetchedSoFar,
    updateCheckoutStatus,
  };

  return (
    <BagManagerContext.Provider value={manager}>
      {children}
    </BagManagerContext.Provider>
  );
};

export default BagManagerProvider;
