import { APIError } from "../networking/SupabaseAPIManager/SupabaseAPIManager";
import { Product } from "./Product";
import { RequestStatus } from "./Result";
 import { AuthUser } from "./User";

export enum SearchFilter {
  category = "category",
  subcategory = "subcategory",
  
  textQuery = "textQuery",
  
  plating = "plating",
  size = "size",
  price = "price"
  // minPrice = "minPrice",
  // maxPrice = "maxPrice"
}
export interface SearchManager {
  
  // products available for sale
  readonly products: Product[];
  // indicates whether more products are still available to load from the backend
  readonly productsAvailable: boolean;

  readonly filters: Map<SearchFilter, any>

  readonly isSearching: boolean

  // indicates request status for loading products
  productsRequestStatus: RequestStatus | APIError;
  
  cancelSearch: () => void;
   
  clearProducts: () => void;

  /**
   * favorites a variant
   * @param productId id of product that has variants
   * @param variantId id favorited variant
   * @param auth authentication data
   * @returns a promise to an updated auth data if the token was refreshed
   */
   favoriteVariant: (
    productId: string,
    variantId: string,
    user: AuthUser
  ) => Promise<AuthUser | null>;
  
  /**
   * gets a product stored in memory
   * @param productId id of product to retrieve   
   * @returns product if found, null otherwise
   */
  getProductForId: (productId: string) => Product | null;

  shouldInitializeSearch:  (shouldSearch: boolean) => void

   /**
   * Search for products according to filters specified
   *  
   * @param userFilters filters applied to the search
   * @param startIdx Idx the results should start from
   * @param auth authentication data
   * @returns promise to an object that contains updated auth data if token was refreshed
   */
  searchProductsforQuery: (
    userFilters: Map<SearchFilter, any>,
    startIdx: number,
    auth: AuthUser | null
  ) => Promise<AuthUser | null>;

  /**
   * syncronizes product details of a product with that of a source
   * @param sourceProduct 
   * @returns 
   */
  syncProduct: (sourceProduct: Product) => void;
  
   /**
   * unfavorites a variant
   * @param productId id of product that has variants
   * @param favoriteId id of favorited variant
   * @param auth authentication data
   * @returns a promise to updated auth data if the token was refreshed
   */
  unFavoriteVariant: (
    productId: string,
    favoriteId: string,
    user: AuthUser
  ) => Promise<AuthUser | null>;

  updateFilters: (newFilters: Map<SearchFilter, any> | null) => void
  //updateFavoritedStatus: (favorites: FavoriteItem[]) => void;
}
