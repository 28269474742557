/**
 * Abstract A view that shows a product
 */

import { CardActionArea, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React, { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../contexts/AppDataContext";
import { LookupTable } from "../../Models/LookupTables";
import { Product, ProductVariant } from "../../Models/Product";
import { ColorDetails } from "../../Models/ProductOptions";
import { useNavigate } from "react-router-dom";
import { isDiscountRedeemable, productColorForVariant } from "../../utils";
import { UserAccountContext } from "../../contexts/UserAccountContext";
import { FormType } from "../Providers/AccountsProvider";
import DCError from "./DCError";
import { APIError } from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { SearchManager } from "../../Models/SearchManager";
import { AuthUser } from "../../Models/User";
import PriceView from "../SupportingViews/PriceView"
import DiscountView from "../SupportingViews/DiscountView"


interface ProductProps {
  product: Product;
  onWishlisted: () => void;
  searchManager: SearchManager;
}

const ProductView = ({
  product,
  onWishlisted,
  searchManager,
}: ProductProps) => {
  const navigate = useNavigate();
  const appData = useContext(AppDataContext);
  const userAccountManager = useContext(UserAccountContext);
  const [error, setError] = useState<APIError | null>(null);
  const user = userAccountManager.getSignedInUser();
  // const hasSignedIn = user ? true : false
  const [isSelected, setIsSelected] = useState(false)
  const colorTable = appData.getLookuptableForType(
    LookupTable.color
  ) as ColorDetails[];

  const firstVariant = (()=>{
    let temp = product.productDetails.find(variant => {
      if (variant.discountInfo) {
         return isDiscountRedeemable(variant.discountInfo)
      }
      return false
    })

    return temp || product.productDetails[0]
   
  })();

  const [selectedVariantImg, setSelectedVariantImg] = useState<string>(
    firstVariant.photos.photo1.url
  );
  // const [selectedColor, setSelectedColor] = useState<ColorDetails | null>(
  //   productColorForVariant(firstVariant, colorTable)
  // );

  const [selectedColor, setSelectedColor] = useState<ColorDetails | null>(null);

  var selectedVariant = (() => {
    if (!isSelected) {
       return firstVariant
    }
    return product.productDetails.find(
      (item) => item.colorId === selectedColor?.color_id
    );
  })();

  const isFavorited = (() => {
    return selectedVariant?.favoriteId ? true : false;
  })();

  const productURL = (() => {
    return `/product/${product.productId}/${firstVariant.productDetailId}?plating=${selectedColor?.color_id}`;
  })();

  const handleColorSelect = (color: ColorDetails) => {
    setSelectedColor(color);

    const found = product.productDetails.find(
      (variant) => variant.colorId === color.color_id
    );

    if (found) {
      setSelectedVariantImg(found.photos.photo1.url);
    }
    setIsSelected(true)
  };

  const avgRatingView = (() => {
    return (
      product.avgRating !== null && (
        <Stack
          sx={{
            height: "min-height",
            alignItems: "center",
          }}
          direction="row"
        >
          <StarIcon fontSize="small" />
          <Typography
            variant="footnote"
            // color="text.secondary"
            textAlign={"left"}
          >
            {product.avgRating.toFixed(1)}
            {/* {formatRating(product.avgRating)} */}
          </Typography>
        </Stack>
      )
    );
  })();


   useEffect(()=>{
    if (firstVariant && selectedColor === null) {
       let color = productColorForVariant(firstVariant, colorTable)
       setSelectedColor(color)
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
   },[firstVariant?.productDetailId])

  return (
    <Card variant="outlined" sx={{ position: "relative", border: "0px" }}>
      <CardActionArea
        onClick={() => {
          navigate(productURL);
        }}
      >
        <CardMedia
          sx={{ borderRadius: "10px" }}
          component="img"
          alt={product.title}
          height="274px"
          image={selectedVariantImg}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0.5rem",
          }}
        >
          <Stack
            width="100%"
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.primary"
              textAlign={"left"}
              width="80%"
              textTransform={"capitalize"}
              overflow="hidden"
              textOverflow={"ellipsis"}
              whiteSpace="nowrap"
            >
              {product.title}
            </Typography>
            {avgRatingView}
          </Stack>
          {/* </Stack> */}
          <Box marginY={1} >
            <DiscountView discountInfo={selectedVariant?.discountInfo ?? null} />
          </Box>
          <PriceView sellingPrice={selectedVariant?.sellingPrice ?? null} discountInfo={selectedVariant?.discountInfo ?? null} />
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          paddingTop: "unset",
          // py: "0rem"
        }}
      >
        <Typography marginBottom={0.5} variant="footnote">
          Available Colors
        </Typography>
        <ColorPallette
          variants={product.productDetails.reduce(
            (prev: any[], curr): ProductVariant[] => {
              const found = prev.find((item) => item.colorId === curr.colorId);
              let result = [...prev];
              if (!found) {
                result.push(curr);
              }
              return result;
            },
            []
          )}
          onColorSelected={handleColorSelect}
          colorTable={colorTable}
          selected={selectedColor}
        />
        <Typography
          marginTop={1}
          fontWeight={500}
          marginX={"0px !important"}
          variant="caption2"
          textAlign={"left"}
        >
          READY TO SHIP
        </Typography>
      </CardActions>
      <IconButton
        key={isFavorited ? selectedVariant?.favoriteId : product.productId}
        sx={{
          position: "absolute",
          zIndex: "105",
          top: "0.25rem",
          right: "0.25rem",
        }}
        onClick={async () => {
          let refreshedAuthuser: AuthUser | null = null;
          if (!user) {
            userAccountManager.updateCurrentform(FormType.Login);
          } else if (!isFavorited && selectedVariant) {
            refreshedAuthuser = await searchManager.favoriteVariant(
              product.productId,
              selectedVariant.productDetailId,
              user
            );

            await userAccountManager.shouldLoadMoreFavorites(true);
            //userAccountManager.canLoadMoreFavoritesData = true
          } else {
            if (!selectedVariant || !selectedVariant.favoriteId) {
              return;
            }
            refreshedAuthuser = await searchManager.unFavoriteVariant(
              product.productId,
              selectedVariant.favoriteId,
              user
            );
            await userAccountManager.removeFavoritedProduct(
              selectedVariant.favoriteId
            );
          }
          if (refreshedAuthuser) {
            userAccountManager.saveCredentials(refreshedAuthuser);
          }
        }}
      >
        <FavoriteIcon color={isFavorited ? "error" : undefined} />
      </IconButton>
      <DCError error={error} onErrorDisplayed={() => setError(null)} />
    </Card>
  );
};

interface ColorPalletteProps {
  onColorSelected: (color: ColorDetails) => void;
  variants: ProductVariant[];
  colorTable: ColorDetails[];
  selected: ColorDetails | null;
}

const ColorPallette = (props: ColorPalletteProps) => {
  const colors = (() => {
    return props.variants
      .map((variant) => {
        // lookup variant's color value
        return productColorForVariant(variant, props.colorTable);
      })
      .filter((colorValue) => colorValue);
  })();

  return (
    <Stack
      spacing={0}
      //paddingX={1}
      direction="row"
    >
      {colors.map((color, idx) => (
        <IconButton
          onClick={() => props.onColorSelected(color!)}
          key={idx}
          sx={(theme) => ({
            padding: "0.25rem",
            border:
              props.selected?.color_id === color!.color_id
                ? `1px solid ${theme.palette.primary.dark}`
                : "unset",
          })}
        >
          <Box
            sx={{
              width: "0.75rem",
              height: "0.75rem",
              borderRadius: "1rem",
              backgroundColor: `rgba(${color!.color_value.r}, ${
                color!.color_value.g
              }, ${color!.color_value.b}, 1.0)`,
            }}
          />
        </IconButton>
      ))}
    </Stack>
  );
};

export default ProductView;
