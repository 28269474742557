import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { SearchManagerContext } from "../../../contexts/SearchManagerContext";
import { UserAccountContext } from "../../../contexts/UserAccountContext";

import ProductView from "../../SupportingViews/ProductView";
import ProfilePhoto from "../../SupportingViews/ProfilePhoto";
import { LookupTable } from "../../../Models/LookupTables";
import { AppDataContext } from "../../../contexts/AppDataContext";
import {
  CategoryDetails,
  PricesRangeDetails,
} from "../../../Models/ProductOptions";
import RequestStatusView from "../../SupportingViews/RequestStatusView";
//import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { ProductDetailContext } from "../../../contexts/ProductDetailContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchFilter } from "../../../Models/SearchManager";

import FilterForm, {
  FilterFormMenuItem,
  FilterFormModel,
} from "../../SupportingViews/FilterForm";
import { FormikHelpers } from "formik";
import { ChipData, ChipsListVariant } from "../../SupportingViews/ChipsList";
import {
  deepCopyMap,
  
 // isProdEnv,
  mapsAreEqual,
} from "../../../utils";
import { RequestStatus } from "../../../Models/Result";
import { InView } from "react-intersection-observer";
import { AppData } from "../../../Models/AppData";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";

//import ReactGA from 'react-ga'

// import Link from "@mui/material/Link";
/*
function debounce(func: Function, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
          func(...args);
      }, delay);
  };
}*/

const SearchProducts = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchManager = useContext(SearchManagerContext);
  const appData = useContext(AppDataContext);
  const [loadProductsCounter, setLoadProductsCounter] = useState(0);

  const userAccount = useContext(UserAccountContext);
  const productDetailManager = useContext(ProductDetailContext)!;
  const user = userAccount.getSignedInUser();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const hasLoadedProducts = useRef<boolean>(false);

  const pricesLookupTable = appData.getLookuptableForType(LookupTable.price);
  // const [filters, setFilters] = useState<Map<SearchFilter, any>>(
  //   new Map<SearchFilter, any>([])
  // );
  /**
   * keeps track of updates to the query params
   * it's used to make the component rerender after updates search manager's
   * products are cleared and the filters are updated
   */
  const [queryParamsCounter, setQueryParamsCounter] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterFormMenu: FilterFormMenuItem[] = useMemo(
    () => getFilterFormMenu(appData),
    [appData]
  );

  const isSmallAndBelow = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );

  const category = (() => {
    const productCategories = appData.getLookuptableForType(
      LookupTable.category
    ) as CategoryDetails[];
    return productCategories.find(
      (category) => category.category_title.trim() === "jewellery"
    );
  })();

  const loadProducts = async (
    startIdx: number,
    userFilters: Map<SearchFilter, any>
  ) => {
    if (!category || !searchManager || !searchManager.productsAvailable) {
      return;
    }

    // search products using user filters
    const authUser = await searchManager.searchProductsforQuery(
      transformFilterFormValues(userFilters, appData),
      startIdx,
      user
    );
    if (authUser) {
      userAccount.saveCredentials(authUser);
    }
    hasLoadedProducts.current = true;
  };

  const loadMoreProductsIfNeeded = (
    inView: boolean,
    entry: IntersectionObserverEntry
  ) => {
    setTimeout(() => {
      //debugger
      if (!searchManager || !searchManager?.productsAvailable) {
        return null;
      }

      const targetProductId = entry.target.id;

      const secondToLastItem =
        searchManager.products[searchManager.products.length - 2];

      if (secondToLastItem.productId === targetProductId && inView) {
        loadProducts(searchManager.products.length, searchManager.filters);
      }
    }, 350);
  };

  const productsGrid = (() => (
    <Grid
      container
      // columnSpacing={2}
      spacing={"16px"}
      sx={{ px: "1rem" }}
      //rowSpacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {searchManager?.products
        .filter((product) => product.productDetails.length > 0)
        .map((product) => (
          <Grid xs={2} item sm={2} md={3} key={product.productId}>
            <InView
              as="div"
             // style={{ display: product.productId === "17fec36f-269f-4906-bf9b-c1cf50315ea7" && isProdEnv ? "none" : "inherit" }}
              id={product.productId}
              threshold={1}
              onChange={loadMoreProductsIfNeeded}
            >
              <ProductView
                searchManager={searchManager}
                product={product}
                onWishlisted={() => {}}
              />
            </InView>
          </Grid>
        ))}
    </Grid>
  ))();

  const handleFilter = (
    values: FilterFormModel,
    formikHelpers: FormikHelpers<FilterFormModel>
  ): void | Promise<any> => {
    let searchParams = new URLSearchParams();

    if (values.textQuery !== "") {
      searchParams.append("textQuery", values.textQuery);
    }
    for (let [key, value] of Object.entries(values)) {
      if (key === SearchFilter.textQuery || key === "id") {
        continue;
      }
      if (key !== SearchFilter.price) {
        (value as string[]).forEach((item) => searchParams.append(key, item));
      } else {
        // what  range values was selected
        const priceRangeIds = value as string[];
        const selectedFilterId =
          priceRangeIds.length > 0 ? priceRangeIds[0] : undefined;
        const price = pricesLookupTable.find(
          (price) => price.id === selectedFilterId
        );
        if (price !== undefined && price.range.length === 1) {
          searchParams.append("minPrice", price.range[0]);
        } else if (price !== undefined && price.range.length === 2) {
          searchParams.append("minPrice", price.range[0]);
          searchParams.append("maxPrice", price.range[1]);
        }
      }
    }

    let updatedPath = "/";
    const searchParamsStr = searchParams.toString();
    // debugger
    if (searchParamsStr.length !== 0) {
      updatedPath = `${updatedPath}?${searchParamsStr}`;
    }
    navigate(updatedPath);
  };

  useEffect(() => {
    if (!searchManager) {
      return;
    }
    const subcategoriesList = queryParams.getAll("subcategory");
    const platingsList = queryParams.getAll("plating");
    const sizesList = queryParams.getAll("size");
    const minPrice = queryParams.get("minPrice");
    const maxPrice = queryParams.get("maxPrice");
    const textQuery = queryParams.get(SearchFilter.textQuery);
    let tempFilters = new Map<SearchFilter, any>([]);
    //let matchPrices: PricesRangeDetails[] = pricesLookupTable
    let selectedPriceRange: PricesRangeDetails | undefined;

    //if (searchManager && searchManager.products.length > 0) {return}

    if (queryParams.toString().length !== 0) {
      tempFilters = new Map<SearchFilter, any>([
        [SearchFilter.subcategory, subcategoriesList],
        [SearchFilter.plating, platingsList],
        [SearchFilter.size, sizesList],
      ]);

      if (minPrice !== null) {
        //matchPrices = matchPrices.filter(price => price.range[0] === parseFloat(minPrice))
        selectedPriceRange = pricesLookupTable.find(
          (price) => price.range[0] === parseFloat(minPrice)
        );
      }
      if (maxPrice !== null) {
        //matchPrices = matchPrices.filter(price => price.range.length === 2 && price.range[1] === parseFloat(maxPrice))
        selectedPriceRange = pricesLookupTable.find(
          (price) =>
            price.range.length === 2 && price.range[1] === parseFloat(maxPrice)
        );
      }

      if (selectedPriceRange) {
        tempFilters.set(SearchFilter.price, [selectedPriceRange.id]);
      }

      if (textQuery !== null) {
        tempFilters.set(SearchFilter.textQuery, textQuery);
      }
    }
    if (!mapsAreEqual(searchManager.filters, tempFilters)) {
      searchManager.clearProducts();
      searchManager.updateFilters(tempFilters);
    }
    setQueryParamsCounter(queryParamsCounter + 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    const { selectedProduct } = productDetailManager;
    if (selectedProduct && searchManager) {
      searchManager.syncProduct(selectedProduct);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetailManager.selectedProduct]);

  useEffect(() => {
    if (!searchManager) {
      return;
    }
    // express the intent to search
    // if (searchManager.products.length === 0 && searchManager.filters.size > 0) {
    if (searchManager.products.length === 0 && queryParamsCounter > 0) {
      loadProducts(searchManager.products.length, searchManager.filters);

      // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchManager?.products, queryParamsCounter]);

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Helmet>
        <title>Explore</title>
        <meta name="description" content="Explore Prettycharm" />
      </Helmet>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "white",
          mb: "2rem",
        }}
        position="static"
      >
        <Toolbar
          sx={{
            alignItems: "flex-start",
            minHeight: "96px",
            pt: "1rem",
            //pb: "1.75rem",
          }}
        >
          <Typography
            variant="largeTitle"
            noWrap
            component="div"
            sx={{
              color: "primary.dark",
              //flexGrow: 1,
              alignSelf: "flex-end",
            }}
          >
            Explore
          </Typography>
          <Box
            sx={{
              ml: "auto",
            }}
          >
            <ProfilePhoto />
          </Box>
        </Toolbar>

        <FilterForm
          menuList={filterFormMenu}
          onSearchCancel={() => {
            navigate("/");
            // filterId.current += 1
            searchManager?.cancelSearch();
          }}
          onSearchBegin={() => searchManager?.shouldInitializeSearch(true)}
          isSearching={
            (searchManager?.isSearching ?? false) ||
            (searchManager?.filters.size ?? 0) > 0
          }
          handleFilter={handleFilter}
          initialValues={{
            [SearchFilter.textQuery]:
              searchManager?.filters.get(SearchFilter.textQuery) ?? "",
            [SearchFilter.subcategory]:
              searchManager?.filters.get(SearchFilter.subcategory) ?? [],
            id: queryParamsCounter,
            [SearchFilter.category]: [],
            [SearchFilter.size]:
              searchManager?.filters.get(SearchFilter.size) ?? [],
            [SearchFilter.plating]:
              searchManager?.filters.get(SearchFilter.plating) ?? [],
            [SearchFilter.price]:
              searchManager?.filters.get(SearchFilter.price) ?? [],
          }}
        />
        {/* <Box component="div" sx={{padding: "0.5rem", bgcolor: "primary.dark", width: "87.5%", margin: "0 auto", borderRadius: "10px"}}>Go To About Now</Box> */}
        <Divider />
        {appData.promoService !== null && (
          <Box
            component="img"
            src={
              isSmallAndBelow
                ? appData.promoService.photo_url.small
                : appData.promoService.photo_url.large
            }
            width="100%"
          />
        )}
      </AppBar>
      {
        <>
          {(searchManager?.products.length ?? 0) > 0 && productsGrid}
          {hasLoadedProducts.current &&
            (searchManager?.products.length ?? 0) === 0 &&
            searchManager?.productsRequestStatus === RequestStatus.Idle && (
              <Typography variant="headline">
                There are no products available
              </Typography>
            )}
        </>
      }
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          width: "100%",
          paddingBottom: "2rem",
        }}
      >
        {searchManager?.productsRequestStatus !== undefined && (
          <RequestStatusView
            status={searchManager?.productsRequestStatus}
            onRetry={() => setLoadProductsCounter(loadProductsCounter + 1)}
          />
        )}
      </Box>
    </Stack>
  );
};

export default SearchProducts;

const transformFilterFormValues = (
  filters: Map<SearchFilter, any>,
  appData: AppData
): Map<SearchFilter, any> => {
  let tempFilters = deepCopyMap(filters);
  const pricesLookupTable = appData.getLookuptableForType(LookupTable.price);

  if (tempFilters.size > 0) {
    const priceFilter = tempFilters.get(SearchFilter.price) as number[];
    if (priceFilter !== undefined && priceFilter.length === 1) {
      const price = pricesLookupTable.find(
        (price) => priceFilter[0] === price.id
      );
      const [minPrice, maxPrice] = price?.range ?? [];
      if (minPrice) {
        tempFilters.set(SearchFilter.price, [minPrice]);
      }

      if (minPrice !== undefined && maxPrice !== undefined) {
        tempFilters.set(SearchFilter.price, [minPrice, maxPrice]);
      }
    }
  }

  return tempFilters;
};

const getFilterFormMenu = (appData: AppData): FilterFormMenuItem[] => {
  const category = (() => {
    const productCategories = appData.getLookuptableForType(
      LookupTable.category
    ) as CategoryDetails[];
    return productCategories.find(
      (category) => category.category_title.trim() === "jewellery"
    );
  })();

  const pricesLookupTable = appData.getLookuptableForType(LookupTable.price);

  if (category === undefined) {
    return [];
  }
  //debugger
  const subCategoryLookupTable = appData.getLookuptableForType(
    LookupTable.subcategory,
    category.category_id
  );
  const subcategoryFilterData = subCategoryLookupTable.map((subcategory): {
    id: string;
    label: string;
  } => ({
    id: subcategory.subcategory_id as string,
    label: subcategory.subcategory_title as string,
  }));
  //const pricesLookupTable = appData.getLookuptableForType(LookupTable.price);
  const priceFilterData = pricesLookupTable.map((price): ChipData => {
    let rangeLabel = "";
    if (price.range[0] === 0) {
      rangeLabel = `${price.range[0]} up to ${price.range[1]}`;
    } else if (price.range.length === 1) {
      rangeLabel = `${price.range[0]} above`;
    } else {
      rangeLabel = `${price.range[0]} to ${price.range[1]}`;
    }

    return { id: price.id, label: rangeLabel };
  });

  const sizeLookupTable = appData.getLookuptableForType(LookupTable.size);
  const sizeFilterData = sizeLookupTable.map((size): {
    id: string;
    label: string;
  } => ({
    id: size.size_id as string,
    label: size.size_title as string,
  }));

  const colorLookupTable = appData.getLookuptableForType(LookupTable.color);
  const colorFilterData = colorLookupTable.map((color): {
    id: string;
    label: string;
  } => ({
    id: color.color_id as string,
    label: color.color_title as string,
  }));

  const filterMenuData = [
    SearchFilter.subcategory,
    SearchFilter.price,
    SearchFilter.size,
    SearchFilter.plating,
  ].map((filter) => {
    let label = "";
    let selectionType = ChipsListVariant.multiple;
    let filterData: ChipData[] = [];

    switch (filter) {
      case SearchFilter.subcategory:
        label = "subcategories";
        filterData = subcategoryFilterData;
        break;
      case SearchFilter.category:
        label = "categories";
        break;
      case SearchFilter.price:
        label = "prices";
        selectionType = ChipsListVariant.single;
        filterData = priceFilterData;
        break;
      case SearchFilter.size:
        label = "sizes";
        filterData = sizeFilterData;
        break;
      case SearchFilter.plating:
        label = "Color Platings";
        filterData = colorFilterData;
        break;
    }

    return {
      id: filter,
      selectionType,
      data: filterData,
      label,
    };
  });

  return filterMenuData;
};
