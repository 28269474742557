import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppDataContext } from "../../../../contexts/AppDataContext";
import { Accounts } from "../../../../Models/Accounts";
import { FavoriteItem } from "../../../../Models/FavoriteItem";
import { LookupTable } from "../../../../Models/LookupTables";
import { ColorDetails, SizeDetails } from "../../../../Models/ProductOptions";
import { RequestStatus } from "../../../../Models/Result";
//import { RequestStatus } from "../../../../Models/Result";
import { formatPrice } from "../../../../utils";
import FavoriteItemCard from "../../../SupportingViews/FavoriteItemCard";
import RequestStatusView from "../../../SupportingViews/RequestStatusView";
import { InView } from "react-intersection-observer";
// import { UserAccountContext } from "../../../../contexts/UserAccountContext";
// import { useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
   
interface FavoritesListProps {
  userAccount: Accounts;
}

const FavoritesList = ({ userAccount }: FavoritesListProps) => {
  const auth = userAccount.getSignedInUser();
  const [loadFavoritesCounter, setLoadFavoritesCounter] = useState(0)
  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const appearedViewsIds = useRef<string[]>([])
  //const [hasMounted, setHasMounted] = useState(false);
  // const user = useContext(UserAccountContext)
  // const navigate = useNavigate()
  const scrollTimer = useRef<any>()

 
  const loadUserFavorites = async ()  => {
    //debugger
    if ((userAccount.canLoadMoreFavoritesData && auth)) {
      await userAccount.loadFavoritedData(
        auth.user.id,
        auth.access_token,
        auth.refresh_token,
        undefined,
        undefined,
        undefined
      );
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const scrollRef = useBottomScrollListener(loadUserFavorites, {
  //   triggerOnNoScroll: false,
  // });

  const appData = useContext(AppDataContext);

   

   

  const loadMoreProductsIfNeeded =  (inView: boolean, entry: IntersectionObserverEntry) => {
    
  
    setTimeout(()=>{
      //debugger
     // if (!searchManager || !searchManager?.productsAvailable) {return null}

    const targetProductId = entry.target.id
   
    const secondToLastItem = userAccount.favorites[userAccount.favorites.length - 1] //searchManager.products[searchManager.products.length - 2]
      // userAccount.favoritedRequestStatus !== RequestStatus.Loading
      if (secondToLastItem.favoriteId === targetProductId && inView && isScrolling) {
        /*
        console.dir(entry)
        console.log(inView)
        console.dir(secondToLastItem)
        console.log(targetProductId)*/
        const found = appearedViewsIds.current.find((item) => item === targetProductId)
         if (!found) {
         // setLoadFavoritesCounter(loadFavoritesCounter + 1)
          loadUserFavorites()
          appearedViewsIds.current.push(targetProductId)
         // editMode.current = "inactive"
         }
        ///setLoadProductsCounter(loadProductsCounter + 1)
       }
    }, 350)
   
  }

  const canEnableAddToBag = (favoriteItem: FavoriteItem): boolean =>{
    const product = favoriteItem.product
    if (!product) {return false}
    let ans = appData.canAddItem(favoriteItem.productDetailId, product)
   
    return ans
  }

  const updateScrolling = () => {
    if (!isScrolling) {
      setIsScrolling(true);
    }
    
    if (!scrollTimer.current) {return null}
    // Clear the previous timer and set a new one to detect when scrolling stops
    clearTimeout(scrollTimer.current);
    scrollTimer.current = setTimeout(() => {
      if (isScrolling) {
        setIsScrolling(false)
      }
    }, 200); 
  }

  
  const size = (favoriteItem: FavoriteItem) => {
    const product = favoriteItem.product;
    const sizeTable: SizeDetails[] = appData.getLookuptableForType(
      LookupTable.size
    );
    const variant = product?.productDetails.find(
      (variant) => variant.productDetailId === favoriteItem.productDetailId
    );
    return (
      sizeTable.find((size) => size.size_id === variant?.sizeId)?.size_title ??
      ""
    );
  };

  const color = (favoriteItem: FavoriteItem) => {
    const product = favoriteItem.product;
    const colorTable: ColorDetails[] = appData.getLookuptableForType(
      LookupTable.color
    );
    const variant = product?.productDetails.find(
      (variant) => variant.productDetailId === favoriteItem.productDetailId
    );
    return (
      colorTable.find((color) => color.color_id === variant?.colorId)
        ?.color_title ?? ""
    );
  };

  const price = (favoriteItem: FavoriteItem) => {
    const product = favoriteItem.product;

    const variant = product?.productDetails.find(
      (variant) => variant.productDetailId === favoriteItem.productDetailId
    );
    return variant ? formatPrice(variant.sellingPrice) : "";
  };

  const image = (favoriteItem: FavoriteItem) => {
     

    const variant = favoriteItem.product?.productDetails.find(
      (variant) => variant.productDetailId === favoriteItem.productDetailId
    );
    return variant?.photos.photo1.url ?? "";
  };

  const productDetailURL = (favoriteItem: FavoriteItem) => {
     
    const product = favoriteItem.product
    const variant = product?.productDetails.find(
      (variant) => variant.productDetailId === favoriteItem.productDetailId
    );

    if (!product || !variant) {
      return "/";
    }

    return `/favorites/product/${product.productId}/${variant.productDetailId}?plating=${variant.colorId}`;
  };

  
  window.addEventListener('scroll', updateScrolling)
   
   

  useEffect(() => {
      //debugger
      if (userAccount.favorites.length === 0 && userAccount.canLoadMoreFavoritesData) {
        loadUserFavorites();
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadFavoritesCounter]);

  if (
    userAccount.favorites.length === 0 &&
    !userAccount.canLoadMoreFavoritesData &&
    userAccount.favoritesRequestStatus === RequestStatus.Idle
  ) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography sx={{ mt: "-17rem" }} variant={"title2"}>
          Your Wishlist is empty
        </Typography>
      </Stack>
    );
  }

    
  //  console.log(userAccount.favoritesRequestStatus)
  

  return (
    <div >
      {
           userAccount.favorites.length > 0 && (

          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {userAccount.favorites.map((favoriteItem) =>  (
             <InView  key={favoriteItem.favoriteId} as="div" id={favoriteItem.favoriteId} threshold={1} onChange={loadMoreProductsIfNeeded}>
              <ListItem disablePadding>
                
           <FavoriteItemCard
              to={productDetailURL(favoriteItem)}
              key={favoriteItem.favoriteId}
              variantId={favoriteItem.productDetailId}
              data={favoriteItem.product}//{favoritedProduct(favoriteItem)}
              onRemoveItem={async () => {
                //debugger
                if (!auth || userAccount.favoritesRequestStatus === RequestStatus.Loading) {
                  return;
                }
                // just to block the stateobject from loading more items
                // when the observer is triggered. set the editmode to active
                
                const isSuccessful = await userAccount.unFavoriteItem(favoriteItem.favoriteId, auth);
               // const threshold = userAccount.favorites.findIndex((currItem)=>currItem.favoriteId === favoriteItem.favoriteId)
                if (isSuccessful && userAccount.canLoadMoreFavoritesData && userAccount.favorites.length < 6) {
                  setLoadFavoritesCounter(loadFavoritesCounter + 1)
                }
                
                //setEditMode("inactive")
              }}
              enableAddToBag={canEnableAddToBag(favoriteItem)}
              onAddToCart={() => {
              
                appData.saveItemToCart({
                  productId: favoriteItem.productId,
                  variantId: favoriteItem.productDetailId,
                  quantity: 1,
                });
              }}
              onMount={() => {
                //handleOnMount(favoriteItem);
              }}
              size={size(favoriteItem).toUpperCase()}
              color={color(favoriteItem).toUpperCase()}
              price={price(favoriteItem)}
              image={image(favoriteItem)}
            /> 
            </ListItem>
            </InView>
             )
            )}
        </List>
        ) 
      }
      
          
          {
            userAccount.favoritesRequestStatus !== RequestStatus.Idle && (
              <Box
                paddingTop={4} 
                 sx={{ display: "grid", placeItems: "center", width: "100%" }}>
              <RequestStatusView
                status={userAccount.favoritesRequestStatus}
                onRetry={loadUserFavorites}
              />
            </Box>
            )
          }
         
        
    </div>
  );
};



export default FavoritesList;
