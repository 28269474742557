import Close from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { SxProps, Theme } from '@mui/material/styles'
import React from 'react'

interface DCDeleteButtonProps {
    sx: SxProps<Theme> | undefined
    onClick: () => void
    size: string
}
const DCDeleteButton = (props: DCDeleteButtonProps) => {
  return (
     <IconButton
      onClick={props.onClick}
      aria-label="delete photo"
      component="span"
      size={props.size as any}
      sx={props.sx}
    >
      <Close fontSize="inherit" />
    </IconButton>
  )
}

export default DCDeleteButton