import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Field, FieldArray, Formik, FormikProps } from "formik";
import { productDetailsValidationSchema
  //, productVariantValidationSchema 
} from "../../../Models/editProductValidationSchema";

import { useContext, useEffect, useState } from "react";
import { InventoryContext } from "../../../contexts/InventoryContext";
import { DCMenuItem } from "../../../../../Models/ProductOptions";
import OptionsList from "../../../../SupportingViews/OptionsList";

import LoadingButton from "@mui/lab/LoadingButton";
import Toolbar from "@mui/material/Toolbar";
import { sellerApp } from "../../../../../routes";
import {
  
  formdataForVariants,
  getPhotos,
  getValueOf,
  getValuesOf,
  symmetricDifference,
} from "../../../../../utils";
import DCBottomAppBar from "../../../../SupportingViews/DCBottomAppBar";
import EditFormHeader from "../../../../SupportingViews/EditFormHeader";
import {
  DCNumericField,
  DCSelectField,
  DCTextField,
} from "../../../../SupportingViews/InputFields";
//import ProductPhotosGridView from "../../../../SupportingViews/ProductPhotosGridView";
import { EditProductFormMobileProps } from "../mobile/EditProductForm";
import ErrorFocus from "../../../../SupportingViews/ErrorFocus";
import {
  AuthProductVariantList,
  ProductVariantFormData,
} from "../../../../../Models/Product";
import {
  defaultProductFormData,
  ProductFormData,
} from "../../../Models/ProductFormData";
import { UserAccountContext } from "../../../../../contexts/UserAccountContext";
import ProductVariantEditor from "../../../ProductVariantEditor/ProductVariantEditor";
import { RequestStatus } from "../../../../../Models/Result";
import { APIError } from "../../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import DCError from "../../../../SupportingViews/DCError";

interface EditProductFormProps extends EditProductFormMobileProps {}

/**
 *
 * @param categories product categories
 * @param onCategoryChange event/intent handler for when user chooses a category
 * @param subcategories subcategories of a product category
 * @param sizeOptions options for product size
 * @param colorOptions options for product colors
 * @param accessoryOptions options for product accessories
 * @param productId id of product
 * @returns
 */
const EditProductForm = ({
  categories,
  onCategoryChange,
  subCategories,
  sizeOptions,
  colorOptions,
  accessoryOptions,
  productId,
  onSubmit,
  onSaveProduct, //: onPublishProduct,
  onUnpublishProduct
}: EditProductFormProps) => {
  const {
    generateProductVariants,
    getProductDetailForId,
    selectedProduct,
    id,
    inventoryRequestStatus  
  } = useContext(InventoryContext);
  const [draft, setDraft] = useState<ProductFormData>(defaultProductFormData);
  const accountManager = useContext(UserAccountContext);
  const authUser = accountManager.getSignedInUser();

  const [currentSection, setCurrentSection] =
    useState<{ id: string; formValues: ProductFormData } | null>(null);

  const handleGenerateVariants = async (
    attributeOptions: Map<string, string[]>,
    props: FormikProps<ProductFormData>
  ) => {
    if (!authUser) {
      return;
    }

    setCurrentSection({
      id: "manage variants",
      formValues: props.values,
    });
    const result = (await generateProductVariants(
      productId,
      authUser,
      attributeOptions,
      draft
    )) as AuthProductVariantList;

    if (!result.variantsList) {
      return;
    }

    if (result.authUser) {
      accountManager.saveCredentials(result.authUser);
    }
  };

  const variantsAvailableForAttributes = (values: ProductFormData): boolean => {
    if (!selectedProduct) {
      return false;
    }

    let allColorsFound = true;
    let savedColors = new Set<string>();
    let draftColors = new Set<string>(values.colors);
    Object.entries(selectedProduct.colors).forEach(([, colorValue]) =>
      savedColors.add(colorValue)
    );
    allColorsFound = symmetricDifference(savedColors, draftColors).size === 0;

    let allSizesFound = true;
    let savedSizes = new Set<string>();
    let draftSizes = new Set<string>(values.sizes);
    Object.entries(selectedProduct.sizes).forEach(([, sizeValue]) =>
      savedSizes.add(sizeValue)
    );

    allSizesFound = symmetricDifference(savedSizes, draftSizes).size === 0;

    return allColorsFound && allSizesFound;
  };

  useEffect(
    () => {
      if (selectedProduct) {
        console.dir(selectedProduct)
        if (selectedProduct.categoryId) {
          onCategoryChange({ target: { value: selectedProduct.categoryId } });
        } // update draft where corresponding model was updated
        if (currentSection?.id === "manage variants") {
          setDraft({
            ...currentSection.formValues,
            colors: getValuesOf(selectedProduct.colors),
            sizes: getValuesOf(selectedProduct.sizes),
            variants: formdataForVariants(selectedProduct.productDetails || []),
          });
          setCurrentSection(null);
        } else {
          setDraft({
            title: selectedProduct.title,
            description: selectedProduct.description,
            categoryId: selectedProduct.categoryId,
            subcategoryId: selectedProduct.subcategoryId || "", //"",
            photos: getPhotos(selectedProduct),
            stockQuantity: 0, //getValueOf(selectedProduct.stockQuantity),
            wholesaleCost: getValueOf(selectedProduct.wholesaleCost),
            sellingPrice: 0, //getValueOf(selectedProduct.sellingPrice), //product.sellingPrice || undefined,
            colors: getValuesOf(selectedProduct.colors),
            sizes: getValuesOf(selectedProduct.sizes),
            accessories: getValuesOf(selectedProduct.accessories),
            variants: formdataForVariants(selectedProduct.productDetails || []),
          });
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProduct]
  );

  useEffect(() => {
    (async () => {
      if (!authUser) {
        return null;
      }
      const result = await getProductDetailForId(productId, authUser);
      if (!result) {
        return;
      }

      if (result.authUser) {
        accountManager.saveCredentials(result.authUser);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const isLoading = ((): boolean => {
    const status = inventoryRequestStatus as RequestStatus;
    return status === RequestStatus.Loading ? true : false;
  })();

  const error = (() => {
    const apiError = inventoryRequestStatus as APIError;
    return apiError.errorDescription ? apiError : null;
  })();

 

  

  const hasDraftChanged = (formValues: ProductFormData): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(draft)
  }

  /*
  const validateDraft = (formValues: ProductFormData): boolean => {
    const isVariantAvailable = productVariantValidationSchema.isValidSync(
      formValues,
      {
        strict: true,
      }
    );

    const isProductDataValid = productDetailsValidationSchema.isValidSync(
      formValues,
      {
        strict: true,
      }
    );

    return isVariantAvailable && isProductDataValid;
  }*/

  if (!id) {
    return null;
  }



  if (!selectedProduct) {
    return null;
  }

  return (
    <Formik
      initialValues={draft}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={productDetailsValidationSchema}
      onSubmit={onSubmit}
      // onSubmit={(values: ProductFormData, formikHelpers: FormikHelpers<ProductFormData>)=> {
      //   submitProductForm()
      // }}
    >
      {(props: FormikProps<ProductFormData>) => {
        return (
          <Stack
            alignItems="flex-start"
            spacing={3}
            width="100%"
            component="form"
          >
            <DCError error={error} />
            <EditFormHeader
              isSubmitting={props.isSubmitting}
              productId={productId}
              onSave={props.handleSubmit}
              prevScreenURL={`/${sellerApp.path}/inventory/products`}
              lastUpdated={new Date(
                selectedProduct?.lastupdated
              ).toLocaleString()}
            />
            <Typography variant="headline">Product Details</Typography>

            <Stack
              width="100%"
              direction="row"
              justifyContent="space-evenly"
              spacing={4}
              alignItems="flex-start"
            >
              <Field
                label="Title"
                id="title"
                name="title"
                component={DCTextField}
              />

              <Field
                label="Description"
                id="description"
                name="description"
                component={DCTextField}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-evenly"
              spacing={4}
              width="100%"
            >
              <Field
                id="categoryId"
                name="categoryId"
                label="Category"
                onChange={onCategoryChange}
                menuItems={categories.map(
                  (item): DCMenuItem => ({
                    title: item.category_title,
                    id: item.category_id,
                  })
                )}
                component={DCSelectField}
              />
              <Field
                id="subcategoryId"
                name="subcategoryId"
                label="Subcategory"
                menuItems={subCategories.map(
                  (item): DCMenuItem => ({
                    title: item.subcategory_title,
                    id: item.subcategory_id,
                  })
                )}
                component={DCSelectField}
              />
            </Stack>

            {/* <Stack width={"100%"} spacing={0.5} alignItems="flex-start">
              <Typography variant="headline">Product Photos</Typography>
              <Typography
                variant="subtitle1"
                color={(theme) => theme.palette.text.secondary}
              >
                Upload 4 photos of product
              </Typography>
            <FieldArray
                name="photos"
                render={(arrayHelpers) => (
                  <>
                    <ProductPhotosGridView
                      photosStoragePath={storagePathForProductPhotos(productId, id)}
                      dispatch={dispatch}
                      productId={productId}
                      arrayHelpers={arrayHelpers}
                    />
                    {arrayHelpers.form.touched[arrayHelpers.name] &&
                      arrayHelpers.form.errors[arrayHelpers.name] && (
                        <Typography
                          color="error"
                          marginX={"14px"}
                          variant="subhead"
                          width={"100%"}
                        >
                          {
                            arrayHelpers.form.errors[
                              arrayHelpers.name
                            ] as string
                          }
                        </Typography>
                      )}
                  </>
                )}
              /> 
              {productPhotosGridView}
            </Stack> */}

            <Stack width="100%" alignItems="flex-start" spacing={2}>
              <Typography variant="headline">Pricing</Typography>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                spacing={4}
                width="100%"
              >
                <Field
                  id="wholesaleCost"
                  name="wholesaleCost"
                  label="Wholesale Cost"
                  component={DCNumericField}
                />
              </Stack>
            </Stack>

            <Stack width="100%" spacing={4} alignItems="flex-start">
              <Typography variant="headline">Attributes</Typography>
              <OptionsList
                variant="subtitle2"
                title="Colors"
                options={colorOptions.map((item) => ({
                  id: item.color_id,
                  title: item.color_title,
                }))}
              />
              <OptionsList
                variant="subtitle2"
                title="Sizes"
                options={sizeOptions.map((item) => ({
                  id: item.size_id,
                  title: item.size_title,
                }))}
              />
              <Stack width="100%">
                {props.values.variants.length === 0 ||
                !variantsAvailableForAttributes(props.values) ? (
                  <LoadingButton
                    disabled={
                      props.values.sizes.length === 0 ||
                      props.values.colors.length === 0
                    }
                    loading={isLoading}
                    variant="contained"
                    sx={{
                      height: "3.75rem",
                    }}
                    onClick={() => {
                      const attributeOptions = new Map<string, string[]>([
                        ["sizes", props.values.sizes],
                        ["colors", props.values.colors],
                      ]);
                      handleGenerateVariants(attributeOptions, props);
                    }}
                  >
                    Manage Variants
                  </LoadingButton>
                ) : (
                  <Stack>
                  <FieldArray
                    name="variants"
                    render={(arrayHelpers) => {
                      //console.dir(arrayHelpers.form.errors["variants"])
                      return arrayHelpers.form.values.variants.map(
                        (variant: ProductVariantFormData, idx: number) => (
                          <ProductVariantEditor
                            onPhotoDeleted={(photoId) => {
                              if (photoId) {
                                setCurrentSection({
                                  id: "manage variants",
                                  formValues: props.values,
                                });
                              } else {
                                setCurrentSection(null);
                              }
                            }}
                            onPhotoUploaded={(photo) => {
                               
                              if (photo) {
                                setCurrentSection({
                                  id: "manage variants",
                                  formValues: props.values,
                                });
                              } else {
                                setCurrentSection(null);
                              }
                            }}
                            key={variant.id}
                            arrayHelpers={arrayHelpers}
                            variantIndex={idx}
                            draft={variant}
                            inventoryId={id}
                          />
                        )
                      );
                    }}
                  />
                  {(props.errors.variants?.length ?? 0) > 0 ?  <Typography
                          className="Mui-error"
                          color="error"
                          marginX={"14px"}
                          variant="caption1"
                          width={"100%"}
                        >
                          {errorForVariantsSection(props)}
                          
                        </Typography> : null}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <OptionsList
              title="Accessories"
              variant="headline"
              options={accessoryOptions.map((item) => ({
                id: item.accessory_id,
                title: item.accessory_title,
              }))}
            />

            <DCBottomAppBar
              render={() => (
                <Toolbar
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                 <Stack direction="row" width="18%" justifyContent="space-between">
                 <LoadingButton
                    sx={(theme) => ({
                     // background: selectedProduct.isPublished ? "gray" : theme.palette.success.main,
                      background: theme.palette.success.main,
                      color: selectedProduct.isPublished ? "white" : "white",
                      
                    })}
                    size="medium"
                   // disabled={selectedProduct.isPublished ? !selectedProduct.isPublished : !validateDraft(props.values)}
                     loading={props.isSubmitting && !selectedProduct.isPublished}
                  
                    variant="outlined"
                    //onClick={()=> onPublishProduct(selectedProduct)}
                    onClick={async ()=> {
                       
                      if (!selectedProduct.isPublished) {
                        props.handleSubmit()
                      } else {
                         onUnpublishProduct(selectedProduct)
                      }

                    }}

                  >
                    {selectedProduct?.isPublished ? "Unpublish" : "Publish"}
                  </LoadingButton>
                  <LoadingButton
                    sx={(theme) => ({
                      background: hasDraftChanged(props.values) ? theme.palette.primary.main : "gray",

                      "&:hover": {
                        backgroundColor: "rgba(68, 44, 46, 0.72)",
                      },
                    })}
                    size="large"
                    loading={props.isSubmitting && selectedProduct.isPublished}
                    variant="outlined"
                    onClick={() => {
                      //onSaveProduct(props.values)
                      props.submitForm()
                      //onPublishProduct(props.values)
                    }}
                    disabled={!hasDraftChanged(props.values)}
                  >
                    Save
                  </LoadingButton>
                  </Stack>
                </Toolbar>
              )}
            />
            <ErrorFocus />
          </Stack>
        );
      }}
    </Formik>
  );
};

export const errorForVariantsSection = (props: FormikProps<ProductFormData>) => {

   const errorMsg = props.errors["variants"] as string 
   // console.log(errorMsg)
   if (errorMsg && typeof errorMsg === 'string') {
     return errorMsg
   }
   return ""
}

export default EditProductForm;
