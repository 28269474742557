import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, { useContext } from 'react'
import { UserAccountContext } from '../../../contexts/UserAccountContext'
import ProfilePhoto from '../../SupportingViews/ProfilePhoto'
import FavoritesList from './mobile/FavoritesList'

const PCFavorites = () => {

  const userAccount = useContext(UserAccountContext)

  return (
     <Stack 
     //paddingBottom={6}
     >
      
        <AppBar
        elevation={0}
        sx={{
          backgroundColor: "white",
        }}
        position="static"
      >
        <Toolbar
          sx={{
            alignItems: "flex-start",
            minHeight: "96px",
            pt: "1rem",
            //pb: "1.75rem",
          }}
        >
          <Typography
            variant="largeTitle"
            noWrap
            component="div"
            sx={{
              color: "primary.dark",
              //flexGrow: 1,
              alignSelf: "flex-end",
            }}
          >
            Wishlist
          </Typography>
          <Box
            sx={{
              ml: "auto",
            }}
          >
            <ProfilePhoto />
          </Box>
        </Toolbar>
         {/* {navItems} */}
       <Divider />
      </AppBar>
      {/* <a href="prettycharmapp://favorites">Granpa Favorites</a> */}
      <FavoritesList userAccount={userAccount}/>
     </Stack>
   )
}

export default PCFavorites