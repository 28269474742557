import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { InventoryOrder } from '../../../Models/InventoryOrder'
import { AuthUser } from '../../../Models/User'
import { OrderManager } from '../Models/OrderManager'

interface CustomerOrderDetailProps {
    inventoryOrder: InventoryOrder
    ordersManager: OrderManager | null
    auth: AuthUser | null
}

const CustomerOrderDetail = ({inventoryOrder, ordersManager, auth}: CustomerOrderDetailProps) => {
  
  
  useEffect(()=>{
    if (ordersManager && auth) {
        ordersManager.loadCustomerProfile(auth, inventoryOrder.stripeCustomerId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardContent
    sx={{
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Stack mb={1} alignItems="flex-start" width="100%">
      <Typography variant="headline">
        Customer
      </Typography>
      <Typography variant="subhead">
        {inventoryOrder.stripeCustomerName ?? "N/a"}
      </Typography>
    </Stack>
    <Divider sx={{my: "1rem"}} />
    <Stack  alignItems="flex-start" width="100%">
      <Typography variant="headline">
        Contact
      </Typography>
      <Typography variant="subhead">
        {ordersManager?.customerProfile?.email ?? "N/a"}
      </Typography>
    
    </Stack>
    <Divider sx={{my: "1rem"}} />
    {inventoryOrder.shippingDetails && (
      <Stack mb={1} alignItems="flex-start" width="100%">
        <Typography variant="headline">
          Shipping Address
        </Typography>
        <Typography variant="subhead">
          {inventoryOrder.shippingDetails.name}
        </Typography>
        <Typography variant="subhead">
          {inventoryOrder.shippingDetails.address.line1}
        </Typography>
        <Typography variant="subhead">
          {inventoryOrder.shippingDetails.address.line2},{" "}
          {inventoryOrder.shippingDetails.address.state} {" "}
          {inventoryOrder.shippingDetails.address.postalCode}
        </Typography>
       
      </Stack>
    )}
     <Divider  sx={{my: "1rem"}} />
  </CardContent>
  )
}

export default CustomerOrderDetail