import * as yup from "yup";
import { SavedItem } from "../Models/AppData";
import { BagItem } from "../Models/Bag";

import { UploadedAsset } from "../Models/Cloudinary";
import { DataObj } from "../Models/DataObj";
import {
  DiscountInfo,
  Photo,
  Product,
  ProductVariant,
  ProductVariantFormData,
} from "../Models/Product";
import { ColorDetails, SizeDetails } from "../Models/ProductOptions";


// Array.prototype.dcDeepCopy = function() {
//   return JSON.parse(JSON.stringify(this))
// }

const MSPerSec = 1000;
const MSPerMin = 60 * MSPerSec;
const MSPerHr = 60 * MSPerMin;
const MSPerDay = 24 * MSPerHr;
const MSPerWk = 7 * MSPerDay;
const MSPerMth = 4 * MSPerWk;
const MSPerYear = 12 * MSPerMth;

export const excludedAccessories = [
  "2177c309-1a04-4055-8e4b-b5d5f0d1069a",
  "fd424569-9c81-4764-b5aa-2ab3767db35d",
];

export const titleValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required("title must not be empty")
    .min(2, "title must not be below 2 characters")
    .max(100, "title must not exceed 100 characters"),
});

export const getPhotos = (product: Product): [string, Photo][] => {
  // const photos = Object.entries(product.photos).map(([key, value]) => {
  //   let someObj: DataObj = {};
  //   someObj[key] = value;
  //   return someObj;
  // });
  const photos: [string, Photo][] = Object.entries(product.photos).map(
    ([key, value]) => [key, value]
  );

  return photos;
};

export const getValuesOf = (items: DataObj): string[] => {
  let options: string[] = [];
  for (let [, value] of Object.entries(items)) {
    if (value) {
      options.push(value);
    }
  }

  return options;
  //return Object.entries(items).map(([key, value]) => value) as string[];
};

export const getValueOf = (value: number): number | undefined | string => {
  return value >= 0 ? value : "";
};

/**
 * Sets value in photoObj identified by key. Returns updated object
 * @param photoObj
 * @param key photo key
 * @param value asset to set. Null if photo is being deleted
 * @returns Photos object
 */
/*
export const createPhotoObj = (photoObj: Photos,  key: string, value: UploadedAsset | null): Photos => {
  let temp = {...photoObj}
  temp[key as keyof Photos].url = value?.secure_url ?? "";
  temp[key as keyof Photos].publicId = value?.public_id ?? "";

  return temp
};*/

export function createPhotoObj(
  photoObj: any,
  key: string,
  value: UploadedAsset | null
): any {
  let temp = { ...photoObj };
  let uploadedPhotoURL: string
  

  if (value === null) {
    temp[key as keyof any].url = "";
    temp[key as keyof any].publicId = "";
    return temp
  }  
  
  // check whether a transformed image was required
  // but not found throw error
  const transformedAssets = value.eager
  if (transformedAssets) {
     if (transformedAssets[0].secure_url === undefined) {
      throw Error("No optimized image found")
     } else {
       uploadedPhotoURL = transformedAssets[0].secure_url
     }
  } else {
    uploadedPhotoURL = value.secure_url
  }

   
  temp[key as keyof any].url = uploadedPhotoURL;
  temp[key as keyof any].publicId = value.public_id ;

  return temp;
}

export function deepCopy<T>(target: T): T {
  return JSON.parse(JSON.stringify(target));
}

export const formdataForVariants = (
  productVariants: ProductVariant[]
): ProductVariantFormData[] => {
  //console.dir(Object.fromEntries(productVariants[0].photos))
  let data = productVariants.map((variant) => ({
    id: variant.productDetailId,
    productId: variant.productId,
    sizeId: variant.sizeId,
    colorId: variant.colorId,
    stockQuantity:
      variant.stockQuantity > 0 ? variant.stockQuantity : undefined,
    sellingPrice: variant.sellingPrice > 0 ? variant.sellingPrice : undefined,
    discount: variant.discountInfo ? variant.discountInfo.value : undefined,
    photos: Object.entries(variant.photos) as [string, Photo][], //Object.fromEntries(variant.photos)
    isAvailable: variant.isAvailable,
  }));

  return data;
};

export function symmetricDifference<T>(setA: Set<T>, setB: Set<T>) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    } else {
      _difference.add(elem);
    }
  }
  return _difference;
}

export function difference<T>(setA: Set<T>, setB: Set<T>) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

export const productColorForVariant = (
  variant: ProductVariant,
  colorTable: ColorDetails[]
): ColorDetails | null => {
  const found = colorTable.find(
    (colorItem) => colorItem.color_id === variant.colorId
  );
  return found || null;
};

export const productSizeForVariant = (
  variant: ProductVariant,
  sizeTable: SizeDetails[]
): SizeDetails | null => {
  const found = sizeTable.find(
    (sizeItem) => sizeItem.size_id === variant.sizeId
  );
  return found || null;
};

export function formatPrice(value: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
}

export function toBagItem(
  savedItem: SavedItem,
  product: Product,
  colorTable: ColorDetails[],
  sizeTable: SizeDetails[]
): BagItem | null {
  const variant = product.productDetails.find(
    (variant) => variant.productDetailId === savedItem.variantId
  );
  const sizeForVariant = sizeTable.find(
    (size) => size.size_id === variant?.sizeId
  );
  const colorForVariant = colorTable.find(
    (color) => color.color_id === variant?.colorId
  );

  if (!variant || !colorForVariant || !sizeForVariant) {
    return null;
  }

  const item: BagItem = {
    product: product,
    productId: product.productId,
    productDetailId: variant.productDetailId,
    selectedSize: {
      id: sizeForVariant.size_id,
      title: sizeForVariant.size_title,
    },
    selectedPlating: {
      id: colorForVariant.color_id,
      title: colorForVariant.color_title,
    },
    quantity: variant.stockQuantity,
    selectedQuantity: savedItem.quantity,
    sellingPrice: variant.sellingPrice,
    photos: {
      photo1: {
        url: variant.photos["photo1"].url,
        publicId: variant.photos["photo1"].publicId,
      },
    },
    discountInfo: variant.discountInfo
  };

  return deepCopy(item);
}

export function deepCopyMap<T, U>(map: Map<T,U>): Map<T,U> {
  return new Map<T,U>(JSON.parse(JSON.stringify(Array.from(map))));
}

export function deepCopySet<T>(map: Set<T>): Set<T> {
  return new Set<T>(JSON.parse(JSON.stringify(Array.from(map))));
}

export const getTimePassed = (from: Date, to: Date): [number, string] => {
  let duration: [number, string] = [1, "min"];
  const timePassedInMs = to.getTime() - from.getTime();

  switch (true) {
    case timePassedInMs >= MSPerYear:
      duration = [Math.round(timePassedInMs / MSPerYear), "year"];
      break;
    case timePassedInMs >= MSPerMth:
      duration = [Math.round(timePassedInMs / MSPerMth), "month"];
      break;
    case timePassedInMs >= MSPerWk:
      duration = [Math.round(timePassedInMs / MSPerWk), "week"];
      break;

    case timePassedInMs >= MSPerDay:
      duration = [Math.round(timePassedInMs / MSPerDay), "day"];
      break;
    case timePassedInMs >= MSPerHr:
      duration = [Math.round(timePassedInMs / MSPerHr), "hour"];
      break;
    case timePassedInMs >= MSPerMin:
      duration = [Math.round(timePassedInMs / MSPerMin), "minute"];
      break;
    case timePassedInMs >= MSPerSec:
      duration = [Math.round(timePassedInMs / MSPerSec), "second"];
      break;
    default:
      break;
  }

  return duration;
};

export function formatRating(rating: number): string {
  let nf = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 1,
  });
  return nf.format(rating);
}

export const mapsAreEqual = <T,U>(m1: Map<T, U>, m2: Map<T, U>) => m1.size === m2.size && Array.from(m1.keys()).every((key) => m1.get(key) === m2.get(key));

export function getPercentOff(sellingPrice: number, discount: number): number {
  if (sellingPrice  <= 0  || discount <= 0) {return 0}
  return sellingPrice - ((discount * sellingPrice) / 100)
  
}

export const isProdEnv = (()=>{
  return process.env.NODE_ENV === "production"
})()

export const isDiscountRedeemable = (discountInfo: DiscountInfo | null): boolean => {
  if (discountInfo === null) {return false}
  
  const todayInSecs = new Date().getTime() * 0.001
  return (discountInfo?.redeemBy ?? 0) > todayInSecs
}

export function dateToTimestamp(dateString:string): number {
  const date = new Date(dateString)
  return date.getTime()
}
 
/*
export class RequestStatus {
  static loading = "loading";
  static success = "success";

  
}*/

 
