/**
 * A class for making calls to the supabase endpoints
 */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { AuthInventoryList } from "../../components/PCSellerApp/Models/Inventory";

import { DataObj } from "../../Models/DataObj";
import {
  AuthFavoriteItem,
  AuthFavoriteItems,
  FavoriteItem,
} from "../../Models/FavoriteItem";
import {
  AuthFulfillmentStatus,
  AuthInventoryOrder,
  AuthInventoryOrderList,
  FulfillmentStatus,
} from "../../Models/InventoryOrder";
import { LookupTableModel, PromoProduct } from "../../Models/LookupTables";
import { AuthOrderList, OrderDetail } from "../../Models/Order";
import {
  AuthDeleteProductStatus,
  AuthProduct,
  AuthProductList,
  AuthProductVariant,
  AuthProductVariantList,
  Product,
} from "../../Models/Product";

import { Result, ResultType } from "../../Models/Result";
import { AuthReview, Review, ReviewData } from "../../Models/Review";
import { LoginError, SupabaseError } from "../../Models/Supabase";
import { AuthStatus, AuthUser, User } from "../../Models/User";
import { AuthUserProfile, UserProfile } from "../../Models/UserProfile";

export enum SupabaseEndpoint {
  signup = "signup",
  token = "token",
  logout = "logout",
  recover = "recover",
  user = "user",
  profile = "profile",
  product = "product",
  inventory = "inventory",
  lookupTables = "lookup_tables",
  productDetails = "product_details",
  order = "order",
  inventoryOrder = "inventory_order",
  favorites = "favorites",
  review = "review",
  orderDetails = "order_details",
  fulfillmentStatus = "get_fulfillment_status_count",
  promoproduct = "promo",
}

enum SupabaseAPIError {
  unexpected = "Unexpected",
  notfound = "Notfound",
  badrequest = "BadRequest",
  signupError = "SignupError",
  addressUnreachable = "Unreachable",
  serverError = "ServerError",
  loginError = "LoginError",
  logoutError = "LogoutError",
  sendLinkError = "SendLinkError",
  userError = "UserError",
  invalidToken = "invalidToken",
}

//type Promise<Result<Product[], APIError>> = ProductResult

export class APIError {
  private _value = SupabaseAPIError.unexpected;
  private _msg = "An unexpected error occurred. Try again";
  static default = new APIError();

  constructor(
    value: SupabaseAPIError = SupabaseAPIError.unexpected,
    msg: string = "An unexpected error occurred. Try again"
  ) {
    this._value = value;
    this._msg = msg;
  }

  get errorDescription() {
    return this._msg;
  }

  is404() {
    return this._value === SupabaseAPIError.notfound;
  }

  isBadRequest() {
    return this._value === SupabaseAPIError.badrequest;
  }

  get isNetworkError() {
    return this._value === SupabaseAPIError.addressUnreachable;
  }

  get isLoginError() {
    return this._value === SupabaseAPIError.loginError;
  }

  get isUnexpectedError() {
    return this._value === SupabaseAPIError.unexpected;
  }

  get isInvalidTokenError() {
    return this._value === SupabaseAPIError.invalidToken;
  }

  get isServerError() {
    return this._value === SupabaseAPIError.serverError;
  }

  update(newError: SupabaseAPIError, newMsg: string) {
    this._value = newError;
    this._msg = newMsg;
  }
}

export class Endpoint {
  private baseURL = "https://drqpobiziyoxsgfjvela.supabase.co";
  private apiVersionPath = "/auth/v1/";
  private _value = SupabaseEndpoint.signup;

  /**
   *
   */
  constructor(value: SupabaseEndpoint) {
    this._value = value;
  }

  get url(): string | null {
    switch (this._value) {
      case SupabaseEndpoint.signup:
      case SupabaseEndpoint.logout:
      case SupabaseEndpoint.recover:
      case SupabaseEndpoint.user:
      case SupabaseEndpoint.token:
        this.apiVersionPath = "/auth/v1/";
        return `${this.baseURL}${this.apiVersionPath}${this._value}`;
      case SupabaseEndpoint.profile:
      case SupabaseEndpoint.product:
      case SupabaseEndpoint.inventory:
      case SupabaseEndpoint.productDetails:
      case SupabaseEndpoint.order:
      case SupabaseEndpoint.inventoryOrder:
      case SupabaseEndpoint.favorites:
      case SupabaseEndpoint.review:
      case SupabaseEndpoint.orderDetails:
      case SupabaseEndpoint.lookupTables:
      case SupabaseEndpoint.promoproduct:
        this.apiVersionPath = "/rest/v1/";
        return `${this.baseURL}${this.apiVersionPath}${this._value}`;
      case SupabaseEndpoint.fulfillmentStatus:
        this.apiVersionPath = "/rest/v1/rpc/";
        return `${this.baseURL}${this.apiVersionPath}${this._value}`;
      default:
        break;
    }

    return null;
  }

  get value(): SupabaseEndpoint {
    return this._value;
  }

  set value(newValue: SupabaseEndpoint) {
    this._value = newValue;
  }
}

export type NumberResult = Result<number, APIError>;

export class SupabaseAPIManager {
  static shared = new SupabaseAPIManager();
  private endpoint = new Endpoint(SupabaseEndpoint.signup);
  private apiError = new APIError(
    SupabaseAPIError.notfound,
    "An unexpected error occurred"
  );
  private paginationCt = 10;
  private inventoryOrdersPaginationCt = 10;
  private _favoritesPerPage = 10;
  private _reviewsPerPg = 10;
  private refreshedAuthUser: AuthUser | null = null;

  static options = {
    ignoreHeaders: true,
  };

  // private client = applyCaseMiddleware(axios.create(), SupabaseAPIManager.options)
  private client = applyCaseMiddleware(axios.create(), {
    ignoreHeaders: true,
  }); //.interceptors.response.use()

  get productsPerPage(): number {
    return this.paginationCt;
  }

  get inventoryOrdersPerPage(): number {
    return this.inventoryOrdersPaginationCt;
  }

  get favoritesPerPage(): number {
    return this._favoritesPerPage;
  }

  get reviewsPerPg(): number {
    return this._reviewsPerPg;
  }

  get refreshedAuth(): AuthUser | null {
    return this.refreshedAuthUser;
  }

  // Intercepts response errors of invalid access token
  useInvalidTokenInterceptor(
    refreshToken: string,
    apiKey: string,
    useCaseMiddleware: boolean = true
  ): AxiosInstance {
    const options = {
      ignoreHeaders: true,
    };

    this.refreshedAuthUser = null;

    const axiosInstance = useCaseMiddleware
      ? applyCaseMiddleware(axios.create(), options)
      : axios.create();
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        let originalConfig = error.config;
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const refreshTokenResult = await this.refreshAccessToken(
            refreshToken,
            apiKey
          );
          if (refreshTokenResult.type === ResultType.Success) {
            originalConfig.headers = {
              ...originalConfig.headers,
              Authorization: `Bearer ${refreshTokenResult.value.access_token}`,
            };
            this.refreshedAuthUser = refreshTokenResult.value;
            return axiosInstance(originalConfig);
          }
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

  /**
   * Registers a new user, adds it to users table
   * @param email email of user
   * @param password password of user
   * @returns
   */
  async addAuthUser(
    apiKey: string,
    email: string,
    password: string,
    profileData: UserProfile
  ): Promise<Result<AuthUser, APIError>> {
    let result: Result<AuthUser, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    // update endpoint
    this.endpoint.value = SupabaseEndpoint.signup;
    //const { SUPABASE_API_KEY } = process.env;

    const endpointURL = this.endpoint.url;

    if (!endpointURL) return result;

    const headers = {
      apikey: apiKey,
    };

    // await this.client.post<AuthUser>(
    try {
      const response = await axios.post<AuthUser>(
        endpointURL,
        {
          email,
          password,
          data: profileData,
        },
        { headers }
      );
      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async addProductToFavorites(
    productId: string,
    variantId: string,
    apiKey: string,
    userId: string,
    accessToken: string,
    refreshToken: string
  ): Promise<Result<AuthFavoriteItem, APIError>> {
    let result: Result<AuthFavoriteItem, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    // update endpoint
    this.endpoint.value = SupabaseEndpoint.favorites;

    const endpointURL = this.endpoint.url;

    if (!endpointURL) return result;

    const config = {
      headers: {
        apiKey,
        Authorization: `Bearer ${accessToken}`,
        Prefer: "return=representation",
      },
    };

    const data = {
      productId: productId,
      userId: userId,
      productDetailId: variantId,
    };

    this.refreshedAuthUser = null;
    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apiKey);
    try {
      const response = await axiosInstance.post<FavoriteItem[]>(
        endpointURL,
        data,
        config
      );
      result = {
        type: ResultType.Success,
        value: {
          authUser: this.refreshedAuthUser,
          favoriteItem: response.data[0],
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   *
   * @param apikey
   * @param accessToken
   * @param refreshToken
   * @param reviewData data to add to review table
   * @returns an object that contains: Auth info if accesstoken is refreshed; added review
   */
  async addProductReview(
    apikey: string,
    accessToken: string,
    refreshToken: string,
    reviewData: ReviewData
  ): Promise<Result<AuthReview, APIError>> {
    let result: Result<AuthReview, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.review;

    if (!this.endpoint.url) {
      return result;
    }

    const config: AxiosRequestConfig = {
      headers: {
        apikey,
        Authorization: `Bearer ${accessToken}`,
        Prefer: "return=representation",
      },
      // data: reviewData
    };

    this.refreshedAuthUser = null;
    const endpointURL = new URL(this.endpoint.url);
    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apikey);

    try {
      const response = await axiosInstance.post(
        endpointURL.href,
        reviewData,
        config
      );

      result = {
        type: ResultType.Success,
        value: {
          review: response.data[0],
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async deleteProduct(
    queryItems: Map<string, string>,
    apiKey: string,
    accessToken: string,
    refreshToken: string
  ): Promise<Result<AuthDeleteProductStatus, APIError>> {
    let result: Result<AuthDeleteProductStatus, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.product;
    if (!this.endpoint.url) {
      return result;
    }

    const config = {
      headers: {
        apiKey,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const productURL = new URL(this.endpoint.url);
    productURL.searchParams.append(
      "product_id",
      `eq.${queryItems.get("product_id")}`
    );

    this.refreshedAuthUser = null;
    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apiKey);
    try {
      result = await axiosInstance.delete(productURL.href, config);
      result = {
        type: ResultType.Success,
        value: {
          status: "ok",
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      this.processError(error);
      result = { type: ResultType.Failure, error: this.apiError };
    }

    return result;
  }

  /**
   *
   * @param apikey
   * @param accessToken
   * @param refreshToken
   * @param reviewData data to add to review table
   * @returns an object that contains: Auth info if accesstoken is refreshed; added review
   */
  async deleteReview(
    apikey: string,
    accessToken: string,
    refreshToken: string,
    queryParams: Map<string, string>
  ): Promise<Result<AuthReview, APIError>> {
    let result: Result<AuthReview, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.review;

    if (!this.endpoint.url) {
      return result;
    }

    const config: AxiosRequestConfig = {
      headers: {
        apikey,
        Authorization: `Bearer ${accessToken}`,
        Prefer: "return=representation",
      },
      // data: reviewData
    };

    this.refreshedAuthUser = null;
    const endpointURL = new URL(this.endpoint.url);

    for (let [key, value] of queryParams) {
      endpointURL.searchParams.append(key, value);
    }

    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apikey);

    try {
      const response = await axiosInstance.delete(endpointURL.href, config);

      result = {
        type: ResultType.Success,
        value: {
          review: response.data[0],
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getFavorites(
    apiKey: string,
    accessToken: string,
    refreshToken: string,
    queryItems: Map<string, string>,
    startIdx?: number
  ): Promise<Result<AuthFavoriteItems, APIError>> {
    let result: Result<AuthFavoriteItems, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.favorites;
    this.refreshedAuthUser = null;

    if (!this.endpoint.url) {
      return result;
    }

    const favoritesURL = new URL(this.endpoint.url);

    let headers: DataObj = {
      apikey: apiKey,
      Authorization: `Bearer ${accessToken}`,
    };

    if (startIdx !== undefined) {
      headers["Range"] = `${startIdx}-${
        startIdx + (this._favoritesPerPage - 1)
      }`;
    }

    for (let [key, value] of queryItems) {
      favoritesURL.searchParams.append(key, `eq.${value}`);
    }
    favoritesURL.searchParams.append("select", "*");
    favoritesURL.searchParams.append("order", "created_at.desc");

    try {
      const axiosInstance = this.useInvalidTokenInterceptor(
        refreshToken,
        apiKey
      );
      const response = await axiosInstance(favoritesURL.href, {
        headers,
      });

      // if (response.data.length === 0) {
      //   throw Error("An unexpected profile error occurred");
      // }

      result = {
        type: ResultType.Success,
        value: {
          favoriteItems: response.data,
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      console.dir(error);
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async loginUser(
    email: string,
    password: string,
    apiKey: string
  ): Promise<Result<AuthUser, APIError>> {
    let result: Result<AuthUser, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };
    const headers = {
      apikey: apiKey,
    };

    this.endpoint.value = SupabaseEndpoint.token;

    if (!this.endpoint.url) {
      return result;
    }

    let url = new URL(this.endpoint.url);
    url.searchParams.append("grant_type", "password");

    try {
      let response = await axios.post(
        url.href,
        {
          email,
          password,
        },
        {
          headers,
        }
      );
      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }
    return result;
  }

  async refreshAccessToken(
    refreshToken: string,
    apiKey: string
  ): Promise<Result<AuthUser, APIError>> {
    let result: Result<AuthUser, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.token;
    const url = this.endpoint.url;

    if (!url) {
      return result;
    }

    const refreshTokenURL = new URL(url);
    refreshTokenURL.searchParams.append("grant_type", "refresh_token");

    //  const headers = {
    //    apiKey
    //  }

    const config = {
      headers: {
        apiKey,
      },
    };

    const data = {
      refresh_token: refreshToken,
    };

    try {
      // const response = await this.client.post(refreshTokenURL.href, data, config)
      const response = await axios.post(refreshTokenURL.href, data, config);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * logs out user
   * @param accessToken Access token of authenticated user
   * @param refreshToken
   * @apiKey
   * @returns status code
   */
  async logoutUser(
    accessToken: string,
    refreshToken: string,
    apiKey: string
  ): Promise<Result<AuthStatus, APIError>> {
    let result: Result<AuthStatus, APIError> = {
      type: ResultType.Failure,
      error: this.apiError,
    };

    const config = {
      headers: {
        apikey: apiKey,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    this.endpoint.value = SupabaseEndpoint.logout;
    if (!this.endpoint.url) {
      return result;
    }

    this.refreshedAuthUser = null;
    const logoutURL = new URL(this.endpoint.url);
    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apiKey);

    try {
      const response = await axiosInstance.post(logoutURL.href, null, config);
      //const response = await axios.post(logoutURL.href, null, config);
      result = {
        type: ResultType.Success,
        value: {
          status: response.status,
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async sendForgotPasswordLink(
    email: string,
    apiKey: string
  ): Promise<NumberResult> {
    let result: NumberResult = {
      type: ResultType.Failure,
      error: this.apiError,
    };

    const headers = {
      apiKey,
    };

    this.endpoint.value = SupabaseEndpoint.recover;
    if (!this.endpoint.url) {
      return result;
    }

    try {
      const response = await axios.post(
        this.endpoint.url,
        {
          email,
        },
        {
          headers,
        }
      );
      result = { type: ResultType.Success, value: response.status };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }
    return result;
  }

  /**
   * Updates user with new password
   * @param email email of user resetting password
   * @param newPassword new password
   * @param apiKey
   * @param accessToken
   * @returns
   */
  async updateUser(
    email: string,
    newPassword: string,
    apiKey: string,
    accessToken: string
  ): Promise<Result<User, APIError>> {
    let result: Result<User, APIError> = {
      type: ResultType.Failure,
      error: this.apiError,
    };

    this.endpoint.value = SupabaseEndpoint.user;
    if (!this.endpoint.url) {
      return result;
    }

    try {
      const response = await axios.put(
        this.endpoint.url,
        {
         // email,
          password: newPassword,
        },
        {
          headers: {
            apiKey,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      //console.log(error);
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getUserProfile(
    queryItems: Map<string, string>,
    accessToken: string,
    refreshToken: string,
    apiKey: string
  ): Promise<Result<AuthUserProfile, APIError>> {
    let result: Result<AuthUserProfile, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.profile;
    this.refreshedAuthUser = null;

    if (!this.endpoint.url) {
      return result;
    }

    const profileURL = new URL(this.endpoint.url);

    for (let [key, value] of queryItems) {
      profileURL.searchParams.append(key, `eq.${value}`);
    }
    profileURL.searchParams.append("select", "*");

    try {
      const axiosInstance = this.useInvalidTokenInterceptor(
        refreshToken,
        apiKey
      );
      const response = await axiosInstance(profileURL.href, {
        headers: {
          apikey: apiKey,
          Authorization: `Bearer ${accessToken}`,
          // Prefer: "return=representation",
        },
      });

      if (response.data.length === 0) {
        throw Error("An unexpected profile error occurred");
      }

      result = {
        type: ResultType.Success,
        value: {
          profile: response.data[0],
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  computeProfileData(
    firstname?: string,
    lastname?: string,
    birthdate?: string,
    email?: string,
    businessTitle?: string
  ): DataObj {
    let result: DataObj = {};

    if (firstname) {
      result["firstname"] = firstname;
    }

    if (lastname) {
      result["lastname"] = lastname;
    }

    if (birthdate) {
      result["birthdate"] = birthdate;
    }

    if (email) {
      result["email"] = email;
    }

    if (businessTitle) {
      result["business_title"] = businessTitle;
    }

    return result;
  }

  /**
   *
   * @param apikey
   * @param accessToken
   * @param refreshToken
   * @param reviewData data to add to review table
   * @returns an object that contains: Auth info if accesstoken is refreshed; added review
   */
  async updateProductReview(
    apikey: string,
    accessToken: string,
    refreshToken: string,
    queryParams: Map<string, string>,
    reviewData: ReviewData
  ): Promise<Result<AuthReview, APIError>> {
    let result: Result<AuthReview, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.review;

    if (!this.endpoint.url) {
      return result;
    }

    const config: AxiosRequestConfig = {
      headers: {
        apikey,
        Authorization: `Bearer ${accessToken}`,
        Prefer: "return=representation",
      },
      // data: reviewData
    };

    this.refreshedAuthUser = null;
    const endpointURL = new URL(this.endpoint.url);

    for (let [key, value] of queryParams) {
      endpointURL.searchParams.append(key, value);
    }

    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apikey);

    try {
      const response = await axiosInstance.patch(
        endpointURL.href,
        reviewData,
        config
      );

      result = {
        type: ResultType.Success,
        value: {
          review: response.data[0],
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async updateUserProfile(
    userId: string,
    apiKey: string,
    firstname?: string,
    lastname?: string,
    birthdate?: string,
    email?: string,
    businessTitle?: string
  ): Promise<Result<number, APIError>> {
    let result: Result<number, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };
    // const queryValue =  `eq.${userId}`
    this.endpoint.value = SupabaseEndpoint.profile;

    if (!this.endpoint.url || !userId) {
      return result;
    }

    const data = this.computeProfileData(
      firstname,
      lastname,
      birthdate,
      email,
      businessTitle
    );
    const formattedURL = new URL(this.endpoint.url);
    formattedURL.searchParams.append("id", `eq.${userId}`);

    try {
      const response = await axios.patch(formattedURL.href, data, {
        headers: {
          apiKey,
          Authorization: `Bearer ${apiKey}`,
        },
      });
      result = { type: ResultType.Success, value: response.status };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async updateUserProfileData(
    userId: string,
    apiKey: string,
    newData: Map<string, any>
  ): Promise<Result<number, APIError>> {
    let result: Result<number, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };
    // const queryValue =  `eq.${userId}`
    this.endpoint.value = SupabaseEndpoint.profile;

    if (!this.endpoint.url || !userId) {
      return result;
    }

    let data: DataObj = Object.fromEntries(newData);
    const formattedURL = new URL(this.endpoint.url);
    formattedURL.searchParams.append("id", `eq.${userId}`);

    try {
      const response = await axios.patch(formattedURL.href, data, {
        headers: {
          apiKey,
          Authorization: `Bearer ${apiKey}`,
          Prefer: "return=representation",
        },
      });
      result = { type: ResultType.Success, value: response.status };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async createUserProfile(
    userId: string,
    apiKey: string,
    firstname: string,
    lastname: string,
    birthdate: string,
    email: string,
    isPartner: boolean = false,
    businessTitle: string = ""
  ): Promise<Result<number, APIError>> {
    let result: Result<number, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };
    // const queryValue =  `eq.${userId}`
    this.endpoint.value = SupabaseEndpoint.profile;

    if (!this.endpoint.url || !userId) {
      return result;
    }

    const data = {
      id: userId,
      firstname,
      lastname,
      birthdate,
      email,
      business_title: businessTitle,
      is_partner: isPartner,
    };

    const formattedURL = new URL(this.endpoint.url);
    // debugger
    try {
      const response = await axios.post(formattedURL.href, data, {
        headers: {
          apiKey,
          Authorization: `Bearer ${apiKey}`,
          Prefer: "return=representation",
        },
      });
      result = { type: ResultType.Success, value: response.status };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * Adds a product to database table
   * @param title title of product to add to product table
   * @param inventoryId id of inventory table the product belongs
   * @param apiKey  database apiKey
   * @returns result that is a product or an api error
   */
  async addProduct(
    title: string,
    inventoryId: string,
    apiKey: string,
    accessToken: string,
    refreshToken: string
  ): Promise<Result<AuthProduct, APIError>> {
    let result: Result<AuthProduct, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.product;
    if (!this.endpoint.url) {
      return result;
    }

    // const options = {
    //   ignoreHeaders: true,
    // };

    const productURL = new URL(this.endpoint.url);
    const data = { title, inventoryId };
    // setup response interceptors
    this.refreshedAuthUser = null;
    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apiKey);

    try {
      const response = await axiosInstance.post(productURL.href, data, {
        headers: {
          apiKey,
          Authorization: `Bearer ${accessToken}`,
          Prefer: "return=representation",
        },
      });

      result = {
        type: ResultType.Success,
        value: {
          product: response.data[0],
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      // console.error(error)
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * Updates product with id  in the product table
   * @param queryItems column name and value pair to query product table
   * @param data data to update product with
   * @param apiKey database apiKey
   * @returns result the is a product on success or api error on failure
   */
  /*
  async updateProduct(
    queryItems: Map<string, string>,
    //data: DataObj,
    newDetails: Map<string, any>,
    apiKey: string
  ): Promise<Result<Product, APIError>> {
    let result: Result<Product, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    // test that there is any field not in Product
    // Object.entries(data).forEach(([field, value])=> {
    //     Object.entries(Product)
    // })

    // set endpoint
    this.endpoint.value = SupabaseEndpoint.product;
    // get url of endpoint
    const url = this.endpoint.url;
    if (!url) {
      return result;
    }
    const productURL = new URL(url);

    const options = {
      ignoreHeaders: true,
    };
    // append query items if any
    for (let [key, value] of queryItems) {
      productURL.searchParams.append(key, `eq.${value}`);
    }

    const data = Object.fromEntries(newDetails);

    // make request

    try {
      const client = applyCaseMiddleware(axios.create(), options);
      const response = await client.patch(productURL.href, data, {
        headers: {
          apiKey,
          Authorization: `Bearer ${apiKey}`,
          Prefer: "return=representation",
        },
      });
      result = {
        type: ResultType.Success,
        value: response.data[0],
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }*/

  /**
   * Updates a product in the product table.
   * Sets  properties of a product with values from a map, using its keys to identify the properties
   * @param queryItems column name and value pair to query product table
   * @param keyedValues new details to update product with
   * @param apiKey database apiKey
   * @returns result the is a product on success or api error on failure
   */
  async updateProduct(
    queryItems: Map<string, string>,
    keyedValues: Map<string, any>,
    apiKey: string,
    accessToken: string,
    refreshToken: string
  ): Promise<Result<AuthProduct, APIError>> {
    let result: Result<AuthProduct, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.refreshedAuthUser = null;
    // set endpoint
    this.endpoint.value = SupabaseEndpoint.product;
    // get url of endpoint
    const url = this.endpoint.url;
    if (!url) {
      return result;
    }

    const productURL = new URL(url);

    // const options = {
    //   ignoreHeaders: true,
    // };
    // append query items if any
    for (let [key, value] of queryItems) {
      productURL.searchParams.append(key, `eq.${value}`);
    }

    let data = Object.fromEntries(keyedValues);

    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apiKey);
    // make request

    try {
      const response = await axiosInstance.patch(productURL.href, data, {
        headers: {
          apiKey,
          Authorization: `Bearer ${accessToken}`,
          Prefer: "return=representation",
        },
      });

      result = {
        type: ResultType.Success,
        value: {
          product: response.data[0],
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async updateInventoryOrder(
    queryItems: Map<string, string>,
    keyedValues: Map<string, any>,
    apiKey: string,
    auth: AuthUser
  ): Promise<Result<AuthInventoryOrder, APIError>> {
    let result: Result<AuthInventoryOrder, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };
    
    this.refreshedAuthUser = null;
    // set endpoint
    this.endpoint.value = SupabaseEndpoint.inventoryOrder;
    // get url of endpoint
    const url = this.endpoint.url;
    if (!url) {
      return result;
    }

    let params = Object.fromEntries(queryItems)
    for (let field in params) {
      params[field] = `eq.${params[field]}`
    }

    let data = Object.fromEntries(keyedValues);

    const axiosInstance = this.useInvalidTokenInterceptor(auth.refresh_token, apiKey);
    // make request

    try {
      const response = await axiosInstance.patch(url, data, {
        headers: {
          apiKey,
          Authorization: `Bearer ${auth.access_token}`,
          Prefer: "return=representation",
        },
        params
      });

      result = {
        type: ResultType.Success,
        value: {
          inventoryOrder: {
            ...response.data[0],
            orderDate: new Date(response.data[0].orderDate)
          },
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }


  /**
   * Updates a product in the product table.
   * Sets  properties of a product with values from a map, using its keys to identify the properties
   * @param queryItems column name and value pair to query product table
   * @param keyedValues new details to update product with
   * @param apiKey database apiKey
   * @returns result the is a product on success or api error on failure
   */
  async updateProductDetails(
    queryItems: Map<string, string>,
    keyedValues: Map<string, any>,
    apiKey: string,
    accessToken: string,
    refreshToken: string
  ): Promise<Result<AuthProductVariant, APIError>> {
    let result: Result<AuthProductVariant, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.refreshedAuthUser = null;
    // set endpoint
    this.endpoint.value = SupabaseEndpoint.productDetails;
    // get url of endpoint
    const url = this.endpoint.url;
    if (!url) {
      
      return result;
    }

    const productDetailsURL = new URL(url);

    // append query items if any
    for (let [key, value] of queryItems) {
      productDetailsURL.searchParams.append(key, `eq.${value}`);
    }



    
    let data = Object.fromEntries(keyedValues);
    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apiKey);
    // make request
    //console.log(productDetailsURL.href)
    try {
      const response = await axiosInstance.patch(productDetailsURL.href, data, {
        headers: {
          apiKey,
          Authorization: `Bearer ${accessToken}`,
          Prefer: "return=representation",
        },
      });

      result = {
        type: ResultType.Success,
        value: {
          variant: response.data[0],
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getFulfillmentStatus(
    status: FulfillmentStatus,
    inventoryId: string,
    apiKey: string,
    accessToken: string,
    refreshToken: string
  ): Promise<Result<AuthFulfillmentStatus, APIError>> {
    let result: Result<AuthFulfillmentStatus, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.fulfillmentStatus;
    this.refreshedAuthUser = null;
    if (!this.endpoint.url) {
      return result;
    }

    // const reviewsURL = new URL(this.endpoint.url);

    // for (let [key, value] of queryItems) {
    //   reviewsURL.searchParams.append(key, value);
    // }

    const headers: DataObj = {
      apiKey,
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const axiosInstance = this.useInvalidTokenInterceptor(
        refreshToken,
        apiKey
      );

      const response = await axiosInstance(this.endpoint.url, {
        headers,
        params: {
          status,
          partner_inventory_id: inventoryId,
        },
      });

      result = {
        type: ResultType.Success,
        value: {
          data: response.data,
          authUser: this.refreshedAuthUser
        }
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * Get an inventory row from table
   * @param queryItems column name, column value pair tor filtering the inventory table
   * @param accessToken
   * @param apiKey
   * @returns
   */
  async getInventoryRow(
    queryItems: Map<string, string>,
    accessToken: string,
    refreshToken: string,
    apiKey: string
  ): Promise<Result<AuthInventoryList, APIError>> {
    let result: Result<AuthInventoryList, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.inventory;
    this.refreshedAuthUser = null;

    if (!this.endpoint.url) {
      return result;
    }

    const inventoryURL = new URL(this.endpoint.url);

    for (let [key, value] of queryItems) {
      inventoryURL.searchParams.append(key, `eq.${value}`);
    }
    inventoryURL.searchParams.append("select", "*");

    try {
      const axiosInstance = this.useInvalidTokenInterceptor(
        refreshToken,
        apiKey
      );

      const response = await axiosInstance(inventoryURL.href, {
        headers: {
          apikey: apiKey,
          Authorization: `Bearer ${accessToken}`,
          Prefer: "return=representation",
        },
      });

      result = {
        type: ResultType.Success,
        value: {
          inventoryList: response.data,
          authUser: this.refreshedAuthUser,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * Gets rows from product colors lookup table
   * @param apiKey
   * @returns an array of productCategory objects
   */
  async getLookupTables(
    apiKey: string
  ): Promise<Result<LookupTableModel[], APIError>> {
    let result: Result<LookupTableModel[], APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.lookupTables;
    if (!this.endpoint.url) {
      return result;
    }

    const lookupTableURL = new URL(this.endpoint.url);
    lookupTableURL.searchParams.append(
      "select",
      "*,categories_details(*),accessories_details(*),colors_details(*),sizes_details(*),subcategories_details(*),price_ranges_details(*)"
    );

   // console.log(lookupTableURL.toString())
   // Get individual components
 
 

 
    try {
      const response = await axios(lookupTableURL.href, {
        headers: {
          apiKey,
          Authorization: `Bearer ${apiKey}`,
        },
      });

      result = {
        type: ResultType.Success,
        value: response.data,
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getPromoProducts(apikey: string) : Promise<Result<PromoProduct[], APIError>> {

    let result: Result<PromoProduct[], APIError> = {
      type: ResultType.Failure,
      error: APIError.default
    }

    this.endpoint.value = SupabaseEndpoint.promoproduct
    if (this.endpoint.url == null) {return result}

    const promoProductURL = new URL(this.endpoint.url)
    promoProductURL.searchParams.append("select", "*")
    promoProductURL.searchParams.append("is_active", "eq.true")

    try {
      const response = await axios(promoProductURL.href, {
        headers: {
          apikey,
          Authorization: `Bearer ${apikey}`
        }
      })

      result = {
        type: ResultType.Success,
        value: response.data
      }
    } catch(error)  {
      this.processError(error)
      result = {
        type: ResultType.Failure,
        error: this.apiError
      }
    }

    return result

  }

  async getOrderDetailsForProduct(
    apiKey: string,
    accessToken: string,
    queryItems: Map<string, string>
  ): Promise<Result<OrderDetail[], APIError>> {
    let result: Result<OrderDetail[], APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.orderDetails;

    if (!this.endpoint.url) {
      return result;
    }

    let orderDetailsURL = new URL(this.endpoint.url);

    for (let [key, value] of queryItems) {
      orderDetailsURL.searchParams.append(key, value);
    }

    const options = {
      ignoreHeaders: true,
    };

    try {
      const axiosInstance = applyCaseMiddleware(axios.create(), options);

      const response = await axiosInstance(orderDetailsURL.href, {
        headers: {
          apiKey,
          Authorization: `Bearer ${accessToken}`,
          //Prefer: "return=representation",
          // Range: `${rangeStart}-${rangeStart + (this._reviewsPerPg - 1)}`,
        },
      });

      result = {
        type: ResultType.Success,
        value: response.data,
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getProductReviews(
    apiKey: string,
    queryItems: Map<string, string>,
    rangeStart?: number
  ): Promise<Result<Review[], APIError>> {
    let result: Result<Review[], APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.review;

    if (!this.endpoint.url) {
      return result;
    }

    const reviewsURL = new URL(this.endpoint.url);

    for (let [key, value] of queryItems) {
      reviewsURL.searchParams.append(key, value);
    }

    const headers: DataObj = {
      apiKey,
      Authorization: `Bearer ${apiKey}`,
    };

    if (rangeStart !== undefined) {
      headers["Range"] = `${rangeStart}-${
        rangeStart + (this._reviewsPerPg - 1)
      }`;
    }

    const options = {
      ignoreHeaders: true,
    };

    try {
      //const client = applyCaseMiddleware(axios.create(), options)
      const axiosInstance = applyCaseMiddleware(axios.create(), options);

      const response = await axiosInstance(reviewsURL.href, {
        headers,
      });

      result = {
        type: ResultType.Success,
        value: response.data,
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * gets rows from the product table
   * @param queryItems
   * @param apiKey
   * @returns
   */
  async getProductRows(
    queryItems: Map<string, string>,
    apiKey: string,
    rangeStart: number
  ): Promise<Result<Product[], APIError>> {
    let result: Result<Product[], APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.product;

    if (!this.endpoint.url) {
      return result;
    }

    const productURL = new URL(this.endpoint.url);

    for (let [key, value] of queryItems) {
      productURL.searchParams.append(key, `eq.${value}`);
    }
    productURL.searchParams.append("order", `lastupdated.desc`);

    try {
      //const client = applyCaseMiddleware(axios.create(), options)

      const response = await this.client(productURL.href, {
        headers: {
          apiKey,
          Authorization: `Bearer ${apiKey}`,
          Prefer: "return=representation",
          Range: `${rangeStart}-${rangeStart + (this.paginationCt - 1)}`,
        },
      });

      result = {
        type: ResultType.Success,
        value: response.data,
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * Gets user data from database
   * @param accessToken
   * @param apikey
   * @returns A User result if successful or an APIError if not
   */
  async getUser(
    accessToken: string,
    apikey: string
  ): Promise<Result<User, APIError>> {
    let result: Result<User, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.user;

    if (!this.endpoint.url) {
      return result;
    }

    const userURL = new URL(this.endpoint.url);
    const requestConfig = {
      headers: {
        apikey,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios(userURL.href, requestConfig);
      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async addVariantsForProductId(
    productId: string,
    apikey: string,
    accessToken: string,
    refreshToken: string,
    variants: Map<string, any>[]
  ): Promise<Result<AuthProductVariantList, APIError>> {
    let result: Result<AuthProductVariantList, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.productDetails;
    if (!this.endpoint.url) {
      return result;
    }

    this.refreshedAuthUser = null;
    const productDetailsURL = new URL(this.endpoint.url);
    productDetailsURL.searchParams.append("productId", `eq.${productId}`);
    const requestConfig = {
      headers: {
        apikey,
        Authorization: `Bearer ${accessToken}`,
        Prefer: "return=representation",
      },
    };
    let data = variants.map((variant) => Object.fromEntries(variant));
    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apikey);
    try {
      const response = await axiosInstance.post(
        productDetailsURL.href,
        data,
        requestConfig
      );
      result = {
        type: ResultType.Success,
        value: {
          authUser: this.refreshedAuthUser,
          variantsList: response.data,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async deleteProductDetailsForId(
    productId: string,
    apikey: string,
    accessToken: string,
    refreshToken: string
  ): Promise<Result<AuthStatus, APIError>> {
    let result: Result<AuthStatus, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.productDetails;
    if (!this.endpoint.url) {
      return result;
    }

    this.refreshedAuthUser = null;
    const productDetailsURL = new URL(this.endpoint.url);
    productDetailsURL.searchParams.append("product_id", `eq.${productId}`);
    const requestConfig = {
      headers: {
        apikey,
        Authorization: `Bearer ${accessToken}`,
        //  Prefer: "return=representation",
      },
    };

    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apikey);
    try {
      const response = await axiosInstance.delete(
        productDetailsURL.href,
        requestConfig
      );
      result = {
        type: ResultType.Success,
        value: {
          authUser: this.refreshedAuthUser,
          status: response.status,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /*
  async getProductDetailForId( 
    productId: string,
    apikey: string,
    accessToken: string,
    refreshToken: string): Promise<Result<AuthProduct, APIError>> {
      let result: Result<AuthProduct, APIError> = {
        type: ResultType.Failure,
        error: APIError.default,
      };
  
      this.endpoint.value = SupabaseEndpoint.product;
      if (!this.endpoint.url) {
        return result;
      }
  
      this.refreshedAuthUser = null;
      const productDetailsURL = new URL(this.endpoint.url);
      productDetailsURL.searchParams.append("select", `*,product_details(*)`);
      productDetailsURL.searchParams.append("product_id", `eq.${productId}`);

      const requestConfig = {
        headers: {
          apikey,
          Authorization: `Bearer ${accessToken}`,
        //  Prefer: "return=representation",
        },
      };
     
      const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apikey);
      try {
        const response = await axiosInstance(
          productDetailsURL.href,
          requestConfig
        );
        result = {
          type: ResultType.Success,
          value: {
            authUser: this.refreshedAuthUser,
            product: response.data[0],
          },
        };
      } catch (error) {
        this.processError(error);
        result = {
          type: ResultType.Failure,
          error: this.apiError,
        };
      }
  
      return result;
    }*/

  // retrives one or more orders for products of an inventory

  async getOrders(
    queryParams: Map<string, string>,
    apikey: string,
    accessToken: string,
    refreshToken: string,
    rangeStart?: number
  ): Promise<Result<AuthOrderList, APIError>> {
    let result: Result<AuthOrderList, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.order;
    if (!this.endpoint.url) {
      return result;
    }

    this.refreshedAuthUser = null;
    const orderDetailsURL = new URL(this.endpoint.url);
    orderDetailsURL.searchParams.append("select", `*,order_details(*)`);

    for (let [key, value] of queryParams) {
      orderDetailsURL.searchParams.append(key, value);
    }
    // productDetailsURL.searchParams.append("order", `lastupdated.desc`);

    const headers: DataObj = {
      apikey,
      Authorization: `Bearer ${accessToken}`,
    };

    if (rangeStart !== undefined) {
      headers["Range"] = `${rangeStart}-${
        rangeStart + (this.paginationCt - 1)
      }`;
    }

    const requestConfig = {
      headers,
    };

    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apikey);
    try {
      const response = await axiosInstance(orderDetailsURL.href, requestConfig);
      result = {
        type: ResultType.Success,
        value: {
          authUser: this.refreshedAuthUser,
          orders: response.data,
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getInventoryOrders(
    queryParams: Map<string, string>,
    apikey: string,
    accessToken: string,
    refreshToken: string,
    rangeStart?: number
  ): Promise<Result<AuthInventoryOrderList, APIError>> {
    let result: Result<AuthInventoryOrderList, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.inventoryOrder;
    if (!this.endpoint.url) {
      return result;
    }

    this.refreshedAuthUser = null;
    const orderDetailsURL = new URL(this.endpoint.url);
    orderDetailsURL.searchParams.append("select", `*,order_details(*)`);

    for (let [key, value] of queryParams) {
      orderDetailsURL.searchParams.append(key, value);
    }
    // productDetailsURL.searchParams.append("order", `lastupdated.desc`);

    const headers: DataObj = {
      apikey,
      Authorization: `Bearer ${accessToken}`,
    };

    if (rangeStart !== undefined) {
      headers["Range"] = `${rangeStart}-${
        rangeStart + (this.inventoryOrdersPaginationCt - 1)
      }`;
    }

    //console.dir(queryParams)
    // console.dir(Object.fromEntries(queryParams))
    let params = {
      ...Object.fromEntries(queryParams),
      select: "*,order_details(*)",
    };
    //paramsObj["select"] = "*,order_details(*)"

    const requestConfig: AxiosRequestConfig = {
      headers,
      params,
    };

    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apikey);
     try {
      const response = await axiosInstance(this.endpoint.url, requestConfig);

      result = {
        type: ResultType.Success,
        value: {
          authUser: this.refreshedAuthUser,
          inventoryOrdersList: response.data.map((order: any) => ({
            ...order,
            orderDate: new  Date(order.orderDate),
          })),
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  async getProductDetailForId(
    queryParams: Map<string, string>,
    apikey: string,
    rangeStart?: number
  ): Promise<Result<AuthProductList, APIError>> {
    let result: Result<AuthProductList, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    this.endpoint.value = SupabaseEndpoint.product;
    if (!this.endpoint.url) {
      return result;
    }

    // this.refreshedAuthUser = null;
    const queryParamsObj: DataObj = {};
    const productDetailsURL = new URL(this.endpoint.url);
    if (!queryParams.has("select")) {
      queryParamsObj["select"] = `*,product_details(*)`;
    }

    for (let [key, value] of queryParams) {
      queryParamsObj[key] = value;
    }
    queryParamsObj["order"] = `lastupdated.desc`;

    const headers: DataObj = {
      apikey,
      Authorization: `Bearer ${apikey}`,
    };

    if (rangeStart !== undefined) {
      headers["Range"] = `${rangeStart}-${
        rangeStart + (this.paginationCt - 1)
      }`;
    }

    const requestConfig: AxiosRequestConfig<any> = {
      headers,
      params: queryParamsObj,
    };

    const options = {
      ignoreHeaders: true,
      preservedKeys: (input: any) => input.includes("."),
    };

    const axiosInstance = applyCaseMiddleware(axios.create(), options);
    try {
      const response = await axiosInstance(
        productDetailsURL.href,
        requestConfig
      );

      result = {
        type: ResultType.Success,
        value: {
          authUser: this.refreshedAuthUser,
          productsList: response.data,
        },
      };
    } catch (error) {
      console.dir(error);
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * Selects the appropriate error data and updates apiError with it
   * @param error error returned from server
   */
  private processError(error: unknown): void {
    console.dir(error);
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        //if (error.response) {
        // console.error(error.response);
        this.updateAPIError(error.response.data, error.response.status);
      } else if (error.code === "ERR_NETWORK") {
        //else if (error.request) {
        this.apiError.update(
          SupabaseAPIError.addressUnreachable,
          "Diamond Crust seems to be offline"
        );
      }
    } else {
      this.apiError.update(
        SupabaseAPIError.unexpected,
        "An unexpected error occurred"
      );
    }
  }

  /**
   * Updates apiError with response error data
   * @param responseErrorData
   */
  private updateAPIError(responseErrorData: unknown, status: number) {
    const statusCode = status;
    // cast error data to type returned by signup endpoint
    const signupError = responseErrorData as SupabaseError;
    //debugger;

    const msg = signupError.msg || signupError.message || "";

    if (statusCode >= 500 && statusCode < 600) {
      this.apiError.update(
        SupabaseAPIError.serverError,
        "Something went wrong. Probably from the server"
      );
      return;
    }
    if (
      this.endpoint.value === SupabaseEndpoint.signup ||
      this.endpoint.value === SupabaseEndpoint.recover ||
      this.endpoint.value === SupabaseEndpoint.user ||
      this.endpoint.value === SupabaseEndpoint.product ||
      this.endpoint.value === SupabaseEndpoint.inventory ||
      this.endpoint.value === SupabaseEndpoint.lookupTables
    ) {
      switch (true) {
        case statusCode === 422:
          this.apiError.update(SupabaseAPIError.signupError, msg);
          break;
        case statusCode === 400:
          this.apiError.update(
            SupabaseAPIError.badrequest,
            msg || "missing input"
          );
          break;
        case statusCode === 401:
          this.apiError.update(
            SupabaseAPIError.invalidToken,
            msg || "invalid token"
          );
          break;
      }
    } else if (this.endpoint.value === SupabaseEndpoint.token) {
      const { error_description } = responseErrorData as LoginError;
      switch (true) {
        case statusCode === 400:
          this.apiError.update(
            SupabaseAPIError.loginError,
            error_description || "Something went wrong"
          );
          break;
        case statusCode === 401:
          console.log("Invalid Token");
          this.apiError.update(
            SupabaseAPIError.invalidToken,
            error_description || "An unexpected error occurred"
          );
          break;
      }
    } else if (this.endpoint.value === SupabaseEndpoint.logout) {
      if (statusCode === 401) {
        console.log("Invalid Token");
        this.apiError.update(
          SupabaseAPIError.invalidToken,
          msg || "An unexpected error occurred"
        );
      }
    } else {
      this.apiError.update(
        SupabaseAPIError.unexpected,
        "An unexpected error occurred"
      );
    }
  }

  async removeProductFromFavorites(
    apiKey: string,
    accessToken: string,
    refreshToken: string,
    queryItems: Map<string, string>
  ): Promise<Result<AuthFavoriteItem, APIError>> {
    let result: Result<AuthFavoriteItem, APIError> = {
      type: ResultType.Failure,
      error: APIError.default,
    };

    // update endpoint
    this.endpoint.value = SupabaseEndpoint.favorites;

    if (!this.endpoint.url) return result;

    const favoritesURL = new URL(this.endpoint.url);

    const config = {
      headers: {
        apiKey,
        Authorization: `Bearer ${accessToken}`,
        Prefer: "return=representation",
      },
    };

    for (let [key, value] of queryItems) {
      favoritesURL.searchParams.append(key, value);
    }

    this.refreshedAuthUser = null;
    const axiosInstance = this.useInvalidTokenInterceptor(refreshToken, apiKey);
    try {
      const response = await axiosInstance.delete(favoritesURL.href, config);
      if (response.data.length === 0) {
        return result
      }
      result = {
        type: ResultType.Success,
        value: {
          authUser: this.refreshedAuthUser,
          favoriteItem: response.data[0],
        },
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }
}

const supabaseAPIManager = SupabaseAPIManager.shared;
export default supabaseAPIManager;
