import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import { Inventory, ShoppingApp } from "../../routes";
import PCAppBarMenuButton from "../SupportingViews/PCAppBarMenuButton";
import { menuStyle } from "../../utils/styles";
import { UserAccountContext } from "../../contexts/UserAccountContext";
import { PresentationContext } from "../../contexts/PresentationContext";
import Authentication from "../PCAccount/Authentication/Authentication";
import { Dialog } from "@mui/material";
import { appURL } from "../../defaultValues";
import { FormType } from "../Providers/AccountsProvider";
import { APIError } from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import DCError from "../SupportingViews/DCError";
import { AppDataContext } from "../../contexts/AppDataContext";

const SellerOnBoarding = () => {

  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDialogPresented, setIsDialogPresented] = useState<boolean>(false);
  const [error, setError] = useState<APIError | null>(null);
  const [, setLoading] = useState<boolean>(false);

  const {
    getSignedInUser,
    signoutUser,
    getUserprofile,
     updateCurrentform,
     accountModalIsPresented,
     toggleAccountModal,
     hasSelectedFormWithType,
      accountRequestStatus,
  } = useContext(UserAccountContext);
  const appData = useContext(AppDataContext);

  const open = Boolean(anchorEl);
  const id = open ? "auth-popover" : undefined;

  const isShowingLoginForm =
    hasSelectedFormWithType(FormType.Login) ||
    hasSelectedFormWithType(FormType.Signup);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeDialog = (event: object, reason: string) => {
    setIsDialogPresented(false);
  };

  const handleLogout = useCallback(async () => {
    setLoading(true);
    const result = await signoutUser();

    const apiError = result as APIError;
    if (apiError.errorDescription) {
      setError(apiError);
      setLoading(false);
      return;
      // show error
    }

    setAnchorEl(null);
    window.location.href = appURL + location.pathname;
  }, [location.pathname, signoutUser]);

  const loginUser = () => {
    updateCurrentform(FormType.Login);
    appData.setPreAuthPathname(location.pathname);
    setIsDialogPresented(!isDialogPresented);
  }

  const accountRequestError = ((): APIError | null => {
    const apiError = accountRequestStatus as APIError;
    return apiError.errorDescription ? apiError : null;
  })();

  const topAppBar = (() => {
    return (
      <AppBar sx={{display: "none"}} position="fixed">
        <Toolbar sx={{ width: "100%" }}>
          <Box
            sx={{ textDecoration: "none" }}
            to={ShoppingApp.about}
            component={RouterLink}
            display={"flex"}
            alignItems="center"
            justifyContent={"spaceBetween"}
            flexGrow={1}
          >
            <Box
              sx={{
                width: { xs: "3rem", sm: "3rem", md: "3rem" },
                height: { xs: "3rem", sm: "3rem", md: "3rem" },
                // margin: "0 auto",
              }}
              component={"img"}
              src={`${process.env.PUBLIC_URL}/logo192.png`}
            />
            <Typography
              color={"primary.dark"}
              variant="h6"
              noWrap
              component="div"
            >
              Prettycharm
            </Typography>
          </Box>
          <PCAppBarMenuButton
            sx={menuStyle}
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            userProfile={getUserprofile()}
            user={getSignedInUser()}
            loginAction={loginUser}
            logoutAction={()=>{handleLogout()}}
            successAction={()=>appData.gotoSellerApp(Inventory.products)}
            handleClick={handleClick}
            handleClose={handleClose}
            signupAction={loginUser}
            shouldAllowSignup={false}
          />
        </Toolbar>
      </AppBar>
    );
  })();

  useEffect(() => {
    if (isShowingLoginForm) {
      setIsDialogPresented(true);
    }
  }, [isShowingLoginForm]);

 
  return (
    <Stack width="fullWidth">
       {topAppBar}
       <Dialog
        fullScreen={true}
        onClose={closeDialog}
        open={accountModalIsPresented}
        sx={{
          ".css-qs0bdh-MuiStack-root": {
            width: "100%",
          },
          ".css-1d9cypr-MuiStack-root": {
            width: "100%",
          },
          "& .MuiPaper-root": {
            margin: {
              md: "2rem",
            },
          },
        }}
      >
        <PresentationContext.Provider
          value={{
            dismiss: () => toggleAccountModal(),
            isPresented: accountModalIsPresented,
          }}
        >
          <Authentication />
        </PresentationContext.Provider>
      </Dialog>
      <Outlet />
      <DCError error={accountRequestError} />
      <DCError error={error} onErrorDisplayed={() => setError(null)} />
     
    </Stack>
  );
};

export default SellerOnBoarding;
