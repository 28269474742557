import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FormikHelpers,
} from "formik";
import React, { useContext, useState } from "react";
import { AppDataContext } from "../../../contexts/AppDataContext";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import { AuthUploadedAsset } from "../../../Models/Cloudinary";
import { AuthDataObj, DataObj } from "../../../Models/DataObj";
import { LookupTable } from "../../../Models/LookupTables";

import { ProductVariantFormData } from "../../../Models/Product";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import DCError from "../../SupportingViews/DCError";
import { DCNumericField } from "../../SupportingViews/InputFields";
import ProductPhotosGridView from "../../SupportingViews/ProductPhotosGridView";
import DCCheckbox from "../../SupportingViews/ShowPassword";
import { InventoryContext } from "../contexts/InventoryContext";
//import { getPercentOff } from "../../../utils";

interface ProductVariantEditorProps {
  variantIndex: number;
  draft: ProductVariantFormData;
  arrayHelpers: FieldArrayRenderProps;

  inventoryId: string;
  onPhotoUploaded: (photo: any | null) => void;
  onPhotoDeleted: (photoPublicId: string | null) => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductVariantEditor = ({
  variantIndex: index,
  draft,
  arrayHelpers,
  inventoryId,
  onPhotoUploaded,
  onPhotoDeleted,
}: ProductVariantEditorProps) => {
 
  const { updateProductPhoto, deletePhotoForVariant } =
    useContext(InventoryContext);
  const [error, setError] = useState<APIError | null>(null);
  const [removeDetails, setRemoveDetails] = useState<string | null>(null);
  
  const { getLookuptableForType } = useContext(AppDataContext);
  const { dispatch } = useContext(NotificationContext);
  const accountManager = useContext(UserAccountContext);
  const isMediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.up("md")
  );
  const isSmallScreenAndAbove = useMediaQuery((theme: any) =>
    theme.breakpoints.up("sm")
  );
  const user = accountManager.getSignedInUser();

  const colorOptions = getLookuptableForType(LookupTable.color);
  const sizeOptions = getLookuptableForType(LookupTable.size);
  const colorOption = colorOptions.find(
    (item) => item.color_id === arrayHelpers.form.values.variants[index].colorId
  );

   
  const sizeOption = sizeOptions.find(
    (item) => item.size_id === arrayHelpers.form.values.variants[index].sizeId
  );

  const photosStoragePath = `inventories/${inventoryId}/${draft.productId}/variants/${draft.id}/photos/`;

  const anchorOrigin = ((): SnackbarOrigin => {
    return isMediumScreen
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "center",
        };
  })();

  const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
    if (!user) {
      return;
    }

    const fieldName = Object.keys(values)[0];

    const result = await updateProductPhoto(
      values[fieldName],
      draft.productId,
      fieldName,
      photosStoragePath,
      user.access_token,
      user.refresh_token,
      draft.id
    );

   

    const error = result as APIError;
    // let emptyPhotoObj: {photo1: Photo} = {photo1: {url: "", publicId: ""}}
    let emptyPhotoObj: DataObj = {};
    emptyPhotoObj[fieldName] = undefined;

    if (error.errorDescription) {
      setError(error);
      onPhotoUploaded(null);
      // actions.resetForm()
      actions.setFieldValue(fieldName, undefined);
      return;
    }

    onPhotoUploaded(values);

    const { authUser: refreshedUser } = result as AuthUploadedAsset;

    if (refreshedUser) {
      accountManager.saveCredentials(refreshedUser);
    }
  };

  const handleDeletePhoto = async (photoIndex: number, key: string) => {
    if (!user) {
      return;
    }

    //setPhotoToDelete(key);
    onPhotoDeleted(key);
    const result = await deletePhotoForVariant(
      draft.id,
      key,
      user.access_token,
      user.refresh_token
    );

    // do we have an error
    const error = result as APIError;
    if (error.errorDescription) {
      onPhotoDeleted(null);
      setError(error);
      return;
    }

    const { authUser } = result as AuthDataObj;
    if (authUser) {
      accountManager.saveCredentials(authUser);
    }

    setRemoveDetails(
      `Photo${photoIndex + 1} for variant${index + 1} deleted successfully`
    );
  };

  const handleClose = () => {
    setRemoveDetails(null);
    setError(null);
  };

  const direction = (() => {
    return isSmallScreenAndAbove ? "row" : "column";
  })();

  const spacing = (() => {
    return isSmallScreenAndAbove ? 4 : 2;
  })();

  const addPhotoSection = (() => {
    return (
      <Stack>
        <Typography variant="headline">
          Variant {(index + 1).toString()}
        </Typography>

        <FieldArray
          name={`${arrayHelpers.name}.${index}.photos`}
          render={(photosArrayHelpers) => {
            return (
              <Field
                name={`${photosArrayHelpers.name}`}
                id={`${photosArrayHelpers.name}`}
              >
                {({ field, form: { errors, touched }, meta }: any) => {
                  const photosError = errors[arrayHelpers.name] as any[];
                  let errorMsg = "";

                  if (
                    photosError &&
                    photosError[index] &&
                    photosError[index].photos &&
                    photosError[index].photos.length > 0
                  ) {
                    errorMsg = photosError[index].photos[0];
                  }
                  return (
                    <>
                      <ProductPhotosGridView
                        deleteProductPhoto={deletePhotoForVariant}
                        uploadProductPhoto={updateProductPhoto}
                        onSubmit={onSubmit}
                        onDeletePhoto={handleDeletePhoto}
                        photosStoragePath={photosStoragePath}
                        arrayHelpers={photosArrayHelpers}
                        dispatch={dispatch}
                        productId={draft.productId}
                        photos={
                          photosArrayHelpers.form.values.variants[index].photos
                        }
                        variantId={
                          photosArrayHelpers.form.values.variants[index].id
                        }
                      />
                      {/* {(field.value as any[])[0][1].url === "" && ( */}
                      {errorMsg !== "" && (
                        <Typography
                          className="Mui-error"
                          color="error"
                          marginX={"14px"}
                          variant="caption1"
                          width={"100%"}
                        >
                          {errorMsg}
                          {/* {errors[arrayHelpers.name] &&
                              (errors[arrayHelpers.name] as any[])?.[0]["photos"]} */}
                        </Typography>
                      )}
                    </>
                  );
                }}
              </Field>
            );
          }}
        />
        <Typography variant="body">Add Photo</Typography>
      </Stack>
    );
  })();

  const attributesSection = (() => {
    return (
      <Stack width="100%" spacing={2} direction="row">
        <Paper
          sx={{
            width: "50%",
            height: "3rem",
            fontWeight: 500,
            display: "grid",
            placeItems: "center",
            textTransform: "capitalize",
          }}
        >
          {colorOption.color_title}
        </Paper>
        <Paper
          sx={{
            width: "50%",
            height: "3rem",
            fontWeight: 500,
            display: "grid",
            placeItems: "center",
            textTransform: "uppercase",
          }}
        >
          {sizeOption.size_title}
        </Paper>
      </Stack>
    );
  })();
  
  /*
  const percentOff = (()=>{
        
         const sellingPrice = (draft.sellingPrice as number) ?? 0
        const discount = (draft.discount  as number) ?? 0
        return getPercentOff(sellingPrice, discount)
     
  })()*/

  return (
    <Stack
      direction={direction}
      alignItems="center"
      spacing={spacing}
      width="100%"
    >
      <DCError onErrorDisplayed={() => setError(null)} error={error} />
      {/* <PCErrorView onErrorDisplayed={()=>setError(null)}  error={error} /> */}
      {addPhotoSection}
      <Stack width="100%">
        <Stack direction={direction} spacing={spacing}>
          {attributesSection}
          <Field
            id={`${arrayHelpers.name}.${index}.sellingPrice`}
            name={`${arrayHelpers.name}.${index}.sellingPrice`}
            label="Selling Price"
            component={DCNumericField}
          />
          <Field
            id={`${arrayHelpers.name}.${index}.stockQuantity`}
            name={`${arrayHelpers.name}.${index}.stockQuantity`}
            label="Stock Quantity"
            component={DCNumericField}
          />
          <Field
            
            id={`${arrayHelpers.name}.${index}.discount`}
            name={`${arrayHelpers.name}.${index}.discount`}
            label="Discount"
            component={DCNumericField}
          />
          {/* <div>{percentOff}</div> */}
        </Stack>
        <Field
          id={`${arrayHelpers.name}.${index}.isAvailable`}
          name={`${arrayHelpers.name}.${index}.isAvailable`}
          label={`${arrayHelpers.name}.${index}.isAvailable`}
          component={({ field, form: { errors, touched } }: any) => {
            let errorMsg = ""; //errors[`${arrayHelpers.name}.${index}.isAvailable`]
            /*
           const variantError = errors[`${arrayHelpers.name}`] as any[]
           if (variantError && variantError.length > 0 && variantError[index] && variantError[index].isAvailable) {
            errorMsg = errors[`${arrayHelpers.name}`][index].isAvailable
           }*/

            return (
              <Stack alignItems={"flex-start"}>
                <DCCheckbox
                  id={`${arrayHelpers.name}.${index}.isAvailable`}
                  sx={{ width: "100%", height: "3rem" }}
                  onChange={arrayHelpers.form.handleChange}
                  checked={arrayHelpers.form.values.variants[index].isAvailable}
                  text="Make variant available for sale"
                />
                {errorMsg && (
                  <Typography
                    className="Mui-error"
                    color="error"
                    marginX={"14px"}
                    variant="caption1"
                    width={"100%"}
                    textAlign="left"
                  >
                    {errorMsg}
                  </Typography>
                )}
              </Stack>
            );
          }}
        />
        {/* <DCCheckbox
          id={`${arrayHelpers.name}.${index}.isAvailable`}
          sx={{ width: "100%", height: "3rem" }}
          onChange={arrayHelpers.form.handleChange}
          checked={arrayHelpers.form.values.variants[index].isAvailable}
          text="Make variant available for sale"
        /> */}
      </Stack>

      <Snackbar
        anchorOrigin={anchorOrigin}
        open={Boolean(removeDetails)}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          //sx={{ width: "100%" }}
        >
          {removeDetails}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default ProductVariantEditor;
