import { ResultType } from "../../../../Models/Result";
import { AuthUser } from "../../../../Models/User";
import supabaseAPIManager, {
  APIError,
} from "../../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { LoginCredentials } from "./LoginState";
import { SignupCredentials } from "./SignupCredentials";

export class SignupDataManager {
  static shared = new SignupDataManager();

  async signupUser(
    signupData: SignupCredentials,
    apiKey: string
  ): Promise<APIError | AuthUser> {
    // create profile
    // debugger;

    const { email, password, firstname, lastname, birthday, allowsMarketing } = signupData;
    const newUserResult = await supabaseAPIManager.addAuthUser(
      apiKey,
      email,
      password, 
      {
        firstname,
        lastname,
        birthdate: birthday.toISOString().split('T', 1)[0],
        businessTitle: "",
        isPartner: false,
        avatarUrl: "",
        stripeCustomerId: "",
        allowsMarketing
      }
    );
   // debugger;
    
    if (newUserResult.type === ResultType.Failure) {
      return newUserResult.error;
    }

    

    return newUserResult.value;
  }

  async loginUserWithCredentials(
    credentials: LoginCredentials,
    apiKey: string
  ): Promise<APIError | AuthUser> {
    const { email, password } = credentials;
    //console.log(apiKey)
    let result = await supabaseAPIManager.loginUser(email, password, apiKey);
    if (result.type === ResultType.Success) {
      return result.value;
    }
    return result.error;
  }

  hasLoginFailedWithCredentials(credentials: LoginCredentials): boolean {
    return credentials.email !== "" && credentials.password !== "";
  }
}

const signupDataManager = SignupDataManager.shared;
export default signupDataManager;
