import React, { ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DiscountInfo } from "../../Models/Product";
import { isDiscountRedeemable } from "../../utils";

interface PriceViewProps {
  discountInfo: DiscountInfo | null;
  sellingPrice: number | null;
  isDetailView?: boolean;
  isFavouriteCardView?: boolean
}

const PriceView = (props: PriceViewProps) => {
  let discountContent: ReactNode = null;
  if (props.sellingPrice === null) {
    return null;
  }

  // const hasDiscount = props.discountInfo?.couponId ? true : false
  let isRedeemable = false
  if (props.discountInfo && isDiscountRedeemable(props.discountInfo)) {
    
    discountContent = (
      <Typography id="dicount-price" variant={props.isDetailView ? "title2" : "caption"} component="div">
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(props.discountInfo.value)}
      </Typography>
    );
    isRedeemable = true
  }

  
 
  return (
    <Stack direction="row" spacing={1}>
      <Typography
        sx={{ textDecoration: isRedeemable ? "line-through" : "none" }}
        variant={props.isDetailView ? "title2" : "caption"}
        component="div"
      >
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(props.sellingPrice)}
      </Typography>
      {discountContent}
    </Stack>
  );
};

export default PriceView;
