import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React   from 'react'
import { FulfillmentStatus, InventoryOrder } from '../../../../../Models/InventoryOrder';
import { formatPrice } from '../../../../../utils';
import { tableCellStyle } from '../../../../../utils/styles';

interface InventoryOrderRowProps {
    order: InventoryOrder;
    onClick: () => void
    sn: number
}

const InventoryOrderRow = ({order, onClick, sn}: InventoryOrderRowProps) => {
  return (
    <TableRow hover sx={{cursor: 'pointer'}} onClick={onClick}>
         <TableCell sx={tableCellStyle} component="th" scope="row">
          {sn}
        {/* {order.inventoryOrderId} */}
      </TableCell>
      <TableCell align="left">{order.orderDate?.toLocaleDateString(undefined, 
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'short',
        }
      ) || new Date().toLocaleDateString()}</TableCell>
      <TableCell sx={{ textTransform: "none" }} align="left">
        {order.stripeCustomerName ?? "N/A"}
      </TableCell>
      <TableCell sx={{ textTransform: "capitalize" }} align="left">
        {order.paid ? `${formatPrice(order.paid)}` : "N/A"  }
      </TableCell>

      <TableCell sx={{ textTransform: "capitalize" }} align="left">
        {order.paid ? `Paid` : "Unpaid"}
      </TableCell>
      <TableCell sx={{ textTransform: "capitalize" }} align="left">
      <Chip
          sx={{fontWeight: 500}}
          label={order.fulfillmentStatus}
          color={order.fulfillmentStatus === FulfillmentStatus.fulfilled ? "success" : (order.fulfillmentStatus === FulfillmentStatus.fulfilling ? "warning" : "error")}
        />
       </TableCell>
      <TableCell align="left">{order.orderDetails?.reduce((partialResult, currOrderDetail) => partialResult + currOrderDetail.quantity, 0) ?? "N/A"}</TableCell>
    </TableRow>
  )
}

export default InventoryOrderRow