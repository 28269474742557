/**
 * Abstract: A view that displays an onscreen error or logs out user in the case of an invalid token
 */
 import React, { useContext, useEffect } from 'react'
import { UserAccountContext } from '../../contexts/UserAccountContext';
 import { NotificationContext } from '../../contexts/NotificationContext';
 import { NotificationActionType, NotificationType } from '../../Models/Notification';
 import { AppDataContext } from '../../contexts/AppDataContext';
import { PCAPIError } from '../../Models/PCAPIError';
 
  
 interface PCErrorViewProps {
     error: PCAPIError | null
     onErrorDisplayed?: ()=>void
 }
 
 /**
  * 
  * @param error
  * @param onErrorDisplayed an action that is performed after on screen error is shown
  * @returns 
  */
 const PCErrorView = ({error, onErrorDisplayed}: PCErrorViewProps) => {
 
     const { clearCredentials } = useContext(UserAccountContext)
     const appData  = useContext(AppDataContext)
     const {dispatch} = useContext(NotificationContext)
  
     useEffect(()=>{
         if (!error) {return}
         const {errorDescription, isUnathourized, isServerError} = error
         if (errorDescription) {
          if (isUnathourized || isServerError) {
            clearCredentials()
            appData.gotoShoppingApp()
          } else {
           dispatch({
             type: NotificationActionType.open,
             payload: { type: NotificationType.error, msg: errorDescription },
           });
           onErrorDisplayed?.()
          }
          return;
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [error])
  
   return (
     <div></div>
   )
 }
 
 export default PCErrorView