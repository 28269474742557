export enum RequestError {
    Notfound,
    AddressUnreachable,
    Unauthorized,
    Server,
    Unexpected,
    Badrequest,
    InvalidURL
}

export class PCAPIError {
    value = RequestError.Unexpected;
    private _msg = "An unexpected error occurred. Try again";
    static default = new PCAPIError();
  
    constructor(
     
      value: RequestError =  RequestError.Unexpected,
      msg: string = "An unexpected error occurred. Try again"
    ) {
      this.value = value;
      this._msg = msg;
    }
  
    get errorDescription(): string {

       switch (this.value) {
        case RequestError.Unexpected:
            return "An unexpected error occurred. Try again later"
        case RequestError.Unauthorized:
            return "Session Expired. Please log in"
        case RequestError.Server:
            return "An error occurred. Probably from the server"
        case RequestError.Notfound:
            return "The information you are looking for cannot be found"
        case RequestError.Badrequest:
            return this._msg || "Invalid request. Try again."
        case RequestError.AddressUnreachable:
             return "Prettycharm seems to be offline."
        case RequestError.InvalidURL:
             return "The url address is invalid. Try again"
        default:
            return "An unexpected error occurred. Try again"
       }
    }
  
    is404() {
      return this.value === RequestError.Notfound;
    }
  
    isBadRequest() {
      return this.value === RequestError.Badrequest;
    }
  
    get isNetworkError() {
      return this.value === RequestError.AddressUnreachable;
    }

    get isServerError() {
        return this.value === RequestError.Server
    }
  
    
    get isUnexpectedError() {
      return this.value === RequestError.Unexpected;
    }
  
    get isUnathourized() {
      return this.value === RequestError.Unauthorized;
    }
  
    // get isServerError() {
    //   return this._value === SupabaseAPIError.serverError;
    // }
  
    update(newError: RequestError, newMsg: string = "") {
      this.value = newError;
      this._msg = newMsg;
    }

    setMessage(msg: string) {
        this._msg = msg
    }

  }



