import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
  
import LoadingButton from "@mui/lab/LoadingButton";
 
 

interface DCAlertDialogProps<T> {
    title: string
    message: string
    onClose: ()=>void 
    onConfirm: ()=>void
    item?: T
    isLoading: boolean
}

/**
 * Shows an alert dialog that prompts to perfrom an action
 * @param props props include title, description, cancelAction and acceptActionn
 * @returns AlertDialog
 */
export default function DCAlertDialog<U>(props: DCAlertDialogProps<U>) {
  
    const {item, onClose, onConfirm, isLoading, message, title} = props


  return (
    <div>
      <Dialog
        open={item ? true : false}
        //open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{
              display: isLoading ? "none" : "inline-flex"
          }}  onClick={onClose}>Cancel</Button>
          <LoadingButton loading={isLoading} onClick={onConfirm} autoFocus>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
