import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import ReactDOM from 'react-dom';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppDataProvider from "./components/Providers/AppDataProvider";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import {
  home,
  Accounts,
  SellerApp,
  Inventory,
  ShoppingApp,
  Orders,
  DiscountsRoute,
} from "./routes";
import PCShoppingApp from "./components/PCShoppingApp/PCShoppingApp";
import Signup from "./components/PCAccount/Signup/Signup";
import ProductEditor from "./components/PCSellerApp/PCInventory/ProductEditor";
import Products from "./components/PCSellerApp/PCInventory/Products";
import InventoryDataProvider from "./components/PCSellerApp/Providers/InventoryDataProvider";
import AccountsProvider from "./components/Providers/AccountsProvider";
import React from "react";
import DCAccounts from "./components/PCAccount/PCAccount";
import UserProfile from "./components/UserProfile/UserProfile";
import RequireAuth from "./components/SupportingViews/RequireAuth";
import RequireNoAuth from "./components/SupportingViews/RequireNoAuth";
//import PCExplore from "./components/PCShoppingApp/PCExplore/PCExplore";
import PCSearch from "./components/PCShoppingApp/PCSearch/PCSearch";
import PCBag from "./components/PCShoppingApp/PCBag/PCBag";
import BagManagerProvider from "./components/Providers/BagManagerProvider";
import PCOrderConfirmation from "./components/PCOrderConfirmation/PCOrderConfirmation";
import PCFavorites from "./components/PCShoppingApp/PCFavorites/PCFavorites";
import Authentication from "./components/PCAccount/Authentication/Authentication";
import InventoryOrders from "./components/PCSellerApp/Orders/InventoryOrders";
import PCSellerApp from "./components/PCSellerApp/PCSellerApp";
import SellerOrders from "./components/PCSellerApp/Orders/SellerOrders";
import OrderManagerProvider from "./components/PCSellerApp/Providers/OrderManagerProvider";
import SearchProducts from "./components/PCShoppingApp/SearchProducts/SearchProducts";
import SearchManagerProvider from "./components/Providers/SearchManagerProvider";
import ProductDetail from "./components/PCShoppingApp/ProductDetail/ProductDetail";
import BagParent from "./components/PCShoppingApp/PCBag/BagParent";
import InventoryOrderDetail from "./components/PCSellerApp/Orders/InventoryOrderDetail";
import RequireAuthView from "./components/SupportingViews/RequireAuthView";
import PCFavoritesLayout from "./components/PCShoppingApp/PCFavorites/PCFavoritesLayout";
import ProductDetailProvider from "./components/Providers/ProductDetailProvider";
import PCInventory from "./components/PCSellerApp/PCInventory/PCInventory";
import ResetStatus from "./components/PCAccount/AccountReset/ResetStatus";
import PCAbout from "./components/PCShoppingApp/PCAbout/PCAbout";
import { isIOS, isMobileOnly } from "react-device-detect";
import PrivacyPolicy from "./components/PCShoppingApp/PrivacyPolicy";
import TermsOfService from "./components/PCShoppingApp/TermsOfService";
import PCLegal from "./components/PCShoppingApp/PCLegal/PCLegal";
import ReturnPolicy from "./components/PCShoppingApp/PCLegal/ReturnPolicy";
import PrettycharmDiscounts from "./components/PCSellerApp/Discounts/PrettycharmDiscounts";
import Discounts from "./components/PCSellerApp/Discounts/Discounts";
import DiscountsManagerProvider from "./components/PCSellerApp/Providers/DiscountsManagerProvider";


//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Contact from "./components/PCShoppingApp/PCLegal/Contact";

// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
//import {  onMessage } from "firebase/messaging";


import SellerOnBoarding from "./components/SellerOnBoarding/SellerOnBoarding";
import OnBoardingHome from "./components/SellerOnBoarding/OnBoardingHome/OnBoardingHome";
//import { app } from "./firebaseConfig";



/*
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries





const messaging = getMessaging(app)

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
getToken(messaging, { vapidKey: 'BIjE8_-hh3LHaJ3x4OTYmDK3Gb_2kJKSP9PJAWINN92DmrIBsQh_p-XWu-ppDsrKrgofjs7fKTDF9R8_VzHEUpo' }).then((currentToken) => {
  if (currentToken) {
    console.log(currentToken)
    alert(currentToken)
    // Send the token to your server and update the UI if necessary
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    alert('No registration token available. Request permission to generate one.')
    requestPermission()
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  alert('An error occurred while retrieving token. ')

  // ...
}); */




// onMessage(messaging, (payload)=>{
//     console.log("on-message", payload)
//     alert("on message called when app is in fore ground")
// })


//logEvent(analytics, 'goal_completion', { name: 'lever_puzzle'});

//import ReactGA from 'react-ga'

// caches.delete("workbox-precache-v2-https://www.prettycharm.app/")
// caches.delete("pwabuilder-page")
/*
console.log("delete offline cache")
caches.delete("pwabuilder-page")*/

const container = document.getElementById("root");
const root = createRoot(container!);

//ReactGA.initialize("G-4D8S4RGQHD")

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${home.path}`}
            element={
              <AppDataProvider>
                <AccountsProvider>
                  <App />
                </AccountsProvider>
              </AppDataProvider>
            }
          >
            <Route
              element={
                <SearchManagerProvider>
                  <ProductDetailProvider>
                    <PCShoppingApp />
                  </ProductDetailProvider>
                </SearchManagerProvider>
              }
            >
              <Route
                path={`${home.path}`}
                // path={isMobileOnly && !isIOS ? ShoppingApp.search : `${home.path}`}
                element={
                  isMobileOnly && !isIOS ? (
                    // <SearchManagerProvider>
                    <PCSearch />
                  ) : (
                    // </SearchManagerProvider>
                    <PCAbout />
                  )
                }
              >
                <Route index element={<SearchProducts />} />
                <Route
                  path="product/:productId/:variantId"
                  element={<ProductDetail />}
                />
              </Route>
              <Route
                path={ShoppingApp.bag}
                element={
                  <BagManagerProvider>
                    <BagParent />
                  </BagManagerProvider>
                }
              >
                <Route index element={<PCBag />} />
                <Route
                  path="product/:productId/:variantId"
                  element={<ProductDetail />}
                />
                <Route
                  path={ShoppingApp.orderConfirmation}
                  element={<PCOrderConfirmation />}
                />
              </Route>
              <Route
                path={`${ShoppingApp.account}`}
                element={
                  <RequireAuthView>
                    <DCAccounts />
                  </RequireAuthView>
                }
              >
                <Route index element={<UserProfile />} />
              </Route>
              <Route
                path={ShoppingApp.favorites}
                element={
                  <RequireAuthView>
                    <PCFavoritesLayout />
                  </RequireAuthView>
                }
              >
                <Route index element={<PCFavorites />} />
                <Route
                  path="product/:productId/:variantId"
                  element={<ProductDetail />}
                />
              </Route>
              <Route path="sell" element={<SellerOnBoarding />}>
                <Route index element={<OnBoardingHome />} />
              </Route>
              <Route element={<PCAbout />} path={ShoppingApp.about} />
              <Route element={<PCLegal />} path="/legal">
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="tos" element={<TermsOfService />} />
                <Route path="return" element={<ReturnPolicy />} />
                <Route path="support" element={<Contact />} />
              </Route>
            </Route>

            <Route
              path={SellerApp.pathname}
              element={
                
                <RequireAuth>
                   <InventoryDataProvider>
                      <PCSellerApp />
                   </InventoryDataProvider>
                </RequireAuth>
               

              }
            >
              <Route
                path={Inventory.pathname}
                element={
                  // <InventoryDataProvider>
                    <PCInventory />
                  // </InventoryDataProvider>
                }
              >
                <Route path="products" element={<Products />} />
                <Route
                  path={`products/:productId`}
                  element={<ProductEditor />}
                />
                <Route
                  path="*"
                  element={
                    <RequireNoAuth>
                      <Signup />
                    </RequireNoAuth>
                  }
                />
              </Route>
              <Route
                path={Orders.pathname}
                element={
                  <OrderManagerProvider>
                    <SellerOrders />
                  </OrderManagerProvider>
                }
              >
                <Route path="all" element={<InventoryOrders />} />
                <Route
                  path=":inventoryOrderId"
                  element={<InventoryOrderDetail />}
                />
              </Route>
              <Route
                path={DiscountsRoute.pathname}
                element={
                <DiscountsManagerProvider>
                    <Discounts />
                </DiscountsManagerProvider>
              
              }
              >
                <Route
                  path={DiscountsRoute.prettycharmDiscountsPathname}
                  element={<PrettycharmDiscounts />}
                />
              </Route>
            </Route>
            <Route path={`${Accounts.pathname}`} element={<DCAccounts />}>
              <Route
                path=":status"
                element={
                  <RequireNoAuth>
                    <Signup />
                  </RequireNoAuth>
                }
              />
              <Route
                path="user"
                element={
                  <RequireAuth>
                    <UserProfile />
                  </RequireAuth>
                }
              />
              <Route path="resetConfirmation" element={<ResetStatus />} />
              <Route path="auth" element={<Authentication />} />
              <Route
                path="*"
                element={
                  <RequireNoAuth>
                    <Signup />
                  </RequireNoAuth>
                }
              />
            </Route>
            <Route path="*" element={<div>Nothing Here!</div>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

/*
serviceWorkerRegistration.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    window.dispatchEvent(new CustomEvent("sw", { detail: registration }));

    console.log("Service worker on update called");
  },
});*/

/*
async function requestPermission()  {
  console.log('Requesting permission...');
  const permission = await Notification.requestPermission() 
  if (permission !== 'granted') {
    console.log('Unable to get permission to notify.');
    alert('Unable to get permission to notify.')
    return 
  }
   
    console.log('Notification permission granted.');
    // TODO(developer): Retrieve a registration token for use with FCM.
    try {
      const currentToken = await getToken(messaging, { vapidKey: 'BIjE8_-hh3LHaJ3x4OTYmDK3Gb_2kJKSP9PJAWINN92DmrIBsQh_p-XWu-ppDsrKrgofjs7fKTDF9R8_VzHEUpo' })
      console.log('currentToken', currentToken)
    } catch (error) {
       console.log("couldn't generate token")
    }
    // In many cases once an app has been granted notification permission,
    // it should update its UI reflecting this.
    //resetUI();
   
}*/




//serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
