 import {
  Login,
  LoginAction,
  LoginActionType,
} from "../components/PCAccount/Signup/Model/Login";
import { LoginCredentials } from "../components/PCAccount/Signup/Model/LoginState";
 
// export const LoginContext = createContext<{
//   state: Login;
//   dispatch: Dispatch<LoginAction>;
// }>({
//   state: initialLoginState,
//   dispatch: () => null,
// });

export const loginReducer = (state: Login, action: LoginAction): Login => {
  switch (action.type) {
    case LoginActionType.setCredentials:
      return {
        ...state,
        loginCredentials: action.payload as LoginCredentials,
      };
    case LoginActionType.toggleShowingSignupForm:
     // console.dir(action.payload)
      return {
        ...state,
        showingSignupForm: action.payload as boolean //!state.showingSignupForm,
      };
    default:
      return state;
  }
};
