import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
//import useScrollTrigger from "@mui/material/useScrollTrigger";
import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { AppDataContext } from "../../contexts/AppDataContext";
import { BagManagerContext } from "../../contexts/BagManagerContext";
import { PCAPIError } from "../../Models/PCAPIError";
import { RequestStatus } from "../../Models/Result";
import OfflineView from "../SupportingViews/OfflineView";
import { CircularProgress } from "@mui/material";
import { PCTab, ShoppingApp } from "../../routes";

// interface Props {
//   children: ReactElement;
// }

/*
function ElevationScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    //target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}*/

const PCOrderConfirmation = () => {
  const manager = useContext(BagManagerContext);
  const appData = useContext(AppDataContext);
  const [params] = useSearchParams(); //useParams<{ platform: string }>();
  const [counter, setCounter] = useState(0);
  const [shoppingLink, setShoppingLink] = useState("/");

  const btnStyle = {
    width: "87.5%",
    height: "3.1rem",
    borderRadius: "10px",
  };

  const isSessionCompleted = (() =>
    manager?.session?.status === "complete" ? true : false)();

  const checkoutStatusError = ((): PCAPIError | null => {
    const apiError = manager?.checkoutStatus as PCAPIError;
    return apiError.errorDescription ? apiError : null;
  })();

  const sessionStatusView = (() => (
    <Stack spacing={3}>
      <Typography variant={"headline"}>Order Complete</Typography>
      <Typography variant={"headline"} height={"7.5rem"} fontSize="7rem">
        👍
      </Typography>
      <Stack>
        <Typography variant={"largeTitle"}>Thank You!</Typography>
        <Typography variant={"body"}>
          You will be receiving a confirmation email with order details. Another
          email containing your order tracking details will be sent to you
          when your order starts to ship.
        </Typography>
      </Stack>

      <Stack width="fullWidth" alignItems="center" spacing={2}>
        {shoppingLink.includes("success") ? (
          <Button
            sx={btnStyle}
            variant="contained"
            component={"a"}
            href={shoppingLink}
          >
            Continue Shopping
          </Button>
        ) : (
          <Button
            sx={btnStyle}
            variant="contained"
            component={Link}
            to={shoppingLink}
          >
            Continue Shopping
          </Button>
        )}

        {/* <Button sx={btnStyle} variant="outlined" component={Link} to="/">
        View Order History
      </Button> */}
      </Stack>
    </Stack>
  ))();

  const orderInfoView = (() => {
    return checkoutStatusError ? (
      <OfflineView
        sx={{ pt: 6, width: "100vw", height: "100vh" }}
        msg={checkoutStatusError.errorDescription}
        onRetry={() => setCounter((prev) => prev + 1)}
      />
    ) : (
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          
          padding: "2rem",
          width: "inherit",
          height: "inherit",
          zIndex: 105,
        }}
      >
        {/* {sessionStatusView} */}
        {isSessionCompleted ? sessionStatusView : <Navigate to="/bag" />}
      </Box>
    );
  })();

  useEffect(() => {
    // if (params.sessionId) {
    manager?.updateCheckoutStatus(RequestStatus.Idle);
    let sessionId = params.get("session_id");
    if (sessionId !== null) {
      manager?.loadOrderInfo(sessionId);
    }

    //  }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useEffect(() => {
    const platform = params.get("platform");
    if (platform === "ios") {
      setShoppingLink("prettycharmapp://success");
    }
  }, [params]);

  useEffect(() => {
    if (isSessionCompleted) {
      ShoppingApp.setLastVisitedForRoute(PCTab.bag, ShoppingApp.bag);
      appData.clearCart();
      manager?.clearBag();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionCompleted]);

  // if (manager?.checkoutStatus === RequestStatus.Loading) {
  //   return (
  //     <CircularProgress />
  //   )
  // }

  // if (!manager?.session?.status) {
  //   return null
  // }

  

  return (
    <Stack
      sx={{
         height: "calc(100vh - 64px)",
        display: "grid",
        placeItems: "center",
      }}
    >
      {manager?.checkoutStatus === RequestStatus.Loading && (
        
          <CircularProgress />
           
      )}
      {manager?.session?.status && (
        <>
          {/* <ElevationScroll>
            <Toolbar
              sx={{
                width: "inherit",
                px: 0,
                justifyContent: "space-between",
              }}
            ></Toolbar>
          </ElevationScroll> */}
          {orderInfoView}
        </>
      )}
    </Stack>
  );
};

export default PCOrderConfirmation;
