import { AuthUser } from "./User";

export interface Sizes {
  size1: string;
  size2: string;
  size3: string;
  size4: string;
  size5: string;
}

export interface Colors {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
}

export interface Photo {
  publicId: string | null; // photo public id in cloud storage
  url: string;
}

export type PhotoWithId = [string, Photo];

export const defaultPhoto: Photo = {
  publicId: "1234",
  url: "https://res.cloudinary.com/rapid-interactive/image/upload/v1655818129/inventories/41d267e7-4b4e-4a29-a64a-3073f407f1df/60b400b3-8be3-4f8e-ad24-f178a7d736df/photo3.jpg",
};

export const defaultPhoto2: Photo = {
  publicId: "1234",
  url: "https://res.cloudinary.com/rapid-interactive/image/upload/v1655818129/inventories/41d267e7-4b4e-4a29-a64a-3073f407f1df/60b400b3-8be3-4f8e-ad24-f178a7d736df/photo1.jpg",
};

export interface AuthProduct {
  product: Product;
  authUser: AuthUser | null;
}

export interface AuthDeleteProductStatus {
  status: string;
  authUser: AuthUser | null;
}

export interface Photos {
  photo1: Photo;
  photo2: Photo;
  photo3: Photo;
  photo4: Photo;
}

// const defaultPhotos = {
//   "photo1": "https://res.cloudinary.com/rapid-interactive/image/upload/v1655818117/inventories/41d267e7-4b4e-4a29-a64a-3073f407f1df/60b400b3-8be3-4f8e-ad24-f178a7d736df/photo1.jpg",
//   "photo2": "",
//   "photo3": "https://res.cloudinary.com/rapid-interactive/image/upload/v1655818129/inventories/41d267e7-4b4e-4a29-a64a-3073f407f1df/60b400b3-8be3-4f8e-ad24-f178a7d736df/photo3.jpg",
//   "photo4": ""
// }

interface Accessories {
  accessory1: string;
  accessory2: string;
  accessory3: string;
}

export interface ProductDialog {
  toggleDialog: () => void;
  open: boolean;
}

export interface Product {
  productId: string;
  inventoryId: string;
  wholesaleCost: number;
  description: string;
  title: string;
  stockQuantity: number;
  sellingPrice: number;
  sizes: Sizes;
  colors: Colors;
  photos: Photos;
  accessories: Accessories;
  categoryId: string;
  subcategoryId: string;
  lastupdated: string;
  createdAt: string;
  isPublished: boolean;
  productDetails: ProductVariant[];
  avgRating: number | null
  reviewCount: number
}

export const defaultProduct: Product = {
  productId: "",
  inventoryId: "",
  wholesaleCost: 0,
  description: "",
  title: "",
  stockQuantity: 0,
  sellingPrice: 0,
  avgRating: null,
  reviewCount: 0,
  sizes: {
    size1: "417d1064-5027-4e2c-ad45-965f7e6bf3f4",
    size2: "1e8afcb4-5a25-4076-bc1d-16db98ef2e70",
    size3: "",
    size4: "",
    size5: "",
  },
  colors: {
    color1: "7e97e6ab-29d8-4af3-a32d-858c615858b2",
    color2: "f579a3e6-758d-431a-89f3-9ebdabe43bfa",
    color3: "",
    color4: "",
  },
  accessories: { accessory1: "", accessory2: "", accessory3: "" },
  categoryId: "",
  subcategoryId: "",
  photos: {
    photo1: defaultPhoto,
    photo2: defaultPhoto,
    photo3: defaultPhoto,
    photo4: defaultPhoto,
  },
  lastupdated: "",
  createdAt: "",
  isPublished: false,
  productDetails: [],
};

export enum ProductAttribute {
  size = "Size",
  plating = "Plating",
  quantity = "Quantity",
}

export interface ProductVariant {
  productDetailId: string;
  sizeId: string;
  colorId: string;
  photos: { photo1: Photo };
  productId: string;
  stockQuantity: number;
  inventoryId: string;
  sellingPrice: number;
  isAvailable: boolean;
  discount: number;
  favoriteId: string | null
  discountInfo: DiscountInfo | null
}

export const dummyProductVariant: ProductVariant = {
  productDetailId: "1",
  favoriteId: null,
  sizeId: "",
  colorId: "f579a3e6-758d-431a-89f3-9ebdabe43bfa",
  photos: { photo1: defaultPhoto },
  productId: defaultProduct.productId,
  stockQuantity: 2,
  inventoryId: defaultProduct.inventoryId,
  sellingPrice: 400,
  isAvailable: true,
  discount: 0,
  discountInfo: null
};

export const dummyProductVariant2: ProductVariant = {
  productDetailId: "1",
  favoriteId: null,
  sizeId: "",
  colorId: "7e97e6ab-29d8-4af3-a32d-858c615858b2",
  photos: { photo1: defaultPhoto2 },
  productId: defaultProduct.productId,
  stockQuantity: 4,
  inventoryId: defaultProduct.inventoryId,
  sellingPrice: 300,
  isAvailable: true,
  discount: 0,
  discountInfo: null

};

export interface DiscountInfo {
  value: number 
  percentOff: number
  couponId: string | null
  redeemBy: number | null
 }

export interface ProductVariantFormData {
  id: string;
  productId: string;
  stockQuantity: number | undefined | string;
  sellingPrice: number | undefined | string;
  photos: [string, Photo][];
  isAvailable: boolean;
  sizeId: string;
  colorId: string;
  discount: number | undefined | string;
}

export interface AuthProductVariantList {
  authUser: AuthUser | null;
  variantsList: ProductVariant[];
}
export interface AuthProductVariant {
  authUser: AuthUser | null;
  variant: ProductVariant;
}

export interface AuthProductList {
  authUser: AuthUser | null;
  productsList: Product[];
}

export interface Coupon {
  id: string
  valid: boolean
  percentOff: number 
  livemode: boolean
  name: string
  redeemBy: number | null
}

export interface AuthCoupon {
  authUser: AuthUser | null
  coupon: Coupon
}
