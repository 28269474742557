import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormikHelpers } from "formik";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDataContext } from "../../../contexts/AppDataContext";
import { InventoryContext } from "../contexts/InventoryContext";
import { UserAccountContext } from "../../../contexts/UserAccountContext";

import { LookupTable } from "../../../Models/LookupTables";

import { SubcategoryDetails } from "../../../Models/ProductOptions";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { Inventory, SellerApp } from "../../../routes";
import DCError from "../../SupportingViews/DCError";
import ScrollToTop from "../../SupportingViews/ScrollToTop";

import EditProductForm from "./view/desktop/EditProductForm";
import EditProductFormMobile from "./view/mobile/EditProductForm";
import { ProductFormData } from "../Models/ProductFormData";
import { AuthProduct, Product } from "../../../Models/Product";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  NotificationActionType,
  NotificationType,
} from "../../../Models/Notification";

const ProductEditor = () => {
  const { updateProduct, updateSelectedProduct } =
    useContext(InventoryContext);
  const { getSignedInUser, saveCredentials } = useContext(UserAccountContext);

  const isMediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.up("md")
  );
  const [subCategories, setSubcategories] = useState<SubcategoryDetails[]>([]);

  const appData = useContext(AppDataContext);
  const { dispatch } = useContext(NotificationContext);
  const { getLookuptableForType } = appData;
  const params = useParams<{ productId: string }>();
  const navigate = useNavigate();
  const user = getSignedInUser();
  const [error, setError] = useState<APIError | null>(null);

  

  async function saveProductData(values: ProductFormData):  Promise<Product | null> {
    if (!user || !params.productId) {
      return null;
    }

     
    
     const result = await updateProduct(
       params.productId,
       values,
       user.access_token,
       user.refresh_token
     );
 
     const error = result as APIError;
 
     if (error.errorDescription) {
       setError(error);
       return null;
     }
 
     const { authUser, product } = result as AuthProduct;
     if (authUser) {
       saveCredentials(authUser);
     }

     return product
   }

  const handleSaveData = async (
    values: ProductFormData,
    formikHelpers?: FormikHelpers<ProductFormData>
  ) => {
   

   if (await saveProductData(values)) {
    dispatch({
      type: NotificationActionType.open,
      payload: {
        type: NotificationType.success,
        msg: "Product was updated successfully",
      },
    });
   }

    // navigate(`/${SellerApp.pathname}/${Inventory.products}`);
  };

  const handleChosenCategory = async (e: any) => {
    if (!e.target.value) {
      return;
    }

    const value = appData.getLookuptableForType(
      LookupTable.subcategory,
      e.target.value
    );
    setSubcategories(value);
  };

  // debugger
  if (!params.productId) {
    return (
      <Stack
        alignItems={"center"}
        sx={{ pt: 16, width: "100vw", height: "100vh" }}
      >
        <Typography variant="subhead">Product does not exist</Typography>
      </Stack>
    );
  }

  
  /*
  const handlePublishProduct = async (product: Product | null) => {
    if (!user || !product || !isProductDetailsComplete(product)) {
      return;
    }

    const refreshedUser = await updateSelectedProduct(
      new Map([["is_published", !product.isPublished]]),
      user.access_token,
      user.refresh_token
    );
    if (refreshedUser) {
      saveCredentials(refreshedUser);
    }
    navigate(`/${SellerApp.pathname}/${Inventory.products}`);
  };*/

  const handleUnPublishProduct = async (product: Product | null) => {
    
    if (!user || !product) {
      return;
    }


    const refreshedUser = await updateSelectedProduct(
      new Map([["is_published", false]]),
      user.access_token,
      user.refresh_token
    );
    if (refreshedUser) {
      saveCredentials(refreshedUser);
    }
   // navigate(`/${SellerApp.pathname}/${Inventory.products}`);
  };

  const handlePublishProduct = async (values: ProductFormData,
    formikHelpers?: FormikHelpers<ProductFormData>) => {

    if (!user) {return}
  
    const savedProduct = await saveProductData(values)
    
    if (!savedProduct) {return}
      
    

    // if product is currently published we are just saving updated to it
    if (savedProduct.isPublished) {
      return
    }
    
    const refreshedUser = await updateSelectedProduct(
      new Map([["is_published", true]]),
      user.access_token,
      user.refresh_token
    );
    if (refreshedUser) {
      saveCredentials(refreshedUser);
    }

    navigate(`/${SellerApp.pathname}/${Inventory.products}`);
  };

  return (
    <>
      <ScrollToTop />
      <DCError onErrorDisplayed={() => setError(null)} error={error} />
      <Stack spacing={2} mb="4rem">
        {isMediumScreen ? (
          <EditProductForm
            onSaveProduct={handleSaveData}
            subCategories={subCategories}
            onCategoryChange={handleChosenCategory}
            categories={getLookuptableForType(LookupTable.category)}
            sizeOptions={getLookuptableForType(LookupTable.size)}
            colorOptions={getLookuptableForType(LookupTable.color)}
            accessoryOptions={getLookuptableForType(LookupTable.accessory)}
            onSubmit={handlePublishProduct}
            productId={params.productId}
            onUnpublishProduct={handleUnPublishProduct}
          />
        ) : (
          <EditProductFormMobile
            onSaveProduct={handleSaveData}
            onSubmit={handlePublishProduct}
            subCategories={subCategories}
            onCategoryChange={handleChosenCategory}
            categories={getLookuptableForType(LookupTable.category)}
            sizeOptions={getLookuptableForType(LookupTable.size)}
            colorOptions={getLookuptableForType(LookupTable.color)}
            accessoryOptions={getLookuptableForType(LookupTable.accessory)}
            productId={params.productId}
            onUnpublishProduct={handleUnPublishProduct}
          />
        )}
      </Stack>
    </>
  );
};

export default ProductEditor;
