import React, { ReactNode, useState } from "react";
import { ResultType } from "../../../Models/Result";
import { AuthUser } from "../../../Models/User";
import { DiscountsManager } from "../Models/DiscountsManager";
import {
  
  AuthProduct,
  
  Coupon,
  DiscountInfo,
  Product,
} from "../../../Models/Product";
import { DiscountsContext } from "../contexts/DiscountsContext";
import stripeAPIManager from "../../../networking/StripeAPIManager/StripeAPIManager";
import supabaseAPIManager, {
  APIError,
} from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
 import { deepCopy } from "../../../utils";
//import { DiscountsContext } from '../contexts/DiscountsContext';

interface DiscountsManagerProviderProps {
  children: ReactNode;
}

const DiscountsManagerProvider = ({
  children,
}: DiscountsManagerProviderProps) => {
  const [prettycharmCoupon, setPrettycharmCoupon] =
    useState<Coupon | null>(null);
  const apiKey = process.env.REACT_APP_SUPABASE_API_KEY;

  const loadDiscountCoupon = async (salesId: string, auth: AuthUser): Promise<AuthUser | null> => {
    if (apiKey === undefined) {
      throw new Error(APIError.default.errorDescription)
    }
    const result = await stripeAPIManager.getCoupons(
      salesId,
      auth.access_token,
      auth.refresh_token,
      apiKey
    );
    if (result.type === ResultType.Failure) {
      throw new Error(result.error.errorDescription)
    } 

    setPrettycharmCoupon(result.value.coupon);
    return result.value.authUser
     
    
    
  };

  /**
   * @throws
   * Updates discounts info of variants of a product
   * @param product Prouduct whose variants are updated
   * @param auth Auth credentials
   * @returns Product
   */
  const updateDiscountInfo = async (
    product: Product,
    auth: AuthUser
  ): Promise<AuthProduct> => {
    if (apiKey === undefined) {
      throw new Error(APIError.default.errorDescription);
    }

    let tempAuthProduct: AuthProduct = {
      authUser: null,
      product: deepCopy(product)
    } 
    //let tempProductDetails: AuthProductVariant[] = [];

    let accessToken = auth.access_token
    let refreshToken = auth.refresh_token


    for (let variant of tempAuthProduct.product.productDetails) {

      const queryItems = new Map<string, string>([
        ["product_detail_id", `${variant.productDetailId}`],
      ]);
      const keyedValues = new Map<string, DiscountInfo | null>([
        ["discount_info", variant.discountInfo || null],
      ]);
      const result = await supabaseAPIManager.updateProductDetails(
        queryItems,
        keyedValues,
        apiKey,
        accessToken,
        refreshToken
      );
      if (result.type === ResultType.Failure) {
        throw new Error(result.error.errorDescription);
      }
      
      const {variant: updatedVariant, authUser: updatedAuth} = result.value
      const idx = tempAuthProduct.product.productDetails.findIndex(tempVariant => tempVariant.productDetailId === updatedVariant.productDetailId)
      if (idx > -1) {
        tempAuthProduct.product.productDetails[idx] = variant
        if (updatedAuth) {
          tempAuthProduct.authUser = updatedAuth
          // update access and refresh tokens for the subsequent iteration
          accessToken = updatedAuth.access_token
          refreshToken = updatedAuth.refresh_token
        }
      }
       
    }

    
    return tempAuthProduct;
  };

  const manager: DiscountsManager = {
    prettycharmCoupon,
    loadDiscountCoupon,
    updateDiscountInfo,
  };

  return (
    <DiscountsContext.Provider value={manager}>
      {children}
    </DiscountsContext.Provider>
  );
};

export default DiscountsManagerProvider;
