import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

interface LoggedOutViewProps {
    text: string
    onSignup: ()=>void
    onLogin: () => void
}

const LoggedOutView = (props: LoggedOutViewProps) => {
  return (
    <Stack
    spacing={2}
    padding={2}
    width="100%"
    alignItems={"flex-start"}
  >
     <Typography
    variant="headline"
    noWrap
  >
    {props.text}
  </Typography>
     <LoadingButton
    onClick={props.onLogin}
    sx={{
        height: "2.5rem",
        width: "100%",
        fontWeight: "500",
        mt: {
            xs: "0.5rem !important",
            md: "1.5rem !important",
          },
      }}
    size="large"
    loading={false}
    fullWidth
    variant="contained"
  >
    log in
  </LoadingButton>
  <Stack spacing={1} direction={"row"}>
  <Typography
    variant="body2"
    component="span"
  >
    Don't have an account?
  </Typography>
  <Typography
    onClick={props.onSignup}
    variant="body2"
    component="span"
    sx={{
        textDecoration: "underline"
    }}
   >
    Sign up
  </Typography>
  </Stack>
 
  </Stack>
  )
}

export default LoggedOutView