import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Product } from "../../Models/Product";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import PriceView from "./PriceView";

import { useNavigate } from "react-router-dom";
import DiscountView from "./DiscountView";

interface FavoriteItemCardProps {
  data: Product | undefined;
  variantId: string;
  enableAddToBag: boolean;
  onAddToCart: () => void;
  onMount: () => void;
  size: string;
  color: string;
  price: string;
  image: string;
  onRemoveItem: () => void;
  to: string;
}

const FavoriteItemCard = ({
  data,
  variantId,
  enableAddToBag,
  onAddToCart,
  onMount,
  size,
  color,
  price,
  image,
  onRemoveItem,
  to,
}: FavoriteItemCardProps) => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    onMount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const cardLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
  //   itemProps,
  //   ref,
  // ) {
  //   return <RouterLink ref={ref} {...itemProps} role={undefined} />;
  // });

  const isOutOfStock = ((): boolean => {
    const found = data?.productDetails.find(
      (item) => item.productDetailId === variantId
    );

    return found && found.stockQuantity <= 0 ? true : false;
  })();

  const selectedVariant = (() => {
    return data?.productDetails.find(
      (item) => item.productDetailId === variantId
    );
  })();

  const stockQuantity = (() => {
    return (
      data?.productDetails.find((item) => item.productDetailId === variantId)
        ?.stockQuantity ?? undefined
    );
  })();
  //console.log(to)

  var contentForCard = (() => {
    return data ? (
      <>
        <ListItemAvatar>
          <Avatar
            alt={data.title}
            src={image}
            sx={{ width: 80, height: 80, mr: "1rem" }}
            variant="rounded"
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                noWrap
                sx={{
                  display: "block",
                  fontWeight: "500",
                  marginBottom: "0.5rem",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflowX: "hidden",
                  width: "inherit",
                  // width:  "12rem"
                }}
                component="span"
                variant="subhead"
                color="text.primary"
              >
                {`${data.title} - ${color} / ${size}`}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <Stack spacing={1} component="span">
              
              <Stack
                color={"text.primary"}
                direction="row"
                spacing={1}
                alignItems={"center"}
              >
                <DiscountView
                  variant="short"
                  discountInfo={selectedVariant?.discountInfo ?? null}
                />
                <PriceView
                  isFavouriteCardView
                  discountInfo={selectedVariant?.discountInfo ?? null}
                  sellingPrice={selectedVariant?.sellingPrice ?? null}
                />
              </Stack>
              <Typography
                sx={{
                  display: "inline",
                  fontWeight: "500",
                  width: "fit-content",
                }}
                component="span"
                variant="subhead"
                color="primary.main"
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  if (e.currentTarget === e.target) {
                    e.stopPropagation();
                    //console.dir(e)
                    setShowMore(!showMore);
                  }
                }}
              >
                {showMore ? "Show Less" : "Show More"}
              </Typography>
            </Stack>
          }
        />
      </>
    ) : (
      <>
        <BlockIcon sx={{ width: 55, height: 55, mr: "1rem" }} />
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{
                  display: "block",
                  fontWeight: "500",
                  marginBottom: "0.5rem",
                }}
                component="span"
                variant="subhead"
                color="text.primary"
              >
                Product Not Available
              </Typography>
            </React.Fragment>
          }
        />
      </>
    );
  })();

  return (
    <>
      <ListItemButton
        alignItems="flex-start"
        sx={{ justifyContent: "space-between", flexDirection: "column" }}
      >
        <Stack onClick={() => navigate(to)} direction="row" width="100%">
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              onRemoveItem();
            }}
          >
            <DeleteIcon />
          </IconButton>
          {contentForCard}
        </Stack>
        {data && showMore && (
          <Button
            disabled={!enableAddToBag || isOutOfStock}
            disableElevation
            sx={{ width: "100%", height: "2.5rem", my: "1rem" }}
            variant="contained"
            onClick={(e: any) => {
              e.stopPropagation();
              onAddToCart();
            }}
          >
            {isOutOfStock
              ? "Out Of Stock"
              : enableAddToBag
              ? "Add to bag"
              : `Only ${stockQuantity} Available`}
          </Button>
        )}

        <Divider />
      </ListItemButton>
    </>
  );
};

export default FavoriteItemCard;
