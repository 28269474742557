import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ScrollToTop from "../SupportingViews/ScrollToTop";

const PrivacyPolicy = () => {
  return (
    <Stack textAlign={"left"} spacing={3} alignItems="flex-start" sx={{   '& .MuiTypography-body1': {color: "text.secondary"} }}>
       <ScrollToTop />
      {/* <Typography variant="title3" fontWeight="500">PC Privacy Policy</Typography> */}
      <Stack  alignItems="inherit">
      <Typography variant="subtitle1">
        PERSONAL INFORMATION WE COLLECT
      </Typography>

      <Typography variant="body1" textAlign={"left"}>
        When you visit the Site, we automatically collect certain information
        about your device, including information about your web browser, IP
        address, time zone, and some of the cookies that are installed on your
        device. Additionally, as you browse the Site, we collect information
        about the individual web pages or products that you view, what websites
        or search terms referred you to the Site, and information about how you
        interact with the Site. We refer to this automatically-collected
        information as “Device Information.”
      </Typography>

      <Typography variant="body1">
        We collect Device Information using the following technologies:
      </Typography>
      <Stack spacing={0} alignItems="inherit" textAlign={"left"} component="ul" sx={{margin: "0px"}}>
        <li>
        <Typography variant="body1">
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier.
          {/* For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org. */}
          </Typography>
        </li>
        <li>
        <Typography variant="body1">
          “Web beacons,” “tags,” and “pixels” are electronic files used to
          record information about how you browse the Site.
          </Typography>
        </li>
      </Stack>
      <Box>
        <Typography variant="body1">
        Additionally when you make a purchase or attempt to make a purchase
        through the Site, we collect certain information from you, including
        your name, billing address, shipping address, email address, and phone
        number. We refer to this information as “Order Information.”
      </Typography>

      <Typography variant="body1">
        When we talk about “Personal Information” in this Privacy Policy, we are
        talking both about Device Information and Order Information.
      </Typography>
      </Box>
      
      </Stack>
     
     

      <Stack>
        <Typography variant="subtitle1">
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography variant="body1">
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information to: Communicate with you; Screen our orders for potential
          risk or fraud; and When in line with the preferences you have shared
          with us, provide you with information or advertising relating to our
          products or services. We use the Device Information that we collect to
          help improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </Typography>
        <Typography variant="body1">
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. For example, we use
          Stripe to process your payments — you can read more about how Stripe
          uses your Personal Information here: https://stripe.com/privacy.
           {/* We
          also use Google Analytics to help us understand how our customers use
          the Site--you can read more about how Google uses your Personal
          Information here: https://www.google.com/intl/en/policies/privacy/.
          You can also opt-out of Google Analytics here:
          https://tools.google.com/dlpage/gaoptout. */}
        </Typography>
        <Typography variant="body1">
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </Typography>
      </Stack>

      <Stack>
        <Typography variant="subtitle1">DO NOT TRACK</Typography>
        <Typography variant="body1">
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </Typography>
      </Stack>

      <Stack>
        <Typography variant="subtitle1">DATA RETENTION</Typography>
        <Typography variant="body1">
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.(See Support)
        </Typography>
      </Stack>

      <Stack>
        <Typography variant="subtitle1">MINORS</Typography>
        <Typography variant="body1">
          The site is not intended for individuals under the age of 18.
        </Typography>
      </Stack>

      <Stack>
        <Typography variant="subtitle1">CHANGES</Typography>
        <Typography variant="body1">
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </Typography>
      </Stack>

      <Stack>
        <Typography variant="subtitle1">CONTACT US</Typography>
        <Typography variant="body1">
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at info@prettycharm.app or by mail using the details
          provided below:
        </Typography>
        <Typography variant="body2">
          15 astro court parkville, baltimore, MD, 21234, United States
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PrivacyPolicy;
