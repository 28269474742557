 
interface Presentation {
    /**
     * indicates whether any of the authentication forms is displayed
     */
    isPresented: boolean
    /**
     * for dismissing any/all currently displayed form
     */
    dismiss: (()=>void) | undefined
}

export const defaultPresentation: Presentation = {
    isPresented: false, 
    dismiss: undefined
} 