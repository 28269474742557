import { Product } from "./Models/Product";

export const home = {
  title: "home",
  name: "home",
  path: "/",
};
export const login = {
  title: "login",
  name: "login",
  path: "/login",
  recover: "/login/recover",
  paths: {
    main: "/login",
    recover: "/login/recover",
  },
};
// export const recover = {
//   title: "recover",
//   name: "recover",
//   path: "/recover",
// };
export const sellerApp = {
  title: "seller app",
  name: "seller+app",
  path: "/seller+app",
  paths: {
    products: `/seller+app/inventory/products`,
  },
};

export class Home {
  static title = "home";
  static pathname = "home";

  static get index(): string {
    return "/";
  }
}

export class SellerApp {
  static title = "seller app";
  static pathname = "seller+app";

  static get index(): string {
    return `/${SellerApp.pathname}`;
  }

  // static get products(): string {
  //   return `${SellerApp.index}/inventory/products`;
  // }
}

export enum PCTab {
  search = "search",
  wishlist = "wishlist",
  bag = "bag",
  account = "account",
}

interface PCRoute {
  lastVisited?: string;
  id: string;
}

export class ShoppingApp {
  static title = "shopping app";
  static pathname = "shopping+app";

  static searchRoute: PCRoute = {
    id: PCTab.search,
  };
  static wishlistRoute: PCRoute = {
    id: PCTab.wishlist,
  };
  static bagRoute: PCRoute = {
    id: PCTab.bag,
  };
  static accountRoute: PCRoute = {
    id: PCTab.account,
  };

  static get basename(): string {
    return `/`;
  }

  static get search(): string {
    return `${ShoppingApp.basename}search`;
  }

  static get bag(): string {
    return `${ShoppingApp.basename}bag`;
  }

  static get account(): string {
    return `${ShoppingApp.basename}account`;
  }

  static get orderConfirmation(): string {
    return `${ShoppingApp.bag}/orderConfirmation`;
  }

  static get favorites(): string {
    return `${ShoppingApp.basename}favorites`;
  }

  static get about(): string {
    return `about`;
  }

  static get sellerOnBoarding(): string {
    return `${ShoppingApp.basename}sell`;
  }

  static productDetailURL(
    routePath: string,
    product?: Product,
    variantId?: string
  ): string {
    if (!product || !variantId) {
      return "/";
    }

    const variant = product.productDetails.find(
      (variant) => variant.productDetailId === variantId
    );

    if (!variant) {
      return "/";
    }

    return `${routePath}/product/${product.productId}/${variant.productDetailId}?plating=${variant.colorId}`;
  }

  static setLastVisitedForRoute(name: string, path: string) {
    switch (true) {
      case name === PCTab.search:
        this.searchRoute.lastVisited = path;
        break;
      case name === PCTab.wishlist:
        this.wishlistRoute.lastVisited = path;
        break;
      case name === PCTab.bag:
        this.bagRoute.lastVisited = path;
        break;
      case name === PCTab.account:
        this.accountRoute.lastVisited = path;
        break;
      default:
        throw Error("An unexpected error occurred");
    }
  }

  static getLastVisitedForRoute(name: string): string {
    switch (true) {
      case name === PCTab.search:
        return this.searchRoute.lastVisited || this.basename;
      case name === PCTab.wishlist:
        return this.wishlistRoute.lastVisited || this.favorites;

      case name === PCTab.bag:
        return this.bagRoute.lastVisited || this.bag;
      case name === PCTab.account:
        return this.accountRoute.lastVisited || this.account;

      default:
        return this.basename;
    }
  }
}

// Login route
export class Accounts {
  static title = "accounts";
  static pathname = "accounts";

  // relative paths in accounts route
  static get index(): string {
    return `/${Accounts.pathname}`;
  }

  static get login(): string {
    return `${Accounts.pathname}/login`;
  }

  static get user(): string {
    return `${Accounts.pathname}/user`;
  }

  static get recover(): string {
    return `${Accounts.pathname}/recovery`;
  }

  static get forgotPassword(): string {
    return `${Accounts.pathname}/forgotPassword`;
  }

  static get resetConfirmation(): string {
    return `${Accounts.pathname}/resetConfirmation`;
  }
}

export class Inventory {
  static title = "inventory";
  static pathname = "inventory";

  // Absolute routh path
  static get index(): string {
    return `/${Inventory.pathname}`;
  }

  static get products(): string {
    return `${Inventory.pathname}/products`;
  }
}

export class Orders {
  static title = "orders";
  static pathname = "orders";

  // Absolute routh path
  static get index(): string {
    return `/${Orders.pathname}`;
  }

  static get all(): string {
    return `${Orders.pathname}/all`;
  }
}

export class DiscountsRoute {
  static title = "discounts";
  static pathname = "discounts";
  static sellerDiscountsPathname = "seller";
  static customDiscountsPathname = "custom";
  static prettycharmDiscountsPathname = "prettycharm";

  // Absolute routh path
  static get index(): string {
    return `/${DiscountsRoute.pathname}`;
  }

  static get prettycharm(): string {
    return `${DiscountsRoute.pathname}/${DiscountsRoute.prettycharmDiscountsPathname}`;
  }
}

export const buyerApp = {
  title: "buyer app",
  name: "buyer+app",
  path: "/buyer+app",
};

export const inventory = {
  title: "inventory",
  name: "inventory",
  path: `/inventory`,
};

export const inventoryProduct = {
  title: "inventory product",
  name: "inventory+product",
  path: `${sellerApp.name}/inventory`,
};
/*
export const routes = {
  home: {
    title: "home",
    name: "home",
    path: "/",
  },
  login: {
    title: "login",
    name: "login",
    path: "/login",
  },
  sellerApp: {
    title: "seller app",
    name: "seller+app",
    path: "/seller+app",
  },
  buyerApp: {
    title: "buyer app",
    name: "buyer+app",
    path: "/buyer+app",
  },
};*/
