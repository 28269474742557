import axios from "axios";
import { ResourceType } from "cloudinary";
import {
  CloudinaryError,
  Signature,
  UploadedAsset,
} from "../../Models/Cloudinary";
import { DataObj } from "../../Models/DataObj";
import { Result, ResultType } from "../../Models/Result";

export enum CloudinaryEndpoint {
  upload = "upload",
  destroy = "destroy",
}

enum APIErrorType {
  unexpected = "Unexpected",
  notfound = "Notfound",
  badrequest = "BadRequest",
  addressUnreachable = "Unreachable",
  serverError = "ServerError",
  authorizationError = "AuthorizationError",
  rateError = "RateError",
}

export class CloudinaryAPIError {
  private _value = APIErrorType.unexpected;
  private _msg = "An unexpected error occurred";
  static default = new CloudinaryAPIError();

  //   constructor(
  //     value: CloudinaryAPIError = CloudinaryAPIError.unexpected,
  //     msg: string = "An unexpected error occurred"
  //   ) {
  //     this._value = value;
  //     this._msg = msg;
  //   }

  get errorDescription() {
    return this._msg;
  }

  is404() {
    return this._value === APIErrorType.notfound;
  }

  is400() {
    return this._value === APIErrorType.badrequest;
  }

  get isNetworkError() {
    return this._value === APIErrorType.addressUnreachable;
  }

  update(newError: APIErrorType, newMsg: string) {
    this._value = newError;
    this._msg = newMsg;
  }
}

/**
 * A model for an endpoint
 */
export class Endpoint {
  private baseURL = "https://api.cloudinary.com";
  private _value = CloudinaryEndpoint.upload;

   

  get url(): URL | null {
    let urlString = "";
    let apiVersionPath = "v1_1/prettycharm";
    switch (this._value) {
      case CloudinaryEndpoint.upload:
        urlString = `${this.baseURL}/${apiVersionPath}/image/${this._value}`;
        return new URL(urlString);
      case CloudinaryEndpoint.destroy:
        urlString = `${this.baseURL}/${apiVersionPath}/:resource_type/destroy`;
        return new URL(urlString);
      default:
        break;
    }

    return null;
  }

  get value(): CloudinaryEndpoint {
    return this._value;
  }

  set value(newValue: CloudinaryEndpoint) {
    this._value = newValue;
  }
}

export class CloudinaryAPIManager {
  static shared = new CloudinaryAPIManager();
  private endpoint = new Endpoint();
  private apiError = new CloudinaryAPIError();

  /**
   * uploads files to cloudinary
   * @param files image files from the dom
   */
  async upload(
    file: File,
    signature: Signature,
    folderPath: string,
    publicId: string,
    apiKey: string,
    transformation?: string,
  ): Promise<Result<UploadedAsset, CloudinaryAPIError>> {
    this.apiError.update(
      APIErrorType.unexpected,
      "An unexpected error occurred"
    );
    let result: Result<UploadedAsset, CloudinaryAPIError> = {
      type: ResultType.Failure,
      error: this.apiError,
    };
    let config = {
      uploadProgress: (ev: any) => {
        console.log(ev);
      },
      headers: {}
      
    };

    this.endpoint.value = CloudinaryEndpoint.upload;

    const url = this.endpoint.url;
    if (!url) {
      return result;
    }
    let formData = new FormData();
    // let file = files[0];
    formData.append("file", file);
    formData.append("public_id", publicId);
    formData.append("signature", signature.signature);
    formData.append("timestamp", signature.timestamp.toString());
    formData.append("folder", folderPath);
    formData.append("api_key", apiKey);
    if (transformation) {
      formData.append('eager', transformation)
    }

    try {
      const response = await axios.post(url.href, formData, config);
      result = {
        type: ResultType.Success,
        value: response.data,
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * Generates a signature to sign parameters used for uploading files to cloudinary
   * @param params parameters to sign
   */
  async generateSignature(
    params: DataObj
  ): Promise<Result<Signature, CloudinaryAPIError>> {
    // this.apiError.update(CloudinaryAPIError.unexpected, "An unexpected error occurred")
    let result: Result<any, CloudinaryAPIError> = {
      type: ResultType.Failure,
      error: this.apiError,
    };

    if (Object.keys(params).length === 0) {
      return result;
    }

    try {
      let response = await axios.post(
        "/.netlify/functions/uploadSignature",
        params
      );
      //console.dir(result)
      result = {
        type: ResultType.Success,
        value: response.data,
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };
    }

    return result;
  }

  /**
   * deletes a file using cloudinary's destroy method
   * @param publicIdPath publicId as a path
   * @param signature
   * @param apiKey
   * @param resourceType
   */
  async destroyPhotos(
    publicIdPaths: string[],
    apiKey: string,
    resourceType: ResourceType
  ): Promise<Result<DataObj, CloudinaryAPIError>> {
    let result: Result<{ result: string }, CloudinaryAPIError> = {
      type: ResultType.Failure,
      error: CloudinaryAPIError.default,
    };

    // update endpoint
    // this.endpoint.value = CloudinaryEndpoint.destroy;
    // if (!this.endpoint.url) {
    //   return result;
    // }
 
    try {
     
      let response = await axios.post(
        "/.netlify/functions/deletePhotos",
        {
          public_ids: publicIdPaths
        },
        {
          timeout: 20000
        }
      );
      result = {
        type: ResultType.Success,
        value: response.data.deleted,
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };

      //console.error(error)
    }

    return result;
  }

  async destroyPhotoWithSignature(
    publicIdPath: string,
    signature: Signature,
    apiKey: string,
    resourceType: ResourceType
  ): Promise<Result<{ result: string }, CloudinaryAPIError>> {
    let result: Result<{ result: string }, CloudinaryAPIError> = {
      type: ResultType.Failure,
      error: CloudinaryAPIError.default,
    };

    // update endpoint
    this.endpoint.value = CloudinaryEndpoint.destroy;
    if (!this.endpoint.url) {
      return result;
    }
    

    const destroyURL = this.endpoint.url.href.replace(
      ":resource_type",
      resourceType
    );

 
    try {
      
      const response = await axios.post(destroyURL, {
        public_id: publicIdPath,
        api_key: apiKey,
        signature: signature.signature,
        timestamp: signature.timestamp.toString(),
      });
       
      result = {
        type: ResultType.Success,
        value: {result: response.data.deleted},
      };
    } catch (error) {
      this.processError(error);
      result = {
        type: ResultType.Failure,
        error: this.apiError,
      };

      //console.error(error)
    }

    return result;
  }


  /**
   * Selects the appropriate error data and updates apierror with it
   * @param error error returned from server
   */
  private processError(error: unknown): void {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        // console.error(error.response);
        const { data, status }: any = error.response;
        this.updateAPIError({ data, status });
      } else if (error.code === "ERR_NETWORK") {
        this.apiError.update(
          APIErrorType.addressUnreachable,
          "Diamond Crust seems to be offline"
        );
      }
    } else {
      this.apiError.update(
        APIErrorType.unexpected,
        "An unexpected error occurred"
      );
    }
  }

  /**
   * Updates apiError with response error data
   * @param responseErrorData
   */
  private updateAPIError(responseError: {
    data: CloudinaryError;
    status: number;
  }) {
    switch (responseError.status) {
      case 400:
        this.apiError.update(
          APIErrorType.badrequest,
          //responseError.data.error.message
          "An unexpected error occurred"
        );
        break;
      default:
        this.apiError.update(
          APIErrorType.unexpected,
          "An unexpected error occurred"
        );
    }

    /*
    if (this.endpoint.value === CloudinaryEndpoint.upload) {
      switch (responseError.status) {
        case 400:
          this.apiError.update(
            CloudinaryAPIError.badrequest,
            responseError.data.error.message
            // "An unexpected error occurred"
          );
          break;
        default:
      }
    }*/
  }
}

const cloudinaryAPIManager = CloudinaryAPIManager.shared;
export default cloudinaryAPIManager;
