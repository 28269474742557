import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

interface ShowPasswordProps {
  sx: SxProps<Theme>;
  text?: string
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
  checked: boolean;
  id?: string
}

const DCCheckbox = ({ sx, text, onChange, checked, id }: ShowPasswordProps) => {
   return (
    <FormGroup sx={sx}>
      <FormControlLabel
        sx={{
         
          ".MuiFormControlLabel-label": {
            marginRight: "0px !important",
            fontSize: {
              xs: "0.9rem",
              md: "1rem"
            },
          }
        }}
        control={
          <Checkbox
            id={id}
            checked={checked}
            onChange={onChange}
            inputProps={{ "aria-label": "show password" }}
          />
        }
        label={text ?? "Show Password"}
      />
    </FormGroup>
  );
};

export default DCCheckbox;
