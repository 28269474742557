import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import { FormikHelpers, useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ChipsList, { ChipData, ChipsListVariant } from "./ChipsList";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { SearchFilter } from "../../Models/SearchManager";
import { DataObj } from "../../Models/DataObj";
import { ArrowBack } from "@mui/icons-material";

// export interface FilterFormModel {
//   textQuery: string;
//   subcategory: string[];
//   id: number;
// }

//const {textQuery, subcategory, category, price,  size,} = SearchFilter
export type FilterFormModel = {
  [SearchFilter.textQuery]: string;
  [SearchFilter.subcategory]: string[];
  [SearchFilter.price]: string[];
  [SearchFilter.category]: string[];
  [SearchFilter.plating]: string[];
  [SearchFilter.size]: string[];
  id: number;
};
export interface FilterFormMenuItem {
  selectionType: ChipsListVariant;
  id: SearchFilter;
  data: { id: string; label: string }[];
  label?: string;

}

interface FilterFormProps {
  handleFilter: (
    values: FilterFormModel,
    formikHelpers: FormikHelpers<FilterFormModel>
  ) => void | Promise<any>;
  initialValues: FilterFormModel;
  onUpdate?: (values: FilterFormModel) => void;
  menuList: FilterFormMenuItem[];
  onSearchCancel: ()=>void
  onSearchBegin: ()=>void
  isSearching: boolean
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FilterForm = (props: FilterFormProps) => {
  // const [draft, setDraft] = useState<FilterFormModel>({
  //   textQuery: "",
  //   subcategory: [],
  //   id: 0,
  //   category: [],
  //   price: [],
  //   size: [],
  //   plating: [],
  // });
  const [isFilterMenuPresented, setIsFilterMenuPresented] = useState(false);

  // console.dir

  const formik = useFormik({
    initialValues: {...props.initialValues},
    enableReinitialize: true,
    onSubmit: props.handleFilter,
  });

  const isFilterApplied = (() => {
    let isApplied = false;

   // for (let [key, values] of Object.entries(formik.values)) {
    for (let [key, values] of Object.entries(props.initialValues)) {
      if (
        key !== SearchFilter.textQuery &&
        key !== "id" &&
        (values as string[]).length > 0
      ) {
        isApplied = true;
      }
    }

    return isApplied;
  })();

  const hasSelectionChanged = (() => {
    let formValues: DataObj = {}
    let draftValues: DataObj = {}

    props.menuList.forEach(menuItem => {
        if (menuItem.id !== SearchFilter.textQuery) {
            formValues[menuItem.id] =  formik.values[menuItem.id] 
            draftValues[menuItem.id] = props.initialValues[menuItem.id] //draft[menuItem.id]   
  
        }
    })
    
    return JSON.stringify(formValues) !== JSON.stringify(draftValues)
  })()
  

 
  

    
  return (
    <Box
      paddingX="1rem"
      paddingY="1.5rem"
      component="form"
      display="flex"
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      {
        props.isSearching && (
          <IconButton onClick={()=>{
            props.onSearchCancel()
          }} sx={{paddingLeft: "unset"}}>
            <ArrowBack/>
          </IconButton>
        )
      }
      <TextField
        value={formik.values.textQuery}
        onChange={formik.handleChange}
        
        onClick={props.onSearchBegin}
        placeholder="What are you looking for?"
        // onChange={(e: any) =>{}}
        fullWidth
        size="small"
        id="textQuery"
        variant="outlined"
        type="search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disableFocusRipple
                sx={{
                  backgroundColor: isFilterApplied
                    ? "primary.main"
                    : "rgba(0, 0, 0, 0.04)",
                  borderRadius: "0.25rem",
                  "&:hover": {
                    backgroundColor: isFilterApplied
                    ? "primary.main"
                    : "rgba(0, 0, 0, 0.04)",
                  },
                }}
                aria-label="filter"
                onClick={(e: any) => {
                  e.stopPropagation()
                  setIsFilterMenuPresented(true);
                }}
                edge="end"
              >
                <FilterListIcon
                  htmlColor={isFilterApplied ?  "white": "primary.dark"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog
        fullScreen={true}
        onClose={() => setIsFilterMenuPresented(false)}
        open={isFilterMenuPresented}
        TransitionComponent={Transition}
        sx={{pt: "3rem"}}
      >
        <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                size="small"
                sx={{ mr: 2 }}
                onClick={() => {
                  setIsFilterMenuPresented(false);
                }}
                aria-label="close"
              >
                <Close />
              </IconButton>

              <Typography
                sx={{ flexGrow: 1 }}
                variant="headline"
                // color="text.secondary"
                textAlign={"left"}
              >
                Refine
              </Typography>

              <Button disabled={isFormCleared(formik.values)} onClick={()=> {
                props.menuList.forEach(menuItem => {
                    if (menuItem.id !== SearchFilter.textQuery) {
                         
                        formik.setFieldValue(menuItem.id, [])
                    }
                })
                //setIsFilterMenuPresented(false)
               // props.onClearFilter?.()
              }}>clear</Button>
            </Toolbar>
          </AppBar>
        
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
             
              flexGrow: 1
            }}
          >
            {props.menuList.map((menu) => {
              const selection = new Set<ChipData>(
                menu.data.filter((menuItem) =>
                  formik.values[menu.id].includes(menuItem.id)
                )
              );
              // const selection = new Set<ChipData>(formik.values.categories

              return (
                <ListItem
                  alignItems="flex-start"
                  key={menu.id}
                  divider
                  sx={{ flexDirection: "column", width: "inherit" }}
                >
                  <Typography textTransform={"capitalize"} variant="headline">
                    {menu.label}
                  </Typography>
                  <ChipsList
                    textTransform={menu.id === SearchFilter.size ? "uppercase" : "capitalize"}
                    key={menu.id}
                    data={menu.data}
                    value={selection}
                    variant={menu.selectionType}
                    onChange={(userSelection) => {
                      let idsSelectedSoFar = Array.from(userSelection).map(
                        (value) => value.id
                      );
                      formik.setFieldValue(menu.id, []);
                      formik.setFieldValue(menu.id, [...idsSelectedSoFar]);
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
          <Button
            disabled={!hasSelectionChanged}
            variant="contained"
            size="large"
            sx={{width: "87.5%",  margin: "2rem auto",}}
            onClick={() => {
              setIsFilterMenuPresented(false);
              formik.handleSubmit();
            }}
          >
            apply
          </Button>
       
      </Dialog>
    </Box>
  );
};

export default FilterForm;

const isFormCleared = (formValuesObj: FilterFormModel): boolean => {
  for (const [field, value] of Object.entries(formValuesObj)) {
    if (field !== 'id' && field !== 'textQuery') {
       const values = value as string[]
       if (values.length > 0) {
        return false
       }
    }
  }
  return true
}
