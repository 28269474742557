import { APIError } from "../networking/SupabaseAPIManager/SupabaseAPIManager";
import { LookupTable, PromoProduct } from "./LookupTables";
import { Product } from "./Product";
 
export interface AppData {
    cart: SavedItem[]
    promoService: PromoProduct |  null
    canAddItem: (variantId: string, product: Product) => boolean,
    clearCart: () => void
    clearSavedAppData: () => void
    loadLookupTables: () => Promise<APIError | boolean>;
     getCategoryTitle: (categoryId: string) => string
    getCart: () => SavedItem[],
    getSubcategoryTitleOfCategory: (categoryId: string, subcategoryId: string) => string
    sizeTitleForId: (sizeId: string) => string 
    colorTitleForId: (colorId: string) => string
    accessoryTitleForId: (accessoryId: string) => string
    getLookuptableForType: (type: LookupTable, categoryId?: string) => any[]
    updateRPAT: (newRPAT: string) => void
    getRPAT: ()=> string | null
    getPasswordRecoveryData: () => Map<string, string>
    authProviderDataFromHash: (hash: string) => Map<string, string> | null
    setPreAuthPathname: (currPathname: string) => void
    getPreAuthPathname: () => string | undefined
    gotoSellerApp: (pathname: string) => void
    gotoShoppingApp: (pathname?: string, search?: string) => void
    saveItemToCart: (item: SavedItem) => void
    updateQuantityForItem: (variantId: string, newQuantity: number) => boolean
    removeBagItem: (variantId: string) => SavedItem[] | null
    /**
     * @throws APIError
     * 
     */
    loadPromoProduct: () => Promise<void>
}

 

export const defaultAppData: AppData = {
     cart: [],
     promoService:  null,
     canAddItem: (variantId: string, product: Product): boolean => {
      return false
     },
     clearCart: () => {},
     clearSavedAppData: () => {return},
     loadLookupTables: (): Promise<APIError | boolean> => {
        return new Promise((resolve, reject) => {
          resolve(APIError.default);
        });
      },
       getCategoryTitle: (categoryId: string): string => {return "N/A"},
      getCart: (): SavedItem[] => {return []},
      getSubcategoryTitleOfCategory: (categoryId: string, subcategoryId: string): string => {return "N/A"},
      sizeTitleForId: (sizeId: string): string => {return "N/A"},
      colorTitleForId: (colorId: string): string => {return "N/A"},
      accessoryTitleForId: (accessoryId: string): string => {return "N/A"},
      getLookuptableForType: (type: LookupTable, categoryId?: string): any[] => {return []},
      updateRPAT: (newRPAT: string) => {},
      getRPAT: (): string | null => {return null},
      getPasswordRecoveryData: ():Map<string, string> => {return new Map()},
      authProviderDataFromHash: (hash: string): Map<string, string> | null => {return null},
      setPreAuthPathname: (currPathname: string) => {},
      getPreAuthPathname: (): string | undefined => {return undefined},
      gotoSellerApp: (pathname: string) => {},
      gotoShoppingApp: (pathname?: string, search?: string) => {},
      removeBagItem: (variantId: string): SavedItem[] | null  => {return null},
      saveItemToCart: (item: SavedItem) => {},
      updateQuantityForItem: (variantId: string, newQuantity: number): boolean => {return false},
    /**
     * @throws APIError
     * 
     */
    loadPromoProduct: (): Promise<void> => {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
}

export interface SavedItem {
  productId: string
  variantId: string
  quantity: number
}