import React from 'react'
import { Outlet  } from 'react-router-dom'
 


const PCAccount = () => {

 // const params = usePa
  return (
    <div>
      <Outlet />
    </div>
  )
}

export default PCAccount