import Grid from "@mui/material/Grid";
import { SxProps, Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Field, FieldArrayRenderProps, FormikHelpers } from "formik";

import { Dispatch, useEffect, useState } from "react";

import { AuthDataObj } from "../../Models/DataObj";
import { NotificationAction } from "../../Models/Notification";
import { APIError } from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import ProductPhotoUploader from "../PCSellerApp/PCInventory/view/mobile/ProductPhotoUploader";
import UploadedPhoto from "../PCSellerApp/PCInventory/view/mobile/UploadedPhoto";
import { Photo, PhotoWithId } from "../../Models/Product";
import DCError from "./DCError";
import { AuthUploadedAsset } from "../../Models/Cloudinary";

interface ProductPhotosGridViewProps {
  arrayHelpers: FieldArrayRenderProps;
  dispatch: Dispatch<NotificationAction>;
  productId: string;
  photosStoragePath: string;
  photos: [string, Photo][];
  variantId?: string;
  uploadProductPhoto: (
    file: File,
    productId: string,
    publicId: string,
    storagePath: string,
    accessToken: string,
    refreshToken: string,
    variantId?: string
  ) => Promise<APIError | AuthUploadedAsset>;
  deleteProductPhoto: (
    id: string,
    publicId: string,
    accessToken: string,
    refreshToken: string
  ) => Promise<APIError | AuthDataObj>;
  onSubmit: (<T>(
    values: T,
    formikHelpers: FormikHelpers<T>
  ) => void | Promise<any>) &
    (<T>(values: T, actions: FormikHelpers<T>) => Promise<any>);
  onDeletePhoto: (index: number, key: string) => void;
}

const ProductPhotosGridView = ({
  arrayHelpers,
  dispatch,
  productId,
  photosStoragePath,
  photos,
  variantId,
  uploadProductPhoto,
  deleteProductPhoto,
  onSubmit,
  onDeletePhoto,
}: ProductPhotosGridViewProps) => {
 
  const [error, setError] = useState<APIError | null>(null);

  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  const [imgStyle, setImgStyle] = useState<SxProps<Theme>>({
    border: "2px solid primary.main", //`2px solid ${theme.palette.primary.main}`,
    borderRadius: "8%",
    height: { xs: "144px", md: "160px", lg: "196px" },
    objectFit: "cover",
  });

  const [uploaderStyle, setUploaderStyle] = useState<SxProps<Theme>>(() => ({
    cursor: "pointer",
    height: { xs: "144px", md: "160px", lg: "196px" },
  }));

  const [photoToDelete, setPhotoToDelete] = useState<string | null>(null);

  useEffect(() => {
    if (CSS.supports("aspect-ratio", "4 / 3.75")) {
      setImgStyle({
       // aspectRatio: "4 / 3.75",
       aspectRatio: "3.8 / 4",
        border: (theme => `2px solid ${theme.palette.primary.main}`),
        borderRadius: "8%",
       
        height: { xs: "144px", md: "160px", lg: "196px" },
        objectFit: "cover",
      });

      setUploaderStyle({
        cursor: "pointer",
        aspectRatio: "3.8 / 4",
        height: { xs: "144px", md: "160px", lg: "196px" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DCError onErrorDisplayed={() => setError(null)} error={error} />
      <Grid width="100%" container spacing={matches ? 4 : 2}>
        {photos.map((photo: PhotoWithId, index: number) => {
         
          const [key, value] = photo;

          return (
            // <Grid id={key} key={key} item xs={6} md={3}>
             <Grid id={key} key={key} item xs={12} md={12}>
              <Field
                id={`${arrayHelpers.name}.${index}`}
                name={`${arrayHelpers.name}.${index}`}
                
              >
                {({ field, meta, form }: any) => {
                  const [, photo] = field.value as PhotoWithId;

                  return photo.url === "" ? (
                    <ProductPhotoUploader
                      dispatch={dispatch}
                      storagePath={photosStoragePath}
                      id={field.name}
                      name={field.name}
                      onUpload={uploadProductPhoto}
                      arrayHelpers={arrayHelpers}
                      productId={productId}
                      sx={uploaderStyle}
                      variantId={variantId}
                      onSubmit={onSubmit}
                    />
                  ) : (
                    <UploadedPhoto
                      src={value.url}
                      sx={imgStyle}
                      showProgress={key === photoToDelete}
                      onDelete={async () => {
                        setPhotoToDelete(key);
                        await onDeletePhoto(index, key);
                        setPhotoToDelete(null);
                      }}
                    />
                  )
                }}
              </Field>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
};

export default ProductPhotosGridView;
