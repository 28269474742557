import {
  createTheme,
  Theme,
  experimental_sx as sx,
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    largeTitle: React.CSSProperties;
    title1: React.CSSProperties;
    title2: React.CSSProperties;
    title3: React.CSSProperties;
    headline: React.CSSProperties;
    body: React.CSSProperties;
    callout: React.CSSProperties;
    subhead: React.CSSProperties;
    footnote: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    largeTitle?: React.CSSProperties;
    title1?: React.CSSProperties;
    title2?: React.CSSProperties;
    title3?: React.CSSProperties;
    headline?: React.CSSProperties;
    body?: React.CSSProperties;
    callout?: React.CSSProperties;
    subhead?: React.CSSProperties;
    footnote?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    largeTitle: true;
    title1: true;
    title2: true;
    title3: true;
    headline: true;
    body: true;
    callout: true;
    subhead: true;
    footnote: true;
    caption1: true;
    caption2: true;
  }
}


export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: sx((theme: Theme) => ({
          color: theme.palette.primary.dark,
          "&:hover": {
            
             backgroundColor: "primary.main",
          },
          // "&:hover": {
            
          //    backgroundColor: "rgba(68, 44, 46, 0.72)",
          // },
        })),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: sx((theme: Theme) => ({
          color: theme.palette.text.primary,
        })),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: "rgba(254, 219, 208, 0.54)"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "red",
          // "& label.Mui-focused": {
          //   color: "red",
          // },

          // "& div.Mui-focused": {
          //   borderColor: "yellow",
          // },

          // "& .Mui-OutlinedInput-root.Mui-focused": {
          //   "& fieldset": {
          //     borderColor: "yellow",
          //   },
          // },
        },
        /*
        sx((theme: Theme) => ({
          "& label.Mui-focused": {
            color: theme.palette.primary.dark,
          },

          

          "& .MuiInput-underline:after": {
            borderBottomColor: "green",
          },

          "& .Mui-OutlinedInput-root.Mui-focused": {
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.dark,
            },
          },
           
        })),*/
      },
    },
  },
  palette: {
    primary: {
      main: "#fedbd0",
      dark:  "#442C2E",
    },
    secondary: {
      main: "#feeae6",
    },
    text: {
     primary: "#442C2E", //"rgba(129,47,47,0.87)",
      secondary: "#212121",
    },
  },
  typography: {
    largeTitle: {
      fontWeight: 700,
      fontSize: 34,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    title1: {
      fontWeight: 400,
      fontSize: 28,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    title2: {
      fontWeight: 400,
      fontSize: 22,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    title3: {
      fontWeight: 400,
      fontSize: 20,
      fontFamily: '"Rubik","system-ui", "Helvetica", "Arial", sans-serif',
    },
    headline: {
      fontWeight: 500,
      fontSize: 17,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',

     // fontFamily: '"Rubik", "Helvetica", "Arial", sans-serif',
    },
    body: {
      
      fontWeight: 400,
      fontSize: 17,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    callout: {
      fontWeight: 400,
      fontSize: 16,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    subhead: {
      fontWeight: 400,
      fontSize: 15,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    footnote: {
      fontWeight: 400,
      fontSize: 13,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    caption1: {
      fontWeight: 400,
      fontSize: 12,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    caption2: {
      fontWeight: 400,
      fontSize: 11,
      fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
    },
    fontFamily: '"Rubik", "system-ui", "Helvetica", "Arial", sans-serif',
  },
});
