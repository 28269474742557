export enum NotificationType {
  error = "error",
  success = "success",
  warning = "warning"
}

export enum NotificationActionType {
  open = "Open",
  close = "Close",
}

export interface NotificationState {
  opened: boolean;
  type: NotificationType;
  msg: string;
}

export interface NotificationAction {
  payload: { type: NotificationType; msg: string };
  type: NotificationActionType;
}
