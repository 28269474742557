import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { Review } from "../../Models/Review";
import { getTimePassed } from "../../utils";
import Button from "@mui/material/Button";

interface ReviewCardProps {
  review: Review;
  variant?: "elevation" | "outlined" | undefined;
  height?: string;
  onShowMore?: () => void;
}

const ReviewCard = ({
  review,
  variant,
  height,
  onShowMore,
}: ReviewCardProps) => {
  const descRef = useRef<HTMLElement | null>(null);
  const unclampedDescRef = useRef<HTMLElement | null>(null);
  const [showButton, setShowButton] = useState<boolean>(false);

  const reviewDate = (() => {
    let [value, type] = getTimePassed(new Date(review.createdAt), new Date());
    const pluralizedType = value > 1 ? type + "s" : type;
    return `${value} ${pluralizedType} ago`;
  })();

  useEffect(() => {
    // console.dir(descRef);
    // console.dir(unclampedDescRef);

    if (
      onShowMore !== undefined &&
      (unclampedDescRef.current?.clientHeight ?? 0) >
        (descRef.current?.clientHeight ?? 0)
    ) {
      setShowButton(true);
    }
  }, [onShowMore]);
  var content = (() => {
    return (
      <CardContent
        id="dummy"
        sx={{
          visibility: "hidden",
          textAlign: "left",
          position: "absolute",
        }}
      >
        <Typography
          ref={unclampedDescRef}
          variant="body2"
          color="text.secondary"
        >
          {review.description}
        </Typography>
      </CardContent>
    );
  })();

  return (
    <>
      {content}
      <Card variant={variant} sx={{ width: "100%", minHeight: "12.5rem" }}>
        <CardHeader
          sx={{
            textAlign: "left",
            "& .MuiCardHeader-title": {
              textTransform: "capitalize",
            },
          }}
          avatar={
            <Avatar
              src={review.avatarUrl ?? undefined}
              sx={{ bgcolor: "primary.main", color: "primary.dark" }}
              aria-label="recipe"
            >
              {review.firstname?.length > 0
                ? review.firstname[0].toUpperCase()
                : "G"}
            </Avatar>
          }
          title={review.firstname ?? "Guest"}
          subheader={reviewDate}
        />

        <CardContent
          id="original"
          sx={{
            textAlign: "left",
            "&.MuiCardContent-root:last-child": {
              paddingBottom: "1rem",
            }
          }}
           
        >
          <Typography
            sx={{
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp:
                onShowMore !== undefined ? (showButton ? "3" : "5") : "unset",
              WebkitBoxOrient: "vertical",
              mb: "0.5rem"
            }}
            ref={descRef}
            variant="body2"
            color="text.secondary"
          >
            {review.description}
          </Typography>
          {showButton && (
            <Button
              onClick={onShowMore}
              size="small"
              sx={{ textDecoration: "underline" }}
            >
              Show More
            </Button>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ReviewCard;
