import LoadingButton from "@mui/lab/LoadingButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AppDataContext } from "../../../contexts/AppDataContext";
import { SavedItem } from "../../../Models/AppData";
import { LookupTable } from "../../../Models/LookupTables";
import {
  CategoryDetails,
  ColorDetails,
  SizeDetails,
  SubcategoryDetails,
} from "../../../Models/ProductOptions";
import { formatPrice } from "../../../utils";
import AttributeOptionsGrid from "../../SupportingViews/AttributeOptionsGrid";
import ProductDetailColorChooser from "./ProductDetailColorChooser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccordionDetails from "@mui/material/AccordionDetails";
import { UserAccountContext } from "../../../contexts/UserAccountContext";

import DCError from "../../SupportingViews/DCError";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import RequestStatusView from "../../SupportingViews/RequestStatusView";
import ReviewsSection from "./ReviewsSection";
import { RequestStatus } from "../../../Models/Result";
import { ProductDetailContext } from "../../../contexts/ProductDetailContext";
import { AuthUser } from "../../../Models/User";
import { FormType } from "../../Providers/AccountsProvider";
import DCBackButton from "../../SupportingViews/DCBackButton";
import ScrollToTop from "../../SupportingViews/ScrollToTop";
import { ShoppingApp } from "../../../routes";
import { Helmet } from "react-helmet";
import DiscountView from "../../SupportingViews/DiscountView";
import PriceView from "../../SupportingViews/PriceView";
import { isDiscountRedeemable } from "../../../utils";

const ProductDetail = () => {
  const params = useParams<{ productId: string; variantId: string }>();
  const [queryParams] = useSearchParams();
  const searchManager = useContext(ProductDetailContext)!;
  const appData = useContext(AppDataContext);
  const userAccountManager = useContext(UserAccountContext);
  const [selectedPlating, setSelectedPlating] =
    useState<ColorDetails | null>(null);
  const [selectedSize, setSelectedSize] = useState<SizeDetails | null>(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [reloadDetailCounter, setReloadDetailCounter] = useState(0);
  const user = userAccountManager.getSignedInUser();
  const navigate = useNavigate();
  const [isProductDetailLoaded, setIsProductDetailLoaded] =
    useState<boolean>(false);
  const [purchaseStatus, setPurchaseStatus] =
    useState<boolean | APIError>(false);
  const location = useLocation();

  const platingTable = appData.getLookuptableForType(
    LookupTable.color
  ) as ColorDetails[];
  const sizesTable = appData.getLookuptableForType(
    LookupTable.size
  ) as SizeDetails[];

  const product = (() => {
    return searchManager.selectedProduct;
  })();

  const productCategoryData = (() => {
    const productCategoriesData = appData.getLookuptableForType(
      LookupTable.category
    ) as CategoryDetails[];
    return productCategoriesData.find(
      (item) => item.category_id === product?.categoryId
    );
  })();

  const productSubcategoryData = (() => {
    const productSubcategoriesData = appData.getLookuptableForType(
      LookupTable.subcategory,
      product?.categoryId
    ) as SubcategoryDetails[];
    return productSubcategoriesData.find(
      (item) => item.subcategory_id === product?.subcategoryId
    );
  })();

  const photoForVariant = (() => {
    const found = product?.productDetails.find(
      (variant) => variant.colorId === selectedPlating?.color_id
    );
    return found ? found.photos["photo1"] : null;
  })();

  const sizesForSelectedPlating: SizeDetails[] | null = (() => {
    const variants = product?.productDetails.filter(
      (variant) => variant.colorId === selectedPlating?.color_id
    );
    if (!variants) {
      return null;
    }
    let temp = variants.map((variant) =>
      productSizeForVariant(variant.sizeId, sizesTable)
    );
    const sizes = temp.reduce((prevResult, currSize) => {
      let result: SizeDetails[] = [...prevResult];
      if (currSize) {
        result.push(currSize);
      }
      return result;
    }, [] as SizeDetails[]);

    return sizes;
  })();

  // variant that matches selected color
  var selectedVariant = (() => {
    return product?.productDetails.find(
      (item) =>
        item.colorId === selectedPlating?.color_id &&
        item.sizeId === selectedSize?.size_id
    );
  })();

  const isFavorited = (() => {
    return selectedVariant?.favoriteId ? true : false;
  })();

  const handleAddToCart = () => {
    if (!product || !selectedVariant) {
      return;
    }
    const itemToAdd: SavedItem = {
      productId: product.productId,
      variantId: selectedVariant.productDetailId,
      quantity: 1,
    };
    appData.saveItemToCart(itemToAdd);
  };

  const handleSelectedPlating = (plating: ColorDetails) => {
    setSelectedPlating(plating);
    setSelectedSize(null);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const productDetailStatus = (() => {
    //  debugger
    let error: APIError;
    error = purchaseStatus as APIError;
    //console.dir(error)
    if (error.errorDescription) {
      return error;
    }

    error = searchManager.reviewRequestStatus as APIError;
    if (error.errorDescription) {
      return error;
    } else if (searchManager.reviewRequestStatus === RequestStatus.Loading) {
      return searchManager.reviewRequestStatus;
    }

    error = userAccountManager.reviewsRequestStatus as APIError;
    if (error.errorDescription) {
      return error;
    } else if (
      userAccountManager.reviewsRequestStatus === RequestStatus.Loading
    ) {
      return userAccountManager.reviewsRequestStatus;
    }

    error = searchManager.selectedProductStatus as APIError;
    if (error.errorDescription) {
      return error;
    } else if (searchManager.selectedProductStatus === RequestStatus.Loading) {
      return searchManager.selectedProductStatus;
    }

    return RequestStatus.Idle;
  })();

  const isSelectionComplete = (() => {
    return selectedPlating && selectedSize;
  })();

  var isOutOfStock = (() => {
    return (selectedVariant?.stockQuantity ?? 0) <= 0;
  })();

  var canAddItem = (() => {
    if (selectedVariant === undefined || product === null) {
      return false;
    }
    return appData.canAddItem(selectedVariant.productDetailId, product);
  })();

  const dividerStyle = { mt: "2rem", width: "100%" };

  const loadReviewsSectionData = async (productId: string) => {
    await searchManager.loadReviewsForProduct(productId, true);
    await userAccountManager.loadUserReviews(productId);
  };

  const handleBack = () => {
    if (location.pathname.indexOf(ShoppingApp.bag) === 0) {
      navigate(ShoppingApp.bag);
      //navigate(ShoppingApp.getLastVisitedForRoute(PCTab.bag))
    } else if (location.pathname.indexOf(ShoppingApp.favorites) === 0) {
      navigate(ShoppingApp.favorites);
    } else {
      navigate(ShoppingApp.basename);
    }
  };

  const handleWishlistVariant = async () => {
    let refreshedAuthuser: AuthUser | null = null;
    if (product === null) {
      return;
    }

    if (!user) {
      userAccountManager.updateCurrentform(FormType.Login);
    } else if (!isFavorited && selectedVariant) {
      refreshedAuthuser = await searchManager.favoriteVariant(
        product.productId,
        selectedVariant.productDetailId,
        user
      );
    } else {
      if (!selectedVariant || !selectedVariant.favoriteId) {
        return;
      }
      refreshedAuthuser = await searchManager.unFavoriteVariant(
        product.productId,
        selectedVariant.favoriteId,
        user
      );
    }
    if (refreshedAuthuser) {
      userAccountManager.saveCredentials(refreshedAuthuser);
    }
  };

  useEffect(() => {
    (async () => {
      if (params.productId) {
        const result = await userAccountManager.hasPurchasedProduct(
          params.productId
        );
        setPurchaseStatus(result);
        const authUser = await searchManager.loadSelectedProduct(
          params.productId,
          user
        );
        if (authUser) {
          userAccountManager.saveCredentials(authUser);
        }

        await loadReviewsSectionData(params.productId);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, reloadDetailCounter]);

  // initialize selectedPlating
  useEffect(() => {
    const platingId = queryParams.get("plating");

    const foundPlating =
      platingTable.find((plating) => plating.color_id === platingId) || null;
    setSelectedPlating(foundPlating);

    if (foundPlating) {
      const foundVariant = product?.productDetails.find(
        (variant) => variant.productDetailId === params.variantId
      );
      const foundSize = sizesTable.find(
        (size) => size.size_id === foundVariant?.sizeId
      );
      if (foundVariant && foundSize) {
        setSelectedSize(foundSize);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, platingTable, product?.productId]);

  useEffect(() => {
    if (productDetailStatus === RequestStatus.Idle) {
      setIsProductDetailLoaded(true);
    } else if (
      (productDetailStatus as APIError).errorDescription ||
      productDetailStatus === RequestStatus.Loading
    ) {
      setIsProductDetailLoaded(false);
    }
  }, [productDetailStatus]);

  useEffect(() => {
    return () => {
      searchManager.clearSelectedProduct();
      userAccountManager.clearUserReviews();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // loading for the 1st time or there's an error loading for the 1st time
  if (
    (productDetailStatus === RequestStatus.Loading && !isProductDetailLoaded) ||
    ((productDetailStatus as APIError).errorDescription &&
      !isProductDetailLoaded)
  ) {
    return (
      <Box
        display="grid"
        sx={{ placeItems: "center" }}
        width="100%"
        height="100vh"
      >
        <ScrollToTop />
        <RequestStatusView
          onRetry={() => {
            searchManager.clearSelectedProduct();
            setReloadDetailCounter(reloadDetailCounter + 1);
          }}
          status={productDetailStatus}
        />
      </Box>
    );
  }

  if (product === null) {
    return null;
  }

  return (
    <Stack spacing={3}>
      <Helmet>
        <title>Product Detail</title>
        <meta name="description" content="Product Detail Prettycharm" />
      </Helmet>
      <Box position={"relative"} width="100%">
        <Box
          sx={{
            width: "100%",
            height: { xs: "72.5vh", md: "82.5vh" },
            objectFit: "cover",
            //height: {{xs: "30vh", md: "50vh"}}
          }}
          component="img"
          src={photoForVariant?.url}
        />

        <Stack
          width="100%"
          paddingX={1}
          paddingY={2}
          justifyContent="space-between"
          direction="row"
          sx={{
            position: "absolute",
            zIndex: "105",
            top: "0.25rem",
            //  right: "0.25rem"
          }}
          id="toolbar"
        >
          <DCBackButton variant="large" onClick={() => handleBack()} />
          <IconButton
            size="large"
            key={isFavorited ? selectedVariant?.favoriteId : product.productId}
            onClick={handleWishlistVariant}
          >
            <FavoriteIcon color={isFavorited ? "error" : undefined} />
          </IconButton>
        </Stack>
      </Box>
      <Stack paddingX="1rem" alignItems={"flex-start"}>
        <Box marginBottom={1}>
        <DiscountView
          isDetailView
          discountInfo={selectedVariant?.discountInfo ?? null}
        />
        </Box>
        

        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography
            textAlign={"left"}
            textTransform={"capitalize"}
            variant="title1"
          >
            {product.title}
          </Typography>
          <PriceView
            sellingPrice={selectedVariant?.sellingPrice ?? null}
            discountInfo={selectedVariant?.discountInfo ?? null}
            isDetailView={true}
          />
        </Stack>
        <Accordion
          variant="outlined"
          sx={{ width: "100%" }}
          expanded={expanded === "description"}
          onChange={handleChange("description")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              textTransform="uppercase"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Description
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign="left">{product.description}</Typography>
          </AccordionDetails>
        </Accordion>
        {productCategoryData && productSubcategoryData && (
          <Stack
            width="100%"
            marginTop={2}
            spacing={2}
            alignItems={"flex-start"}
          >
            <Stack justifyContent={"flexStart"}>
              <Typography
                textTransform="capitalize"
                //sx={{ width: "33%"}}
                textAlign="left"
              >
                Category
              </Typography>
              <Typography textTransform="capitalize" textAlign="left">
                {productCategoryData.category_title}
              </Typography>
            </Stack>

            <Stack justifyContent={"flexStart"}>
              <Typography
                textTransform="capitalize"
                //sx={{ width: "33%"}}
                textAlign="left"
              >
                Type
              </Typography>
              <Typography textTransform="capitalize" textAlign="left">
                {productSubcategoryData.subcategory_title}
              </Typography>
            </Stack>

            <Divider sx={dividerStyle} />
          </Stack>
        )}
      </Stack>

      <Stack spacing={2} sx={{ paddingX: "1em" }}>
        <Stack id="plating-section" alignItems={"flex-start"} width="inherit">
          <Typography mb="1rem" variant="subhead">
            Choose your plating
          </Typography>
          <ProductDetailColorChooser
            colorTable={platingTable}
            variants={product.productDetails}
            selected={selectedPlating}
            onColorSelected={handleSelectedPlating}
          />
          <Divider sx={dividerStyle} />
        </Stack>

        <Stack id="size-section" alignItems={"flex-start"} width="inherit">
          <Typography variant="subhead" mb="1rem">
            Choose your size
          </Typography>
          <AttributeOptionsGrid
            data={sizesForSelectedPlating ?? []}
            render={(size) => (
              <IconButton
                onClick={() => setSelectedSize(size)}
                key={size.size_id}
                sx={(theme) => ({
                  display: "grid",
                  placeItems: "center",
                  height: "6.5rem",
                  width: "100%",
                  borderRadius: "0.5rem",
                  border: `2px solid ${
                    selectedSize?.size_id === size.size_id
                      ? theme.palette.primary.main
                      : "lightgray"
                  }`,
                })}
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight="medium"
                  variant="title3"
                >
                  {size.size_title}
                </Typography>
              </IconButton>
            )}
          />
          <Divider sx={dividerStyle} />
        </Stack>
        <Stack spacing={2} alignItems={"flex-start"} width="inherit">
          <Typography mb="1rem" variant="subhead">
            Return Policy
          </Typography>
          <Typography mb="1rem" textAlign="left">
            Not Accepted. But you can contact us at{" "}
            <a
              style={{ textDecorationColor: "#442C2E", color: "#442C2E" }}
              href="mailto: support@prettycharm.app"
            >
              support@prettycharm.app
            </a>
            &nbsp; if you have any problems with your order
          </Typography>
          <Divider sx={dividerStyle} />
        </Stack>
        <Stack spacing={2} alignItems={"flex-start"} width="inherit">
          {selectedVariant && (
            <>
              {selectedVariant.stockQuantity > 0 &&
                selectedVariant.stockQuantity < 10 && (
                  <Typography>
                    Only {selectedVariant.stockQuantity} left
                  </Typography>
                )}
               

              <Typography textTransform={"uppercase"} variant="headline">
                Final Price:{" "}
                {formatPrice(
                  isDiscountRedeemable(selectedVariant.discountInfo)
                    ? (selectedVariant.discountInfo?.value ?? selectedVariant.sellingPrice)
                    : selectedVariant.sellingPrice
                )}
              </Typography>
            </>
          )}
          <LoadingButton
            onClick={handleAddToCart}
            disabled={!isSelectionComplete || isOutOfStock || !canAddItem}
            sx={{
              height: "3.2rem",
            }}
            // loading={}
            type="submit"
            fullWidth
            variant="contained"
          >
            {isSelectionComplete && isOutOfStock
              ? "Out of Stock"
              : canAddItem
              ? "Add to cart"
              : `Only ${1} available`}
          </LoadingButton>
          <Divider sx={dividerStyle} />
        </Stack>
        <>
          <ReviewsSection
            isProductPurchased={Boolean(purchaseStatus)}
            productId={product.productId}
            variantId={selectedVariant?.productDetailId}
            searchManager={searchManager}
            userAccountManager={userAccountManager}
            avgRating={product.avgRating?.toFixed(1) ?? ""}
            totalReviews={product.reviewCount}
            onUpdateReviews={() => {
              // clear product reviews in order to reload them
              searchManager.loadReviewsForProduct(product.productId, true);
              searchManager.updateProductDetailsForId();
              // setReloadDetailCounter(reloadDetailCounter + 1);
              //  searchManager.clearProductReviews();
            }}
          />
        </>
      </Stack>
      <DCError error={searchManager.editReviewStatus as APIError} />
      <DCError error={searchManager.favoriteStatus as APIError} />
    </Stack>
  );
};

const productSizeForVariant = (
  sizeId: string,
  sizesTable: SizeDetails[]
): SizeDetails | null => {
  return sizesTable.find((size) => size.size_id === sizeId) || null;
};

export default ProductDetail;
