import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import { OrdersContext } from "../contexts/OrdersContext";
import RequestStatusView from "../../SupportingViews/RequestStatusView";
import { RequestStatus } from "../../../Models/Result";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const SellerOrders = () => {
  const userAccount = useContext(UserAccountContext);
  const ordersManager = useContext(OrdersContext);

  const auth = userAccount.getSignedInUser();

  const [loadOrdersCounter, setloadOrdersCounter] = useState(0);

  useEffect(() => {
    (async () => {
      if (!auth || !auth.access_token || !auth.refresh_token) {
        return;
      }

      let authUser = await ordersManager?.loadInventoryForPartner(
        auth.user.id,
        auth.access_token,
        auth.refresh_token
      );
      if (authUser) {
        userAccount.saveCredentials(authUser);
      }

      authUser = await ordersManager?.loadOrdersForInventory(
        auth,
        undefined,
        0
      );
      if (authUser) {
        userAccount.saveCredentials(authUser);
      }
      setloadOrdersCounter(loadOrdersCounter + 1);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  if (!ordersManager) {
    return null;
  }

  return (
    <Box width={"100%"} height={"100%"}>
      
          {ordersManager.inventoryOrders.length === 0 &&
            ordersManager.ordersRequestStatus && (
              <Box
              display="flex"
              width={"100%"}
              height={"-webkit-fill-available"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <RequestStatusView status={ordersManager.ordersRequestStatus} />
             </Box>
            )}

          {loadOrdersCounter > 0 &&
            ordersManager.inventoryOrders.length === 0 &&
            ordersManager.ordersRequestStatus === RequestStatus.Idle && (
              <Box
              display="flex"
              width={"100%"}
              height={"-webkit-fill-available"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="headline">
                There are no orders available
              </Typography>
              </Box>
            )}
        
      
      {loadOrdersCounter > 0 && ordersManager.inventoryOrders.length > 0 && (
        <Outlet />
      )}
    </Box>
  );
};

export default SellerOrders;
