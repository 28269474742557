import { OrderDetail, ShippingCost, ShippingDetails } from "./Order"
import { AuthUser } from "./User"

 export interface InventoryOrder {

    inventoryOrderId: string 
    stripeCustomerId: string 
    customerOrderId: string 
    inventoryId: string
    stripeCustomerName?: string 
    amountTotal: number 
    orderDate?: Date
    fulfilled: boolean
    orderDetails?: OrderDetail[]
    shippingDetails?: ShippingDetails 
    shippingCost: ShippingCost
    billingDetails?: any
    paid?: number 
    createdAt: Date
    fulfillmentStatus: FulfillmentStatus

}

export enum FulfillmentStatus {
     fulfilled = "fulfilled",
     unfulfilled = "unfulfilled",
     fulfilling = "fulfilling"
} 
export interface FulfillmentData {
    fulfillmentStatus: FulfillmentStatus
    count: number
}

export interface AuthInventoryOrderList {
    authUser: AuthUser | null
    inventoryOrdersList: InventoryOrder[]
}

export interface AuthInventoryOrder {
    authUser: AuthUser | null
    inventoryOrder: InventoryOrder
}

export type AuthFulfillmentStatus = {
    authUser: AuthUser | null 
    data: FulfillmentData
}
 