import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

interface OptionalImageProps {
  src: string | undefined;
  sx: SxProps<Theme> | undefined;
  alt: string;
}

const OptionalImage = ({ src, sx, alt }: OptionalImageProps) => {
  return (
    <>
      {!src ? (
        <Box
          sx={{
            ...sx,
            backgroundColor: "gray",
          }}
        />
      ) : (
        <Box component="img" sx={sx} alt={alt} src={src} />
      )}
    </>
  );
};

export default OptionalImage;
