import Button from "@mui/material/Button";
 import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Review, ReviewFormData } from "../../../Models/Review";
import {  DCTextField } from "../../SupportingViews/InputFields";

interface ReviewEditorProps {
  onSubmit: (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => void;
  productId: string;
  variantId?: string;
  review: Review | null;
 // requestStatus: RequestStatus
  onDelete: ()=>void
}

const reviewValidationSchema = yup.object().shape({
  description: yup
    .string()
    .required("Description must not be empty")
    .min(20, "Description must not be below 20 characters")
    .max(500, "Description must not exceed 500 characters"),

  title: yup
    .string()
    .required("Title must not be empty")
    .min(2, "Title must not be below 20 characters")
    .max(50, "Title must not exceed 50 characters"),

  rating: yup.number().required("Rating must be less than 1 star"),
  // .required()
});

const ReviewEditor = (props: ReviewEditorProps) => {
  //const [value, setValue] = React.useState<number | null>(null);
  //let initialValues: ReviewFormData = {title: "", description: "", rating: undefined}
  const {onDelete} = props
  const [draft, setDraft] = useState<ReviewFormData>({
    title: "",
    description: "",
    rating: null,
  });
 
  useEffect(() => {
    setDraft({
      title: props.review?.title ?? "",
      description: props.review?.description ?? "",
      rating: props.review?.rating ?? null,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.review?.reviewId]);

 
  return (
    <Formik
      enableReinitialize
      validationSchema={reviewValidationSchema}
      onSubmit={props.onSubmit}
      initialValues={draft}
      
    >
      {(formikProps: FormikProps<ReviewFormData>) => {
        return (
          <Stack width="100%" p={"1rem"} spacing={2} alignItems="flex-start">
            <Field
              label="Title"
              id="title"
              name="title"
              component={DCTextField}
            />
            <Field
              label="Description"
              id="description"
              name="description"
              component={DCTextField}
            />

            {/* <Field
              label="Rating"
              id="rating"
              name="rating"
              component={DCRating}
            /> */}

            <Field id="rating" name="rating">
              {(fieldProps: any) => {
                return (
                  <Stack>
                    
                    <Rating
                      id="rating"
                      name={fieldProps.field.name}
                      
                      value={fieldProps.field.value}
                      onChange={(_, newValue) => {
                        
                        formikProps.setFieldValue("rating", newValue);
                         //setValue(newValue);
                      }}
                    />
                    {formikProps.touched["rating"] &&
                    Boolean(formikProps.errors["rating"]) ? (
                      <Typography
                        marginX={"14px"}
                        color="error"
                        variant="caption1"
                      >
                        {formikProps.errors["rating"]}
                      </Typography>
                    ) : null}
                  </Stack>
                );
              }}
            </Field>
            <Stack width="100%" justifyContent="space-between" direction="row">
              <Button variant="outlined"  sx={{width: "45%"}}  onClick={() => formikProps.handleSubmit()}>Submit</Button>
               
              <Button sx={{display: props.review !== null ? "unset" : "none", width: "45%"}} color="error" fullWidth variant="contained"  onClick={() => onDelete()}>Delete</Button>
            </Stack>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default ReviewEditor;
