import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { ReactNode, useContext  } from "react";
import {  useNavigate } from "react-router-dom";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import { RequestStatus } from "../../../Models/Result";
import { APIError } from "../../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { Orders, SellerApp  } from "../../../routes";
import { tableCellStyle } from "../../../utils/styles";
import DCError from "../../SupportingViews/DCError";
import LoadMoreButton from "../../SupportingViews/LoadMoreButton";
import { OrdersContext } from "../contexts/OrdersContext";
import InventoryOrderRow from "../PCInventory/view/mobile/InventoryOrderRow";

const InventoryOrders = () => {

  const userAccountManager = useContext(UserAccountContext)
  const auth = userAccountManager.getSignedInUser();
  const ordersManager = useContext(OrdersContext);
  const navigate = useNavigate()

  const inventoryOrdersError = (() => {
    const error = ordersManager?.ordersRequestStatus as APIError;
   
    return error.errorDescription ? error : null;
  })();

  const isLoadingOrders = (():boolean => {
    const status = ordersManager?.ordersRequestStatus as RequestStatus;
    return status === RequestStatus.Loading
  })();

  const tableHead = ((): ReactNode => {
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={tableCellStyle} align="left">
            S/N
          </TableCell>
          <TableCell align="left">Order Date</TableCell>
          <TableCell
            //width={400}
            align="left"
          >
            Customer&nbsp;
          </TableCell>
          <TableCell align="left">Total&nbsp;</TableCell>
          <TableCell align="left">Payment&nbsp;</TableCell>
          <TableCell align="left">FulFillment&nbsp;</TableCell>
          <TableCell align="left">Items&nbsp;</TableCell>
        </TableRow>
      </TableHead>
    );
  })();

  const inventoryOrderRows = (() => {
    return ordersManager?.inventoryOrders.map((order, index) => (
      <InventoryOrderRow sn={index + 1} onClick={() => navigate(`${SellerApp.index}${Orders.index}/${order.inventoryOrderId}`)} key={order.inventoryOrderId} order={order} />
    ));
  })();

  const handleLoadMoreOrders = async ()=> {
     if (!ordersManager) {return }
     let authUser = await ordersManager?.loadOrdersForInventory(auth, undefined, ordersManager.inventoryOrders.length)
     if (authUser) {
       userAccountManager.saveCredentials(authUser)
     }
  }

  if (ordersManager?.ordersRequestStatus === RequestStatus.Loading && ordersManager.inventoryOrders.length === 0) {
    return (
      <Box sx={{ pt: 6, width: "100%", height: "100vh" }}>
        <CircularProgress />;
      </Box>
    );
  }

  //   if (errorDesc) {
  //      return (
  //       <OfflineView
  //         msg={errorDesc}
  //         sx={{ pt: 6, width: "100%", height: "100vh" }}
  //         onRetry={() => {
  //           setCounter((prev) => prev + 1);
  //           // setErrorDesc(null)
  //         }}
  //       />
  //     );
  //   }
  
 
  return (
    <Stack
      sx={{
        width: "100%",
        minHeight: "100vh",
      }}
    >
      InventoryOrders
      <DCError error={inventoryOrdersError} />
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: "100%",
            borderCollapse: "inherit",
            tableLayout: "auto",
          }}
          // sx={{ width: "max-content", borderCollapse: "inherit", tableLayout: "auto" }}
          size="medium"
          aria-label="orders table"
        >
          {tableHead}
          <TableBody sx={(theme) => ({ color: theme.palette.text.secondary })}>
            {inventoryOrderRows}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadMoreButton
            sx={{
              display: ordersManager?.hasMoreInventoryOrders ? "initial" : "none",
            }}
            loading={isLoadingOrders}
            onClick={handleLoadMoreOrders}
          />
    </Stack>
  );
};

export default InventoryOrders;
