import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { SxProps } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Theme } from "@mui/system";
import React from "react";
 import { UserProfile } from "../../Models/UserProfile";
 
interface PCMenuButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id: string | undefined;
  userprofile: UserProfile | null;
}

const PCMenuButton = ({ onClick, id, userprofile }: PCMenuButtonProps) => {
  
  const avatarURL = userprofile?.avatarUrl;
  const firstname = userprofile?.firstname?.toUpperCase()
  
   const buttonLabel = (() => {
      
    return avatarURL || firstname ? (
      <Avatar sx={{
        width: "2rem",
        height: "2rem",
        ml: "auto",
        bgcolor: "primary.dark"
      }} alt={firstname} src={avatarURL}>{firstname?.[0] ?? ""}</Avatar>
    ) : (
      <Stack direction="row">
        <MenuIcon />
        <AccountCircle />
      </Stack>
    );
  })();

  const buttonStyle: SxProps<Theme> | undefined = (() => {
    return userprofile
      ? {
          ml: "auto",
        }
      : {
          ml: "auto",
          width: 10,
          height: 3,
          paddingBlock: 2,
          "&.MuiButton-outlined": {
            color: "primary.dark",
            border: "2px solid",
            borderColor: "primary.dark",
          },
        };
  })();

   return (
    <Button
      aria-describedby={id}
      aria-label="menu"
      variant="outlined"
      onClick={onClick}
      sx={buttonStyle}
    >
      {buttonLabel}
    </Button>
  );
};

export default PCMenuButton;
