import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import SignupForm from "./views/mobile/SignupForm";
import { useContext, useReducer } from "react";
import LoginForm from "./views/mobile/LoginForm";
import { ChevronLeftOutlined, CloseOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import { useNavigate, useParams } from "react-router-dom";
import { Accounts } from "../../../routes";
import { loginReducer } from "../../../contexts/LoginContext";
import { initialLoginState } from "../../../defaultValues";
 import ResetPasswordForm from "./views/mobile/ResetPasswordForm";
import ForgotPasswordForm from "./views/mobile/ForgotPasswordForm";
import { PresentationContext } from "../../../contexts/PresentationContext";
import { UserAccountContext } from "../../../contexts/UserAccountContext";
import { FormType } from "../../Providers/AccountsProvider";

export interface SignupProps {
  // onCloseBtnClick: ()=>void
}

const Signup = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const params = useParams<{ status: string }>();
  const { dismiss } = useContext(PresentationContext);
  const accountManager = useContext(UserAccountContext);


  const mainPaddingStyle = {
    paddingInline: isMediumScreen ? 3 : 2,
    paddingBlock: isMediumScreen ? 4 : 2,
  };
  

  const [loginModel, dispatch] = useReducer(loginReducer, initialLoginState);
  //const { loginCredentials, showingSignupForm: showSignupForm } = loginModel;
  const { loginCredentials } = loginModel;
 
  const isShowingForgotPassword = params?.status === "forgotPassword";
  const isShowingRecoveryForm = params?.status === "recovery";
  // event listeners

  const onBackBtnClick = () => {
     if (isShowingForgotPassword) {
      navigate(-1);
    } else {
      accountManager.updateCurrentform(FormType.Login)
     
    }
  };

  const showSignupForm = ((): boolean =>{
     return accountManager.hasSelectedFormWithType(FormType.Signup)
  })()

  
  
  
   // computed views
  let selectedForm = (
    <>
      {showSignupForm
      ? (
        <SignupForm
          onForgotPasswordClick={() => navigate(`/${Accounts.forgotPassword}`)}
          loginCredentials={loginCredentials}
        />
      ) : isShowingRecoveryForm ? (
        <ResetPasswordForm />
      ) : isShowingForgotPassword ? (
       
         <ForgotPasswordForm />
      ) : (
       
        <LoginForm
          onForgotPasswordClick={() => navigate(`/${Accounts.forgotPassword}`)}
          loginCredentials={loginCredentials}
          dispatchLoginAction={dispatch}
        />
      )}
    </>
  );

  let backButton = (() => {
    return (
      <IconButton
        onClick={onBackBtnClick}
        aria-label="back"
        size={isMediumScreen ? "large" : "medium"}
      >
        <ChevronLeftOutlined
          sx={{
            border: "1px solid",
            borderRadius: "50%",
          }}
          fontSize="inherit"
        />
      </IconButton>
    );
  })();

  let closeButton = (() => {
    return (
      <IconButton
        onClick={() => {
          if (dismiss) {
            dismiss();
          }
        }}
        aria-label="back"
        size={isMediumScreen ? "large" : "medium"}
      >
        <CloseOutlined
          sx={{
            border: "1px solid",
            borderRadius: "50%",
          }}
          fontSize="inherit"
        />
      </IconButton>
    );
  })();

  const formHeading = showSignupForm
    ? "Finish Signing Up"
    : isShowingRecoveryForm
    ? "Reset Password"
    : isShowingForgotPassword
    ? "Reset Forgotten Password"
    : "Log In or Signup";

  const pageHading = isShowingRecoveryForm
    ? "Enter your email and new password"
    : isShowingForgotPassword
    ? "We will send you an email with instructions on how to reset your password."
    : "Welcome to PrettyCharm";

  const pageHeadingVariant: any = isMediumScreen ? "title3" : "headline";

  return (
    <Stack
      id="reset-form-container"
      alignItems={"flex-start"}
      sx={{
        width: {
          xs: "100%", 
          md: "600px"
        },
        margin: "0 auto"
        // marginBlock: 12,
      }}
    >
      <Stack direction={"row"} alignItems={"center"}  >
        {showSignupForm || isShowingForgotPassword ? backButton : closeButton}

        <Typography
          alignSelf={"center"}
          sx={mainPaddingStyle}
          textAlign={"center"}
          variant="subtitle1"
        >
          {formHeading}
        </Typography>
      </Stack>
      {/* </Box> */}
      <Divider
        sx={{
          width: "inherit",
        }}
      />
      <Stack
        spacing={3}
        sx={{
          width: "inherit",
          ...mainPaddingStyle,
        }}
      >
        <Typography
          textAlign={"left"}
          variant={pageHeadingVariant}
          fontWeight={"medium"}
        >
          {pageHading}
        </Typography>
        {selectedForm}
      </Stack>
    </Stack>
  );
};

export default Signup;
