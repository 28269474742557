//import { PersonPinCircle, } from '@mui/icons-material'

import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Dialog from "@mui/material/Dialog";
import Signup from "../PCAccount/Signup/Signup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PCMenuButton from "../SupportingViews/PCMenuButton";
import { menuStyle } from "../../utils/styles";
import { PresentationContext } from "../../contexts/PresentationContext";
import { UserAccountContext } from "../../contexts/UserAccountContext";
import { AppDataContext } from "../../contexts/AppDataContext";
import { Outlet, useLocation, Link as RouterLink } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { APIError } from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
//import { AuthUser } from "../../Models/User";
import DCError from "../SupportingViews/DCError";
import { Inventory, ShoppingApp } from "../../routes";
import { appURL } from "../../defaultValues";
import { FormType } from "../Providers/AccountsProvider";

import PCBottomBar from "./PCBottomBar";
import Authentication from "../PCAccount/Authentication/Authentication";
import Box from "@mui/material/Box";
import { useRouteMatch } from "../../hooks";
import { isMobile } from "react-device-detect";
import PCDownloadButton from "../SupportingViews/PCDownloadButton";
import CircularProgress from "@mui/material/CircularProgress";
import { RequestStatus } from "../../Models/Result";
// import { LoadingButton } from "@mui/lab";

const PCShoppingApp = () => {
  const [isDialogPresented, setIsDialogPresented] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [error, setError] = useState<APIError | null>(null);
  const [, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const {
    getSignedInUser: user,
    signoutUser,
    getUserprofile,
    updateCurrentform,
    accountModalIsPresented,
    toggleAccountModal,
    hasSelectedFormWithType,
    //updateSellerStatusForUserId,
    accountRequestStatus,
  } = useContext(UserAccountContext);
  const appData = useContext(AppDataContext);
  const isSmallAndBelow = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );
  const isMediumAndAbove = useMediaQuery((theme: any) =>
    theme.breakpoints.up("md")
  );

  //const isStandaloneMode = (()=> window.matchMedia("display-mode: standalone").matches)()

  // console.log(isStandaloneMode)

  const isShowingLoginForm =
    hasSelectedFormWithType(FormType.Login) ||
    hasSelectedFormWithType(FormType.Signup);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeDialog = (event: object, reason: string) => {
    setIsDialogPresented(false);
  };

  const handleLogout = useCallback(async () => {
    setLoading(true);
    const result = await signoutUser();

    const apiError = result as APIError;
    if (apiError.errorDescription) {
      setError(apiError);
      setLoading(false);
      return;
      // show error
    }

    setAnchorEl(null);
    window.location.href = appURL + location.pathname;
  }, [location.pathname, signoutUser]);

  const open = Boolean(anchorEl);
  const id = open ? "auth-popover" : undefined;

  const paths = [
    { value: "/about", to: "/about", label: "About" },
    { value: "/legal/privacy", to: "/legal/tos", label: "Legal" },
    { value: "/legal/tos", to: "/legal/tos", label: "Legal" },
    { value: "/legal/return", to: "/legal/return", label: "Legal" },
    { value: "/sell", to: "/sell", label: "Sell" },
  ];

  const matchCustomerCareRoute = useRouteMatch(paths.map((path) => path.value));

  //const matchHomeRoute = useRouteMatch(["/home"])
  const accountRequestError = ((): APIError | null => {
    const apiError = accountRequestStatus as APIError;
    return apiError.errorDescription ? apiError : null;
  })();

  const menuItems = (() => {
    const userProfile = getUserprofile();
    let temp: ReactNode[] = [];

    if (user()) {
      temp.push(
        <MenuItem key={"logout"} onClick={handleLogout}>
          Log out
        </MenuItem>
      );
      if (userProfile?.isPartner) {
        temp.push(
          <MenuItem
            key={"sell"}
            onClick={() => appData.gotoSellerApp(Inventory.products)}
          >
            Switch to selling
          </MenuItem>
        );
      }
    } else if (
      matchCustomerCareRoute?.pathname === ShoppingApp.sellerOnBoarding
    ) {
      temp.push(
        <MenuItem
          key={"log in"}
          onClick={() => {
            updateCurrentform(FormType.Login);
            appData.setPreAuthPathname(location.pathname);
            setIsDialogPresented(!isDialogPresented);
          }}
        >
          Log in
        </MenuItem>
      );
    } else {
      temp.push(
        <MenuItem
          key={"log in"}
          onClick={() => {
            updateCurrentform(FormType.Login);
            appData.setPreAuthPathname(location.pathname);
            setIsDialogPresented(!isDialogPresented);
          }}
        >
          Log in
        </MenuItem>,
        <MenuItem
          key={"sign up"}
          onClick={() => {
            updateCurrentform(FormType.Login);
            appData.setPreAuthPathname(location.pathname);
            setIsDialogPresented(!isDialogPresented);
          }}
        >
          Sign up
        </MenuItem>
      );
    }

    return temp;
  })();

  const topAppBar = (() => {
    return (
      (isMediumAndAbove || matchCustomerCareRoute) && (
        <AppBar position="fixed">
          <Toolbar sx={{ width: "100%" }}>
            <Box
              sx={{ textDecoration: "none" }}
              to={ShoppingApp.about}
              component={RouterLink}
              display={"flex"}
              alignItems="center"
              justifyContent={"spaceBetween"}
              flexGrow={1}
            >
              <Box
                sx={{
                  width: { xs: "3rem", sm: "3rem", md: "3rem" },
                  height: { xs: "3rem", sm: "3rem", md: "3rem" },
                  // margin: "0 auto",
                }}
                component={"img"}
                src={`${process.env.PUBLIC_URL}/logo192.png`}
              />
              <Typography
                color={"primary.dark"}
                variant="h6"
                noWrap
                component="div"
              >
                Prettycharm
              </Typography>
            </Box>
            {!isMobile &&
            matchCustomerCareRoute?.pathname ===
              ShoppingApp.sellerOnBoarding ? (
              <div>
                {accountRequestStatus === RequestStatus.Loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <PCMenuButton
                      onClick={handleClick}
                      id={id}
                      userprofile={getUserprofile()}
                    />
                    <Menu
                      sx={menuStyle}
                      id="account-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {menuItems}
                    </Menu>
                  </>
                )}
              </div>
            ) : (
              <PCDownloadButton
                shouldShortenLabel
                width={{ md: isMobile ? "1vw" : "12vw" }}
                height={"1.5rem"}
              />
            )}
          </Toolbar>

          {appData.promoService !== null && (
            <Alert
              variant="filled"
              sx={{
                bgcolor: "secondary.main",
                color: "text.primary",
                width: "100%",
              }}
              severity="info"
            >
              {`${appData.promoService.banner_heading} - ${appData.promoService.expiration_text}`}
              .
            </Alert>
          )}
        </AppBar>
      )
    );
  })();

  useEffect(() => {
    if (isDialogPresented) {
      handleClose();
    }

   // console.dir(matchCustomerCareRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogPresented]);

  useEffect(() => {
    if (isShowingLoginForm) {
      setIsDialogPresented(true);
    }
  }, [isShowingLoginForm]);

  return (
    <Stack
      sx={{
        pb: isMediumAndAbove ? "unset" : "4.5rem",
        marginTop:
          ( appData.promoService !== null &&
          matchCustomerCareRoute?.pathname.includes(ShoppingApp.about) )
            ? "4rem"
            : "unset",
        //paddingBottom: "6rem"
        // height: "calc(100vh + 56px)"
      }}
    >
      {topAppBar}

      <Dialog
        fullScreen={isSmallAndBelow ? true : false}
        onClose={closeDialog}
        open={isDialogPresented}
        sx={{
          ".css-qs0bdh-MuiStack-root": {
            width: "100%",
          },
          ".css-1d9cypr-MuiStack-root": {
            width: "100%",
          },
          "& .MuiPaper-root": {
            borderRadius: {
              xs: "0.5rem",
              md: "1rem",
            },
            margin: {
              md: "2rem",
            },
          },

          ".signup-form": {
            padding: 0,
          },
        }}
      >
        <PresentationContext.Provider
          value={{
            dismiss: () => {
              setIsDialogPresented(false);
              updateCurrentform(null);
            },
            isPresented: isDialogPresented,
          }}
        >
          <Signup />
        </PresentationContext.Provider>
      </Dialog>
      <Dialog
        fullScreen={true}
        onClose={closeDialog}
        open={accountModalIsPresented}
        sx={{
          ".css-qs0bdh-MuiStack-root": {
            width: "100%",
          },
          ".css-1d9cypr-MuiStack-root": {
            width: "100%",
          },
          "& .MuiPaper-root": {
            margin: {
              md: "2rem",
            },
          },
        }}
      >
        <PresentationContext.Provider
          value={{
            dismiss: () => toggleAccountModal(),
            isPresented: accountModalIsPresented,
          }}
        >
          <Authentication />
        </PresentationContext.Provider>
      </Dialog>
      <DCError error={accountRequestError} />
      <DCError error={error} onErrorDisplayed={() => setError(null)} />
      <Outlet />

      {!isMediumAndAbove && matchCustomerCareRoute === null && <PCBottomBar />}
    </Stack>
  );
};

export default PCShoppingApp;
