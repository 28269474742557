import React from 'react'
import { Outlet } from "react-router-dom";


const Discounts = () => {
  return (
    <Outlet />
  )
}

export default Discounts