import { SxProps, Theme } from "@mui/material/styles";

export const confirmBtnStyle: SxProps<Theme> = {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'primary.main',
    borderColor: 'primary.main',
    '&:hover': {
      backgroundColor: 'primary.main'
    },
    // '&:hover': {
    //   backgroundColor: '#0069d9',
    //   borderColor: '#0062cc',
    //   boxShadow: 'none',
    // },
    // '&:active': {
    //   boxShadow: 'none',
    //   backgroundColor: '#0062cc',
    //   borderColor: '#005cbf',
    // },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }

  export const tableCellStyle: SxProps<Theme> = {
    position: "sticky",
    left: "0",
    zIndex: "3",
    background: "white",
    borderRight: "0.20em solid rgba(0, 0, 0, 0.2) !important",
  };

  export const menuStyle: SxProps<Theme> = {
    "& .MuiMenu-paper": {
      width: "12.375rem",
    },
    "& .MuiBackdrop-root": {
      background: "rgba(0, 0, 0, 0)",
    }
  }