import { ArrowForward } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ReactNode, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppDataContext } from "../../../../../contexts/AppDataContext";
import { DataObj } from "../../../../../Models/DataObj";
import { SellerApp, Inventory } from "../../../../../routes";
 
import Paper from "@mui/material/Paper";
import OptionalImage from "../../../../SupportingViews/OptionalImage";
import Popover from "@mui/material/Popover";
import { tableCellStyle } from "../../../../../utils/styles";
import { Product } from "../../../../../Models/Product";

interface ProductRowProps {
  product: Product;
  formatValueOf: (value: number) => string;
  buttons: ReactNode[];
}

const ProductRow = ({ product, formatValueOf, buttons }: ProductRowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const appData = useContext(AppDataContext);
  const navigate = useNavigate();

  const {
    categoryId,
    subcategoryId,
    sizes,
    colors,
    accessories,
    productId,
    description,
    wholesaleCost,
   // sellingPrice,

   // stockQuantity,
   
    title,
    productDetails: variants
  } = product;

  const categoryTitle = appData.getCategoryTitle(categoryId);

  const subcategoryTitle = appData.getSubcategoryTitleOfCategory(
    categoryId,
    subcategoryId
  );

  const sizesListView = optionsListView(sizes, (id): string =>
    appData.sizeTitleForId(id)
  );
  const colorsListView = optionsListView(colors, (id): string =>
    appData.colorTitleForId(id)
  );
  const accessoriesListView = optionsListView(accessories, (id): string =>
    appData.accessoryTitleForId(id)
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const productHeaderImage = (()=>(
    <Stack spacing={2} alignItems="center" direction="row">
    <OptionalImage
      sx={{
        width: "32px",
        height: "32px",
        borderRadius: "4px",
        aspectRatio: "1 / 1",
      }}
      alt={title}
      src={variants && variants.length > 0 ? variants[0].photos.photo1.url : ""}
    />
    <Typography variant="subtitle1">{title}</Typography>
    <IconButton
      onClick={handleClick}
      aria-describedby={productId}
      sx={{
        width: "3rem",
        height: "3rem",
      }}
      aria-label="options"
    >
      <MoreVertIcon />
    </IconButton>
   
  </Stack>
  ))()

  const sellingPrice = (()=>{
    const price = variants && variants.length > 0 ? variants[0].sellingPrice : -1
    return formatValueOf(price)
  })()

  const stockQuantity = (()=>{
    const quantity = variants && variants.length > 0 ? variants[0].stockQuantity : -1
    return formatValueOf(quantity)
  })()

 
  return (
    <TableRow
      hover 
     
      key={productId}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        cursor: "pointer",
      }}
      onClick={() => {
        if (anchorEl) {
          return;
        }
        navigate(`/${SellerApp.pathname}/${Inventory.products}/${productId}`);
      }}
    >
      <Popover
        id={productId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableRestoreFocus
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "16px",
          }}
        >
          {buttons[0]}
          {buttons[1]}
        </Paper>
      </Popover>
      <TableCell sx={tableCellStyle} component="th" scope="row">
        {productHeaderImage}
      </TableCell>
      <TableCell align="left">{stockQuantity}</TableCell>
      <TableCell sx={{ textTransform: "none" }} align="left">
        {description}
      </TableCell>
      <TableCell sx={{ textTransform: "capitalize" }} align="left">
        {categoryTitle}
      </TableCell>
      <TableCell sx={{ textTransform: "capitalize" }} align="left">
        {subcategoryTitle}
      </TableCell>
      <TableCell align="left">{formatValueOf(wholesaleCost)}</TableCell>
      <TableCell align="left">{sellingPrice}</TableCell>
      <TableCell sx={{ textTransform: "capitalize" }} align="left">
        {colorsListView}
      </TableCell>
      <TableCell sx={{ textTransform: "uppercase" }} align="left">
        {sizesListView}
      </TableCell>
      <TableCell sx={{ textTransform: "capitalize" }} align="left">
        {accessoriesListView}
      </TableCell>
      <TableCell align="left">
        {<GotoDetailBtn productId={productId} />}
      </TableCell>
    </TableRow>
  );
};

function optionsListView(
  options: DataObj,
  title: (id: string) => string
): ReactNode {
  const views = Object.entries(options).map(([key, id]) =>
    id ? (
      <Chip
        key={id}
        color="primary"
        label={title(id)}
        sx={(theme) => {
          return {
            "& .MuiChip-label": {
              color: theme.palette.primary.dark,
              fontWeight: 500,
            },
          };
        }}
      />
    ) : null
  );

  return <Stack spacing={1}>{views}</Stack>;
}

interface GotoDetailBtnProps {
  productId: string;
}

const GotoDetailBtn = (props: GotoDetailBtnProps) => {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => {
        navigate(`/seller+app/inventory/products/${props.productId}`);
      }}
      aria-label="update product"
      title="Update Product"
      component="span"
      size="small"
      sx={(theme) => {
        return {
          background: theme.palette.text.primary,
          color: theme.palette.common.white,
          "&:hover": {
            background: theme.palette.primary.main,
          },
        };
      }}
    >
      <ArrowForward fontSize="medium" />
    </IconButton>
  );
};

export default ProductRow;
